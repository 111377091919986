import React, {Context, useState} from 'react';

export interface HomeContextProps {
  page: string;
  setPage: (page: string) => void;
}

export const HomeContext: Context<HomeContextProps> = React.createContext(null);

export const HomeProvider: React.FC = ({children}) => {

  const [page, setPage] = useState('Main');
  // const [page, setPage] = useState('UseCases');

  // const setPageHandler = (page: string) => {
  //   if (!page) return;
  //   setPage(page);
  // };

  return (
    <HomeContext.Provider value={{
      page: page,
      setPage: setPage
    }}>
      {children}
    </HomeContext.Provider>
  );
};
