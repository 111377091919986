import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {RouteComponentProps} from '@reach/router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Emit} from './Emit';
import {Burn} from './Burn';
import {PageWrap} from '../components/common/Pages';
import {AuthContext} from "../../../core/providers/AuthProvider";
import Typography from "@material-ui/core/Typography";

export const LoadWrap = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const AlertText = styled(Typography)`
	color: ${props => props.theme.custom.palette.alert};
	// color: #CD1225;
	// font-weight: 500;
`;

export const Token: React.FC<RouteComponentProps> = () => {

	const authContext = useContext(AuthContext);
	const [selectedTab, setSelectedTab] = useState(0);

	// useEffect(() => {
	// 	console.log('>>>', authContext.user);
	// }, [authContext.user]);

	useEffect(() => {
		if (authContext.company === null) {
			authContext.getSettings();
			return;
		}
	}, [authContext.company]); // eslint-disable-line react-hooks/exhaustive-deps

	// interface TabPanelProps {
	// 	children?: React.ReactNode;
	// 	index: any;
	// 	value: any;
	// }
	// function TabPanel(props: TabPanelProps) {
	// 	const { children, value, index} = props;
	//
	// 	return (
	// 		value === index && <div style={{padding: '16px 0 0 0', height: '100%'}}>{children}</div>
	// 	);
	// }

	if (!authContext.user.is2faEnabled) {
		return (
			<PageWrap>
				<AlertText variant="h4">Please enable Two-factor authentication (2fa) for continue</AlertText>
			</PageWrap>
		)
	}

	return (
		<PageWrap>
			<Tabs
				value={selectedTab}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				onChange={(event: React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue)}
			>
				<Tab label="Emit"/>
				<Tab label="Burn"/>
			</Tabs>
			{/*<TabPanel value={selectedTab} index={0}>*/}
			{/*	<Emit/>*/}
			{/*</TabPanel>*/}
			{/*<TabPanel value={selectedTab} index={1}>*/}
			{/*	<Burn/>*/}
			{/*</TabPanel>*/}
			<div style={{padding: '16px 0 0 0', height: '100%'}}>
				{selectedTab === 0 && <Emit/>}
				{selectedTab === 1 && <Burn/>}
			</div>
		</PageWrap>
	)
};
