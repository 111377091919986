import styled from "styled-components";

export const PageWrap = styled.div`
  padding: 50px 100px;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: #f9f9f9;
  @media (max-width: 1280px) {
    padding: 24px 16px;
  }
`;
