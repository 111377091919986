import React from "react";
import { HomeContext } from "../HomeProvider";
import { MainMenu } from "./MainMenu";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

class Team extends React.Component {
  static contextType = HomeContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
    };
  }

  componentDidMount() {
    // document.body.classList.add('admin-page');
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  render() {
    return (
      <>
        <header>
          <MainMenu />

          <div
            className="view full-page-intro"
            style={{
              background:
                "url(/img/useCases-background.png) no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            <div className="mask d-flex justify-content-center align-items-center">
              <div className="container">
                <div
                  className="row wow fadeIn"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  <div className="col-lg-5 mb-4 white-text  text-md-left d-flex align-items-center">
                    <div className="text-center text-lg-left">
                      <h1 className="team-main text-uppercase">{t('team.team_title')}</h1>
                      <p className="mb-lg-4 d-md-block team-text-under-main mt-lg-5">
                        {t('team.team_subtitle')}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 mb-4 mt-5 d-none d-sm-block">
                    <img
                      src="/img/team-main.png"
                      className="img-fluid home-first-img team-img mt-lg-5 ml-lg-1 mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="text-center mt-3">
          <div className="container">
            <section
              className="mt-5 mb-5 wow fadeIn"
              style={{ visibility: "visible", animationName: "fadeIn" }}
            >
              <div className="row">
                <div className="visElem col-md-6 col-lg-4 mb-4 d-flex align-items-center slideInUp">
                  <div className="text-left">
                    <h2 className="h3 mb-3 sub-main">{t('team.Crypto_title')}</h2>
                    <p className="sub-text">
                      {t('team.Crypto_description')}
                      <br />
                      <br />
                      {t('team.Crypto_description_br')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 offset-lg-1 mb-4">
                  <img
                    src="/img/team-crypto.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>

          <section style={{ backgroundColor: "#eef8fb" }}>
            <div className="container">
              <div
                className="pt-5 pb-5 row wow fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div className="col-lg-6 col-md-6 mb-4 order-md-1 order-2">
                  <img
                    src="/img/team-finance.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="visElem col-lg-6 col-md-6 px-4 d-flex align-items-center order-md-2 order-1 slideInUp">
                  <div className="text-left">
                    <h2 className="h3 mb-3 sub-main">
                      {t('team.Finance_investment_title')}
                    </h2>
                    <p className="sub-text">
                      {t('team.Finance_investment_description')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <section
              className="mt-5 mb-5 wow fadeIn"
              style={{ visibility: "visible", animationName: "fadeIn" }}
            >
              <div className="row">
                <div className="visElem col-md-6 mb-4 d-flex align-items-center slideInUp">
                  <div className="text-left">
                    <h2 className="h3 mb-3 sub-main">{t('team.IT_title')}</h2>
                    <p className="sub-text">
                      {t('team.IT_description')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <img src="/img/team-it.png" className="img-fluid" alt="" />
                </div>
              </div>
            </section>
          </div>

          <section style={{ backgroundColor: "#eef8fb" }}>
            <div className="container">
              <div
                className="pt-5 pb-5 row wow fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div className="col-lg-6 col-md-6 mb-4 order-md-1 order-2">
                  <img
                    src="/img/team-regulatory.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="visElem col-lg-6 col-md-6 px-4 d-flex align-items-center order-md-2 order-1 slideInUp">
                  <div className="text-left">
                    <h2 className="h3 mb-3 sub-main">{t('team.Regulatory_title')}</h2>
                    <p className="sub-text">
                      {t('team.Regulatory_description')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="team-cards pt-5 pb-5">
            {/* <div className="container">
              <div className="team-cards_row d-flex flex-wrap">
                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/eduard-antonovich-24143273/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"/>
                    <img src="/img/team1.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Eduard Antonovich</h3>
                  <p className="team-cards__boss">CEO</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/dilkash-bhagat-61578218a/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team2.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Dilkash Bhagat</h3>
                  <p className="team-cards__boss">CFO</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.phosagro-sychev.com/About-Igor-Sychev/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team3.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3"> Igor Sychev</h3>
                  <p className="team-cards__boss">CLO</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/ivan-troshin-a77b2a18a/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team4.jpg" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Ivan Troshin</h3>
                  <p className="team-cards__boss">CTO</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/alex-kirpichonak-9569b718a/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/></a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team5.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Alex Kirpichonak</h3>
                  <p className="team-cards__boss">Business Relationship</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/maxim-kovalev-developer/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team6.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Maxim Kovalev</h3>
                  <p className="team-cards__boss">Technical solution architect</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/ksenia-plehanova-57874a18a/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team7.jpg" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Ksenia Plehanova</h3>
                  <p className="team-cards__boss">PR and corporate communications</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/alexanderreay/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team8.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Alexander H Reay</h3>
                  <p className="team-cards__boss">Trusted OTC Trader</p>
                </div>

                <div
                  className="team-card col-md-6 col-lg-4 d-flex justify-content-center flex-column position-relative align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/igor-verbovik-7b208418a/">
                    <img src="/img/linkedin.png" alt="" className="team-card__in"/>
                  </a>
                  <div className="team-card__img_container position-relative">
                    <div className="team-card__img_container_mask"></div>
                    <img src="/img/team9.png" alt="" className="team-card__img"/>
                  </div>
                  <h3 className="team-card__name mt-3">Igor Verbovik</h3>
                  <p className="team-cards__boss">KYC/AML Officer</p>
                </div>
              </div>
            </div> */}
          </section>
        </main>
      </>
    );
  }
}

export default withTranslation()(Team)
