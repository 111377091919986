import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MuiCard from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardContent from "@material-ui/core/CardContent";
import { Hidden } from "@material-ui/core";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import Pagination from "@material-ui/lab/Pagination";
import { formatDate } from "../../private/helpers/Format";
import { BlogCard } from "./BlogCard";

const ITEM_PER_PAGE: number = 7;

const Component = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GridWrap = styled(Grid)`
  padding: 48px 32px;
  max-width: 1110px;
`;

const Card = styled(MuiCard)`
  box-shadow: none;
  border-radius: 0;

  img {
    width: 100%;
    max-width: 680px;
  }
`;

const CardActionArea = styled(MuiCardActionArea)``;

const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0 0;
`;

const gqlGetBlogPosts = gql`
  query getBlogPosts($first: Int, $skip: Int) {
    getBlogPosts(first: $first, orderBy: "created", desc: true, skip: $skip) {
      count
      posts {
        postId
        status
        created
        published
        title
        shortText
        text
        fileUrl
        files {
          fileId
          fileName
          url
        }
      }
    }
  }
`;

export interface ComponentProps {}

export const BlogList: React.FC<ComponentProps> = () => {
  const [item, setItem] = useState<any>(null);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(0);

  const { loading, error, data, refetch } = useQuery(gqlGetBlogPosts, {
    variables: {
      first: ITEM_PER_PAGE,
      skip: (page - 1) * ITEM_PER_PAGE,
    },
  });

  useEffect(() => {
    if (error) console.warn("Users useEffect error:", error);
  }, [error]);

  useEffect(() => {
    if (!loading && data && data.getBlogPosts && data.getBlogPosts.count) {
      const totalCnt = data.getBlogPosts.count;
      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      if (data && data.getBlogPosts.posts) {
        setItems(
          data.getBlogPosts.posts
            .map((el: any) => {
              return {
                ...el,
                date: formatDate(el.published),
              };
            })
            .filter((el: any) => {
              if (el.status === "Enabled") return true;
              return false;
            })
        );
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
  };

  const cardClickHandler = (item: any) => {
    setItem(item);
  };

  if (item) return <BlogCard item={item} onClose={() => setItem(null)} />;

  if (items && items.length > 0)
    return (
      <Component>
        <GridWrap container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <CardActionArea onClick={() => cardClickHandler(items[0])}>
                <CardContent>
                  <Grid container>
                    <Grid item sm={12} md={"auto"}>
                      {items[0].files &&
                        items[0].files[0] &&
                        items[0].files[0].url && (
                          <img src={items[0].files[0].url} alt="" />
                        )}
                    </Grid>
                    <Grid item xs={"auto"} style={{ display: "flex" }}>
                      <Hidden smDown>
                        <div style={{ width: "24px", height: "16px" }} />
                      </Hidden>
                      <CardBody>
                        <Typography variant="h2" component="h2">
                          {items[0].title}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="p"
                        >
                          {items[0].shortText}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="p"
                        >
                          {items[0].date}
                        </Typography>
                      </CardBody>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {items
            .filter((el, i) => i !== 0)
            .map((el) => (
              <Grid item xs={12} sm={6} md={4} key={el.postId}>
                <Card>
                  <CardActionArea onClick={() => cardClickHandler(el)}>
                    {el.files && el.files[0] && el.files[0].url && (
                      <img src={el.files[0].url} alt="" />
                    )}
                    <CardContent>
                      <CardBody>
                        <Typography variant="h2" component="h2">
                          {el.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {el.shortText}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {el.date}
                        </Typography>
                      </CardBody>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </GridWrap>

        {pageCnt > 1 ? (
          <PaginWrap>
            <Pagination
              // size="small"
              count={pageCnt}
              page={page}
              onChange={onPageChangeHandler}
            />
          </PaginWrap>
        ) : null}
      </Component>
    );

  return null;
};
