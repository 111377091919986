import React, {useState} from 'react';
import styled from 'styled-components';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";

const Row = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const DeclineButton = styled(Button)`
	height: 100%;
	background-color: ${props => props.theme.custom.palette.alert};
	color: #FFFFFF;
  
  &:hover {
  	opacity: 0.87;
  	background-color: ${props => props.theme.custom.palette.alert};
  }
`;

const CancelButton = styled(Button)`
  height: 100%;    
  margin-left: 16px;
`;

interface ControlProps {
	onClose: (comment: string) => void;
	inProcess?: boolean;
}

export const Decline: React.FC<ControlProps> = (props) => {

	const [msg, setMsg] = useState('');

	return (
		<>
			<Row>
				<Typography variant={"h3"}>
					KYC Request info
				</Typography>
			</Row>

			<Row>
				<TextField
					// error={}
					// helperText={errors.address}
					variant='outlined'
					fullWidth
					required
					label='Decline message for user'
					value={msg}
					onChange={e => setMsg(e.target.value)}
				/>
			</Row>

			<Row>
				<DeclineButton
					variant="contained"
					color="primary"
					size={"large"}
					onClick={() => props.onClose(msg)}
					disabled={!msg || props.inProcess}
				>
					Decline
				</DeclineButton>

				<CancelButton
					variant="outlined"
					size={"large"}
					onClick={() => props.onClose(null)}
					disabled={props.inProcess}
				>
					Back
				</CancelButton>
			</Row>
		</>
	);

};
