import React, { useContext, useState } from "react";
import styled from "styled-components";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as GoogleIcon } from "./assets/ga-google-play.svg";
import { ReactComponent as AppStoreIcon } from "./assets/ga-app-store.svg";
import TextField from "@material-ui/core/TextField";
import { useMutation } from "@apollo/react-hooks";
import { LoginResult } from "../../../../store/generated-models";
import { gql } from "@apollo/client";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { useSnackbar } from "notistack";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { copyTextToClipboard } from "../../../../core/helpers/Clipboard";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const GA_APP_STORE_URL =
  "https://apps.apple.com/us/app/google-authenticator/id388497605";
const GA_GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en";

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const RowML = styled(Row as any)`
  flex-direction: column;
`;

// const Accent = styled.span`
//   color: ${(props) => props.theme.palette.primary.main};
//   font-weight: bold;
// `;

const CopyButton = styled(IconButton)`
  margin: 0 0 0 8px;
  background-color: #0096c3;
  color: white;
  height: 100%;
  width: auto;
  :hover {
    background-color: #087ca7;
  }
`;

const Alert = styled.span`
  color: ${(props) => props.theme.custom.palette.alert};
`;

const QrText = styled.div`
  width: 270px;
  height: 270px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const QrTextLine = styled.div`
  font-family: monospace, Courier, Monaco;
  letter-spacing: 0;
  white-space: pre;
  font-size: 10px;
  line-height: 11px;
`;

const gqlEnable2fa = gql`
  mutation Enable2fa($code: String!, $password: String!) {
    enable2fa(code: $code, password: $password) {
      user {
        userId
        is2faEnabled
      }
    }
  }
`;

export interface ComponentProps {
  qrCode: string;
  qrString: string;
  onCancel: () => void;
}

const Step2faEnable: React.FC<ComponentProps> = ({
  qrCode,
  qrString,
  onCancel,
}) => {
  const authContext = useContext(AuthContext);
  const [enable2fa] = useMutation<{ enable2fa: LoginResult }>(gqlEnable2fa);
  const [authCode, setAuthCode] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = (text: string, message?: string) => {
    try {
      copyTextToClipboard(text).then(() => {
        enqueueSnackbar(message ? message : "address copied");
      });
    } catch (ignored) {}
  };

  const setEnable2fa = async () => {
    let errMessage: string = "";
    try {
      const res = await enable2fa({
        variables: {
          code: authCode,
          password: "ignored",
        },
      });

      if (res.data.enable2fa.user.is2faEnabled) {
        authContext.updateUser({ is2faEnabled: true });
        enqueueSnackbar(t('user.settings.2fa_enable'), { variant: "success" });
        return;
      } else {
        errMessage = t('user.settings.disabled_2FA_err_message');
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  return (
    <>
      <Row style={{ marginBottom: "8px" }}>
        <Typography variant={"body1"}>
          {t('user.settings.download')}
        </Typography>
      </Row>
      <Row>
        <Link
          href={GA_APP_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          style={{ marginRight: "20px" }}
        >
          <AppStoreIcon style={{ maxHeight: "50px", height: "12vw" }} />
        </Link>
        <Link
          href={GA_GOOGLE_PLAY_URL}
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          <GoogleIcon style={{ maxHeight: "50px", height: "12vw" }} />
        </Link>
      </Row>
      <Row style={{ marginBottom: "8px" }}>
        <Typography variant={"body1"}>
          {t('user.settings.download_qr')}
        </Typography>
      </Row>
      <Row>
        <QrText>
          {qrString.split("\n").map((el, i) => (
            <QrTextLine key={i}>{el}</QrTextLine>
          ))}
        </QrText>
      </Row>
      <RowML>
        <Typography variant={"body1"}>
          {t('user.settings.code_title')}
        </Typography>
        <Row
          style={{
            marginTop: "8px",
            fontWeight: 500,
            wordBreak: "break-all",
            backgroundColor: "white",
            width: "fit-content",
          }}
        >
          <div style={{ padding: "15px" }}>{qrCode}</div>
          <CopyButton
            style={{ borderRadius: "5px" }}
            onClick={() => {
              copyToClipboard(qrCode);
            }}
            edge="end"
          >
            <FileCopyIcon />
          </CopyButton>
        </Row>
        <Typography variant={"body1"}>
          <Alert>{t('user.settings.key_title')}</Alert>
        </Typography>
        <Typography variant={"body1"}>
          {t('user.settings.key_desc')}
        </Typography>
      </RowML>
      <Row style={{ marginBottom: "12px" }}>
        <Typography variant={"body1"}>
          {t('user.settings.key_desc_2')}
        </Typography>
      </Row>
      <Row>
        <StyledTextField
          style={{ width: "240px" }}
          variant="outlined"
          label={t('user.settings.placeholder')}
          value={authCode}
          onChange={(e) => setAuthCode(e.target.value)}
        />
      </Row>
      <Row>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          disabled={!authCode}
          style={{ width: "240px", minHeight: "50px" }}
          onClick={setEnable2fa}
        >
          {t('user.settings.enable_btn')}
        </Button>
        <Button
          style={{ width: "120px", marginLeft: "20px", minHeight: "50px" }}
          variant="outlined"
          color="primary"
          size={"large"}
          onClick={() => onCancel()}
        >
          {t('user.settings.cancel')}
        </Button>
      </Row>
    </>
  );
};

export default withTranslation()(Step2faEnable)
