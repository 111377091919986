import React, { useContext, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import { MutationChangePasswordArgs } from "../../../../store/generated-models";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import Typography from "@material-ui/core/Typography";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const Row = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const initFields = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  code2fa: "",
};

const gqlChangePassword = gql`
  mutation changePassword(
    $code2fa: String
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      code2fa: $code2fa
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;

export const ChangePassword: React.FC<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  const showSnackbarErr = (message: string, opt: any = {}) => {
    enqueueSnackbar(message, { ...{ variant: "error" }, ...opt });
  };

  const [changePassword] =
    useMutation<{ changePassword: boolean }, MutationChangePasswordArgs>(
      gqlChangePassword
    );

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});

  const changePasswordClickHandler = () => {
    let err: any = {};
    if (!fields.oldPassword) err.oldPassword = "required";
    if (!fields.newPassword) err.newPassword = "required";
    if (!fields.confirmPassword) err.confirmPassword = "required";
    if (authContext.user.is2faEnabled && !fields.code2fa)
      err.code2fa = "required";

    if (
      !/(?=.*[0-9])(?=.*[!@#$%^&*()_+}{":;?./><,])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+}{":;?./><,]{6,}/g.test(
        fields.newPassword
      )
    ) {
      err.newPassword = t('user.settings.incorrect_password');
      showSnackbarErr(
          t('user.settings.err_pass'),
        { autoHideDuration: 5000 }
      );
      return;
    }

    if (fields.newPassword && fields.newPassword !== fields.confirmPassword)
      err.confirmPassword = t('user.settings.err_1');

    if (fields.newPassword && fields.newPassword === fields.oldPassword)
      err.newPassword = t('user.settings.err_2');

    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar(t('user.settings.err_3'), {
        variant: "default",
      });
      return;
    }

    processChangePassword();
  };

  const processChangePassword = () => {
    changePassword({
      variables: {
        oldPassword: fields.oldPassword,
        newPassword: fields.newPassword,
        code2fa: fields.code2fa ? fields.code2fa : null,
      },
    })
      .then((res) => {
        if (res && res.data && res.data.changePassword) {
          setFields({ ...initFields });
          setErrors({ ...initFields });
          enqueueSnackbar(t('user.settings.err_4'), { variant: "success" });
        } else {
          showSnackbarErr(t('user.settings.err_5'));
        }
      })
      .catch((error: any) => {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.password_invalid") {
          showSnackbarErr(t('user.settings.err_6'));
        } else if (errorCode === "auth.access_denied") {
          showSnackbarErr(t('user.settings.err_7'));
        } else if (errorCode === "auth.two_factor_code_invalid") {
          showSnackbarErr(t('user.settings.err_8'));
        } else {
          showSnackbarErr(t('user.settings.err_9'));
        }
      });
  };

  if (authContext.user.hasEmailAuth) {
    return (
      <>
        <Grid container spacing={3} style={{ marginTop: "4px" }}>
          <Grid item xs={12} md={6}>
            <StyledTextField
              fullWidth
              type={"password"}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword}
              variant="outlined"
              label={t('user.settings.curr_pass')}
              value={fields.oldPassword}
              onChange={(e) =>
                setFields({ ...fields, oldPassword: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={6}>
            <StyledTextField
              fullWidth
              type={"password"}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
              variant="outlined"
              label={t('user.settings.new_pass')}
              value={fields.newPassword}
              onChange={(e) =>
                setFields({ ...fields, newPassword: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={6}>
            <StyledTextField
              fullWidth
              type={"password"}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              variant="outlined"
              label={t('user.settings.confirm_pass')}
              value={fields.confirmPassword}
              onChange={(e) =>
                setFields({ ...fields, confirmPassword: e.target.value })
              }
            />
          </Grid>
          {authContext.user.is2faEnabled ? (
            <>
              <Grid item xs={12} style={{ padding: 0 }} />
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  error={!!errors.code2fa}
                  helperText={errors.code2fa}
                  variant="outlined"
                  label={t('user.settings.code_2fa')}
                  value={fields.code2fa}
                  onChange={(e) =>
                    setFields({ ...fields, code2fa: e.target.value })
                  }
                />
              </Grid>
            </>
          ) : null}
        </Grid>

        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            color="primary"
            size={"large"}
            style={{ minWidth: "240px", minHeight: "50px" }}
            onClick={changePasswordClickHandler}
          >
            {t('user.settings.change_pass_btn')}
          </Button>
        </Row>
      </>
    );
  } else {
    return (
      <Typography variant={"body1"}>
        {t('user.settings.change_pass_title')}
      </Typography>
    );
  }
};

export default withTranslation()(ChangePassword)
