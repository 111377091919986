import React from "react";
import {HomeContext} from "../HomeProvider";
import {MainMenu} from "./MainMenu";
import {withTranslation} from "react-i18next";
import {t} from "i18next";
import {navigate} from "@reach/router";

class UseCases extends React.Component {
    static contextType = HomeContext;
    context;

    // constructor(props) {
    //   super(props);
    // }

    render() {
        return (
            <>
                <header>
                    <MainMenu/>

                    <div
                        className="view full-page-intro"
                        style={{
                            background:
                                "url(/img/useCases-background.png) no-repeat center center",
                            backgroundSize: "cover",
                        }}
                    >
                        <div className="mask d-flex justify-content-center align-items-center">
                            <div className="container">
                                <div
                                    className="row wow fadeIn"
                                    style={{visibility: "visible", animationName: "fadeIn"}}
                                >
                                    <div className="col-lg-6 col-xl-5 mt-sm-5 mt-lg-0 d-none d-md-flex">
                                        <img
                                            src="/img/home-img.png"
                                            className="img-fluid home-first-img use-cases-img-top mx-auto align-self-center mt-lg-5"
                                            alt="cryptocurrency exchanges"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-4 white-text  d-flex align-items-center ">
                                        <div>
                                            <h1 className="display-4 use-cases-main mb-4 text-uppercase">
                                                {t('useCases.use_cases_header')}
                                            </h1>
                                            <p className="mb-0 d-md-block use-cases-text mt-sm-4">
                                                {t('useCases.use_cases_header_description_top')}
                                            </p>
                                            <p className="mb-0 d-md-block use-cases-text mt-sm-4">
                                                {t('useCases.use_cases_header_description_bottom')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div id="cross-border-anchor"/>

                <main className="text-center ">

                    <section>
                        <div className="container">

                            <div className="use-case-dedicated-title">
                                Dedicated vIBANs providing
                            </div>
                            <div className="d-flex flex-column use-cases-text-providing" style={{textAlign: "justify"}}>
                                <p>Oero provides an opportunity for our clients to open a dedicated
                                    IBAN in the UK bank ClearJunction Ltd.</p>
                                <p>This service is available to individuals and legal entities.</p>
                                <p>The registration procedure takes 1-3 weeks, the list of necessary documents and
                                    details may vary depending on the type of activity and the size of the company,
                                    as well as on the number of beneficiaries.</p>
                                <p>The cost of the service depends on the client's risk rank and includes a
                                    compliance fee for checking procedure (regardless of whether the IBAN is
                                    approved or not) and a final fee for providing a dedicated IBAN.</p>
                            </div>
                            <div className="use-case-dedicated-title-fees">
                              Dedicated vIBANs providing fees
                            </div>

                            <section className="fees-card-block">
                            <div className="fees-card">
                              <div className="fees-card-title">
                                Low Risk
                              </div>
                              <div className="fees-card-subtitle">
                                Simplified due Diligence
                              </div>
                              <div className="fees-card-text">
                                Simplified due diligence is the lowest level of verification. We use Simplified due
                                Diligence when there is little chance or risk that customers engage in money
                                laundering or terrorist financing.
                              </div>
                              <div className="fees-line"></div>
                              <div className="d-flex justify-content-between"
                                   style={{width: "100%", marginBottom: "30px"}}>
                                <div>
                                  <div className="fees-iban">IBAN provision fee</div>
                                  <div className="fees-price-iban">€250</div>
                                </div>
                                <div>
                                  <div className="fees-compliance">Compliance fee</div>
                                  <div className="fees-price-compliance">€300</div>
                                </div>
                              </div>
                              <div>
                                <button className="my-btn" onClick={() => navigate("/home/risk-rate")}>learn more</button>
                              </div>
                            </div>
                            <div className="fees-card">
                              <div className="fees-card-title">
                                Medium Risk
                              </div>
                              <div className="fees-card-subtitle">
                                Customer due Diligence
                              </div>
                              <div className="fees-card-text">
                                Customer due diligence is a kind of basic analysis of the client. The company
                                verifies a customer’s identity to a sufficient level of confidence.
                              </div>
                              <div className="fees-line"></div>
                              <div className="d-flex justify-content-between"
                                   style={{width: "100%", marginBottom: "30px"}}>
                                <div>
                                  <div className="fees-iban">IBAN provision fee</div>
                                  <div className="fees-price-iban">€500</div>
                                </div>
                                <div>
                                  <div className="fees-compliance">Compliance fee</div>
                                  <div className="fees-price-compliance">€300</div>
                                </div>
                              </div>
                              <div>
                                <button className="my-btn" onClick={() => navigate("/home/risk-rate")}>learn more</button>
                              </div>
                            </div>
                            <div className="fees-card">
                              <div className="fees-card-title">
                                High Risk
                              </div>
                              <div className="fees-card-subtitle">
                                Enhanced due Diligence
                              </div>
                              <div className="fees-card-text">
                                Enhanced due Diligence means increased control over customers who are more likely to
                                participate in financial crimes due to their nature of business or operations.
                              </div>
                              <div className="fees-line"></div>
                              <div className="d-flex justify-content-between"
                                   style={{width: "100%", marginBottom: "30px"}}>
                                <div>
                                  <div className="fees-iban">IBAN provision fee</div>
                                  <div className="fees-price-iban">€750</div>
                                </div>
                                <div>
                                  <div className="fees-compliance">Compliance fee</div>
                                  <div className="fees-price-compliance">€300</div>
                                </div>
                              </div>
                              <div>
                                <button className="my-btn" onClick={() => navigate("/home/risk-rate")}>learn more</button>
                              </div>
                            </div>
                          </section>

                            <div
                                className="pt-5 pb-5 row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div className="col-md-6 d-flex align-items-center">
                                    <img
                                        src="/img/useCases-volatility.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="visElem col-md-6 px-4 d-flex align-items-center">
                                    <div className="text-left">
                                        <h2 className="mb-3 use-cases-h3-main">{t('useCases.fiat_channels_title')}</h2>
                                        <p className="use-cases-text">
                                            {t('useCases.fiat_channels_description_1')}
                                        </p>
                                        <p className="use-cases-text">
                                            {t('useCases.fiat_channels_description_2')}
                                        </p>
                                        <p className="use-cases-text">
                                            {t('useCases.fiat_channels_description_3')}
                                        </p>
                                        <p className="use-cases-text">
                                            {t('useCases.fiat_channels_description_4')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="container">
                        <section
                            className="wow fadeIn"
                            style={{visibility: "visible", animationName: "fadeIn"}}
                        >
                            <div className="row">
                                <div className="visElem col-lg-6 col-md-6 px-4 d-flex align-items-center">
                                    <div className="text-left">
                                        <h2 className="mb-3 use-cases-h3-main">
                                            {t('useCases.method_transfer_title')}
                                        </h2>
                                        <p className="use-cases-text">
                                            {t('useCases.method_transfer_description')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-4 order-md-1 order-2">
                                    {/*<img src="/img/useCases-transactions.png" className="img-fluid" alt=""/>*/}
                                    <img
                                        src={"/img/useCases-transactions.png"}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </section>
                    </div>

                    <div id="Hedge-against-volatility"></div>

                    <section style={{backgroundColor: "#eef8"}}>
                        <div className="container">
                            <div
                                className="pt-5 pb-5 row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div className="col-md-6">
                                    <img
                                        src="/img/useCases-volatility.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="visElem col-md-6 px-4 d-flex align-items-center">
                                    <div className="text-left">
                                        <h2 className="mb-3 use-cases-h3-main">{t('useCases.method_storage_title')}</h2>
                                        <p className="use-cases-text">
                                            {t('useCases.method_storage_description')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div id="exchange-method"></div>

                    <section>
                        <div className="container">
                            <div
                                className="pt-5 pb-5 row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div className="visElem col-md-6 px-4 d-flex align-items-center">
                                    <div className="text-left">
                                        <h2 className="mb-3 use-cases-h3-main">{t('useCases.method_exchange_title')}</h2>
                                        <p className="use-cases-text">
                                            {t('useCases.method_exchange_description')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img
                                        src="/img/exchange-use-cases.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <div id="testimonials"/>

                    <section
                        id="acess-to-reliable-currency"
                        className="text-center py-5"
                        style={{backgroundColor: "#eef8"}}
                        // style={{
                        //   background: 'url(/img/useCases-background.png) no-repeat center center',
                        //   backgroundSize: 'cover'
                        // }}
                    >
                        <div className="container">
                            <div
                                className="row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div className="col-lg-6 col-md-6 mb-4 order-md-1 order-2">
                                    <img
                                        src="/img/useCases-access.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 px-4 d-flex align-items-center order-md-2 order-1">
                                    <div className="visElem text-left">
                                        <h2 className="mb-3 use-cases-h3-main ">{t('useCases.method_payment_title')}</h2>
                                        <p className="mb-5 use-cases-text">
                                            {t('useCases.method_payment_description')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div id="oero-staking"></div>

                    <section style={{paddingBottom: "20px"}}>
                        <div className="container">
                            <div
                                className="pt-5 row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div className="visElem col-md-6 px-4 d-flex align-items-center">
                                    <div className="text-left">
                                        <h2 className="mb-3 use-cases-h3-main">{t('useCases.oero_staking')}</h2>
                                        <p className="use-cases-text">
                                            {t('useCases.oero_staking_description_first')}
                                        </p>
                                        <p className="use-cases-text">
                                            {t('useCases.oero_staking_description_second')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img
                                        src="/img/useCases-oero-staking.png"
                                        className="img-fluid"
                                        style={{marginTop: "45px"}}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                className="px-4 row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <p className="use-cases-text text-left">
                                    {t('useCases.oero_staking_description_third')}
                                </p>
                                <p className="use-cases-text text-left">
                                    {t('useCases.oero_staking_description_fourth')}
                                </p>
                            </div>
                            <div className="col-md-12 mb-4">
                                <table className="table-fees table-staking">
                                    <thead>
                                    <tr>
                                        <th>{t('useCases.oero_staking_table_one')}</th>
                                        <th>{t('useCases.oero_staking_table_second')}</th>
                                        <th>{t('useCases.oero_staking_table_third')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th>&lt; 10 000</th>
                                        <td>1.5%</td>
                                        <td>+1% (Total: 2.5%)</td>
                                    </tr>
                                    <tr>
                                        <th>10 000 - 100 000</th>
                                        <td>1.75%</td>
                                        <td>+1% (Total: 2.75%)</td>
                                    </tr>
                                    <tr>
                                        <th>100 000 - 1 000 000</th>
                                        <td>2%</td>
                                        <td>+1% (Total: 3%)</td>
                                    </tr>
                                    <tr>
                                        <th>1 000 000 - 10 000 000</th>
                                        <td>2.25%</td>
                                        <td>+1% (Total: 3.25%)</td>
                                    </tr>
                                    <tr>
                                        <th>10 000 000 - 50 000 000</th>
                                        <td>2.5%</td>
                                        <td>+1% (Total: 3.5%)</td>
                                    </tr>
                                    <tr>
                                        <th>50 000 000 - 100 000 000</th>
                                        <td>2.75%</td>
                                        <td>+1% (Total: 3.75%)</td>
                                    </tr>
                                    <tr>
                                        <th>&gt; 100 000 000</th>
                                        <td>3%</td>
                                        <td>+1% (Total: 4%)</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default withTranslation()(UseCases)
