import React from 'react';
import styled from 'styled-components';
// import {HomeContext} from '../HomeProvider';
// import {Link as MuiLink} from "@reach/router";
import {MainMenu} from './MainMenu';

// import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
//
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LegalContent from './LegalContent';
import {withTranslation} from "react-i18next";
// import Api from "./Api";
import {t} from "i18next";



class Legal extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			expanded: -1
		};

		this._handleChange = this._handleChange.bind(this);
	}

	componentDidMount() {
		// document.body.classList.add('admin-page');
	}

	componentWillUnmount() {
		// document.body.classList.remove('admin-page');
	}

	_handleChange = (panel) => (event, isExpanded) => {
		this.setState({expanded: isExpanded ? panel : false})
	};

	render() {

		return (
			<>
				<header>
					<MainMenu/>

					<div className="view full-page-intro"
							 style={{
								 background: 'url(/img/useCases-background.png) no-repeat center center',
								 backgroundSize: 'cover'
							 }}
					>
						<div className="mask d-flex justify-content-center align-items-center">
							<div className="legal-main-block align-items-center container mt-5 mt-lg-0">
								<h2 className="h2-legal-main mt-5 mt-lg-0">{t('legal.legal_title')}</h2>
								<p className="legal-main-description mt-lg-5">
									{t('legal.legal_subtitle')}
								</p>
								<p className="legal-main-after-description mt-3">{t('legal.legal_last_revised')}</p>
							</div>
						</div>
					</div>
				</header>

				<main className="container mt-5 py-5">
					<LegalContent handleChange={this._handleChange} expanded={this.state.expanded}/>
				</main>
			</>
		);
	}
}

export default withTranslation()(Legal)
