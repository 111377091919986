import React, { useEffect, useState } from "react";
import styled from "styled-components";
//import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import {t} from "i18next";
import {withTranslation} from "react-i18next";

const Row = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const initFields = {
  accountAlias: "",
  bankName: "",
  iban: "",
  bic: "",
  address: "",
  ownerName: "",
};

interface ControlProps {
  item: any;
  onClose: () => void;
  onSave: (item: any) => void;
  inProcess: boolean;
}

const BankAccountCard: React.FC<ControlProps> = (props) => {
  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors] = useState<any>({});

  useEffect(() => {
    if (props.item) {
      setFields({ ...props.item });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveHandler = () => {
    // ToDo: check require

    props.onSave({ ...fields });
  };

  return (
    <>
      <Grid container spacing={3} sm={9}>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={!!errors.accountAlias}
            helperText={errors.accountAlias}
            variant="outlined"
            fullWidth
            label={t('user.settings.title')}
            value={fields.accountAlias}
            onChange={(e) =>
              setFields({ ...fields, accountAlias: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={!!errors.bankName}
            helperText={errors.bankName}
            variant="outlined"
            fullWidth={true}
            label={t('user.settings.bank_name')}
            value={fields.bankName}
            onChange={(e) => setFields({ ...fields, bankName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }} />
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={!!errors.bic}
            helperText={errors.bic}
            variant="outlined"
            fullWidth
            label="BIC"
            value={fields.bic}
            onChange={(e) => setFields({ ...fields, bic: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={!!errors.ownerName}
            helperText={errors.ownerName}
            variant="outlined"
            fullWidth
            label={t('user.settings.account_owner_name')}
            value={fields.ownerName}
            onChange={(e) =>
              setFields({ ...fields, ownerName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={!!errors.iban}
            helperText={errors.iban}
            variant="outlined"
            fullWidth
            label="IBAN"
            value={fields.iban}
            onChange={(e) => setFields({ ...fields, iban: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={!!errors.address}
            helperText={errors.address}
            variant="outlined"
            fullWidth
            label={t('user.settings.address')}
            value={fields.address}
            onChange={(e) => setFields({ ...fields, address: e.target.value })}
          />
        </Grid>
      </Grid>

      <Row style={{ marginTop: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{
            minWidth: "150px",
            maxWidth: "280px",
            minHeight: "50px",
            width: "100%",
          }}
          onClick={() => saveHandler()}
          disabled={props.inProcess || !fields.accountAlias}
        >
          {t('user.settings.save')}
        </Button>

        <Button
          style={{ minWidth: "150px", marginLeft: "24px" }}
          variant="outlined"
          color="primary"
          size={"large"}
          onClick={() => props.onClose()}
        >
          {t('user.settings.cancel')}
        </Button>
      </Row>
    </>
  );
};

export default withTranslation()(BankAccountCard)
