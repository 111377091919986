/* eslint-disable */

import React, {useContext, useState, Fragment, useEffect} from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { navigate } from "@reach/router";
import { AuthContext } from "../../../core/providers/AuthProvider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { UserRoles } from "../../../core/constants/userRoles";
import MuiIconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import i18n, {t} from "i18next";

const IconButton = styled(MuiIconButton)`
  padding: 0;
  margin: 0;
`;

const Control = styled.nav`
  height: 79px;
`;

const Nav = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  display: inline-block;
  // background: #0d2035;
  color: #fff;
  text-align: left;

  a {
    display: block;
    padding: 0 16px;
    line-height: inherit;
    cursor: pointer;

    &.submenu-element::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }

    &:hover {
      &.submenu-element::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
      }
    }
  }

  .nav__menu {
    line-height: 45px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .nav__menu-item {
    display: inline-block;
    position: relative;
    margin-right: 42px;

    &:hover {
      color: #ccc;

      .nav__submenu {
        display: block;
      }
    }
  }

  .nav__submenu {
    width: max-content;
    display: none;
    font-weight: 300;
    text-transform: none;
    position: absolute;
    background-color: #fff;
    color: #000;
    border-radius: 5px;

    -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
  }

  .nav__submenu-item {
    border-radius: 5px;
    &:hover {
      background: #eee;
    }
  }
`;

const LogoutButton = styled(Button)`
  min-width: 32px;

  &:hover {
    opacity: 0.75;
  }
`;

const NameButton = styled(Button)`
  display: flex;
  alignitems: center;
  color: #fff;
  margin-left: 60px;
  fontsize: 12px;
  text-align: right;
  text-transform: uppercase;

  &:hover {
    opacity: 0.75;
  }
`;

const ListStyled = styled(List)`
  width: 220px;
`;

// const Link = styled(MuiLink)`
// 	text-decoration: none;
// 	color: #FFF;
//
// 	&:hover {
// 	  color: #FFF;
// 	}
// `;

const menu = [
  { id: "1", name: "use_cases", link: "use-cases" },

  {
    id: "2",
    name: "oero",
    submenu: [
      { name: "transparent", link: "transparent" },
      { name: "wallet", link: "/login" },
      { name: "buying_gude", link: "buying-guide" },
      { name: "blog", link: "blog" },
      { name: "api", link: "api"},
      { name: "risk_rate", link: "risk-rate"}
    ],
    submenuWidth: "225px",
  },

  {
    id: "3",
    name: "about_us",
    submenu: [
      { name: "whitepaper", link: "white-paper" },
      { name: "legal", link: "legal" },
      { name: "fees", link: "fees" },
      { name: "team", link: "team" },
      { name: "faq", link: "faq" },
    ],
  },
];



interface ControlProps {
  items?: any[];
}

export const MainMenu: React.FC<ControlProps> = (props) => {
  const authContext = useContext(AuthContext);
  const [showSubmenu, setShowSubmenu] = useState("");
  const [mmOpened, setMmOpened] = useState(false);
  const [langName, setLangName] = useState(localStorage.getItem('langName') ? localStorage.getItem('langName') : 'EN');

  const lang = [
    {
      id: 1,
      name: langName,
      submenu: [
        {name: 'EN'},
        {name: 'РУС'},
      ]
    }
  ]

  const handleHover = (name: string) => {
    setShowSubmenu(name);
  };

  const handleLeave = (name: string) => {
    setShowSubmenu("");
  };

  const handleClick = (link: string) => {
    // if (!link) return;
    // homeContext.setPage(link);
    navigate(`${link}`).then();
  };

  const formatUserName = () => {
    try {
      const spacePos = authContext.user.name.indexOf(" ");
      if (spacePos !== -1)
        return (
          <>
            {authContext.user.name.substr(0, spacePos)}
            <br />
            {authContext.user.name.substr(spacePos + 1)}
          </>
        );
      return authContext.user.name;
    } catch {
      return "";
    }
  };

  const onLogoutClick = (e: any) => {
    e.preventDefault();
    authContext.logout();
  };

  const onNameClick = (e: any) => {
    e.preventDefault();
    if (authContext.user.roles.includes(UserRoles.admin)) {
      navigate("/private/users").then();
    } else {
      // navigate('/private').then();
      if (authContext.menuItems.findIndex((el) => el.label === "home") === -1) {
        navigate("/private/settings").then();
      } else {
        navigate("/private/home").then();
      }
    }
  };

  const mobileMenuClickHandler = () => {
    setMmOpened(!mmOpened);
  };

  const drawerOnClose = () => {
    setMmOpened(false);
  };

  useEffect(() => {
    localStorage.setItem("lang", 'en-UA')
  },[])

  const handleChangeLanguage = (name: string) => {
    if (name === 'EN') {
      setLangName('EN')
      const lang = 'en-UA'
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", 'en-UA')
      localStorage.setItem("langName", 'EN')
    } else if (name === 'РУС') {
      setLangName('РУС')
      const lang = 'ru-RUS'
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", 'ru-RUS')
      localStorage.setItem("langName", 'РУС')
    }
    console.log(localStorage)
  }

  return (
    <Control className="navbar navbar-expand-lg navbar-dark custom-pt">
      <div className="container header-container">
        <a className="navbar-brand" href="/">
          <img src={"/img/logo.png"} alt="OERO" className="logo-img" />
        </a>

        <Hidden mdUp>
          <IconButton onClick={mobileMenuClickHandler}>
            <MenuIcon
              style={{
                width: "32px",
                height: "32px",
                fill: "#ffffff",
              }}
            />
          </IconButton>
          <Drawer anchor="right" open={mmOpened} onClose={drawerOnClose}>
            <ListStyled>
              {authContext && authContext.user && authContext.user.userId ? (
                <>
                  <ListItem button onClick={onNameClick}>
                    <ListItemText primary={authContext.user.name} />
                  </ListItem>
                  <ListItem button onClick={onLogoutClick}>
                    <ListItemText primary={"LOGOUT"} />
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem button onClick={() => navigate("/login").then()}>
                    <ListItemText primary={"Login"} />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/signup").then()}>
                    <ListItemText primary={"Sign up"} />
                  </ListItem>
                </>
              )}
              <Divider />
              {menu.map((elM: any) =>
                elM.submenu ? (
                  <Fragment key={elM.id}>
                    <Divider />
                    {elM.submenu.map((elS: any) => {
                      if (elS.name === "whitepaper") {
                        return (
                          <a
                            key={elM.name}
                            style={{ color: "black" }}
                            href="/White_paper_oero.pdf"
                            target="_blank"
                          >
                            <ListItem
                              key={elS.name}
                              //onClick={() => handleClick(elS.link)}
                            >
                              <ListItemText primary={elS.name} />
                            </ListItem>
                          </a>
                        );
                      }
                      return (
                        <ListItem
                          button
                          key={elS.name}
                          onClick={() => handleClick(elS.link)}
                        >
                          <ListItemText primary={elS.name} />
                        </ListItem>
                      );
                    })}
                  </Fragment>
                ) : (
                  <ListItem
                    button
                    key={elM.id}
                    onClick={() => handleClick(elM.link)}
                  >
                    <ListItemText primary={elM.name} />
                  </ListItem>
                )
              )}
            </ListStyled>
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <div
            // className="collapse navbar-collapse navigation-menu"
            // id="navbarSupportedContent"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Nav className="nav">
              <ul className="nav__menu">
                {menu.map((el: any) => (
                  <li
                    key={el.id}
                    className="nav__menu-item"
                    onMouseLeave={() => handleLeave(el.id)}
                  >
                    <a
                      onMouseEnter={() => handleHover(el.id)}
                      onClick={() => handleClick(el.link)}
                      className={el.submenu ? "submenu-element" : null}
                    >
                      {t('main.header.nav.' + el.name)}
                    </a>
                    {showSubmenu === el.id && el.submenu && (
                      <ul
                        className="nav__submenu"
                        style={{ width: el.submenuWidth }}
                      >
                        {el.submenu.map((els: any) => (
                          <li key={els.name} className="nav__submenu-item">
                            {els.name === "whitepaper" ? (
                                <div>
                                  {langName === "РУС" ? (
                                      <a className="text-uppercase"
                                         style={{ color: "black" }}
                                         href="/White_paper_oero_ru.pdf"
                                         target="_blank"
                                      >
                                        {t('main.header.nav.' + els.name)}
                                      </a>
                                  ) : (
                                      <a className="text-uppercase"
                                         style={{ color: "black" }}
                                         href="/White_paper_oero.pdf"
                                         target="_blank"
                                      >
                                        {t('main.header.nav.' + els.name)}
                                      </a>)}
                                </div>
                            ) : (
                              <a className="text-uppercase" onClick={() => handleClick(els.link)}>
                                {t('main.header.nav.' + els.name)}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </Nav>

            {authContext && authContext.user && authContext.user.userId ? (
              <>
                <NameButton onClick={onNameClick}>
                  {formatUserName()}
                </NameButton>
                <LogoutButton onClick={onLogoutClick}>
                  <ExitToAppIcon // AccountCircleIcon, AccountBoxIcon
                    style={{
                      width: "38px",
                      height: "38px",
                      fill: "#fff",
                      margin: "0 -4px 0 12px",
                    }}
                  />
                </LogoutButton>
              </>
            ) : (
              <>
                <Button
                  style={{ width: "120px", height: "28px" }}
                  variant="contained"
                  color="primary"
                  size={"small"}
                  onClick={() => navigate("/login").then()}
                >
                  {t('main.header.nav.login')}
                </Button>
                <Button
                  style={{ marginLeft: "12px", width: "158px", height: "28px" }}
                  variant="contained"
                  color="primary"
                  size={"small"}
                  onClick={() => navigate("/signup").then()}
                >
                  {t('main.header.nav.sign_up')}
                </Button>
                <Nav className="nav">
                  <ul className="nav__menu">
                    {lang.map((el: any) => (
                        <li
                            key={el.id}
                            className="nav__menu-item"
                            onMouseLeave={() => handleLeave(el.id)}
                        >
                          <a
                              onMouseEnter={() => handleHover(el.id)}
                              className={el.submenu ? "submenu-element" : null}
                          >
                            {el.name}
                          </a>
                          {showSubmenu === el.id && el.submenu && (
                              <ul
                                  className="nav__submenu"
                                  style={{ width: el.submenuWidth }}
                              >
                                {el.submenu.map((els: any) => (
                                    <li key={els.name} className="nav__submenu-item">
                                          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}  onClick={() => handleChangeLanguage(els.name)}>
                                            {els.name}
                                          </div>
                                    </li>
                                ))}
                              </ul>
                          )}
                        </li>
                    ))}
                  </ul>
                </Nav>

              </>
            )}
          </div>
        </Hidden>
      </div>
    </Control>
  );
};
