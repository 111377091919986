import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import {HomeProvider} from "./HomeProvider";
import Footer from "./components/Footer";
import {routes} from "./routes";
// import {AuthContext} from "../../core/providers/AuthProvider";

export const Home: React.FC<RouteComponentProps> = () => {

	// const authContext = useContext(AuthContext);

	return (
		<HomeProvider>
			{/*<link rel="stylesheet" href="/css/bootstrap.min.css"/>*/}
			{/*<link rel="stylesheet" href="/css/mdb.min.css"/>*/}
			{/*<link rel="stylesheet" href="/css/style.css"/>*/}
			<Router>
				{routes}
			</Router>
			<Footer/>
		</HomeProvider>
	)
};
