import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "@reach/router";
import styled from 'styled-components';

import {HeadCell, Grid} from '../../../../core/ui/grids/Grid';

import BankAccountCard from './BankAccountCard';
import {gql} from "@apollo/client";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
	MutationAddBankAccountArgs,
	MutationUpdateBankAccountArgs,
	MutationDeleteBankAccountArgs,
	UserBankAccountShort
} from "../../../../store/generated-models";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const LoadWrap = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const gqlAddBankAccount = gql`
    mutation addBankAccount (
        $bankName: String!,
        $iban: String!,
        $bic: String!,
        $address: String!,
        $accountAlias: String!,
        $ownerName: String!
    ) {
        addBankAccount (
            bankName: $bankName,
            iban: $iban,
            bic: $bic,
            address: $address,
            accountAlias: $accountAlias,
            ownerName: $ownerName
        ) {
            userBankAccountId
            accountAlias
            bankName
            iban
            bic
            address
            ownerName
        }
    }
`;

const gqlUpdateBankAccount = gql`
    mutation updateBankAccount (
        $userBankAccountId: ID!,
        $bankName: String!,
        $iban: String!,
        $bic: String!,
        $address: String!,
        $accountAlias: String!,
        $ownerName: String!
    ) {
        updateBankAccount (
            userBankAccountId: $userBankAccountId,
            bankName: $bankName,
            iban: $iban,
            bic: $bic,
            address: $address,
            accountAlias: $accountAlias,
            ownerName: $ownerName
        ) {
            userBankAccountId
            accountAlias
            bankName
            iban
            bic
            address
            ownerName
        }
    }
`;

const gqlDeleteBankAccount = gql`
    mutation deleteBankAccount (
        $userBankAccountId: ID!,
    ) {
        deleteBankAccount (
            userBankAccountId: $userBankAccountId
        ) {
            accountAlias
            address
            bankName
            bic
            description
            iban
            ownerName
            userBankAccountId
        }
    }
`;

// const gqlMyBankAccounts = gql`
//     query {
//         myBankAccounts {
//             userBankAccountId
//             accountAlias
//             bankName
//             iban
//             bic
//             address
//             ownerName
//         }
//     }`;

const gqlMyBankAccounts = gql`{
    myBankAccounts {
        userBankAccountId
        accountAlias
        bankName
        iban
        bic
        address
        ownerName
    }
}`;


const BankAccounts: React.FC<RouteComponentProps> = () => {

	const headCells: HeadCell[] = [
		{id: 'accountAlias', label: t('user.settings.alias')},
		{id: 'bankName', label: t('user.settings.bank_name')},
		{id: 'iban', label: 'IBAN'},
		{id: 'bic', label: 'BIC'},
		{id: 'address', label: t('user.settings.address')},
		{id: 'ownerName', label: t('user.settings.owner_name')},
	];

	const {enqueueSnackbar} = useSnackbar();

	const [addBankAccount, {loading: addBankAccountLoading}
	] = useMutation<{ addBankAccount: UserBankAccountShort }, MutationAddBankAccountArgs>(
		gqlAddBankAccount,
		{
			update(cache, {data: {addBankAccount}}) {
				const {myBankAccounts} = cache.readQuery<any>({query: gqlMyBankAccounts});
				cache.writeQuery({
					query: gqlMyBankAccounts,
					data: {myBankAccounts: myBankAccounts.concat([addBankAccount])},
				});
			}
		}
	);

	const [updateBankAccount] = useMutation<{ updateBankAccount: UserBankAccountShort }, MutationUpdateBankAccountArgs>(
		gqlUpdateBankAccount,
		{
			update(cache, {data: {updateBankAccount}}) {
				const {myBankAccounts} = cache.readQuery<any>({query: gqlMyBankAccounts});
				cache.writeQuery({
					query: gqlMyBankAccounts,
					data: {
						myBankAccounts: myBankAccounts.map((el: any) => {
							if (el.userBankAccountId === updateBankAccount.userBankAccountId) {
								return updateBankAccount;
							}
							return el;
						})
					},
				});
			}
		}
	);

	const [deleteBankAccount] = useMutation<{ deleteBankAccount: UserBankAccountShort }, MutationDeleteBankAccountArgs>(
		gqlDeleteBankAccount
		// ,{
		// 	update(cache, {data: {deleteBankAccount}}) {
		// 		const {myBankAccounts} = cache.readQuery({query: gqlMyBankAccounts});
		// 		cache.writeQuery({
		// 			query: gqlMyBankAccounts,
		// 			data: {
		// 				myBankAccounts: myBankAccounts.filter((el: any) => {
		// 					return el.userBankAccountId !== deleteBankAccount.userBankAccountId;
		// 				})
		// 			},
		// 		});
		// 	}
		// }
	);

	const {data, loading, refetch} = useQuery(gqlMyBankAccounts);

	const [mode, setMode] = useState('');
	const [item, setItem] = useState<any>(null);
	const [items, setItems] = useState<any[]>([]);

	useEffect(() => {
		if (data && data.myBankAccounts && Array.isArray(data.myBankAccounts)) {
			setItems([...data.myBankAccounts]);
		}
	}, [data]);


	const addItemHandler = () => {
		setItem(null);
		setMode('card');
	};

	const editItemHandler = (item: any) => {
		setItem(item);
		setMode('card');
	};

	const deleteItemHandler = async (item: any) => {
		let errMessage = '';
		try {
			const res = await deleteBankAccount({
				variables: {
					userBankAccountId: item.userBankAccountId
				}
			});
			if (res.data.deleteBankAccount) {
				refetch().then();
				setMode('');
				return;
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
				if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {
			}
		}

		if (!errMessage) errMessage = 'Unknown error';
		enqueueSnackbar(errMessage, {variant: 'error'});
	};

	const itemSaveHandler = async (item: any) => {
		let errMessage = '';
		if (item.userBankAccountId) {
			// edit item
			let errMessage = '';
			try {
				const res = await updateBankAccount({variables: {...item}});
				if (res.data.updateBankAccount.userBankAccountId) {
					// refetch().then();
					setMode('');
					return;
				}
			} catch (error: any) {
				try {
					const errorCode = error.graphQLErrors[0].extensions.code;
					if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
					if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
				} catch (ignored) {
				}
			}
		} else {
			// add item
			let errMessage = '';
			try {
				const res = await addBankAccount({variables: {...item}});
				if (res.data.addBankAccount.userBankAccountId) {
					// refetch().then();
					setMode('');
					return;
				}
			} catch (error: any) {
				try {
					const errorCode = error.graphQLErrors[0].extensions.code;
					if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
					if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
				} catch (ignored) {
				}
			}
		}

		if (!errMessage) errMessage = 'Unknown error';
		enqueueSnackbar(errMessage, {variant: 'error'});
	};

	if (!data && loading) {
		return (
			<LoadWrap>
				<CircularProgress/>
			</LoadWrap>
		);
	}

	if (mode === '')
		return (
			<Grid
				headCells={headCells}
				items={items}
				onAddItem={addItemHandler}
				addLabel={t('user.settings.add_account_btn')}
				// onEditItem={editItemHandler}
				// onDeleteItem={deleteItemHandler}
				idField="userBankAccountId"
				actions={[
					{id: 'edit', onAction: editItemHandler},
					{id: 'del', onAction: deleteItemHandler},
				]}
			/>
		);

	if (mode === 'card')
		return (
			<BankAccountCard
				item={item}
				onClose={() => setMode('')}
				onSave={itemSaveHandler}
				inProcess={addBankAccountLoading}
			/>
		);

};

export default withTranslation()(BankAccounts)
