import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CurrencyInput } from "../../components/common/CurrencyInput";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  BankTransactionResult,
  MutationConfirmDepositFiatArgs,
  MutationDepositFiatArgs,
} from "../../../../store/generated-models";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { Dropdown } from "../../components/common/Dropdown";
import { gqlGetSupportedCurrenciesWithRates, LoadWrap } from "./Deposit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { round, OERO_PRECISION } from "../../../../core/helpers/Currencies";
import { BANK_OPTIONS } from "../../../../core/constants/options";
import { BankOptionInput } from "../../components/common/BankOptionInput";
import {t} from "i18next";
import {withTranslation} from "react-i18next";

const Row = styled.div`
  display: flex;
`;

const InvoiceWrap = styled.div`
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const WrapText = styled(Typography)`
  overflow-wrap: break-word;
`;

const gqlDepositFiat = gql`
  mutation depositFiat(
    $immutableAmount: Int!
    $fiatCurrency: String!
    $fiatAmount: Float!
    $oeroAmount: Float!
    $firstName: String
    $lastName: String
    $comment: String
    $companyName: String
    $bankOptionId: Int
  ) {
    depositFiat(
      immutableAmount: $immutableAmount
      fiatCurrency: $fiatCurrency
      fiatAmount: $fiatAmount
      oeroAmount: $oeroAmount
      firstName: $firstName
      lastName: $lastName
      comment: $comment
      companyName: $companyName
      bankOptionId: $bankOptionId
    ) {
      id
      transaction {
        firstUserName
        lastUserName
        companyName
        currency
        amount
        comment
      }
    }
  }
`;

const gqlConfirmDepositFiat = gql`
  mutation confirmDepositFiat($id: String) {
    confirmDepositFiat(id: $id) {
      transaction {
        firstUserName
        lastUserName
        currency
        amount
        comment
        transactionCode
        paymentId
      }
    }
  }
`;

const DEF_CURR_NAME = "EUR";

const initFields = {
  type: "p",
  firstName: "",
  lastName: "",
  companyName: "",
  fromAmount: "",
  toAmount: "",
  fromCurrency: DEF_CURR_NAME,
  toCurrency: "OERO",
  comment: "",
};

const defaultCurrency = {
  minAmount: 0,
  name: DEF_CURR_NAME,
  depositRate: 0,
};

const defaultBankOption = {
  id: BANK_OPTIONS[1],
  option_id: 1,
  currency: DEF_CURR_NAME,
};

interface ComponentProps extends RouteComponentProps {}

const BankTransaction: React.FC<ComponentProps> = () => {

  const TYPE: any[] = [
    { id: "p", value: t('user.deposit.type_value_1') },
    { id: "c", value: t('user.deposit.type_value_2') },
  ];

  const authContext = useContext(AuthContext);
  const COMPANY = authContext.company;
  const { enqueueSnackbar } = useSnackbar();

  const [depositFiat, { loading: depositFiatLoading }] = useMutation<
    { depositFiat: BankTransactionResult },
    MutationDepositFiatArgs
  >(gqlDepositFiat);

  const [confirmDepositFiat, { loading: confirmDepositFiatLoading }] =
    useMutation<
      { confirmDepositFiat: BankTransactionResult },
      MutationConfirmDepositFiatArgs
    >(gqlConfirmDepositFiat);

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});

  const [fromAmountValue, setFromAmountValue] = useState(0);
  const [toAmountValue, setToAmountValue] = useState(0);

  //const [currencies, setCurrencies] = useState<any[]>([{ id: DEF_CURR_NAME }]);
  const [bankOptions, setBankOptions] = useState<any[]>([defaultBankOption]);
  const [selectedBankOption, setSelectedBankOption] =
    useState<any>(defaultBankOption);
  const [immutableAmount, setImmutableAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [txId, setTxId] = useState("");
  const [txInfo, setTxInfo] = useState<any>({});
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(defaultCurrency);

  const { data, error, loading, refetch } = useQuery(
    gqlGetSupportedCurrenciesWithRates
  );

  useEffect(() => {
    refetch().catch((err) => console.warn(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext.company === null) {
      authContext.getSettings();
      return;
    }
  }, [authContext.company]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSelectedCurrency = (cur: string) => {
    if (!data) return defaultCurrency;
    const selCur = data.getSupportedCurrenciesWithRates.fiat.find(
      (el: any) => el.id === cur
    );
    if (!selCur) return defaultCurrency;
    return selCur;
  };

  const getSelectedBankOption = (option: string) => {
    return bankOptions.find((bank) => bank.id === option);
  };

  const setInitial = () => {
    try {
      const names = authContext.user.name.split(" ");
      setFields({
        ...initFields,
        companyName: authContext.user.name,
        firstName: names[0],
        lastName: names[1] ? names[1] : "",
        type: authContext.user.userType ? authContext.user.userType : "p",
      });
      setStatus("");
      setFromAmountValue(0);
      setToAmountValue(0);
      setImmutableAmount(0);
      setTxId("");
      setTxInfo({});
      setSelectedBankOption(defaultBankOption);
      setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    setInitial();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDeposit = async () => {
    let err: any = {};
    if (fields.type === "p") {
      if (!fields.firstName) err.firstName = "required";
      if (!fields.lastName) err.lastName = "required";
    } else {
      if (!fields.companyName) err.companyName = "required";
    }
    if (!fromAmountValue) err.fromAmount = "required";
    if (!toAmountValue) err.toAmount = "required";

    if (
      fromAmountValue &&
      fields.fromCurrency &&
      authContext.settings &&
      authContext.settings.restrictMinAmounts &&
      fromAmountValue < selectedCurrency.minAmount
    ) {
      err.fromAmount = `minimum ${selectedCurrency.minAmount} ${selectedCurrency.name}`;
    }

    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar("Correct errors to continue", { variant: "error" });
      return;
    }

    let errMessage = "";
    try {
      let variables: any = {
        immutableAmount: immutableAmount,
        fiatCurrency: fields.fromCurrency,
        fiatAmount: fromAmountValue,
        oeroAmount: toAmountValue,
        comment: fields.comment,
        bankOptionId: selectedBankOption.option_id,
      };

      if (fields.type === "p") {
        variables.firstName = fields.firstName;
        variables.lastName = fields.lastName;
      } else {
        variables.companyName = fields.companyName;
      }

      const res = await depositFiat({ variables: variables });
      if (res.data.depositFiat.id) {
        setTxId(res.data.depositFiat.id);
        setTxInfo(res.data.depositFiat.transaction);
        setStatus("confirm");
        return;
      } else {
        errMessage = "Failed to send deposit";
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const submitConfirmDeposit = async () => {
    let errMessage = "";
    try {
      const res = await confirmDepositFiat({
        variables: {
          id: txId,
        },
      });
      if (res.data.confirmDepositFiat.transaction.transactionCode) {
        setTxInfo({
          ...res.data.confirmDepositFiat.transaction,
          companyName: txInfo.companyName,
        });
        setStatus("done");
        return;
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  useEffect(() => {
    if (!data || !data.getSupportedCurrenciesWithRates) return;

    // setCurrencies(
    //   data.getSupportedCurrenciesWithRates.fiat.map((el: any) => {
    //     return {
    //       id: el.id,
    //       value: el.name,
    //     };
    //   })
    // );
    const bankOptions = COMPANY.banks
      .filter((bank) => {
        return bank.isActive;
      })
      .map((bank) => {
        return {
          id: BANK_OPTIONS[bank.bankOption],
          option_id: bank.bankOption,
          currency: bank.bankAcceptedCurrency,
          ...bank,
        };
      })
      .sort((a, b) => a.option_id - b.option_id);

    setBankOptions(bankOptions);
    setSelectedBankOption(bankOptions[0]);
    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // let isValid = true;

    // parse what we have in the form
    let fromAmt =
      fields.fromAmount === null ? null : parseFloat(fields.fromAmount);
    let toAmt = fields.toAmount === null ? null : parseFloat(fields.toAmount);

    if (fromAmt === null || toAmt === null || !selectedCurrency.depositRate) {
      setFromAmountValue(0);
      setToAmountValue(0);
      return;
    }

    if (!selectedCurrency.depositRate) {
      console.error("Not find rate");
      return;
    }

    if (immutableAmount === 0) {
      if (isNaN(fromAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        setFields({ ...fields, toAmount: "" });
        return;
      }

      fromAmt = round(fromAmt, selectedCurrency.precision);
      const calc = round(
        fromAmt * selectedCurrency.depositRate,
        OERO_PRECISION
      );
      setFromAmountValue(fromAmt);
      setToAmountValue(calc);
      setFields({ ...fields, toAmount: calc.toString() });
    } else {
      if (isNaN(toAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        setFields({ ...fields, fromAmount: "" });
        return;
      }

      toAmt = round(toAmt, OERO_PRECISION);
      const calc = round(
        toAmt / selectedCurrency.depositRate,
        selectedCurrency.precision
      );
      setFromAmountValue(calc);
      setToAmountValue(toAmt);
      setFields({ ...fields, fromAmount: calc.toString() });
    }
  }, [fields.fromAmount, fields.toAmount, fields.fromCurrency]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputFromAmountChange = (val: string) => {
    setFields({ ...fields, fromAmount: val });
    setImmutableAmount(0);
  };

  const handleInputToAmountChange = (val: string) => {
    setFields({ ...fields, toAmount: val });
    setImmutableAmount(1);
  };

  const handleFromCurrencyChange = (val: string) => {
    const newBankOption = getSelectedBankOption(val);
    setSelectedBankOption(getSelectedBankOption(val));
    setSelectedCurrency(getSelectedCurrency(newBankOption.currency));
    setFields({ ...fields, fromCurrency: newBankOption.currency });
    setImmutableAmount(0);
  };

  if (error) {
    return <LoadWrap>Internal server error. Try again later</LoadWrap>;
  }

  if (!data || loading) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  // RENDER CONFIRM AND DONE STEP
  if (status === "confirm" || status === "done") {
    return (
      <>
        <Row>
          <Typography variant={"h2"} style={{ marginBottom: "10px" }}>
            {status === "confirm" ? "INVOICE" : "INVOICE CONFIRMED"}
          </Typography>
        </Row>
        {status === "confirm" ? (
          <Row>
            <Typography variant={"body1"}>
              Please check data and approve the payment
            </Typography>
          </Row>
        ) : null}

        <InvoiceWrap>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                PAYMENT RECEIVER
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Company name
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.companyName}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Company address
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.companyAddress}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank name
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.bankName}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank address
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.bankAddress}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank account/IBAN
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.bankAccount}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank SWIFT/BIC
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.bankSwiftBic}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Routing Number
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.routingNumber}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Reference
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {selectedBankOption.referenceNumber}
                  </WrapText>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                PAYMENT SENDER
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    NAME
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={"body1"}>
                    {txInfo.companyName
                      ? txInfo.companyName
                      : `${txInfo.firstUserName} ${txInfo.lastUserName}`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    EMAIL
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={"body1"}>
                    {authContext.user.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: "24px",
              paddingBottom: "24px",
              borderBottom: "1px solid lightgray",
            }}
          >
            <div>
              <Typography variant={"body1"}>
                Payment description:{" "}
                {status === "confirm"
                  ? "will be available after confirmation"
                  : txInfo.transactionCode}
              </Typography>
            </div>
            {status === "done" ? (
              <div style={{ marginTop: "8px" }}>
                <Typography variant={"body1"}>
                  <b>IMPORTANT</b>: This code identifies your deposit with your
                  account. Include this when submitting the wire transfer.
                </Typography>
              </div>
            ) : null}
          </div>

          <div style={{ marginTop: "24px" }}>
            <Typography variant={"h3"}>
              Total amount: {txInfo.amount}&nbsp;{txInfo.currency}
            </Typography>
          </div>
        </InvoiceWrap>

        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            color="primary"
            size={"large"}
            style={{
              minWidth: "120px",
              maxWidth: "240px",
              width: "100%",
              minHeight: "50px",
            }}
            onClick={() =>
              status === "confirm" ? submitConfirmDeposit() : setInitial()
            }
            disabled={confirmDepositFiatLoading}
          >
            {status === "confirm" ? "Confirm" : "OK"}
          </Button>

          {status === "done" ? (
            <Button
              variant="outlined"
              color="primary"
              size={"large"}
              style={{
                minWidth: "120px",
                marginLeft: "20px",
                minHeight: "50px",
              }}
              onClick={() => {
                window.open(txInfo.paymentId, "_blank");
              }}
            >
              Print invoice
            </Button>
          ) : null}

          {status === "confirm" ? (
            <Button
              variant="outlined"
              color="primary"
              size={"large"}
              style={{
                minWidth: "120px",
                marginLeft: "20px",
                minHeight: "50px",
              }}
              onClick={() => setInitial()}
              disabled={confirmDepositFiatLoading}
            >
              Cancel
            </Button>
          ) : null}
        </Row>
      </>
    );
  }

  // RENDER INITIAL STEP
  return (
    <>
      <Grid container spacing={3} style={{ maxWidth: "1000px" }}>
        <Grid item xs={12}>
          <Dropdown
            label={t('user.deposit.type')}
            values={TYPE}
            value={fields.type}
            onChange={(val) => setFields({ ...fields, type: val })}
            style={{ width: "160px", backgroundColor: "white" }}
            // disabled={files.length > 0}
          />
        </Grid>
        {fields.type === "p" ? (
          <>
            <Grid item xs={12} md={6}>
              <StyledTextField
                error={!!errors.firstName}
                helperText={errors.firstName}
                variant="outlined"
                fullWidth
                label={t('user.deposit.first_name')}
                value={fields.firstName}
                onChange={(e) =>
                  setFields({ ...fields, firstName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                error={!!errors.lastName}
                helperText={errors.lastName}
                variant="outlined"
                fullWidth={true}
                label={t('user.deposit.last_name')}
                value={fields.lastName}
                onChange={(e) =>
                  setFields({ ...fields, lastName: e.target.value })
                }
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <StyledTextField
              error={!!errors.companyName}
              helperText={errors.companyName}
              variant="outlined"
              fullWidth
              label={t('user.deposit.company_name')}
              value={fields.companyName}
              onChange={(e) =>
                setFields({ ...fields, companyName: e.target.value })
              }
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <BankOptionInput
            error={!!errors.fromAmount}
            helperText={errors.fromAmount}
            label={t('user.deposit.from')}
            amount={fields.fromAmount}
            onAmountChange={(val) => handleInputFromAmountChange(val)}
            bankOption={selectedBankOption.id}
            onBankOptionChange={(val) => handleFromCurrencyChange(val)}
            bankOptions={bankOptions}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.toAmount}
            helperText={errors.toAmount}
            label={t('user.deposit.to')}
            amount={fields.toAmount}
            onAmountChange={(val) => handleInputToAmountChange(val)}
            currency={fields.toCurrency}
            onCurrencyChange={(val) =>
              setFields({ ...fields, toCurrency: val })
            }
            currencies={[{ id: "OERO" }]}
            fullWidth
            dropdownDisabled
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.comment}
            helperText={errors.comment}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
          	maxRows={6}
            label={t('user.deposit.comment')}
            value={fields.comment}
            //onChange={(event) => setComment(event.target.value)}
            onChange={(e) => setFields({ ...fields, comment: e.target.value })}
          />
        </Grid>
      </Grid>

      <Row style={{ marginTop: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "240px", minHeight: "50px" }}
          onClick={() => submitDeposit()}
          disabled={depositFiatLoading}
        >
          {t('user.deposit.next')}
        </Button>
      </Row>
    </>
  );
};

export default withTranslation()(BankTransaction)
