import React from "react";
import {HomeContext} from "../HomeProvider";
import {MainMenu} from "./MainMenu";
import styled from "styled-components";
import {withTranslation} from "react-i18next";

import {BuyingGuide} from "./BuyingGuide";
import {t} from "i18next";


const ApiList = styled.ol`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #101010;
  text-align: start;

  & > li {
    list-style-type: none;
  }
 
  & ul > li > span {
    color: #DE0000;
   
  }
`;

const ApiLink = styled.a`
  font-size: 20px;
  color: #0096c3;
  text-decoration: underline;
  @media screen and (max-width: 420px) {
    display: inline-block;
  }
  
`;

const ApiTitle = styled.p`
  margin-top: 48px;
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #101010;
`;

const ApiImg = styled.div`
  max-width: 940px;
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #202676;
  background: #FFFFFF;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px 20px;
  margin: 20px 0px;
  & > span {
    color: #9E9E9E;
  }
  @media screen and (max-width: 420px) {
    font-size: 14px;
  }
`;
const ApiSubTitle = styled.div`
  font-weight: bold;
  padding: 28px 0px;
`;


class Api extends React.Component {
    static contextType = HomeContext;
    context;

    constructor(props) {
        super(props);

        this.state = {
            // page: 'Users'
        };
    }

    componentDidMount() {
        // document.body.classList.add('admin-page');
    }

    componentWillUnmount() {
        // document.body.classList.remove('admin-page');
    }

    render() {
        return (
            <>
                <header>
                    <MainMenu/>
                    <div
                        className="view full-page-intro"
                        style={{
                            background:
                                "url('/img/useCases-background.png') no-repeat center center",
                            backgroundSize: "cover",
                        }}
                    >
                        <div className="mask d-flex justify-content-center align-items-center">
                            <div className="container">
                                <div
                                    className="row wow fadeIn"
                                    style={{visibility: "visible", animationName: "fadeIn"}}
                                >
                                    <div
                                        className="col-lg-5 mt-5 mb-4 white-text text-center text-md-left d-flex align-items-center api-text">
                                        <div className="text-left">
                                            <h1 className="transparent-main ">
                                                OERO-API
                                            </h1>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 col-lg-6 mb-4 mx-auto justify-content-center d-lg-flex api-img">
                                        <img
                                            src="/img/api-main.png"
                                            className="img-fluid home-first-img align-self-center"
                                            alt="Fiat Euro on the Blockchain"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="api-main text-center pb-5">
                    <div className="container">
                        <section
                            className="mb-5 wow fadeIn"
                            style={{
                                visibility: "visible",
                                animationName: "fadeIn",
                            }}
                        >
                            <div style={{justifyContent: "center"}}>
                                <ApiList>
                                    <div>
                                        API-base(Live):&nbsp;
                                        <ApiLink target="_blank" href='https://dev.oero.io/merchant/'>
                                            https://api.oero.io/api-doc/
                                        </ApiLink>
                                    </div>
                                    <div>
                                        API-base(Test):&nbsp;
                                        <ApiLink target="_blank" href='https://dev.oero.io/merchant/'>
                                            https://dev.oero.io/api-doc
                                        </ApiLink>
                                    </div>

                                    <li>
                                        <ApiTitle style={{
                                            marginBottom: 32
                                        }}>
                                            {t('api.api_title')}
                                        </ApiTitle>
                                        {t('api.api_subtitle')}
                                        <ApiImg>
                                            “Authentication”: “Bearer xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx”
                                        </ApiImg>
                                        <div style={{
                                            fontSize: '16px',
                                            color: '#5E5E5E',
                                            paddingBottom: 24
                                        }}>
                                            <p>{t('api.api_description_1')}</p>
                                            <p>{t('api.api_description_2')}</p>
                                        </div>
                                        {t('api.api_description_3')}
                                    </li>
                                </ApiList>
                                <ApiList>
                                    <ApiTitle>
                                        {t('api.available_endpoints')}
                                    </ApiTitle>
                                    <ul>
                                        <li>
                                            <ApiSubTitle>{t('api.available_endpoints_subtitle')}</ApiSubTitle>
                                            <div>
                                                Endpoint: /depositCrypto
                                                <p>Method: POST</p>
                                                Required parameters:
                                            </div>
                                            <div>

                                                <ApiImg>
                                                    &#123;<br/>
                                                    “currency”: “BTC” <span>// Cryptocurrency short name (Available now: BTC, ETH, ETC, USDT (ERC-20), XRP) Type: String </span><br/>
                                                    “amount”: 1.543 <span>// Amount to deposit (Must be greater than zero). Type: Number </span><br/>
                                                    “paymentPurpose”: “any purpose” <span>//Purpose of deposit. Type: String</span>
                                                    <br/>&#125;
                                                </ApiImg>
                                            </div>
                                            {t('api.available_responses')}
                                            <ul>
                                                <li>
                                                    {t('api.available_responses_1')}
                                                    <ApiImg>
                                                        &#123;<br/>
                                                        “address”: “xxxxxxxxxxxxxxxxxxxxxxxxxxxx” <span>//wallet address where to transfer crypto. Type: String</span><br/>
                                                        “transactionCode”: “OERO-XXXXXXXXX-XXXXXXX”<span>//Code of created transaction in our system. Type: String</span><br/>
                                                        “transactionId”: “xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx” <span>//Id of created transaction in our system. Will be used to get status. Type: String</span>
                                                        <br/>&#125;
                                                    </ApiImg>
                                                </li>
                                                <li>
                                                    {t('api.available_responses_2')}
                                                </li>
                                                <li>
                                                    {t('api.available_responses_3')}
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <ApiSubTitle>{t('api.get_status_title')}</ApiSubTitle>
                                            <div>
                                                Endpoint: /getStatus
                                                <p>Method: POST</p>
                                                Required parameters:
                                            </div>
                                            <ApiImg>
                                                &#123;<br/>
                                                “id”: “xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx” <span>//Transaction id which you get from /depositCrypto request</span>
                                                <br/>&#125;
                                            </ApiImg>
                                            {t('api.get_status_responses')}
                                            <ul>
                                                <li>
                                                    {t('api.get_status_responses_1')}
                                                    <ApiImg>
                                                        &#123;<br/>
                                                        “status”: “Pending” <span>//One of the possible transaction statuses (Possible statuses are: Pending – transaction in wait for processing</span>
                                                        <span>Confirmed – transaction started processing</span>
                                                        <span>Rejected – transaction was rejected. In this case you must create new one</span>
                                                        <span>Succeeded – transaction was successfully closed. In this case you got your OERO). Type: String</span>
                                                        <br/>“isPayedByClient”: false <span>//Is user have transferred crypto to the address of created transaction. Type: Boolean</span>
                                                        <br/>“payedByClientDate”: 2021-09-14T12:18:15.289Z <span>//If previous parameter is true, date/time when user payed for transaction. Type: DateTime or null</span>
                                                        <br/>&#125;
                                                    </ApiImg>

                                                </li>
                                                <li>
                                                    {t('api.get_status_responses_2')}
                                                </li>
                                                <li>
                                                    {t('api.get_status_responses_3')}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </ApiList>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        )

    }
}

export default withTranslation()(Api)
