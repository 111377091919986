import {gql, InMemoryCache} from '@apollo/client';

const initialData: { [attribute: string]: any } = {
  appState: {
    __typename: 'AppState',
    isFullScreen: false,
    isProMode: true,
    isRealTrading: true,
    isSideBarCollapsed: false,
    currentMarket: null
  }
};

export const initLocalCache = (cache: InMemoryCache) => {
  // Используем writeQuery для записи initialData в кэш
  cache.writeQuery({
    query: gql`
      query GetAppState {
        appState {
          __typename
          isFullScreen
          isProMode
          isRealTrading
          isSideBarCollapsed
          currentMarket
        }
      }
    `,
    data: initialData
  });
};
