import React from 'react';
import {RouteComponentProps} from '@reach/router';

interface ComponentProps extends RouteComponentProps {
	fields: any;
	action: string;
	submitRef: any;
}

export const Epay: React.FC<ComponentProps> = ({fields, action, submitRef}) => {

	return (
		<form
			method="post"
			target="_blank"
			action={action}
			// action="https://api.epay.com/paymentApi/merReceive"
		>
			<input hidden readOnly type="text" name="PAYEE_ACCOUNT" defaultValue={fields.PAYEE_ACCOUNT}/>
			<input hidden readOnly type="text" name="PAYEE_NAME" defaultValue={fields.PAYEE_NAME}/>
			<input hidden readOnly type="text" name="PAYMENT_AMOUNT" defaultValue={fields.PAYMENT_AMOUNT}/>
			<input hidden readOnly type="text" name="PAYMENT_UNITS" defaultValue={fields.PAYMENT_UNITS}/>
			<input hidden readOnly type="text" name="PAYMENT_ID" defaultValue={fields.PAYMENT_ID}/>
			<input hidden readOnly type="text" name="STATUS_URL" defaultValue={fields.STATUS_URL}/>
			<input hidden readOnly type="text" name="PAYMENT_URL" defaultValue={fields.PAYMENT_URL}/>
			<input hidden readOnly type="text" name="NOPAYMENT_URL" defaultValue={fields.NOPAYMENT_URL}/>
			<input hidden readOnly type="text" name="BAGGAGE_FIELDS" defaultValue={fields.BAGGAGE_FIELDS}/>
			<input hidden readOnly type="text" name="KEY_CODE" defaultValue={fields.KEY_CODE}/>
			<input hidden readOnly type="text" name="BATCH_NUM" defaultValue={fields.BATCH_NUM}/>
			<input hidden readOnly type="text" name="SUGGESTED_MEMO" defaultValue={fields.SUGGESTED_MEMO}/>
			<input hidden readOnly type="text" name="FORCED_PAYEE_ACCOUNT" defaultValue={fields.FORCED_PAYEE_ACCOUNT}/>
			<input hidden readOnly type="text" name="INTERFACE_LANGUAGE" defaultValue={fields.INTERFACE_LANGUAGE}/>
			<input hidden readOnly type="text" name="CHARACTER_ENCODING" defaultValue={fields.CHARACTER_ENCODING}/>
			<input hidden readOnly type="text" name="V2_HASH" defaultValue={fields.V2_HASH}/>
			<button hidden type="submit" ref={submitRef}>submit</button>
		</form>
	);
};
