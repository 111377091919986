import Grid from "@material-ui/core/Grid";
import { RouteComponentProps } from "@reach/router";
import { CurrencyInput } from "../../../components/common/CurrencyInput";
import { styled } from "styled-components";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import InputMask from 'react-input-mask';

interface ComponentProps extends RouteComponentProps {
  supportedCurrencies: any;
  handleFromCurrencyChange: (val: string) => void;
  handleInputFromAmountChange: (val: string) => void;
  handleInputToAmountChange: (val: string) => void;
  handleInputCardPanChange: (val: string) => void;
  handleInputCardCvvChange: (val: string) => void;
  handleInputExpiredDateChange: (val: string) => void;
  handleInputCardHolderChange: (val: string) => void;
  setFields: (val: string) => void;
  fields: any;
  errors: any;
}

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const StyledCardTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #cccccc; // Светло-серый цвет границы
    }
    &:hover fieldset {
      border-color: #b3b3b3; // Цвет при наведении
    }
    &.Mui-focused fieldset {
      border-color: #999999; // Цвет при фокусе
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: 20px;
`;

const VisaCard = styled.div`
  background: linear-gradient(135deg, #3b5998, #192f6a);
  color: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: left;
  font-family: 'Roboto', sans-serif;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .card-logo {
      font-size: 24px;
      font-weight: bold;
    }
    .visa-logo {
      font-size: 24px;
      font-weight: bold;
      color: #f7b600;
    }
  }

  .card-fields {
    margin-top: 10px;
  }
`;

const CardGrid = styled(Grid)`
  max-width: 600px;
  width: 100%;
`;

const DEF_CURR_NAME = "EUR";

export const MercuryoPaymentDetails: React.FC<ComponentProps> = ({
  supportedCurrencies,
  handleFromCurrencyChange,
  handleInputFromAmountChange,
  handleInputToAmountChange,
  handleInputCardPanChange,
  handleInputCardCvvChange,
  handleInputExpiredDateChange,
  handleInputCardHolderChange,
  setFields,
  fields,
  errors
}) => {
  const [currencies, setCurrencies] = useState<any[]>([{ id: DEF_CURR_NAME }]);

  useEffect(() => {
    setCurrencies(
      supportedCurrencies.getSupportedCurrenciesWithRates.fiat.map((el: any) => {
        return {
          id: el.id,
          value: el.name,
        };
      })
    );
  }, []);

  return (
    <>
      <Grid container spacing={3} style={{ maxWidth: "1000px" }}>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.fromAmount}
            helperText={errors.fromAmount}
            label={t('user.deposit.from')}
            amount={fields.fromAmount}
            onAmountChange={(val) => handleInputFromAmountChange(val)}
            currency={fields.fromCurrency}
            onCurrencyChange={(val) => handleFromCurrencyChange(val)}
            currencies={currencies}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.toAmount}
            helperText={errors.toAmount}
            label={t('user.deposit.to')}
            amount={fields.toAmount}
            onAmountChange={(val) => handleInputToAmountChange(val)}
            currency={fields.toCurrency}
            onCurrencyChange={(val) => setFields({ ...fields, toCurrency: val })}
            currencies={[{ id: "OERO" }]}
            fullWidth
            dropdownDisabled
          />
        </Grid>
				<Grid item xs={12}>
					<CardWrapper>
						<VisaCard>
							<div className="card-header">
								<div className="card-logo">{t("user.payment.cardDetails")}</div>
								<div className="visa-logo">Visa/Mastercard</div>
							</div>
							<div className="card-fields">
								<CardGrid container spacing={3}>
									{/* Поле Card Number */}
									<Grid item xs={12} sm={12}>
										<InputMask
											mask="9999 9999 9999 9999"
											value={fields.cardNumber}
											onChange={(e) => handleInputCardPanChange(e.target.value)}
										>
											{(inputProps: any) => (
												<StyledCardTextField
													{...inputProps}
													error={!!errors.cardNumber}
													helperText={errors.cardNumber}
													label={t("user.payment.cardNumber")}
													variant="outlined"
													InputLabelProps={{style: {color: 'white'}}}
													fullWidth
													inputProps={{
														...inputProps.inputProps,
														style: { color: 'white' },
													}}
												/>
											)}
										</InputMask>
									</Grid>

									{/* Поле Holder Name */}
									<Grid item xs={12} sm={12}>
										<StyledCardTextField
											error={!!errors.holderName}
											helperText={errors.holderName}
											label={t("user.payment.holderName")}
											variant="outlined"
											fullWidth
											value={fields.holderName}
											onChange={(e) => handleInputCardHolderChange(e.target.value)}
											inputProps={{ style: { color: 'white' } }} // Стиль текста внутри
											InputLabelProps={{style: {color: 'white'}}}
										/>
									</Grid>

									{/* Поле CVV с маской */}
									<Grid item xs={12} sm={6}>
										<InputMask
											mask="999"
											value={fields.cvv}
											onChange={(e) => handleInputCardCvvChange(e.target.value)}
										>
											{(inputProps: any) => (
												<StyledCardTextField
													{...inputProps}
													error={!!errors.cvv}
													helperText={errors.cvv}
													label={t("user.payment.cvv")}
													variant="outlined"
													fullWidth
													InputLabelProps={{style: {color: 'white'}}}
													inputProps={{
														...inputProps.inputProps,
														style: { color: 'white' },
													}}
												/>
											)}
										</InputMask>
									</Grid>

									{/* Поле Expiry Date с маской "MM/YYYY" */}
									<Grid item xs={12} sm={6}>
										<InputMask
											mask="99/9999"
											value={fields.expiryDate}
											onChange={(e) => handleInputExpiredDateChange(e.target.value)}
										>
											{(inputProps: any) => (
												<StyledCardTextField
													{...inputProps}
													error={!!errors.expiryDate}
													helperText={errors.expiryDate}
													label={t("user.payment.expiryDate")}
													variant="outlined"
													fullWidth
													InputLabelProps={{style: {color: 'white'}}}
													inputProps={{
														...inputProps.inputProps,
														style: { color: 'white' },
													}}
												/>
											)}
										</InputMask>
									</Grid>
								</CardGrid>
							</div>
						</VisaCard>
					</CardWrapper>
				</Grid>
      </Grid>

      {/* Поле комментария */}
      <Grid container spacing={3} style={{ maxWidth: "1000px", marginTop: "20px" }}>
        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.comment}
            helperText={errors.comment}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            maxRows={6}
            label={t('user.deposit.comment')}
            value={fields.comment}
            onChange={(e) => setFields({ ...fields, comment: e.target.value })}
          />
        </Grid>
      </Grid>
			
    </>
  );
};
