import React from "react";
import { Redirect, RouteComponentProps } from "@reach/router";

import { Users } from "./admin/users/Users";
import { Transactions } from "./admin/transactions/Transactions";
import { DailyStatements } from "./admin/daily-statements/DailyStatements";
import { Messages } from "./admin/messages/Messages";
import { BlogPosts } from "./admin/blog-posts/BlogPosts";
import { Kyc } from "./admin/kyc/Kyc";

import { Token } from "./token/Token";

import { Home } from "./user/home/Home";
import { Deposit } from "./user/deposit/Deposit";
import Withdraw from "./user/withdraw/Withdraw";
import History from "./user/history/History";
import { Settings } from "./user/settings/Settings";
import Support from "./user/support/Support";

const Default: React.FC<RouteComponentProps> = () => {
  return <Redirect to={"./home"} />;
};

export const routes = [
  <Users key="users" path="users" />,
  <Transactions key="transactions" path="transactions" />,
  <DailyStatements key="daily-statements" path="daily-statements" />,
  <Messages key="messages" path="messages" />,
  <BlogPosts key="blog-posts" path="blog-posts" />,
  <Kyc key="kyc" path="kyc" />,

  <Token key="token" path="token" />,

  <Home key="home" path="home" />,
  <Deposit key="deposit" path="deposit" />,
  <Withdraw key="withdraw" path="withdraw" />,
  <History key="history" path="history" />,
  <Settings key="settings" path="settings" />,
  <Settings key="settings" path="settings/:tabNo" />,
  <Support key="support" path="support" />,

  <Default key="default" path="def" />,
  <Redirect noThrow default key="default" from="/" to="/private/home" />,
];
