import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {handleFileChange} from "../../components/common/FileUploaderHelpers";
import {SimpleFileUploader} from "../../components/common/SimpleUploaderFile";
import {RichText} from '../../components/common/RichText';
import {DateInput} from '../../components/common/DateInput';
import Switch from "@material-ui/core/Switch";

const Row = styled.div`
  display: flex;
`;

const EditWrap = styled.div`
	margin-top: 24px;
	
	.ql-editor {
		min-height: 140px;
		max-height: 260px;
		overflow-y: auto;
	}
`;

const initFields = {
	id: '',
	title: '',
	shortText: '',
	published: new Date(),
	active: true,
	text: ''
};

interface ControlProps {
	item: any;
	onClose: () => void;
	onSave: (item: any) => void;
	inProcess?: boolean
}

export const BlogPostCard: React.FC<ControlProps> = (props) => {

	const [fields, setFields] = useState<any>({...initFields});
	const [errors] = useState<any>({});
	const [file, setFile] = useState<any>(null);
	const [fileErr, setFileErr] = useState('');
	const [value, setValue] = useState('');

	useEffect(() => {
		if (props.item) {
			setFields({...props.item});
			if (props.item.text) setValue(props.item.text);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const saveHandler = () => {
		let res = {...fields};
		res.text = value;
		res._file = file;

		props.onSave(res);
	}; // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Row style={{marginBottom: '24px'}}>
				<Typography variant={"h3"}>
					Edit Blog Posts
				</Typography>
			</Row>


			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<FormControlLabel
						control={
							<Switch
								color="primary"
								checked={fields.active}
								// onChange={() => setIsEnabled(old => !old)}
								onChange={e => setFields({...fields, active: e.target.checked})}
							/>
						}
						label="Active"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<DateInput
						error={!!errors.published}
						label="Date"
						value={fields.published}
						onChange={(date: Date | null) => setFields({...fields, published: date})}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						error={!!errors.title}
						helperText={errors.title}
						variant='outlined'
						fullWidth={true}
						label='Title'
						value={fields.title}
						onChange={e => setFields({...fields, title: e.target.value})}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						error={!!errors.shortText}
						helperText={errors.shortText}
						variant='outlined'
						fullWidth
						label='Short text'
						value={fields.shortText}
						onChange={e => setFields({...fields, shortText: e.target.value})}
					/>
				</Grid>

				<Grid item xs={12} style={{
					display: 'flex',
					alignItems: 'center'
				}}>
					<SimpleFileUploader
						errText={fileErr}
						variant={'outlined'}
						label={'Browse file'}
						onChange={e => handleFileChange(e, setFile, setFileErr)}
					/>
					<Typography
						variant="body1"
						style={{marginLeft: '32px', opacity: 0.6}}
					>
						Please, upload image with resolution 680x350
					</Typography>

				</Grid>
			</Grid>

			<EditWrap>
				{/*<ReactQuill*/}
				{/*	theme="snow"*/}
				{/*	value={value}*/}
				{/*	onChange={setValue}*/}
				{/*	// value={fields.text}*/}
				{/*	// onChange={val => setFields({...fields, text: val})}*/}
				{/*/>*/}
				<RichText
					text={value}
					onChange={setValue}
				/>
			</EditWrap>

			<Row style={{marginTop: '24px'}}>
				<Button
					variant="contained"
					color="primary"
					size={"large"}
					style={{minWidth: '240px'}}
					onClick={() => saveHandler()}
					disabled={props.inProcess}
				>
					save
				</Button>

				<Button
					style={{minWidth: '120px', marginLeft: '24px'}}
					variant="outlined"
					color="primary"
					size={"large"}
					onClick={() => props.onClose()}
				>
					cancel
				</Button>
			</Row>
		</>
	);

};
