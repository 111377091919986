import React from "react";
import { RouteComponentProps } from "@reach/router";

interface ComponentProps extends RouteComponentProps {
  fields: any;
  action: string;
  submitRef: any;
}

export const Advcash: React.FC<ComponentProps> = ({
  fields,
  action,
  submitRef,
}) => {
  return (
    <form method="post" action={action} target="_blank">
      <input
        hidden
        readOnly
        type="text"
        name="ac_account_email"
        defaultValue={fields.ac_account_email}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_sci_name"
        defaultValue={fields.ac_sci_name}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_amount"
        defaultValue={fields.ac_amount}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_currency"
        defaultValue={fields.ac_currency}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_order_id"
        defaultValue={fields.ac_order_id}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_sign"
        defaultValue={fields.ac_sign}
      />
      {/*Optional Fields*/}
      <input
        hidden
        readOnly
        type="text"
        name="ac_success_url"
        defaultValue={fields.ac_success_url}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_success_url_method"
        defaultValue={fields.ac_success_url_method}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_fail_url"
        defaultValue={fields.ac_fail_url}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_fail_url_method"
        defaultValue={fields.ac_fail_url_method}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_status_url"
        defaultValue={fields.ac_status_url}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_status_url_method"
        defaultValue={fields.ac_status_url_method}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_comments"
        defaultValue={fields.ac_comments}
      />
      <input
        hidden
        readOnly
        type="text"
        name="ac_ps"
        defaultValue={fields.ac_ps}
      />
      <button hidden type="submit" ref={submitRef}>
        submit
      </button>
    </form>
  );
};
