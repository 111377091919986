import React, { useEffect, useState } from "react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import styled from "styled-components";
import { HeadCell, Grid } from "../../../../core/ui/grids/Grid";
import { PageWrap } from "../../components/common/Pages";
import { UserCard } from "./UserCard";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  MutationUpdateUserArgs,
  User,
} from "../../../../store/generated-models";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import { formatAmount, formatValue } from "../../helpers/Format";
import { UserDetails } from "./UserDetails";
import { useSnackbar } from "notistack";
import { KycCard } from "../kyc/KycCard";
import { format } from "date-fns";
import {KycProcess} from "../../user/settings/kyc/KycProcess";
import {log} from "util";

const ITEM_PER_PAGE: number = 20;

const InfoIconStyled = styled(InfoIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

const Row = styled.div`
  display: flex;
`;

const LoadWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const gqlUpdateUser = gql`
  mutation updateUser(
    $avatar: String
    $birthday: DateTime
    $deleted: Boolean
    $email: String
    $name: String
    $roles: [String!]
    $termsOfUse: Boolean
    $userId: ID!
    $merchantApiKey: String
  ) {
    updateUser(
      avatar: $avatar
      birthday: $birthday
      deleted: $deleted
      email: $email
      name: $name
      roles: $roles
      termsOfUse: $termsOfUse
      userId: $userId
      merchantApiKey: $merchantApiKey
    ) {
      accessFailedCount
      avatar
      birthday
      blockchain
      created
      deleted
      deletedDate
      email
      emailConfirmed
      ethPrivateKey
      firstName
      hasEmailAuth
      is2faEnabled
      lastName
      name
      nameConfirmed
      roles
      state {
        ethBalance
        trxBalance
        contractBalance
      }
      termsOfUse
      updated
      userId
      userType
      valid
      validationDate
      merchantApiKey
    }
  }
`;

// users(desc: Boolean, filter: String, first: Int, orderBy: String, skip: Int): [User]!
const gqlUsers = gql`
  query users($first: Int, $skip: Int, $filter: String) {
    users(
      first: $first
      orderBy: "created"
      desc: true
      skip: $skip
      filter: $filter
    ) {
      count
      users {
        accessFailedCount
        avatar
        beneficiaryCount
        birthday
        blockchain
        countryCode
        created
        deleted
        deletedDate
        email
        emailConfirmed
        ethAddress
        ethPrivateKey
        files {
          approved
          created
          doesFileExist
          encoding
          fileId
          fileName
          fileSize
          linkedId
          mimeType
          order
          originExtension
          originFileName
          originFileNameWithoutExtension
          type
          url
        }
        firstName
        hasEmailAuth
        is2faEnabled
        kycName
        lastName
        login {
          date
        }
        name
        nameConfirmed
        phoneNumber
        roles
        state {
          contractBalance
          ethBalance
          trxBalance
        }
        termsOfUse
        trxAddress
        trxPrivateKey
        updated
        userId
        userType
        valid
        validatedByUser
        validationDate
        merchantApiKey
      }
    }
  }
`;

const headCells: HeadCell[] = [
  { id: "email", label: "Email" },
  { id: "name", label: "Name", style: { maxWidth: "200px" } },
  {
    id: "roles",
    label: "Roles",
    style: { maxWidth: "110px", wordBreak: "break-word" },
  },
  {
    id: "created",
    label: "Created",
    style: { wordBreak: "break-word", width: "110px" },
  },
  { id: "ethAddress", label: "ETH address" },
  { id: "ethBalance", label: "Balance ETH", align: "right" },
  { id: "trxBalance", label: "Balance TRX", align: "right" },
  { id: "contractBalance", label: "Balance OERO", align: "right" },
  { id: "blockchain", label: "Blockchain" },
  { id: "trxAddress", label: "TRX address" },
  { id: "valid", label: "KYC Valid" },
  {
    id: "login",
    label: "Last login",
    style: { wordBreak: "break-word", width: "110px" },
  },
];

export const Users: React.FC<RouteComponentProps> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [mode, setMode] = useState("");
  const [item, setItem] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [shownItems, setShownItems] = useState<any[]>([]);
  const [filter, setFilter] = useState("");
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(0);

  const { loading, error, data, refetch } = useQuery(gqlUsers, {
    variables: {
      first: 10 /*ITEM_PER_PAGE*/,
      skip: 0 /*(page - 1) * ITEM_PER_PAGE*/,
      filter: filter,
    },
  });

  const getQueryParams = (queryParam: string) => {
    const search = new URLSearchParams(location.search);
    return search.get(queryParam);
  };

  // const [updateUser, {
  // 	loading: updateUserLoading
  // }] = useMutation<{ updateUser: User }, MutationUpdateUserArgs>(
  // 	gqlUpdateUser,
  // 	{
  // 		update(cache, {data: {updateUser}}) {
  // 			const {users} = cache.readQuery({query: gqlUsers});
  // 			cache.writeQuery({
  // 				query: gqlUsers,
  // 				data: {
  // 					users: users.map((el: any) => {
  // 						if (el.userId === updateUser.userId) {
  // 							return updateUser;
  // 						}
  // 						return el;
  // 					})
  // 				},
  // 			});
  // 		}
  // 	}
  // );

  const [updateUser, { loading: updateUserLoading }] = useMutation<
    { updateUser: User },
    MutationUpdateUserArgs
  >(gqlUpdateUser);

  useEffect(() => {
    refetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) console.warn(">> Users useEffect error", error);
  }, [error]);

  useEffect(() => {
    if (!loading && data && data.users && data.users.count !== undefined) {
      const totalCnt = data.users.count;
      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      if (data && data.users) {
        let res: any[] = [];

        data.users.users.forEach((el: any) => {
          let resEl: any = {};
          for (let key in el) {
            if (key === "state") {
              resEl.ethBalance = formatAmount(el.state.ethBalance, 5);
              resEl.trxBalance = formatAmount(el.state.trxBalance, 5);
              resEl.contractBalance = formatAmount(el.state.contractBalance);
            } else if (key === "files") {
              resEl._files = el.files ? el.files : [];
            } else if (key === "roles") {
              resEl.roles = formatValue(el.roles);
              resEl._roles = el.roles;
              resEl._admin =
                el.roles.findIndex((val: string) => val === "ADMIN") !== -1;
              resEl._blogger =
                el.roles.findIndex((val: string) => val === "BLOGGER") !== -1;
            } else if (key === "valid") {
              resEl.valid =
                el.valid === true ? "Y" : el.valid === false ? "N" : "";
            } else if (key === "login") {
              resEl.login = el.login
                ? format(
                    new Date(el.login[el.login.length - 1].date),
                    "dd.MM.yyyy, HH:mm"
                  )
                : "";
            } else {
              resEl[key] = formatValue(el[key]);
            }
          }
          res.push(resEl);
        });

        setItems(res);
        // console.log(
        //   (page - 1) * ITEM_PER_PAGE,
        //   page * ITEM_PER_PAGE <= res.length - 1
        //     ? page * ITEM_PER_PAGE
        //     : res.length
        // );
        setShownItems(
          res.slice(
            (page - 1) * ITEM_PER_PAGE,
            page * ITEM_PER_PAGE <= res.length - 1
              ? page * ITEM_PER_PAGE
              : res.length
          )
        );
        const params = getQueryParams("user");
        if (params) {
          //console.log(params);
          setItem(res.find((item) => item.email === params));
          setMode("card");
        }
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // const addItemHandler = () => {
  // 	setItem(null);
  // 	setMode('card');
  // };

  const editItemHandler = (val: any) => {
    setItem(val);
    setMode("card");
  };

  const itemSaveHandler = async (val: any) => {
    let deletedModified = val.deleted !== (item.deleted === "Y");
    let rolesModified =
      item._roles.sort().toString() !== val.roles.sort().toString();

    let apiKeyModified = val.merchantApiKey !== item.merchantApiKey;

    if (!deletedModified && !rolesModified && !apiKeyModified) return;

    let variables: any = { userId: item.userId };

    if (rolesModified) {
      variables.roles = val.roles;
    }

    if (deletedModified) {
      variables.deleted = val.deleted;
    }

    if (apiKeyModified) {
      variables.merchantApiKey = val.merchantApiKey;
    }

    // edit item
    let errMessage = "";
    try {
      const res = await updateUser({ variables: variables });
      if (res.data) {
        navigate("users").then();
        refetch().then();
        setMode("");
        return;
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
    setShownItems(
      items.slice(
        (value - 1) * ITEM_PER_PAGE,
        value * ITEM_PER_PAGE <= items.length - 1
          ? value * ITEM_PER_PAGE
          : items.length - 1
      )
    );
  };

  const searchHandler = (val: string) => {
    if (loading) return;

    setPage(1);
    // setShownItems(
    //   items.slice(
    //     0,
    //     ITEM_PER_PAGE <= items.length - 1 ? ITEM_PER_PAGE : items.length - 1
    //   )
    // );
    setFilter(val);
  };

  if (!data && loading) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  if (mode === "card")
    return (
      <PageWrap>
        <UserCard
          item={item}
          onClose={() => {
            navigate("users").then();
            setMode("");
          }}
          onDetails={() => setMode("details")}
          onKYC={() => setMode("kyc")}
          onCreateKYC={(val: React.SetStateAction<string>) => setMode(val)}
          onSave={itemSaveHandler}
          inProcess={updateUserLoading}
        />
      </PageWrap>
    );

  if (mode === "kyc")
    return (
      <PageWrap>
        <KycCard readOnly item={item} onClose={(val) => setMode("card")} />
      </PageWrap>
    );

  if (mode === "createKyc")
    return (
      <PageWrap>
        {console.log(item)}
        <KycProcess
            isEdit={false}
            kycUserId={item.userId}
            onClose={(val) => setMode("card")}
        />
      </PageWrap>
    );
  if (mode === "updateKyc")
    return (
        <PageWrap>
          {console.log(item)}
          <KycProcess
              isEdit={true}
              kycUserId={item.userId}
              onClose={(val) => setMode("card")}
          />
        </PageWrap>
    );




  if (mode === "details")
    return (
      <PageWrap>
        <UserDetails item={item} onClose={() => setMode("card")} />
      </PageWrap>
    );

  return (
    <PageWrap>
      <Row style={{ marginBottom: "12px" }}>
        <Typography variant={"h3"}>Users</Typography>
      </Row>
      <Grid
        headCells={headCells}
        items={shownItems}
        idField="userId"
        page={page}
        pageCnt={pageCnt}
        onPageChange={onPageChangeHandler}
        actions={[
          {
            id: "info",
            type: "icon",
            icon: <InfoIconStyled />,
            onAction: editItemHandler,
          },
        ]}
        extSearch
        extSearchValue={filter}
        onSearchApply={searchHandler}
      />
    </PageWrap>
  );
};
