import React from "react";
import styled from "styled-components";

import TextField from "@material-ui/core/TextField";
import { Dropdown } from "./Dropdown";

const Root = styled(TextField)`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
    background-color: white;

    .MuiFormControl-root {
      fieldset {
        border-top: none;
        border-right: none;
        border-bottom: none;
      }
    }
  }

  .MuiFormControl-root {
    min-width: 142px;
  }

  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }

  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.6);
    background-color: #f4f4f4;
    .MuiSvgIcon-root {
      display: none;
    }
  }
`;

interface ControlProps {
  label?: any;
  amount: string;
  onAmountChange: (value: string) => void;
  currency: string;
  onCurrencyChange: (value: string) => void;
  currencies: any[];
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  dropdownDisabled?: boolean;
	disabled?: boolean;

  // onChange: (value: string) => void,
  // label?: any;
  // labelWidth?: number;
  // variant?: 'standard' | 'outlined' | 'filled';
  // style?: any;
}

export const CurrencyInput: React.FC<ControlProps> = (props) => {
  return (
    <Root
      variant="outlined"
      fullWidth={props.fullWidth}
      label={props.label}
      value={props.amount}
      onChange={(e) => props.onAmountChange(e.target.value)}
      error={props.error}
      helperText={props.helperText}
			disabled={props?.disabled}
      InputProps={{
        endAdornment: (
          <Dropdown
            // style={{width: '60px'}}
            // variant="standard"
            values={props.currencies}
            value={props.currency}
            disabled={props.dropdownDisabled}
            onChange={(val) => props.onCurrencyChange(val)}
          />
        ),
      }}
    />
  );
};
