import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";

import { HeadCell, Grid } from "../../../../core/ui/grids/Grid";
import { PageWrap } from "../../components/common/Pages";

import { KycCard } from "./KycCard";
import { Decline } from "./Decline";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  MutationUpdateUserKycStatusArgs,
  UserKyc,
} from "../../../../store/generated-models";
import Typography from "@material-ui/core/Typography";

import InfoIcon from "@material-ui/icons/Info";

import { formatValue } from "../../helpers/Format";
import { getCountryByCode } from "../../../../core/ui/CountrySelector";
import { useSnackbar } from "notistack";

const ITEM_PER_PAGE: number = 20;

const InfoIconStyled = styled(InfoIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

const Row = styled.div`
  display: flex;
`;

const gqlGetKyc = gql`
  query gqlGetKyc($first: Int, $skip: Int, $filter: String) {
    getKyc(
      # notValidatedKycOnly: true,
      notValidatedKycOnly: false
      first: $first
      #            orderBy: "created",
      #            desc: true,
      skip: $skip
      filter: $filter
    ) {
      count
      kyc {
        beneficiaryCount
        countryCode
        created
        email
        files {
          approved
          fileId
          doesFileExist
          fileName
          originFileName
          originFileNameWithoutExtension
          originExtension
          type
          mimeType
          encoding
          fileSize
          linkedId
          order
          created
          url
        }
        kycCreated
        kycId
        kycName
        name
        phoneNumber
        userId
        userType
        valid
        validatedByUser
        validationDate
      }
    }
  }
`;

// updateUserKycStatus(comment: String, fileApprovals: [KycFileValidationList!], kycId: String!, valid: Boolean): UserKycResult
const gqlUpdateUserKycStatus = gql`
  mutation updateUserKycStatus(
    $comment: String
    $fileApprovals: [KycFileValidationList!]
    $kycId: String!
    $userName: String
    $valid: Boolean
  ) {
    updateUserKycStatus(
      comment: $comment
      fileApprovals: $fileApprovals
      kycId: $kycId
      userName: $userName
      valid: $valid
    ) {
      countryCode
      files {
        approved
        created
        doesFileExist
        encoding
        fileId
        fileName
        fileSize
        linkedId
        mimeType
        order
        originExtension
        originFileName
        originFileNameWithoutExtension
        type
        url
      }
      name
      phoneNumber
      userType
    }
  }
`;

const headCells: HeadCell[] = [
  { id: "email", label: "Email" },
  { id: "name", label: "Name" },
  // {id: 'phoneNumber', label: 'Phone'},
  { id: "country", label: "Country" },
  { id: "_status", label: "Status", colorAD: true },
  { id: "kycCreated", label: "Created", align: "right" },
];

export const Kyc: React.FC<RouteComponentProps> = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [mode, setMode] = useState(""); // ''
  const [item, setItem] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [filter, setFilter] = useState("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [inProcess, setInProcess] = useState(false);

  let userName = useRef(null);

  const { loading, error, data, refetch } = useQuery(gqlGetKyc, {
    variables: {
      first: ITEM_PER_PAGE,
      skip: (page - 1) * ITEM_PER_PAGE,
      filter: filter,
    },
  });

  const [updateUserKycStatus] = useMutation<
    { updateUserKycStatus: UserKyc },
    MutationUpdateUserKycStatusArgs
  >(gqlUpdateUserKycStatus);

  useEffect(() => {
    refetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) console.warn(">> Users useEffect error", error);
    if (error) console.warn(">> Users useEffect error", error);
  }, [error]);

  useEffect(() => {
    if (!loading && data && data.getKyc && data.getKyc.count !== undefined) {
      const totalCnt = data.getKyc.count;

      if (totalCnt === 0) {
        setPageCnt(1);
        setItems([]);
        return;
      }

      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      if (data && data.getKyc) {
        const res = data.getKyc.kyc.map((el: any) => {
          let res: any = {};
          for (let key in el) {
            if (key === "files") {
							res[key] = el.files
								? [...el.files].sort((a: any, b: any) => {
										if (a.type > b.type) return 1;
										if (a.type < b.type) return -1;
										return 0;
									})
								: [];
						} else if (key === "countryCode") {
              res[key] = el.countryCode;
              res.country = getCountryByCode(el.countryCode).label;
            } else if (key === "valid") {
              res.valid = el.valid;
              res._status =
                el.valid === null ? "" : el.valid ? "accepted" : "declined";
            } else {
              res[key] = formatValue(el[key]);
            }
            if (el.processingStatus === 1) res._actions = ["status"];
          }

          return res;
        });
        setItems(res);
        if (mode === "card") {
          setItem(res.find((val: any) => val.kycId === item.kycId));
        }
      } else {
        setItems([]);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUserKycStatusProcess = async (
    valid: boolean,
    fileApprovals: any[],
    comment?: string
  ) => {
    setInProcess(true);

    let variables: any = {
      kycId: item.kycId,
      valid: valid,
      fileApprovals: fileApprovals,
      comment: comment,
      userName: userName.current,
    };

    //console.log(variables);

    let errMessage = "";
    try {
      const res = await updateUserKycStatus({ variables: variables });

      if (res.data.updateUserKycStatus) {
        refetch().then();
        setMode("");
        setInProcess(false);
        return;
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    setInProcess(false);
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const onCardCloseHandler = (
    val: "accept" | "decline" | "update" | null,
    fileApprovals: any[],
    kycName: string
  ) => {
    userName.current = kycName;

    // cancel
    if (val === null) {
      setFileList([]);
      setMode("");
      //refetch().then();
      return;
    }

    // decline
    if (val === "decline") {
      setFileList(fileApprovals);
      setMode("declined");
      return;
    }

    //update
    if (val === "update") {
      setFileList([]);
      refetch().then();
      setMode("");
      return;
    }
    // accept
    updateUserKycStatusProcess(true, fileApprovals).then();
  };

  const onDeclineCloseHandler = (comment: string) => {
    if (comment === null) {
      setMode("card");
      return;
    }

    updateUserKycStatusProcess(false, fileList, comment).then();
  };

  const infoItemHandler = (item: any) => {
    setItem(item);
    setMode("card");
  };

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
  };

  const searchHandler = (val: string) => {
    if (loading) return;

    setPage(1);
    setFilter(val);
  };

  if (mode === "")
    return (
      <PageWrap>
        <Row style={{ marginBottom: "12px" }}>
          <Typography variant={"h3"}>Users KYC</Typography>
        </Row>
        <Grid
          headCells={headCells}
          items={items}
          idField="userId"
          page={page}
          pageCnt={pageCnt}
          onPageChange={onPageChangeHandler}
          actions={[
            {
              id: "info",
              type: "icon",
              icon: <InfoIconStyled />,
              onAction: infoItemHandler,
            },
          ]}
          extSearch
          extSearchValue={filter}
          onSearchApply={searchHandler}
        />
      </PageWrap>
    );

  if (mode === "card")
    return (
      <PageWrap>
        <KycCard
          item={item}
          fileList={fileList}
          onClose={onCardCloseHandler}
          inProcess={inProcess}
        />
      </PageWrap>
    );

  if (mode === "declined")
    return (
      <PageWrap>
        <Decline onClose={onDeclineCloseHandler} inProcess={inProcess} />
      </PageWrap>
    );
};
