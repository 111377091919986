interface Config {
  apiUrl: string;
  apiUrlWS: string;
  apiUrlRest: string;
  recaptchaSiteKey: string;
  googleSiteId: string;
  facebookSiteId: string;
  markets: {
    [attribute: string]: {
      pricePrecision: number;
      minTradeAmount: number;
    };
  };
  symbols: {
    [attribute: string]: {
      amountPrecision: number;
    };
  };
}

const envConfig = {
  local: {
    apiUrl: "http://localhost:3001/gql/api",
    apiUrlWS: "ws://localhost:3001/subscriptions",
    apiUrlRest: "http://localhost:3001/",
    recaptchaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    googleSiteId:
      "933100312195-1v2ulsliddmbhqbkh84bg36k5q6krmv3.apps.googleusercontent.com",
    facebookSiteId: "964684310657736",
    markets: {},
    symbols: {},
  },
  // dev: {
  //   apiUrl: "htt /gql/api",
  //   apiUrlWS: "wss://dev.test.oero.io/subscriptions",
  //   apiUrlRest: "https://dev.test.oero.io/",
  //   recaptchaSiteKey: "6LfPELgZAAAAAKOvL9c4Lotg9TgwpMhtxUEAi0Gv",
  //   googleSiteId:
  //     "933100312195-1v2ulsliddmbhqbkh84bg36k5q6krmv3.apps.googleusercontent.com",
  //   facebookSiteId: "964684310657736",
  //   markets: {},
  //   symbols: {},
  // },
  dev: {
    apiUrl: "https://test.oero.io/gql/api",
    apiUrlWS: "wss://test.oero.io/subscriptions",
    apiUrlRest: "https://test.oero.io/",
    recaptchaSiteKey: "6LfPELgZAAAAAKOvL9c4Lotg9TgwpMhtxUEAi0Gv",
    googleSiteId:
      "250200921243-5743vlb0td6knp5beu2qo8m9fi0bhfu2.apps.googleusercontent.com",
    facebookSiteId: "763571170943966",
    markets: {},
    symbols: {},
  },
  pre: {
    apiUrl: "https://api.pre.oero.io/gql/api",
    apiUrlWS: "wss://api.pre.oero.io/subscriptions",
    apiUrlRest: "https://api.pre.oero.io/",
    recaptchaSiteKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
    googleSiteId:
      "933100312195-1v2ulsliddmbhqbkh84bg36k5q6krmv3.apps.googleusercontent.com",
    facebookSiteId: "964684310657736",
    markets: {},
    symbols: {},
  },
  //OLD CONFIGURATION FOR PRODUCTION
  // prod: {
  //   apiUrl: "https://api.oero.io/gql/api",
  //   apiUrlWS: "wss://api.oero.io/subscriptions",
  //   apiUrlRest: "https://api.oero.io/",
  //   recaptchaSiteKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  //   googleSiteId:
  //     "933100312195-1v2ulsliddmbhqbkh84bg36k5q6krmv3.apps.googleusercontent.com",
  //   facebookSiteId: "763571170943966",
  //   markets: {},
  //   symbols: {},
  // },
  prod: {
    apiUrl: "https://api.oero.io/gql/api",
    apiUrlWS: "wss://api.oero.io/subscriptions",
    apiUrlRest: "https://api.oero.io/",
    recaptchaSiteKey: "6LfPELgZAAAAAKOvL9c4Lotg9TgwpMhtxUEAi0Gv",
    googleSiteId:
      "250200921243-5743vlb0td6knp5beu2qo8m9fi0bhfu2.apps.googleusercontent.com",
    facebookSiteId: "763571170943966",
    markets: {},
    symbols: {},
  },
};

let config: Config;

switch (process.env.REACT_APP_STAGE) {
  case "dev":
    config = envConfig.dev;
    break;
  case "pre":
    config = envConfig.pre;
    break;
  case "prod":
    config = envConfig.prod;
    break;
  default:
    config = envConfig.local;
}

// precision: "this affects price column, in orderbooks - price, in last trades - price and in trading panel - price"
config.markets = {
  default: {
    pricePrecision: 6,
    minTradeAmount: 0.000001,
  },
};

// precision: "my assets related columns, amount columns in trade panel & orderbooks  &  lasttrade, withdrawals, trading history, etc"
config.symbols = {
  default: {
    amountPrecision: 6,
  },
};

export default Object.freeze(config);
