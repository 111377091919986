import React, { useContext, useState } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useMutation } from "@apollo/react-hooks";
import { LoginResult } from "../../../../store/generated-models";
import { gql } from "@apollo/client";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {ChangePassword} from "./ChangePassword";
import {t} from "i18next";

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const gqlDisable2fa = gql`
  mutation Disable2fa($code: String!, $password: String!) {
    disable2fa(code: $code, password: $password) {
      user {
        userId
        is2faEnabled
      }
    }
  }
`;

export interface ComponentProps {
  onCancel: () => void;
}

const Step2faDisable: React.FC<ComponentProps> = ({ onCancel }) => {
  const authContext = useContext(AuthContext);
  const [disable2fa] = useMutation<{ disable2fa: LoginResult }>(gqlDisable2fa);
  const [authCode, setAuthCode] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const setDisable2fa = async () => {
    let errMessage: string = "";
    try {
      const res = await disable2fa({
        variables: {
          code: authCode,
          password: "ignored",
        },
      });

      if (res.data.disable2fa.user.is2faEnabled === false) {
        authContext.updateUser({ is2faEnabled: false });
        enqueueSnackbar(t('user.settings.disabled_2FA'), { variant: "success" });
        return;
      } else {
        errMessage = t('user.settings.disabled_2FA_err_message');
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  return (
    <>
      <Row>
        <Typography variant={"body1"}>
          {t('user.settings.title_2fa')}
        </Typography>
      </Row>
      <Row>
        <StyledTextField
          style={{ width: "240px" }}
          variant="outlined"
          label={t('user.settings.qr')}
          value={authCode}
          onChange={(e) => setAuthCode(e.target.value)}
        />
      </Row>
      <Row>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          disabled={!authCode}
          style={{ width: "240px", minHeight: "50px" }}
          onClick={setDisable2fa}
        >
          {t('user.settings.disable_2fa')}
        </Button>
        <Button
          style={{ width: "120px", marginLeft: "20px", minHeight: "50px" }}
          variant="outlined"
          color="primary"
          size={"large"}
          onClick={() => onCancel()}
        >
          {t('user.settings.cancel')}
        </Button>
      </Row>
    </>
  );
};

export default withTranslation()(Step2faDisable)
