import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import {gql} from "@apollo/client";
import {useMutation} from "@apollo/react-hooks";
import {MutationUpdateMeArgs, User} from "../../../../store/generated-models";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../../../core/providers/AuthProvider";
import {withTranslation} from "react-i18next";
import {t} from "i18next";
import {Dropdown} from "../../components/common/Dropdown";
import {BLOCKCHAIN} from "../../../../core/constants/options";

const Row = styled.div`
  display: flex;
`;

const gqlUpdateMe = gql`
    mutation updateMe(
        $avatar: String 
				$birthday: DateTime 
				$blockchain: String 
				$email: String
				$name: String 
				$termsOfUse: Boolean
    ) {
        updateMe(
						avatar: $avatar
						birthday: $birthday
						blockchain: $blockchain
						email: $email
						name: $name
						termsOfUse: $termsOfUse
        ) {
            accessFailedCount
            avatar
            beneficiaryCount
            birthday
            blockchain
            changePasswordRequired
            countryCode
            created
            deleted
            deletedDate
            email
            emailConfirmed
            ethAddress
            ethPrivateKey
            files {
                approved
                created
                doesFileExist
                encoding
                fileId
                fileName
                fileSize
                linkedId
                mimeType
                order
                originExtension
                originFileName
                originFileNameWithoutExtension
                type
                url
            }
            firstName
            hasEmailAuth
            is2faEnabled
            kycComment
            kycName
            lastName
            login {
                date
						}
            merchantApiKey
            name
            nameConfirmed
            phoneNumber
            referralCode
            roles
            state {
                ethBalance
                contractBalance
            }
            termsOfUse
            trxAddress
            trxPrivateKey
            updated
            userId
            userType
            valid
            validatedByUser
            validationDate
        }
    }
`;



// Основной смысл в том, что у пользователя в настройках должен появиться комбобокс с вариантами блокчейна (enum Blockchain в графкуэле),
// соответственно, он может выбрать себе, в какой сети будет работать. В updateMe и в updateUser я этот параметр пробросил. Это первое.
// И второе - в админке в таблицу транзакций добавить поле Blockchain и фильтр по нему. У каждой транзакции это поле уже есть.
// Все остальное апи должно уже делать по идее

export const Common: React.FC<RouteComponentProps> = () => {
	const authContext = useContext(AuthContext);

	const {enqueueSnackbar} = useSnackbar();
	const showSnackbarErr = (message: string, opt: any = {}) => {
		enqueueSnackbar(message, {...{variant: "error"}, ...opt});
	};

	const [updateMe, { loading: updateMeLoading }] = useMutation<
		{ updateMe: User },
		MutationUpdateMeArgs
		>(gqlUpdateMe);

	const [blockchain, setBlockchain] = useState<any>('');
	const [errors, setErrors] = useState<any>({});

	useEffect(() => {
		if (authContext.user.blockchain) {
			setBlockchain(authContext.user.blockchain);
		}
	}, [authContext.user]);

	const applyClickHandler = async () => {
		let variables: any = {
			// blockchain: blockchain.toString().toUpperCase()
			blockchain: blockchain
		};

		let errMessage = "";
		try {
			const res = await updateMe({ variables: variables });
			if (res.data) {
				// console.log('>>> res.data', res.data);
				enqueueSnackbar("Updated", { variant: "success" });
				return;
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === "auth.access_denied") errMessage = "Access denied";
				if (!errMessage && error.graphQLErrors[0].message)
					errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {}
		}

		if (!errMessage) errMessage = "Unknown error";
		enqueueSnackbar(errMessage, { variant: "error" });
	};

	return (
		<>
			<Grid container spacing={3} style={{marginTop: "4px"}}>
				<Grid item xs={12} md={6}>
					<Dropdown
						style={{
							width: "160px",
							marginRight: "auto",
						}}
						label="Blockchain"
						values={BLOCKCHAIN}
						value={blockchain}
						onChange={val => setBlockchain(val)}
					/>
				</Grid>

			</Grid>

			<Row style={{marginTop: "24px"}}>
				<Button
					variant="contained"
					color="primary"
					size={"large"}
					style={{minWidth: "240px", minHeight: "50px"}}
					onClick={applyClickHandler}
				>
					{t('user.settings.confirm_btn')}
				</Button>
			</Row>
		</>
	);
};

export default withTranslation()(Common)
