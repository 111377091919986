/* eslint-disable */

import React from "react";
import { AuthContext } from "../../../core/providers/AuthProvider";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

class Footer extends React.Component {
  static contextType = AuthContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
    };

    this.logout.bind(this);
  }

  componentDidMount() {
    // document.body.classList.add('admin-page');
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  logout() {
    this.context.logout();
  }

  render() {

    const lang = localStorage.getItem('lang')
    return (
      <>
        <footer
          className="page-footer text-dark font-small pt-5"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container text-md-left">
            <div className="row">
              <div className="col-sm-6 col-lg-3 mt-md-0 mt-3 footer-col">
                <a className="navbar-brand" href="/">
                  <img
                    src="/img/logo-footer.png"
                    alt="Oero"
                    className="footer-img"
                  />
                </a>
                <div className="mt-2 first-footer-column">
                  <a
                      className="footer-link"
                      href="https://mtr.mkm.ee/juriidiline_isik/256426?backurl=%2Fjuriidiline_isik"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                   {t('footer.footer_oero_platform')}
                  </a>
                  <br />
                  <a
                    className="footer-link"
                    href="https://mtr.mkm.ee/juriidiline_isik/256426?backurl=%2Fjuriidiline_isik"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.footer_title')}
                  </a>
                  <br />
                  <a
                    className="footer-link"
                    href="https://mtr.mkm.ee/juriidiline_isik/256426?backurl=%2Fjuriidiline_isik"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.footer_address')}
                  </a>
                  <br />
                  <a
                    className="footer-link"
                    href="https://mtr.mkm.ee/juriidiline_isik/256426?backurl=%2Fjuriidiline_isik"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.footer_code')}
                  </a>
                  <br />
                  <br />

                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.exchange_operator')}
                  </a>
                  <br />
                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.footer_title_sec')}
                  </a>
                  <br />
                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.second_address')}
                  </a>
                  <br />
                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.krs')}
                  </a>
                  <br />
                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.nip')}
                  </a>
                  <br />
                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.regon')}
                  </a>
                  <br />
                  <a
                      className="footer-link"
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.lisense')}
                  </a>
                  <br />
                  <br />
                  <a
                      className="footer-link"
                      href="https://mtr.mkm.ee/juriidiline_isik/256426?backurl=%2Fjuriidiline_isik"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t('footer.contact_us')}
                  </a>
                  <br />
                  <a className="footer-link" href="tel:+37281937708">
                    + 372 81 93 7708
                  </a>
                  <br />
                  <a className="footer-link" href="mailto:info@oero.io">
                    info@oero.io
                  </a>

                </div>
              </div>
              <hr className="clearfix w-100 d-sm-none pb-3" />
              <div className="col-sm-6 col-lg-2 mb-md-0 mb-3 footer-col">
                <h5 className="text-uppercase mb-4">{t('footer.use_cases')}</h5>
                <ul className="list-unstyled" style={{ fontWeight: 500 }}>
                  <li>
                    <a href="/home/use-cases">{t('footer.for_exchange')}</a>
                  </li>
                  <li>
                    <a href="/home/use-cases#cross-border-anchor">
                      {t('footer.method_transfer')}
                    </a>
                  </li>
                  <li>
                    <a href="/home/use-cases#Hedge-against-volatility">
                      {t('footer.storage_method')}
                    </a>
                  </li>
                  <li>
                    <a href="/home/use-cases#exchange-method">
                      {t('footer.exchange_method')}
                    </a>
                  </li>
                  <li>
                    <a href="/home/use-cases#testimonials">{t('footer.payment_method')}</a>
                  </li>
                  <li>
                    <a href="/home/use-cases#oero-staking">{t('footer.staking')}</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 mb-md-0 mb-3 footer-col">
                <h5 className="text-uppercase mb-4 ">{t('footer.OERO')}</h5>
                <ul className="list-unstyled" style={{ fontWeight: 500 }}>
                  <li>
                    <a href="/login">{t('main.header.nav.wallet')}</a>
                  </li>
                  <li>
                    <a href="/home/api">{t('footer.oero_api')}</a>
                  </li>
                  <li>
                    <a href="/home/transparent">{t('footer.transparent')}</a>
                  </li>
                  <li>
                    <a href="/home/buying-guide">{t('footer.buying_guide')}</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 mb-md-0 mb-3 order-2 footer-col">
                <h5 className="text-uppercase mb-4">{t('footer.about_us')}</h5>
                <ul className="list-unstyled" style={{ fontWeight: 500 }}>
                  <li>
                    {
                      lang === 'ru-RUS' ? (
                          <a href="/White_paper_oero_ru.pdf" target="_blank">
                            {t('footer.whitepaper')}
                          </a>
                        ) : (
                          <a href="/White_paper_oero.pdf" target="_blank">
                            {t('footer.whitepaper')}
                          </a>
                      )
                    }

                  </li>
                  <li>
                    <a href="/home/legal">{t('footer.legal')}</a>
                  </li>
                  <li>
                    <a href="/home/fees">{t('footer.fees')}</a>
                  </li>
                  <li>
                    <a href="/home/team">{t('footer.team')}</a>
                  </li>
                  <li>
                    <a href="/home/faq">{t('footer.faq')}</a>
                  </li>
                </ul>
              </div>
              <div className="col-8 col-sm-4 col-lg-2 mb-md-0 mb-3 order-2 footer-col">
                <h5 className="text-uppercase social-main mb-4">
                  {t('footer.social_networks')}
                </h5>
                <div className="social-columns col-9">
                  <div className="row  px-0 d-flex justify-content-between">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/oero.platform"
                      className="social-columns_link col-4"
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://bitcointalk.org/index.php?topic=5179606.msg52292171#msg52292171"
                      className="social-columns_link2 col-4"
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/oero-international/"
                      className="social-columns_link3 col-4"
                    />
                  </div>
                  <div className="row  px-0 d-flex justify-content-between">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://t.me/+4wJUlW1zFXk2NWU0"
                      className="social-columns_link4 col-4 mt-2"
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/oero_int"
                      className="social-columns_link5 col-4 mt-2"
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/OEROIO"
                      className="social-columns_link6 col-4 mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withTranslation()(Footer)
