import React from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

interface UploadFilesPromptProps {
  isOpen: boolean;
  handleClose?: () => void;
  handleUpload?: () => void;
  handleChange?: (e: any) => void;
  fileType?: string;
  isError?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "auto",
      minHeight: "340px",
      width: "95vw",
      maxWidth: "580px !important",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        padding: "5px 30px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "30px 10px",
        alignItems: "center",
      },
      border: "1px solid #C1E5EF",
      borderRadius: "15px",
      margin: "10px !important",
    },
    tooltip: {
      fontSize: "14px",
      fontWeight: "normal",
    },
    actions: {
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& > *": {
          margin: "5px !important",
        },
      },
    },
  })
);

const StyledDialogTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
  padding: 8px;
`;

const DialogContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const UploadFilesPrompt = ({
  isOpen,
  handleClose,
  handleUpload,
  handleChange,
  fileType,
  isError,
}: UploadFilesPromptProps) => {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={isOpen}
    >
      <StyledDialogTitle>Upload Updated File</StyledDialogTitle>

      <DialogContent
        style={{
          padding: "8px",
          height: "fit-content",
          flex: "none",
        }}
      >
        <DialogContentWrapper>
          <DialogContentText
            style={{
              fontSize: "18px",
              width: "100%",
              textAlign: "justify",
            }}
            id="alert-dialog-description"
          >
            File type: {fileType}
          </DialogContentText>
          {isError ? (
            <DialogContentText
              style={{
                fontSize: "18px",
                width: media ? "330px" : "100%",
                textAlign: "justify",
                color: "#DE0000",
              }}
              id="alert-dialog-description"
            >
              Error: You must choose a file before uploading
            </DialogContentText>
          ) : (
            ""
          )}
          <InputBase type="file" onChange={(e) => handleChange(e)}></InputBase>
        </DialogContentWrapper>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button
          style={{ padding: "20px" }}
          onClick={handleUpload}
          size="large"
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
        <Button
          style={{ padding: "20px" }}
          onClick={handleClose}
          size="large"
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFilesPrompt;
