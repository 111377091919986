import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageWrap } from "../../components/common/Pages";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import {QRCodeSVG} from "qrcode.react";
import { CurrencyInput } from "../../components/common/CurrencyInput";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { useSnackbar } from "notistack";
import { copyTextToClipboard } from "../../../../core/helpers/Clipboard";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { ReactComponent as EtherIcon } from "../../../../assets/ethereum-logo.svg";
import { ReactComponent as TrxIcon } from "../../../../assets/trx-logo.svg";
import OeroIcon from "../../../../assets/oero-icon.svg";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import {
  BankTransactionShort,
  MutationTransferArgs,
} from "../../../../store/generated-models";
import {t} from "i18next";

const Row: any = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const RowL = styled(Row)`
  justify-content: center;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
  min-width: 120px;
`;

const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 24px;
  padding: 16px;
  background-color: white;
  padding: 32px;
  max-width: 390px;
`;

const CardContent = styled.div`
  display: flex;
  padding: 16px 0 0;
`;

const TotalAmount = styled.span`
  font-size: 36px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-weight: bold;
`;

const TotalCurrency = styled.span`
  font-size: 24px;
  font-family: Roboto, sans-serif;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.span`
  font-size: 28px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-weight: bold;
`;

const HeaderAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin-left: 12px;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  padding-top: 24px;
`;

const CopyButton = styled(IconButton)`
  margin: 0 0 0 8px;
  background-color: #0096c3;
  color: white;
  height: 100%;
  width: auto;
  :hover {
    background-color: #087ca7;
  }
`;

const StyledGrid = styled(Grid)`
  @media (min-width: 600px) {
    margin-left: 10vh !important;
  }
`;

const TextAlert: any = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.alert};
`;

const TextSuccess: any = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.success};
`;

const initFields = {
  amount: "",
  address: "",
  comment: "",
};

const gqlTransfer = gql`
  mutation Transfer($address: String!, $amount: Float!, $comment: String) {
    transfer(address: $address, amount: $amount, comment: $comment) {
      address
      addressService
      amount
      bankTransactionId
      beneficiaryAccountAddress
      beneficiaryAccountCountry
      beneficiaryAccountName
      beneficiaryAccountPostalCity
      beneficiaryAccountPostalCode
      beneficiaryBankAddress
      beneficiaryBankCountry
      beneficiaryBankName
      beneficiaryBankPostalCity
      beneficiaryBankPostalCode
      comment
      companyName
      created
      currency
      description
      fineAmount
      firstUserName
      iban
      ibanAccountNumber
      intermediaryBankRoutingNumberBic
      lastUserName
      oero
      oeroExchangeRate
      ownerName
      paymentDetails
      paymentId
      paymentResult
      processingStatus
      processingStatusDescription
      swiftBic
      transactionCode
      transactionHash
      transactionNumber
      transactionType
      updateStatusUserId
      updated
      # user
      wallet
    }
  }
`;

export const Home: React.FC<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});
  const [mode, setMode] = useState("");
  const [blockchain, setBlockchain] = useState<string>(null);
  const [ethAddress, setEthAddress] = useState("");
  const [trxAddress, setTrxAddress] = useState("");

  const [sendStatus, setSendStatus] = useState("");
  const [sendText, setSendText] = useState("");

  const [transfer, { loading }] =
    useMutation<{ transfer: BankTransactionShort }, MutationTransferArgs>(
      gqlTransfer
    );

  useEffect(() => {
    if (authContext.menuItems.findIndex((el) => el.label === "home") === -1) {
      navigate("/home/main").then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    authContext.getSettings();
  }, [authContext.user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext?.user?.blockchain) {
      setBlockchain(authContext.user.blockchain.toUpperCase());
    }

    if (authContext?.user?.ethAddress) {
      setEthAddress(authContext.user.ethAddress);
    }

    if (authContext?.user?.trxAddress) {
      setTrxAddress(authContext.user.trxAddress);
    }
  }, [authContext.user]);

  const copyToClipboard = (text: string, message?: string) => {
    try {
      copyTextToClipboard(text).then(() => {
        enqueueSnackbar(message ? message : "address copied");
      });
    } catch (ignored) {}
  };

  const formatBalanceEURO = () => {
    try {
      return new Intl.NumberFormat("en-EN", {
        maximumFractionDigits: 2,
      }).format(authContext.user.state.euroBalance);
    } catch {
      return "";
    }
  };

  const formatBalanceOERO = () => {
    try {
      return new Intl.NumberFormat("en-EN", {
        maximumFractionDigits: 2,
      }).format(authContext.user.state.contractBalance);
    } catch {
      return "";
    }
  };

  const formatBalanceCoin = () => {
    try {
      let balance: number;
      if (blockchain === 'TRX') {
        balance = authContext.user.state.trxBalance;
      }
      else if (blockchain === 'ETH') {
        balance = authContext.user.state.ethBalance;
      }
      else {
          return "";
      }

      return new Intl.NumberFormat("en-EN", {
        maximumFractionDigits: 6,
      }).format(balance) + ' ' + blockchain;
    } catch {
      return "";
    }
  };

  const getTransactionCost = () => {
    try {
      let text: string = '...';

      if (blockchain === 'TRX') {
        text = 'less than 10 TRX';
      }
      else if (blockchain === 'ETH') {
        text = new Intl.NumberFormat("en-EN", {
          maximumFractionDigits: 6,
        }).format(authContext.transactionParams.averageTransactionFee) + ' ETH';
      }

      return text;
    } catch {
      return "...";
    }
  };

  const getAlertMode = () => {
    try {
      let balance: number;
      if (blockchain === 'TRX') {
        balance = authContext.user.state.trxBalance;
      }
      else if (blockchain === 'ETH') {
        balance = authContext.user.state.ethBalance;
      }
      else {
        return false;
      }

      return authContext.transactionParams.averageTransactionFee > balance;
    } catch {
      return false;
    }
  };

  const confirmSendHandler = async () => {
    let amountValue = fields.amount === null ? null : parseFloat(fields.amount);

    let err: any = {};
    if (!amountValue) err.amount = "required";
    if (!fields.address) err.address = "required";

    if (!err.amount && authContext.user.state.contractBalance < amountValue) {
      err.amount = "Insufficient funds";
    }

    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar("Correct errors to continue", { variant: "error" });
      return;
    }

    let text = "";
    let status = "ok";

    try {
      const res = await transfer({
        variables: {
          address: fields.address,
          amount: amountValue,
          comment: fields.comment,
        },
      });

      if (res.data.transfer.transactionCode) {
        text = `The operation was successful. Transaction code is ${res.data.transfer.transactionCode}`;
        setFields({ ...initFields });
      } else {
        text = `Operation failed. Please try again later`;
        console.warn(">> submitConfirmDepositCrypto not OK, res:", res);
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") {
          text = `Operation failed. Please try again later`;
        } else if (errorCode === "core.insufficient_funds") {
          text = "Operation failed. Insufficient funds";
        } else {
          text = `Operation failed. ${error.graphQLErrors[0].message}`;
        }
      } catch (err) {
        console.error(">> submitConfirmDepositCrypto error (unknown):", err);
        text = `Operation failed. Please try again later`;
      }

      status = "error";
    }

    setSendStatus(status);
    setSendText(text);
    setMode("send-status");
  };

  if (mode === "send-status")
    return (
      <PageWrap>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {sendStatus === "ok" ? (
              <TextSuccess variant="body1">{sendText}</TextSuccess>
            ) : (
              <TextAlert variant="body1">{sendText}</TextAlert>
            )}
          </Grid>
          <Grid item xs={12}>
            <ButtonStyled
              variant="outlined"
              color="primary"
              size={"large"}
              onClick={() => setMode("")}
            >
              OK
            </ButtonStyled>
          </Grid>
        </Grid>
      </PageWrap>
    );

  if (mode === "send")
    return (
      <PageWrap>
        <Grid
          container
          spacing={3}
          style={{ flexBasis: "0", maxWidth: "800px" }}
        >
          <Grid item xs={12} sm={6}>
            <CurrencyInput
              error={!!errors.amount}
              helperText={errors.amount}
              label={t('user.home.amount')}
              amount={fields.amount}
              onAmountChange={(val) => setFields({ ...fields, amount: val })}
              currency={"OERO"}
              onCurrencyChange={(val) => console.log(val)}
              currencies={[{ id: "OERO" }]}
              fullWidth
              dropdownDisabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              error={!!errors.address}
              helperText={errors.address}
              variant="outlined"
              fullWidth
              label={t('user.home.address')}
              value={fields.address}
              onChange={(e) =>
                setFields({ ...fields, address: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              error={!!errors.comment}
              helperText={errors.comment}
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
          		maxRows={6}
              label={t('user.home.comment')}
              value={fields.comment}
              onChange={(e) =>
                setFields({ ...fields, comment: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <ButtonStyled
              variant="contained"
              color="primary"
              size={"large"}
              style={{
                marginRight: "50px",
                width: "calc(70% - 50px)",
                height: "50px",
              }}
              onClick={confirmSendHandler}
              disabled={loading}
            >
              {t('user.home.confirm')}
            </ButtonStyled>
            <Button
              variant="outlined"
              color="primary"
              size={"large"}
              style={{ width: "30%", height: "50px" }}
              onClick={() => setMode("")}
              disabled={loading}
            >
              {t('user.home.back')}
            </Button>
          </Grid>
        </Grid>
      </PageWrap>
    );

  return (
    <PageWrap>
      <Row style={{ marginBottom: "24px" }}>
        <Typography
          variant={"h1"}
          style={{ fontWeight: "normal", fontSize: "36px" }}
        >
          {t('user.home.balance')}
        </Typography>
      </Row>
      <Row>
        <Typography variant="body1">{t('user.home.total_amount')}</Typography>
      </Row>
      <Row
        style={{
          marginTop: "8px",
          marginBottom: "36px",
          alignItems: "baseline",
        }}
      >
        <TotalAmount>{formatBalanceEURO()}</TotalAmount>
        <TotalCurrency style={{ marginLeft: "12px" }}>EURO</TotalCurrency>
      </Row>

      <Grid container spacing={3} style={{ height: "100%" }}>
        <Grid item xs={12} sm={5} xl={4} style={{ marginBottom: "24px" }}>
          <CardStyled>
            <CardHeader>
              <HeaderText>Oero</HeaderText>
              <HeaderAvatar style={{ backgroundColor: "#f2f2f2" }}>
                <img
                  src={OeroIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
              </HeaderAvatar>
            </CardHeader>
            <CardContent>
              <Typography variant="body1">
                {formatBalanceOERO()} OERO
              </Typography>
            </CardContent>
            <CardFooter>
              <Button
                variant="contained"
                color="primary"
                size={"large"}
                style={{ height: "50px" }}
                onClick={() => setMode("send")}
                fullWidth
              >
                {t('user.home.send')}
              </Button>
            </CardFooter>
          </CardStyled>

          <CardStyled>
            <CardHeader>
              <HeaderText>{t(`user.home.${blockchain ? blockchain.toLowerCase() : ''}`)}</HeaderText>
              <HeaderAvatar style={{ backgroundColor: "#f2f2f2" }}>
                {
                    blockchain === 'ETH' ?
                    <EtherIcon style={{width: "30px", height: "30px"}}/> :
                    (
                      blockchain === 'TRX' ?
                      <TrxIcon style={{width: "30px", height: "30px"}}/> :
                      null
                    )
                }
              </HeaderAvatar>
            </CardHeader>

            {getAlertMode() ? (
              <React.Fragment>
                <CardContent style={{ flexDirection: "column" }}>
                  <TextAlert variant="body1">
                    {formatBalanceCoin()}
                  </TextAlert>
                  <TextAlert variant="body1">
                    {t('user.home.insufficient')}
                  </TextAlert>
                </CardContent>
              </React.Fragment>
            ) : (
              <CardContent>
                <Typography variant="body1">
                  {formatBalanceCoin()}
                </Typography>
              </CardContent>
            )}
          </CardStyled>

          <Typography variant="body1" style={{ maxWidth: "390px" }}>

            {t('user.home.description')}
            <br />
            {t('user.home.description_2')}{getTransactionCost()}

          </Typography>
        </Grid>

        <StyledGrid
          item
          xs={12}
          sm={5}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <RowL style={{ alignSelf: "flex-start" }}>
            {t('user.home.wallet_address')}
          </RowL>
          <RowL
            style={{
              marginTop: "8px",
              fontWeight: 500,
              wordBreak: "break-all",
              backgroundColor: "white",
              width: "fit-content",
              border: "1px solid #efefef",
              borderRadius: "4px",
            }}
          >
            <div style={{ padding: "5px 15px" }}>{blockchain === 'ETH' ? ethAddress : trxAddress}</div>
            <CopyButton
              style={{ borderRadius: "5px" }}
              onClick={() => {
                copyToClipboard(blockchain === 'ETH' ? ethAddress : trxAddress);
              }}
              edge="end"
            >
              <FileCopyIcon />
            </CopyButton>
          </RowL>
          <RowL style={{ marginTop: "48px", alignSelf: "flex-start" }}>
            {t('user.home.wallet_address_qr')}
          </RowL>
          <RowL
            style={{
              marginTop: "16px",
              backgroundColor: "white",
              border: "1px solid #efefef",
              padding: "20px",
              width: "fit-content",
            }}
          >
            <QRCodeSVG
              value={blockchain === 'ETH' ? ethAddress : trxAddress}
              renderAs={"canvas"}
              size={210}
              level={"M"}
            />
          </RowL>
        </StyledGrid>
      </Grid>
    </PageWrap>
  );
};
