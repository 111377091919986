import React from 'react';
import {HomeContext} from '../HomeProvider';
import {MainMenu} from './MainMenu';
import {BlogList} from '../parts/BlogList'

export class Blog extends React.Component {
  static contextType = HomeContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
    };
  }

  componentDidMount() {
    // document.body.classList.add('admin-page');
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  render() {
    return (
      <>
        <header>
          <MainMenu/>

          <div className="view full-page-intro"
               style={{
                 background: 'url(/img/main-background.png) no-repeat center center',
                 backgroundSize: 'cover'
               }}
          >
            <div className="mask d-flex justify-content-center align-items-center">
              <div className="container">
                <div className="row wow fadeIn mt-5 d-flex align-items-center flex-lg-row flex-column"
                     style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="col-lg-5 mb-4 white-text text-center text-lg-left">
                    <h1 className="main-header-f mb-5 mt-5">OEROBLOG</h1>
                  </div>
                  <div className="col-lg-7 mt-md-5 mb-4">
                    <img src="/img/blog-main.png" className="img-fluid home-first-img mx-auto" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

				<BlogList/>
      </>
    );
  }
}
