import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { PageWrap } from "../../components/common/Pages";
import { gql } from "@apollo/client";
import styled from "styled-components";
import Cryptocurrency from "./Cryptocurrency";
import { BankTransaction } from "./BankTransaction";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import Typography from "@material-ui/core/Typography";
import EPayments from "./EPayments";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

export const LoadWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const gqlGetSupportedCurrenciesWithRates = gql`
  query {
    getSupportedCurrenciesWithRates {
      fiat {
        depositRate
        id
        minAmount
        name
        precision
        validateAs
        withdrawRate
      }
      crypto {
        depositRate
        id
        minAmount
        name
        precision
        validateAs
        withdrawRate
      }
    }
  }
`;

const Withdraw: React.FC<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (authContext.menuItems.findIndex((el) => el.label === "home") === -1) {
      navigate("/home/main").then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext.company === null) {
      authContext.getSettings();
      return;
    }
  }, [authContext.company]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrap>
      <Typography
        variant={"h1"}
        style={{ padding: "10px 0", fontSize: "36px" }}
      >
          {t('user.withdraw.title')}
      </Typography>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
          setSelectedTab(newValue)
        }
      >
        <Tab label={t('user.withdraw.bank_transaction')} />
        <Tab label={t('user.withdraw.cryptocurrency')} />
        <Tab label={t('user.withdraw.e_payments')} />
      </Tabs>
      <div style={{ padding: "16px 0 0 0", height: "calc(100% - 48px)" }}>
        {selectedTab === 0 && <BankTransaction />}
        {selectedTab === 1 && <Cryptocurrency />}
        {selectedTab === 2 && <EPayments />}
      </div>
    </PageWrap>
  );
};

export default withTranslation()(Withdraw)
