import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { HeadCell, Grid } from "../../../../core/ui/grids/Grid";
import { PageWrap } from "../../components/common/Pages";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import { formatDateTime } from "../../helpers/Format";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const ITEM_PER_PAGE: number = 20;

// const CloudDownloadIconStyled = styled(CloudDownloadIcon)`
// 	fill: ${props => props.theme.palette.primary.main};
// `;

const Row = styled.div`
  display: flex;
`;

// const LoadWrap = styled.div`
// 	display: flex;
// 	width: 100%;
// 	height: 100%;
// 	justify-content: center;
// 	align-items: center;
// `;

const headCells: HeadCell[] = [
  { id: "user", label: "User", style: { verticalAlign: "top" } },
  { id: "created", label: "Date created", style: { verticalAlign: "top" } },
  { id: "type", label: "Type", style: { verticalAlign: "top" } },
  { id: "text", label: "Message", style: { verticalAlign: "top" } },
];

const gqlGetSupportCases = gql`
  query getSupportCases($first: Int, $skip: Int, $filter: String) {
    getSupportCases(
      first: $first
      orderBy: "created"
      desc: true
      skip: $skip
      filter: $filter
    ) {
      count
      cases {
        attachments {
          fileId
          fileName
          url
        }
        created
        createds
        supportCaseId
        text
        type
        user {
          userId
          email
        }
      }
    }
  }
`;

export const Messages: React.FC<RouteComponentProps> = () => {
  const [items, setItems] = useState<any[]>([]);

  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [filter, setFilter] = useState("");

  const { loading, error, data, refetch } = useQuery(gqlGetSupportCases, {
    variables: {
      first: ITEM_PER_PAGE,
      skip: (page - 1) * ITEM_PER_PAGE,
      filter: filter,
    },
  });

  useEffect(() => {
    refetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) console.warn(">> Users useEffect error", error);
  }, [error]);

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.getSupportCases &&
      data.getSupportCases.count !== undefined
    ) {
      const totalCnt = data.getSupportCases.count;
      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      if (data.getSupportCases.cases) {
        setItems(
          data.getSupportCases.cases.map((el: any) => {
            return {
              attachments: el.attachments,
              created: formatDateTime(el.created),
              supportCaseId: el.supportCaseId,
              text: el.text,
              type: el.type,
              user: el.user.email,
              userId: el.user.userId,
              _fileName:
                el.attachments && el.attachments[0] && el.attachments[0].url,
            };
          })
        );
      } else {
        setItems([]);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
  };

  const searchHandler = (val: string) => {
    if (loading) return;

    setPage(1);
    setFilter(val);
  };

  // if (!data && loading) {
  // 	return (
  // 		<LoadWrap>
  // 			<CircularProgress/>
  // 		</LoadWrap>
  // 	);
  // }

  return (
    <PageWrap>
      <Row style={{ marginBottom: "12px" }}>
        <Typography variant={"h3"}>Messages</Typography>
      </Row>
      <Grid
        headCells={headCells}
        items={items}
        idField="supportCaseId"
        page={page}
        pageCnt={pageCnt}
        onPageChange={onPageChangeHandler}
        actions={[{ id: "download" }]}
        extSearch
        extSearchValue={filter}
        onSearchApply={searchHandler}
      />
    </PageWrap>
  );
};
