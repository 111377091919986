import React from "react";
import { HomeContext } from "../HomeProvider";
import { MainMenu } from "./MainMenu";
import styled from "styled-components";
import Guide1 from "../assets/guide/guide_1.png";
import Guide2_1 from "../assets/guide/guide_2_1.png";
import Guide2_2 from "../assets/guide/guide_2_2.png";
import Guide2_3 from "../assets/guide/guide_2_3.png";
import Guide3 from "../assets/guide/guide_3.png";
import Guide4 from "../assets/guide/guide_4.png";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const ExteriorList = styled.ol`
  font-size: 26px;
  text-align: start;
  & > li {
    margin: 70px 0;
    margin-left: 35px;
    margin-right: 10px;
  }
`;

const InteriorList = styled.ol`
  font-size: 20px;
  & > li {
    margin: 10px 0;
  }
  list-style-type: none;
`;

const GuideLink = styled.a`
  font-size: 16px;
  color: #0096c3;
  text-decoration: underline;
`;

const GuideTitle = styled.p`
  margin: 0;
`;

const GuideImage = styled.img`
  margin: 50px 0 0 0;
  width: 100%;
`;

class BuyingGuide extends React.Component {
  static contextType = HomeContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
    };
  }

  componentDidMount() {
    // document.body.classList.add('admin-page');
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  render() {
    return (
      <>
        <header>
          <MainMenu />
          <div
            className="view full-page-intro"
            style={{
              background: "url(/img/buying-guide.png) no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            <div className="mask d-flex justify-content-center align-items-center">
              <div className="container">
                <div
                  className="row wow fadeIn"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  <div className="col-lg-6 mb-4 white-text  d-flex align-items-center ">
                    <div>
                      <h1 className="display-4 use-cases-main mb-4 text-uppercase">
                        {t('buyingGuide.title')}
                      </h1>
                      <h4 className="mb-2">
                        {t('buyingGuide.subtitle')}
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-5 mt-sm-5 mt-lg-0 d-none d-md-flex"></div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="text-center py-5 mt-3">
          <div className="container">
            <section
              className="mt-5 mb-5 wow fadeIn"
              style={{
                visibility: "visible",
                animationName: "fadeIn",
              }}
            >
              <div className="row" style={{ justifyContent: "center" }}>
                <ExteriorList>
                  <li>
                    <GuideTitle>
                      {t('buyingGuide.title_1')}
                    </GuideTitle>
                    <GuideLink href="https://oero.io/private/settings">
                      https://oero.io/private/settings
                    </GuideLink>
                    <GuideImage src={Guide1} />
                  </li>
                  <li>
                    <GuideTitle>
                      {t('buyingGuide.title_2')}
                    </GuideTitle>
                    <InteriorList>
                      <li>
                        <GuideTitle>
                          <span>2.1.</span>
                          {t('buyingGuide.title_2_1')}
                        </GuideTitle>
                        <GuideLink href="https://oero.io/private/deposit">
                          https://oero.io/private/deposit
                        </GuideLink>
                        <GuideImage src={Guide2_1} />
                      </li>
                      <li>
                        <GuideTitle>
                          <span>2.2.</span>
                          {t('buyingGuide.title_2_2')}
                        </GuideTitle>
                        <GuideLink href="https://oero.io/private/deposit">
                          https://oero.io/private/deposit
                        </GuideLink>
                        <GuideImage src={Guide2_2} />
                      </li>
                      <li>
                        <GuideTitle>
                          <span>2.3.</span> {t('buyingGuide.title_2_3')}
                        </GuideTitle>
                        <GuideLink href="https://oero.io/private/deposit">
                          https://oero.io/private/deposit
                        </GuideLink>
                        <GuideImage src={Guide2_3} />
                      </li>
                    </InteriorList>
                  </li>
                  <li>
                    <GuideTitle>{t('buyingGuide.title_3')}</GuideTitle>
                    <InteriorList>
                      <li>
                        <GuideTitle>
                          <span>3.1. </span>
                          {t('buyingGuide.title_3_1')}
                          <GuideLink
                            style={{ fontSize: "20px" }}
                            href="#exchanges"
                          >
                            {t('buyingGuide.title_3_1_link')}
                          </GuideLink>
                        </GuideTitle>
                      </li>
                      <li>
                        <GuideTitle>
                          <span>3.2. </span>
                          {t('buyingGuide.title_3_2')}
                        </GuideTitle>
                      </li>
                      <li>
                        <GuideTitle>
                          <span>3.3. </span>
                          {t('buyingGuide.title_3_3')}
                        </GuideTitle>
                      </li>
                      <li>
                        <GuideTitle>
                          <span>3.4. </span>
                          {t('buyingGuide.title_3_4')}
                        </GuideTitle>
                      </li>
                      <li>
                        <GuideTitle>
                          <span>3.5. </span>
                          {t('buyingGuide.title_3_5')}
                          <GuideLink
                            style={{ fontSize: "20px" }}
                            href="https://oero.io/private/home"
                          >
                            https://oero.io/private/home
                          </GuideLink>
                        </GuideTitle>
                      </li>
                    </InteriorList>
                    <GuideImage src={Guide3} />
                  </li>
                  <li>
                    <GuideTitle>
                      {t('buyingGuide.title_4')}
                    </GuideTitle>
                    <p style={{ fontSize: "20px", marginTop: "30px" }}>
                      {t('buyingGuide.subtitle_4')}
                      <GuideLink href="#" style={{ fontSize: "20px" }}>
                        {t('buyingGuide.subtitle_4_link')}
                      </GuideLink>{" "}
                      {t('buyingGuide.subtitle_4_after_link')}
                    </p>
                    <GuideImage src={Guide4} />
                  </li>
                </ExteriorList>
              </div>
            </section>
            <section
              className="mt-5 mb-5 wow fadeIn"
              style={{ visibility: "visible", animationName: "fadeIn" }}
              id="exchanges"
            >
              <div className="row">
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/oero.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5 ml-3 mr-3"
                    style={{ fontSize: "1.4rem" }}
                  >{t('buyingGuide.subtitle_5')}</p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/logo-short.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://balticchange.ru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit Balticchange
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/bitcratic.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://www.bitcratic.com/#!/trade/0xe1fe2a5815d0ff09c317ed04cd5b5a1a5684fb2b-ETH"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit Bitcratic
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/etherdeltalogo.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://etherdelta.com/#PKT-ETH"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit EtherDelta
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/airswap.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://www.airswap.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit Airswap
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/forkDelta.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://forkdelta.app/#!/trade/0xe1fe2a5815d0ff09c317ed04cd5b5a1a5684fb2b-ETH"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit ForkDelta
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/tokenJar.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://tokenjar.io/OEUR"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit TokenJar
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div className="buying-blog d-flex align-items-center">
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/swapsNetwork.png"
                      alt=""
                    />
                  </div>
                  <a
                    href="https://swaps.network/trades"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem", textDecoration: "underline" }}
                  >
                    Visit Swaps.Network
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/bancor.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/Yo.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/Huobi.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/vindax.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/hitBTC.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/BW.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 text-left">
                  <div
                    className="buying-blog d-flex align-items-center"
                    style={{ background: "#e8e8e8" }}
                  >
                    <img
                      className="mx-auto d-block"
                      src="/img/icons/changelly.png"
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center font-weight-bolder mt-4 mb-5"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Coming Soon
                  </p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }
}

export default withTranslation()(BuyingGuide)
