import React from "react";
import { Redirect } from "@reach/router";

import UseCases from "./components/UseCases";
import Transparent from "./components/Transparent";
import Api from "./components/Api";
import BuyingGuide from "./components/BuyingGuide";
import { Blog } from "./components/Blog";
//import { WhitePaper } from "./components/WhitePaper";
import Legal  from "./components/Legal";
import Fees from "./components/Fees";
import Team from "./components/Team";
import Faq from "./components/Faq";
import Main from "./components/Main";
import RiskRate from "./components/RiskRate";

//import { DailyStatements } from "./components/DailyStatements";

export const routes = [
  <Main key="main" path="main" />,
  <UseCases key="use-cases" path="use-cases" />,
  <Transparent key="transparent" path="transparent" />,
  <Api key="api" path="api" />,
  <BuyingGuide key="buying-guide" path="buying-guide" />,
  <Blog key="blog" path="blog" />,
  //<WhitePaper key="white-paper" path="white-paper" />,
  <Legal key="legal" path="legal" />,
  <Fees key="fees" path="fees" />,
  <Team key="team" path="team" />,
  <Faq key="faq" path="faq" />,
  <RiskRate key="risk-rate" path="risk-rate" />,
  //<DailyStatements key="daily-statements" path="daily-statements" />,
  <Redirect noThrow default key="default" from="/" to="/home/main" />,
];
