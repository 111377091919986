import React from 'react';
import {RouteComponentProps} from '@reach/router';

interface ComponentProps extends RouteComponentProps {
	fields: any;
	action: string;
	submitRef: any;
}

export const Payeer: React.FC<ComponentProps> = ({fields, action, submitRef}) => {
	return (
		<form method="post" action={action} target="_blank">
			<input hidden readOnly type="text" name="m_shop" defaultValue={fields.m_shop}/>
			<input hidden readOnly type="text" name="m_orderid" defaultValue={fields.m_orderid}/>
			<input hidden readOnly type="text" name="m_amount" defaultValue={fields.m_amount}/>
			<input hidden readOnly type="text" name="m_curr" defaultValue={fields.m_curr}/>
			<input hidden readOnly type="text" name="m_desc" defaultValue={fields.m_desc}/>
			<input hidden readOnly type="text" name="m_sign" defaultValue={fields.m_sign}/>
			<button hidden type="submit" ref={submitRef}>submit</button>
		</form>

	);
};
