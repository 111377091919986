import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { tokenStorage } from "../bundles/common/tokenStorage";
import remoteSchema from "./remote.schema.graphql";
import localSchema from "./local.schema.graphql";
import { localResolvers } from "./local.resolvers";
//import { navigate } from "@reach/router";
import config from "../config";

import { InMemoryCache, split, ApolloClient } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from "@apollo/client/utilities";

const cache = new InMemoryCache();

let refreshPromise: Promise<string> = null; // keep track of the current refresh request, don't call it if it's in progress

const fetchWithToken = (
  input: RequestInfo | URL,
  options: RequestInit
): Promise<Response> => {
  let originalResponse: Response = null;

  // Преобразуем input в строку, если это URL
  const uri = typeof input === "string" ? input : input.toString();

  return fetch(uri, options)
    .then((response) => {
      originalResponse = response;
      return response.clone().json();
    })
    .then((json) => {
      if (
        json &&
        json.errors &&
        json.errors[0] &&
        json.errors[0].extensions &&
        json.errors[0].extensions.code === "UNAUTHENTICATED"
      ) {
        if (!refreshPromise) {
          const params = {
            operationName: "RefreshToken",
            query: `
              mutation RefreshToken {
                refreshToken
              }
            `,
          };

          refreshPromise = fetch(uri, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
          })
            .then((refreshResponse) => refreshResponse.json())
            .then((refreshResponseJson) => {
              if (
                refreshResponseJson.data &&
                refreshResponseJson.data.refreshToken
              ) {
                const newToken = refreshResponseJson.data.refreshToken as string;
                tokenStorage.accessToken = newToken;
                return newToken;
              } else {
                tokenStorage.clearToken();
                //navigate("/").then();
              }
            })
            .catch((error: any) => {
              return Promise.reject(error);
            });
        }

        return refreshPromise.then((newAccessToken) => {
          refreshPromise = null;
          (options.headers as any)[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;
          return fetch(uri, options);
        });
      }

      return originalResponse;
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });
};


// const wsLink = new WebSocketLink({
//   uri: config.apiUrlWS,
//   options: {
// 		connectionCallback(error, result) {
// 			console.log(error, result)
// 		},
//     reconnect: true,
//     // timeout: 10000,
//     // connectionParams: {
//     // 	authToken: tokenStorage.accessToken,
//     // },
//   },
// });
const wsLink = new GraphQLWsLink(createClient({
	url: config.apiUrlWS,
	lazy: true,
	connectionParams: {
		authToken: `Bearer ${localStorage.getItem('currentToken')}`
	},
}));

const uploadLink = createUploadLink({
  uri: config.apiUrl,
  credentials: "include",
  fetch: fetchWithToken,
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  uploadLink
);

export const apolloClient = new ApolloClient({
  cache,
  typeDefs: [remoteSchema, localSchema],
  resolvers: localResolvers,
  link: link,
});
