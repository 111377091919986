import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CurrencyInput } from "../../components/common/CurrencyInput";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  BankTransactionResult,
  MutationConfirmDepositCryptoArgs,
  MutationDepositCryptoArgs,
} from "../../../../store/generated-models";
import {QRCodeSVG} from "qrcode.react";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { gqlGetSupportedCurrenciesWithRates, LoadWrap } from "./Deposit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { round, OERO_PRECISION } from "../../../../core/helpers/Currencies";
import {t} from "i18next";

const Row = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const CopyButton = styled(IconButton)`
  margin: 0 0 0 8px;
  background-color: #0096c3;
  color: white;
  height: 100%;
  width: auto;
  :hover {
    background-color: #087ca7;
  }
`;

// currencies={[
// 	{id: 'BTC'},
// 	{id: 'XRP'},
// 	{id: 'BCH'},
// 	{id: 'LTC'},
// 	{id: 'USDT'},
// 	{id: 'ETC'},
// 	{id: 'OERO'},
// ]}

const gqlDepositCrypto = gql`
  mutation depositCrypto(
    $immutableAmount: Int!
    $cryptoCurrency: String!
    $cryptoAmount: Float!
    $oeroAmount: Float!
    $comment: String
  ) {
    depositCrypto(
      immutableAmount: $immutableAmount
      cryptoCurrency: $cryptoCurrency
      cryptoAmount: $cryptoAmount
      oeroAmount: $oeroAmount
      comment: $comment
    ) {
      id
      transaction {
        addressService
        currency
        amount
        comment
        oero
      }
    }
  }
`;

const gqlConfirmDepositCrypto = gql`
  mutation confirmDepositCrypto($id: String) {
    confirmDepositCrypto(id: $id) {
      transaction {
        addressService
        bankTransactionId
        firstUserName
        lastUserName
        companyName
        currency
        amount
        comment
        transactionCode
        transactionNumber
      }
    }
  }
`;

const DEF_CURR_NAME = "BTC";

const initFields = {
  fromAmount: "",
  toAmount: "",
  fromCurrency: DEF_CURR_NAME,
  toCurrency: "OERO",
  comment: "",
};

const defaultCurrency = {
  minAmount: 0,
  name: DEF_CURR_NAME,
  depositRate: 0,
};

interface ComponentProps extends RouteComponentProps {}

export const Cryptocurrency: React.FC<ComponentProps> = () => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  function copyToClipboard(text: string, message?: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(message ? message : "copied");
      })
      .catch((err) => {
        console.warn("Something went wrong", err);
      });
  }

  const [depositCrypto, { loading: depositCryptoLoading }] = useMutation<
    { depositCrypto: BankTransactionResult },
    MutationDepositCryptoArgs
  >(gqlDepositCrypto);

  const [confirmDepositCrypto, { loading: confirmDepositCryptoLoading }] =
    useMutation<
      { confirmDepositCrypto: BankTransactionResult },
      MutationConfirmDepositCryptoArgs
    >(gqlConfirmDepositCrypto);

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});

  const [fromAmountValue, setFromAmountValue] = useState(0);
  const [toAmountValue, setToAmountValue] = useState(0);

  const [currencies, setCurrencies] = useState<any[]>([{ id: DEF_CURR_NAME }]);
  const [immutableAmount, setImmutableAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [txId, setTxId] = useState("");
  const [txInfo, setTxInfo] = useState<any>({});
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(defaultCurrency);

  const { data, error, loading } = useQuery(gqlGetSupportedCurrenciesWithRates);

  const getSelectedCurrency = (cur: string) => {
    let selCur = data.getSupportedCurrenciesWithRates.crypto.find(
      (el: any) => el.id === cur
    );
    if (!selCur) selCur = defaultCurrency;
    return selCur;
  };

  const setInitial = () => {
    setFields({ ...initFields });
    setStatus("");
    setFromAmountValue(0);
    setToAmountValue(0);
    setImmutableAmount(0);
    setTxId("");
    setTxInfo({});
    // setRate(getRate(DEF_CURR_NAME));
    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  };

  const submitDepositCrypto = async () => {
    let err: any = {};
    if (!fromAmountValue) err.fromAmount = "required";
    if (!toAmountValue) err.toAmount = "required";
    // if (!fields.address) err.address = 'required';
    // if (!fields.comment) err.comment = 'required';

    // if (fields.address) {
    // 	const currencyForCheck = fields.fromCurrency !== 'OERO' ? fields.fromCurrency : 'ETH';
    // 	const addressValid = WAValidator.validate(fields.address, currencyForCheck);
    // 	if (!addressValid) err.address = 'invalid address';
    // }

    if (
      fromAmountValue &&
      fields.fromCurrency &&
      authContext.settings &&
      authContext.settings.restrictMinAmounts &&
      fromAmountValue < selectedCurrency.minAmount
    ) {
      err.fromAmount = `minimum ${selectedCurrency.minAmount} ${selectedCurrency.name}`;
    }

    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar("Correct errors to continue", { variant: "error" });
      return;
    }

    let errMessage = "";
    try {
      const res = await depositCrypto({
        variables: {
          immutableAmount: immutableAmount,
          // cryptoAddress: fields.address,
          cryptoCurrency: fields.fromCurrency,
          cryptoAmount: fromAmountValue,
          oeroAmount: toAmountValue,
          comment: fields.comment,
        },
      });
      if (res.data.depositCrypto.id && res.data.depositCrypto.transaction) {
        if (!res.data.depositCrypto.transaction.addressService) {
          enqueueSnackbar("Server error (address service)", {
            variant: "error",
          });
          return;
        }

        // enqueueSnackbar('You have successfully submitted your application', {variant: 'success'});
        // props.onClose();
        setTxId(res.data.depositCrypto.id);
        setTxInfo(res.data.depositCrypto.transaction);
        setStatus("confirm");
        return;
      } else {
        errMessage = "Process error. Please try again later";
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const submitConfirmDepositCrypto = async () => {
    let errMessage = "";
    try {
      const res = await confirmDepositCrypto({
        variables: {
          id: txId,
        },
      });
      if (res.data.confirmDepositCrypto.transaction.transactionCode) {
        // enqueueSnackbar('You have successfully submitted your application', {variant: 'success'});
        // props.onClose();
        setTxInfo(res.data.confirmDepositCrypto.transaction);
        setStatus("done");
        return;
      } else {
        errMessage = "Failed to send deposit confirmation";
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  useEffect(() => {
    if (!data || !data.getSupportedCurrenciesWithRates) return;

    setCurrencies(
      data.getSupportedCurrenciesWithRates.crypto.map((el: any) => {
        return {
          id: el.id,
          value: el.name,
        };
      })
    );

    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // let isValid = true;

    // parse what we have in the form
    let fromAmt =
      fields.fromAmount === null ? null : parseFloat(fields.fromAmount);
    let toAmt = fields.toAmount === null ? null : parseFloat(fields.toAmount);

    if (fromAmt === null || toAmt === null || !selectedCurrency.depositRate) {
      setFromAmountValue(0);
      setToAmountValue(0);
      return;
    }

    if (!selectedCurrency.depositRate) {
      console.error("Not find rate");
      return;
    }

    if (immutableAmount === 0) {
      if (isNaN(fromAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        setFields({ ...fields, toAmount: "" });
        return;
      }

      fromAmt = round(fromAmt, selectedCurrency.precision);
      const calc = round(
        fromAmt * selectedCurrency.depositRate,
        OERO_PRECISION
      );
      setFromAmountValue(fromAmt);
      setToAmountValue(calc);
      setFields({ ...fields, toAmount: calc.toString() });
    } else {
      if (isNaN(toAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        setFields({ ...fields, fromAmount: "" });
        return;
      }

      toAmt = round(toAmt, OERO_PRECISION);
      const calc = round(
        toAmt / selectedCurrency.depositRate,
        selectedCurrency.precision
      );
      setFromAmountValue(calc);
      setToAmountValue(toAmt);
      setFields({ ...fields, fromAmount: calc.toString() });
    }
  }, [fields.fromAmount, fields.toAmount, fields.fromCurrency]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputFromAmountChange = (val: string) => {
    setFields({ ...fields, fromAmount: val });
    setImmutableAmount(0);
  };

  const handleInputToAmountChange = (val: string) => {
    setFields({ ...fields, toAmount: val });
    setImmutableAmount(1);
  };

  const handleFromCurrencyChange = (val: string) => {
    setSelectedCurrency(getSelectedCurrency(val));
    setFields({ ...fields, fromCurrency: val });
    setImmutableAmount(0);
  };

  if (error) {
    return <LoadWrap>Internal server error. Try again later</LoadWrap>;
  }

  if (!data || loading) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  // RENDER CONFIRM STEP
  if (status === "confirm") {
    return (
      <>
        <Row>
          <Typography variant={"h4"}>
            {t('user.deposit.amount_to_transfer')}
            <b>
              {txInfo.amount}&nbsp;{txInfo.currency}
            </b>
          </Typography>
        </Row>

        <Row style={{ marginTop: "24px" }}>
          <Typography variant={"h4"}>
            {t('user.deposit.amount_to_receive')}<b>{txInfo.oero}&nbsp;OEUR</b>
          </Typography>
        </Row>

        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            color="primary"
            size={"large"}
            style={{
              minWidth: "120px",
              maxWidth: "240px",
              width: "100%",
              minHeight: "50px",
            }}
            onClick={submitConfirmDepositCrypto}
            disabled={confirmDepositCryptoLoading}
          >
            {t('user.deposit.confirm')}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size={"large"}
            style={{ minWidth: "120px", marginLeft: "20px", minHeight: "50px" }}
            onClick={() => setInitial()}
          >
            {t('user.deposit.cancel')}
          </Button>
        </Row>
      </>
    );
  }

  // RENDER  DONE STEP
  if (status === "done") {
    return (
      <>
        <Row>
          <Typography variant={"h4"}>
            {t('user.deposit.amount_to_transfer')}{" "}
            <b>
              {txInfo.amount}&nbsp;{txInfo.currency}
            </b>
          </Typography>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <QRCodeSVG
            value={txInfo.addressService}
            renderAs={"canvas"} // svg, canvas
            size={210}
            level={"M"} // ('L' 'M' 'Q' 'H')	'L'
            includeMargin={true}
            style={{ border: "1px solid #efefef" }}
          />
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <StyledTextField
            style={{
              maxWidth: "420px",
              width: "100%",
            }}
            variant="outlined"
            label="Address"
            disabled={true}
            value={txInfo.addressService}
            InputProps={{
              endAdornment: (
                <CopyButton
                  style={{
                    borderRadius: "5px",
                    width: "50px",
                  }}
                  onClick={() =>
                    copyToClipboard(txInfo.addressService, "Address copied")
                  }
                >
                  <FileCopyIcon style={{ width: "24px", height: "24px" }} />
                </CopyButton>
              ),
            }}
          />
        </Row>

        <Row style={{ marginTop: "24px" }}>
          <Typography variant={"h4"}>
            {t('user.deposit.send_your')} {txInfo.currency} {t('user.deposit.to_the_address')}
          </Typography>
        </Row>

        <Row style={{ marginTop: "24px" }}>
          <Typography variant={"h4"}>
            {t('user.deposit.transaction_code')} {txInfo.transactionCode}
          </Typography>
        </Row>

        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            color="primary"
            size={"large"}
            style={{
              minWidth: "120px",
              maxWidth: "240px",
              width: "100%",
              minHeight: "50px",
            }}
            onClick={setInitial}
            disabled={confirmDepositCryptoLoading}
          >
            OK
          </Button>
        </Row>
      </>
    );
  }

  // RENDER INITIAL STEP
  return (
    <>
      <Grid container spacing={3} style={{ maxWidth: "1000px" }}>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.fromAmount}
            helperText={errors.fromAmount}
            label={t('user.deposit.from')}
            amount={fields.fromAmount}
            onAmountChange={(val) => handleInputFromAmountChange(val)}
            currency={fields.fromCurrency}
            onCurrencyChange={(val) => handleFromCurrencyChange(val)}
            currencies={currencies}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.toAmount}
            helperText={errors.toAmount}
            label={t('user.deposit.to')}
            amount={fields.toAmount}
            onAmountChange={(val) => handleInputToAmountChange(val)}
            currency={fields.toCurrency}
            onCurrencyChange={(val) =>
              setFields({ ...fields, toCurrency: val })
            }
            currencies={[{ id: "OERO" }]}
            fullWidth
            dropdownDisabled
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.comment}
            helperText={errors.comment}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
          	maxRows={6}
            label={t('user.deposit.comment')}
            value={fields.comment}
            //onChange={(event) => setComment(event.target.value)}
            onChange={(e) => setFields({ ...fields, comment: e.target.value })}
          />
        </Grid>
      </Grid>

      <Row style={{ marginTop: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "240px", minHeight: "50px" }}
          onClick={() => submitDepositCrypto()}
          disabled={depositCryptoLoading}
        >
          {t('user.deposit.next')}
        </Button>
      </Row>
    </>
  );
};
