import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Dropdown } from "../../components/common/Dropdown";
// import KycType from "../../user/settings/KycType"
import { KYC_TYPE } from "../../user/settings/kyc/Kyc";
import TextField from "@material-ui/core/TextField";
import { getCountryByCode } from "../../../../core/ui/CountrySelector";
import { Grid as Tbl, HeadCell } from "../../../../core/ui/grids/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSnackbar } from "notistack";
import UploadFilesPrompt from "./UploadFilesPrompt";
import { RestApiContext } from "core/providers/RestApiProvider";
import { FileReason } from "store/generated-models";

const AcceptIconStyled = styled(CheckCircleIcon)`
  fill: ${(props) => props.theme.custom.palette.success};
`;

const DeclineIconStyled = styled(CancelIcon)`
  fill: ${(props) => props.theme.custom.palette.alert};
`;

const Row = styled.div`
  display: flex;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TblWrap = styled.div`
  display: flex;
  flex: 1 1 auto;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-top: 16px;
  border-radius: 4px;

  // .MuiTableCell-root {
  // 	// max-width: 360px;
  // 	word-break: break-all;
  // }
`;

const ConfirmButton = styled(Button)`
  height: 100%;
  // min-width: 120px;
  margin-left: 16px;
`;

const DeclineButton = styled(Button)`
  height: 100%;
  // min-width: 120px;
  background-color: ${(props) => props.theme.custom.palette.alert};
  color: #ffffff;
  margin-left: 16px;

  &:hover {
    opacity: 0.87;
    background-color: ${(props) => props.theme.custom.palette.alert};
  }
`;

const CancelButton = styled(Button)`
  height: 100%;
  margin-left: 16px;
`;

const headCells: HeadCell[] = [
  { id: "type", label: "File type" },
  { id: "fileName", label: "File name" },
  { id: "status", label: "Status", colorAD: true },
];

interface ControlProps {
  item: any;
  onClose: (
    valid: "accept" | "decline" | "update" | null,
    files: any[],
    kycName: string
  ) => void;
  fileList?: any[];
  inProcess?: boolean;
  readOnly?: boolean;
}

export const KycCard: React.FC<ControlProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const restContext = useContext(RestApiContext);
  const [files, setFiles] = useState<any[]>(
    props.item.files || props.item._files
  );
  const [kycName, setKycName] = useState(props.item.kycName);
  const [file, setFile] = useState<any>();
  const [newFile, setNewFile] = useState<any>();
  const [isUploadFilesPopUpShown, setUploadFilesPopUpShown] =
    useState<boolean>(false);

  const [isUploadError, setUploadError] = useState<boolean>(false);

  const oldFiles = useMemo(() => {
    return files.filter((file: any) => !file.deleted && !!file.fileId);
  }, [files]);

  const upload = async () => {
    let formData = new FormData();
    formData.append("userId", props.item.userId);
    formData.append("kycId", props.item.kycId);
    formData.append("countryCode", props.item.countryCode);
    formData.append("name", props.item.kycName);
    formData.append("phoneNumber", props.item.phoneNumber);
    formData.append("userType", props.item.userType);

    if (props.item.userType === "c") {
      formData.append("beneficiaryCount", props.item.beneficiaryCount);
    }

    formData.append("reason", FileReason.Kyc);
    const fileObj: any = { type: newFile.typeId };
    formData.append("filesData", JSON.stringify(fileObj));
    formData.append("newFiles", newFile.file);

    oldFiles.forEach((f: any) => {
      formData.append("files", f.fileId);
    });
    //console.log(formData);
    return restContext.upload("updateUserKyc", formData);
  };

  const _handleChange = async (e: any) => {
    e.persist();
    setUploadError(false);
    const fileLoaded = e.target.files[0];
    const fileName = fileLoaded.name;
    const fileSize = fileLoaded.size;
    //console.log(fileLoaded);
    // console.log(file);
    setNewFile({
      typeId: file.type,
      //type: fileTypes.find((el: any) => el.id === file.type).value,
      name: fileName,
      size: fileSize,
      file: fileLoaded,
    });
  };
  const uploadButtonClickHandler = (file: any) => {
    setUploadFilesPopUpShown(true);
    setFile(file);
    //console.log(props.item);
  };

  const closeClickHandler = () => {
    setUploadFilesPopUpShown(false);
    setUploadError(false);
    setNewFile(null);
  };

  const confirmUploadClickHandler = async () => {
    if (!newFile) {
      setUploadError(true);
      return;
    }
    oldFiles.splice(
      oldFiles.findIndex((file) => {
        return file.type === newFile.typeId;
      }),
      1
    );
    setUploadError(false);
    setUploadFilesPopUpShown(false);
    let errMessage = "";
    try {
      const uploadRes = await upload();
      if (uploadRes && uploadRes.data) {
        // !!ToDo
        //console.log(uploadRes);
        props.onClose("update", [], kycName);
      } else {
        //props.onClose("");
      }
      setNewFile(null);
      return;
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") {
          errMessage = "Access denied";
        } else if (errorCode === "user.kyc_data_invalid") {
          errMessage = "Invalid KYC data";
        }
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }
    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  useEffect(() => {
    setFiles(
      files.map((item: any) => {
        const fnd =
          props.fileList &&
          props.fileList.length > 0 &&
          props.fileList.find((fl: any) => fl.fileId === item.fileId);
        if (fnd && fnd.valid !== null) {
          const status = fnd.valid ? "accepted" : "declined";
          return { ...item, status: status };
        }

        const fndFL = files.find((fl: any) => fl.fileId === item.fileId);
        if (fndFL && fndFL.approved !== null) {
          const status = fndFL.approved ? "accepted" : "declined";
          return { ...item, status: status };
        }

        return item;
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const acceptClickHandler = (file: any) => {
    setFiles(
      files.map((item: any) =>
        item.fileId === file.fileId ? { ...item, status: "accepted" } : item
      )
    );
  };

  const declineClickHandler = (file: any) => {
    setFiles(
      files.map((item: any) =>
        item.fileId === file.fileId ? { ...item, status: "declined" } : item
      )
    );
  };

  const onAcceptHandler = () => {
    const foundIndex = files.findIndex((f: any) => f.status !== "accepted");
    if (foundIndex !== -1) {
      enqueueSnackbar("All files must be accepted", { variant: "error" });
      return;
    }

    const fileList = files.map((f: any) => {
      return {
        fileId: f.fileId,
        valid: f.status === "accepted",
      };
    });

    props.onClose("accept", fileList, kycName);
  };

  const onDeclineHandler = () => {
    // const foundIndex = files.findIndex((f: any) => f.status === 'declined');
    // if (foundIndex === -1) {
    // 	enqueueSnackbar('Files must be declined', {variant: 'error'});
    // 	return;
    // }

    // KycFileValidationList {
    // 	fileId: String
    // 	valid: Boolean
    // }

    const fileList = files.map((f: any) => {
      return {
        fileId: f.fileId,
        valid:
          f.status === "accepted"
            ? true
            : f.status === "declined"
            ? false
            : null,
      };
    });

    props.onClose("decline", fileList, kycName);
  };

  if (!props.item || !props.item.userId) return null;

  return (
    <>
      <UploadFilesPrompt
        isOpen={isUploadFilesPopUpShown}
        handleClose={closeClickHandler}
        handleUpload={confirmUploadClickHandler}
        handleChange={_handleChange}
        fileType={file?.type}
        isError={isUploadError}
      />
      <Row style={{ marginBottom: "24px" }}>
        <Typography variant={"h3"}>KYC Request info</Typography>
      </Row>

      <Wrap>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              // justifyContent: 'space-between'
              justifyContent: "flex-end",
            }}
          >
            <Dropdown
              style={{
                width: "160px",
                marginRight: "auto",
              }}
              label="Type"
              values={KYC_TYPE}
              value={props.item.userType}
            />
            {props.readOnly && (
              <Typography
                style={{
                  fontSize: "18px",
                  color: props.item.valid === "Y" ? "#4CB1A0" : "#EF5350",
                  alignSelf: "center",
                }}
              >
                {props.item.valid
                  ? props.item.valid === "Y"
                    ? "ACCEPTED"
                    : "REJECTED"
                  : ""}
              </Typography>
            )}
            {!props.readOnly && (
              <>
                <ConfirmButton
                  variant="contained"
                  color="primary"
                  size={"large"}
                  onClick={onAcceptHandler}
                  disabled={props.inProcess}
                >
                  Accept
                </ConfirmButton>

                <DeclineButton
                  variant="contained"
                  color="primary"
                  size={"large"}
                  onClick={onDeclineHandler}
                  disabled={props.inProcess}
                >
                  Decline
                </DeclineButton>
              </>
            )}

            <CancelButton
              variant="outlined"
              size={"large"}
              onClick={() => props.onClose(null, [], kycName)}
              disabled={props.inProcess}
            >
              Back
            </CancelButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Name"
              value={kycName}
              onChange={(e) => setKycName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Email"
              value={props.item.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Country"
              value={getCountryByCode(props.item.countryCode).label}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Phone"
              value={props.item.phoneNumber}
            />
          </Grid>
        </Grid>

        <TblWrap>
          <Tbl
            noTopBar
            headCells={headCells}
            items={files}
            idField="fileId"
            actions={[
              { id: "download", tooltip: "Download file" },
              {
                id: "accepted",
                type: "icon",
                tooltip: "Accept file",
                icon: <AcceptIconStyled />,
                onAction: acceptClickHandler,
              },
              {
                id: "declined",
                type: "icon",
                tooltip: "Decline file",
                icon: <DeclineIconStyled />,
                onAction: declineClickHandler,
              },
              props.readOnly
                ? {}
                : {
                    id: "edit",
                    onAction: uploadButtonClickHandler,
                    tooltip: "Update file",
                  },
            ]}
          />
        </TblWrap>
      </Wrap>
    </>
  );
};
