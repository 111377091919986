export const TRANSACTION_TYPE: any = {
  1: "Deposit bank", // DEPOSIT_BANK,
  2: "Deposit crypto", // DEPOSIT_CRYPTO,
  3: "Deposit E-payment", // DEPOSIT_EPAYMENT,
  4: "Withdrawal bank", // WITHDRAW_BANK,
  5: "Withdrawal SEPA", // WITHDRAW_SEPA,
  6: "Withdrawal crypto", // WITHDRAW_CRYPTO
  7: "Transfer", // TRANSFER
  8: "Emitting", // EMITTING
  9: "Burning", // BURNING
  10: "Bank fee", // BANK_FEE
  11: "Deposit credit card", // DEPOSIT_CREDIT_CARD
  12: "Withdraw E-payment", // WITHDRAW_EPAYMENT
};

export const PROCESSING_STATUS: any = {
  1: "Pending",
  2: "Rejected",
  3: "Confirmed",
  4: "Succeeded",
  5: "Failed",
};

export const BANK_OPTIONS: any = {
  1: "EUR SEPA ClearJunction",
  2: "EUR SEPA GlobalNetInt",
  3: "EUR SEPA Koalapays",
  4: "EUR SWIFT GlobalNetInt",
  5: "USD SWIFT SignatureBank",
  6: "USD Wire FVBank",
  7: "USD Wire Prime Trust",
  8: "USD Prime Trust network",
  9: "USD SWIFT GlobalNetInt",
};

export const BLOCKCHAIN: any[] = [
	{id: "Eth", value: "ETH"},
	{id: "Trx", value: "TRX"},
	{id: "Avax", value: "AVAX"},
	{id: "Bnb", value: "BNB"},
];
