import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
//import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { AuthContext } from "../../../../../core/providers/AuthProvider";

import iconPassportPhoto from "./assets/Passport_Photo.png";
import iconProofOfAddress from "./assets/Proof_Of_Address.png";
import iconSelfieWithPassport from "./assets/Selfie_With_Passport.png";
import { ReactComponent as IconLegalAccountApplication } from "./assets/Legal_Account_Application.svg";
import { ReactComponent as IconCompanyCharter } from "./assets/Company_Charter.svg";
import { ReactComponent as IconRegistrationCertificate } from "./assets/Registration_Certificate.svg";
import { ReactComponent as IconPoA } from "./assets/PoA.svg";
import { ReactComponent as IconLicence } from "./assets/Licence.svg";

import iconPassportPhotoErr from "./assets/err/Passport_Photo.png";
import iconProofOfAddressErr from "./assets/err/Proof_Of_Address.png";
import iconSelfieWithPassportErr from "./assets/err/Selfie_With_Passport.png";
import { ReactComponent as IconLegalAccountApplicationErr } from "./assets/err/Legal_Account_Application.svg";
import { ReactComponent as IconCompanyCharterErr } from "./assets/err/Company_Charter.svg";
import { ReactComponent as IconRegistrationCertificateErr } from "./assets/err/Registration_Certificate.svg";
import { ReactComponent as IconPoAErr } from "./assets/err/PoA.svg";
import { ReactComponent as IconLicenceErr } from "./assets/err/Licence.svg";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div<{ isDecline: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #efefef;
  color: ${(props) => (props.isDecline ? "#cd1225" : "black")};
`;

const IconWrap: any = styled.div`
  width: 200px;
  min-height: 200px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapErr = styled(IconWrap)`
  /*border: 1px solid #cd1225;*/
`;

const FilesWrap = styled.div`
  margin-bottom: 16px;
`;

const FileCardWrap = styled.div`
  display: flex;
  .MuiIconButton-root {
    padding: 6px;
  }
`;

const FileCard = styled.div`
  display: flex;
  background: #f5f5f5;
  border-radius: 4px;
  min-height: 32px;
  margin-top: 4px;
  margin-bottom: 4px;
  flex: 1;
`;

const FileText = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  flex: 1;
`;

const DownloadWrap = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #212529;

  .alert-text & {
    color: #cd1225;

    a {
      color: #cd1225;
    }
  }
`;

const A = styled.a`
  color: #212529;
  text-decoration: underline;
  font-weight: 400;
`;

const BoldBlock = styled.div`
  color: #212529;
  font-weight: 500;
  margin-top: 16px;
`;

const CardBody = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  & > * {
    padding: 5px;
  }
  flex-wrap: wrap;

  @media (max-width: 700px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

interface ComponentProps {
  docType: string;
  docName: string;
  docRequired?: boolean;
  files: any[];
  onAddFile: () => void;
  onDelFile: (fileObj: any) => void;
  isEdit?: boolean;
  isDecline?: boolean;
}

const DocCard: React.FC<ComponentProps> = (props) => {
  const {
    docType,
    docRequired,
    files,
    onAddFile,
    onDelFile,
    isEdit,
    isDecline,
  } = props;

  const authContext = useContext(AuthContext);
  const [doc, setDoc] = useState<any>(null);

  const docs: any[] = [
    // Person
    {
      id: "Passport_Photo",
      name: t('user.settings.passport'),
      icon: (
        <img
          src={iconPassportPhoto}
          alt=""
          style={{ width: "173px", height: "173px" }}
        />
      ),
      iconErr: (
        <img
          src={iconPassportPhotoErr}
          alt=""
          style={{ width: "173px", height: "173px" }}
        />
      ),
      info: (
        <Ul>
          <li>{t('user.settings.good_photo')}</li>
          <li>
            {t('user.settings.good_photo_li_1')}
          </li>
          <li>{t('user.settings.good_photo_li_2')}</li>
          <li>
            {t('user.settings.good_photo_li_3')}
          </li>
          <li>{t('user.settings.good_photo_li_4')}JPEG, JPG, PNG, GIF, HEIC, PDF.</li>
          <li>{t('user.settings.good_photo_li_5')}800 x 600 pixels.</li>
          <li>{t('user.settings.good_photo_li_6')}8Mb.</li>
        </Ul>
      ),
    },
    {
      id: "Selfie_With_Passport",
      name: t('user.settings.selfie'),
      icon: (
        <img
          src={iconSelfieWithPassport}
          alt=""
          style={{ width: "201px", height: "164px" }}
        />
      ),
      iconErr: (
        <img
          src={iconSelfieWithPassportErr}
          alt=""
          style={{ width: "201px", height: "164px" }}
        />
      ),
      info: (
        <Ul>
          <li>
            {t('user.settings.selfie_info')}
          </li>
        </Ul>
      ),
    },
    {
      id: "Personal_Proof_Of_Address",
      name: t('user.settings.personal_proof'),
      icon: (
        <img
          src={iconProofOfAddress}
          alt=""
          style={{ width: "174px", height: "174px" }}
        />
      ),
      iconErr: (
        <img
          src={iconProofOfAddressErr}
          alt=""
          style={{ width: "174px", height: "174px" }}
        />
      ),
      info: (
        <Ul>
          <li>
            {t('user.settings.personal_proof_1')}
          </li>
          <li>
            {t('user.settings.personal_proof_2')}
          </li>
        </Ul>
      ),
    },
    // Company
    {
      id: "Legal_Account_Application",
      name: t('user.settings.legal'),
      icon: <IconLegalAccountApplication />,
      iconErr: <IconLegalAccountApplicationErr />,
      info: (
        <Ul>
          <li>
            1.{" "}
            <A
              href={authContext.settings.companyKycFileDownloadUrlEn}
              target="_blank"
            >
              {t('user.settings.download_link')}
            </A>{" "}
            {t('user.settings.download_after_link')}
          </li>
          <li>{t('user.settings.download_li_1')}</li>
          <li>{t('user.settings.download_li_2')}</li>
        </Ul>
      ),
    },
    {
      id: "Company_Proof_Of_Address",
      name:t('user.settings.company_proof'),
      icon: (
        <img
          src={iconProofOfAddress}
          alt=""
          style={{ width: "174px", height: "174px" }}
        />
      ),
      iconErr: (
        <img
          src={iconProofOfAddressErr}
          alt=""
          style={{ width: "174px", height: "174px" }}
        />
      ),
      info: (
        <Ul>
          <li>{t('user.settings.company_proof_1')}</li>
          <li>
            {t('user.settings.company_proof_2')}
          </li>
          <li>
            {t('user.settings.company_proof_3')}
          </li>
        </Ul>
      ),
    },
    {
      id: "Company_Charter",
      name: t('user.settings.company_charter'),
      icon: <IconCompanyCharter />,
      iconErr: <IconCompanyCharterErr />,
      info: (
        <Ul>
          <li>
            {t('user.settings.company_charter_description')}
          </li>
        </Ul>
      ),
    },
    {
      id: "Registration_Certificate",
      name: t('user.settings.registration_certificate'),
      icon: <IconRegistrationCertificate />,
      iconErr: <IconRegistrationCertificateErr />,
      info: (
        <Ul>
          <li>
            {t('user.settings.registration_certificate_description')}
          </li>
        </Ul>
      ),
    },
    {
      id: "Beneficiary_Passport_Photo",
      name: t('user.settings.beneficiary_passport_photo'),
      icon: (
        <img
          src={iconPassportPhoto}
          alt=""
          style={{ width: "173px", height: "173px" }}
        />
      ),
      iconErr: (
        <img
          src={iconPassportPhotoErr}
          alt=""
          style={{ width: "173px", height: "173px" }}
        />
      ),
      info: (
        <React.Fragment>
          <Ul>
            <li>{t('user.settings.good_photo')}</li>
            <li>
              {t('user.settings.good_photo_li_1')}
            </li>
            <li>{t('user.settings.good_photo_li_2')}</li>
            <li>
              {t('user.settings.good_photo_li_3')}
            </li>
            <li>{t('user.settings.good_photo_li_4')}JPEG, JPG, PNG, GIF, HEIC, PDF.</li>
            <li>{t('user.settings.good_photo_li_5')}800 x 600 pixels.</li>
            <li>{t('user.settings.good_photo_li_6')}8Mb.</li>
          </Ul>
          <BoldBlock>
            {t('user.settings.good_photo_li_7')}
          </BoldBlock>
        </React.Fragment>
      ),
    },
    {
      id: "Beneficiary_Selfie_With_Passport",
      name: t('user.settings.beneficiary_selfie_with_passport'),
      icon: (
        <img
          src={iconSelfieWithPassport}
          alt=""
          style={{ width: "201px", height: "164px" }}
        />
      ),
      iconErr: (
        <img
          src={iconSelfieWithPassportErr}
          alt=""
          style={{ width: "201px", height: "164px" }}
        />
      ),
      info: (
        <React.Fragment>
          <Ul>
            <li>
              {t('user.settings.beneficiary_selfie_with_passport_1')}
            </li>
          </Ul>
          <BoldBlock>
            {t('user.settings.beneficiary_selfie_with_passport_2')}
          </BoldBlock>
        </React.Fragment>
      ),
    },
    {
      id: "Beneficiary_Proof_Of_Address",
        name: t('user.settings.beneficiary_proof_of_address'),
      icon: (
        <img
          src={iconProofOfAddress}
          alt=""
          style={{ width: "174px", height: "174px" }}
        />
      ),
      iconErr: (
        <img
          src={iconProofOfAddressErr}
          alt=""
          style={{ width: "174px", height: "174px" }}
        />
      ),
      info: (
        <React.Fragment>
          <Ul>
            <li>{t('user.settings.beneficiary_proof_of_address_1')}</li>
            <li>
              {t('user.settings.beneficiary_proof_of_address_2')}
            </li>
            <li>
              {t('user.settings.beneficiary_proof_of_address_3')}
            </li>
          </Ul>
          <BoldBlock>
            {t('user.settings.beneficiary_proof_of_address_4')}
          </BoldBlock>
        </React.Fragment>
      ),
    },
    {
      id: "PoA",
      name: t('user.settings.poa'),
      icon: <IconPoA />,
      iconErr: <IconPoAErr />,
      info: (
        <Ul>
          <li>
            {t('user.settings.poa_1')}
          </li>
        </Ul>
      ),
    },
    {
      id: "Licence",
      name: t('user.settings.licence'),
      icon: <IconLicence />,
      iconErr: <IconLicenceErr />,
      info: (
        <Ul>
          <li>
            {t('user.settings.licence_1')}
          </li>
        </Ul>
      ),
    },
  ];

  useEffect(() => {
    const currDoc = docs.find((el: any) => el.id === docType);
    if (currDoc) setDoc(currDoc);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatFileSize = (size: number) => {
    const sizeKb = Math.floor(size / 1024);
    return sizeKb ? `${sizeKb} Kb` : "< 1 Kb";
  };

  if (!doc) return null;

  return (
    <Component>
      <Header isDecline={isDecline}>
        {doc.name}
        <span style={{ color: "#CD1225", marginLeft: "8px" }}>
          {docRequired ? "*" : ""}
        </span>
      </Header>
      <CardBody>
        <div style={{ padding: "20px", maxWidth: "450px" }}>
          <div>{doc.info}</div>
          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Button
                variant="contained"
                color="primary"
                size={"large"}
                style={{
                  maxWidth: "135px",
                  height: "53px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                onClick={onAddFile}
              >
                {t('user.settings.btn')}&nbsp;&nbsp;+
              </Button>
              <FilesWrap>
                {files &&
                  files.map((el: any, i) => (
                    <FileCardWrap key={i}>
                      <FileCard>
                        <FileText>
                          {el.name} ({formatFileSize(el.size)})
                        </FileText>
                        {!el.approved && (
                          <IconButton onClick={() => onDelFile(el)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                      </FileCard>
                      {isEdit && (
                        <IconButton>
                          <DownloadWrap
                            href={el._fileName || el.url}
                            target="_blank"
                            download
                            className={
                              !el._fileName && !el.url ? "disabled" : null
                            }
                          >
                            <CloudDownloadIcon fontSize="small" />
                          </DownloadWrap>
                        </IconButton>
                      )}
                    </FileCardWrap>
                  ))}
              </FilesWrap>
            </div>
          </div>
        </div>
        <div>
          {isDecline ? (
            <IconWrapErr>{doc.iconErr}</IconWrapErr>
          ) : (
            <IconWrap>{doc.icon}</IconWrap>
          )}
        </div>
      </CardBody>
    </Component>
  );
};

export default withTranslation()(DocCard)
