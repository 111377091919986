import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { PageWrap } from "../../components/common/Pages";
import BankAccounts from "./BankAccounts";
import { ChangePassword } from "./ChangePassword";
import { Security } from "./Security";
import { ApiKeys } from "./ApiKeys";
import { Common } from "./Common";
import Kyc from "./kyc/Kyc";
import { useTranslation } from "react-i18next";


interface SettingsProps extends RouteComponentProps {
  tabNo?: string;
}

export const Settings: React.FC<SettingsProps> = (props) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(
    props.tabNo ? parseInt(props.tabNo) : 0
  );

  return (
    <PageWrap>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
          setSelectedTab(newValue)
        }
      >
        <Tab label={t('user.settings.KYC')} />
        <Tab label={t('user.settings.bank_accounts')} />
        <Tab label={t('user.settings.change_password')} />
        <Tab label={t('user.settings.2FA')} />
        <Tab label={t('user.settings.api_keys.tab_label')} />
        <Tab label={t('user.settings.common')} />
      </Tabs>
      <div style={{ padding: "16px 0 0 0", height: "calc(100% - 48px)" }}>
        {selectedTab === 0 && <Kyc />}
        {selectedTab === 1 && <BankAccounts />}
        {selectedTab === 2 && <ChangePassword />}
        {selectedTab === 3 && <Security />}
        {selectedTab === 4 && <ApiKeys />}
        {selectedTab === 5 && <Common />}
      </div>
    </PageWrap>
  );
};
