import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";

interface ComponentProps extends RouteComponentProps {
  fields: any;
  action: string;
  submitRef: any;
  onClick: any;
  onCancel: any;
}

export const PayPal: React.FC<ComponentProps> = ({
  fields,
  action,
  submitRef,
  onClick,
  onCancel,
}) => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: fields.clientId,
        currency: fields.currency,
      }}
    >
      <PayPalButtons
        fundingSource={FUNDING.PAYPAL}
        style={{
          layout: "horizontal",
          color: "blue",
          height: 50,
          tagline: false,
        }}
        createOrder={(data, actions) => {
          try {
            return actions.order.create({
							intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    value: fields.amount,
                    currency_code: fields.currency,
                  },
                  invoice_id: fields.paymentId,
                  description: fields.paymentId,
                },
              ],
            });
          } catch (e) {
            console.warn(e);
          }
        }}
        onApprove={(data, actions) => {
          return actions.order
            .capture()
            .then(() => {
              onClick();
            })
            .catch(() => {
              console.warn("Capturing error");
            });
        }}
        onCancel={(data, actions) => {
          onCancel();
        }}
      />
    </PayPalScriptProvider>
  );
};
