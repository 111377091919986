// @ts-ignore

import Chart from "chart.js/auto";
import { format } from "date-fns";
const crypto = [
  "bitcoin",
  "ethereum",
  "tether",
  "xrp",
  "bitcoin-cash",
  "litecoin",
  "ethereum-classic",
];

export const GetCryptoRates = async (withGraphs: boolean) => {
  const currentRates = await fetch(
    "https://api.coincap.io/v2/assets?ids=" + crypto.join(",")
  )
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
  const euroExchange = await fetch(
    "https://api.currencyfreaks.com/latest?apikey=e6f8b1008313432c8d842de6cad73743&base=EUR&symbols=USD"
  )
    .then((res) => res.json())
    .then((data) => {
      return data.rates.USD;
    })
    .catch((e) => {
      throw new Error(e);
    });
  const euroExchangeOld = await fetch(
    `https://api.currencyfreaks.com/historical/${format(
      Date.now() - 86400000,
      "yyyy-MM-dd"
    )}?apikey=e6f8b1008313432c8d842de6cad73743&base=EUR&symbols=USD`
  )
    .then((res) => res.json())
    .then((data) => {
      return data.rates.USD;
    })
    .catch((e) => {
      throw new Error(e);
    });
  const weekHistories = await Promise.all(
    crypto.map(async (curr) => {
      return await fetch(
        `https://api.coincap.io/v2/assets/${curr}/history?interval=h1&start=${new Date(
          Date.now() - 604800000
        ).getTime()}&end=${Date.now()}`
      )
        .then((res) => res.json())
        .then((data) => {
          return data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    })
  );

  const weekPercents = weekHistories.map((val, index) =>
    getWeekPercent(
      weekHistories[index][0].priceUsd,
      currentRates[index].priceUsd
    )
  );
  const weekDataset = weekHistories.map((set) => {
    const values = set.map((val: any) => {
      return parseFloat(val.priceUsd);
    });
    return values;
  });

  const results = crypto.map((val, index) => {
    if (withGraphs) {
      generateChart(weekDataset[index], weekPercents[index] >= 0, index);
    }
    return {
      name: currentRates[index].symbol,
      currentPriceUSD: parseFloat(currentRates[index].priceUsd).toFixed(2),
      currentPriceEUR: (
        parseFloat(currentRates[index].priceUsd) / parseFloat(euroExchange)
      ).toFixed(2),
      dayChange: parseFloat(currentRates[index].changePercent24Hr).toFixed(2),
      dayChangeEUR: (
        parseFloat(currentRates[index].changePercent24Hr) +
        getEuroChange(euroExchangeOld, euroExchange)
      ).toFixed(2),
      weekChange: weekPercents[index].toFixed(2),
      fullName: currentRates[index].name,
      eurExcange: euroExchange,
    };
  });
  return results;
};

const getWeekPercent = (start: string, end: string) => {
  const startNum = parseFloat(start);
  const endNum = parseFloat(end);
  //console.log(startNum, endNum);
  //console.log(endNum > startNum);
  return endNum > startNum
    ? (1 - startNum / endNum) * 100
    : (1 - endNum / startNum) * -100;
};

const getEuroChange = (start: number, end: number) => {
  return ((end - start) / start) * 100;
};

const generateChart = (
  dataset: number[],
  isPositive: boolean,
  index: number
) => {
  const canvas = document.getElementById(`canvas_${index}`);
  // @ts-ignore
  const chart = new Chart(canvas, {
    type: "line",
    data: {
      labels: dataset,
      datasets: [
        {
          fill: false,
          data: dataset,
          borderColor: isPositive ? "#1B9650" : "#DE0000",
          backgroundColor: isPositive ? "#1B9650" : "#DE0000",
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      elements: {
        point: {
          radius: 1,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false, //this will remove only the label
          },
          display: false,
        },
        y: {
          ticks: {
            display: false, //this will remove only the label
          },
          display: false,
        },
      },
      responsive: false,
    },
  });
  chart.resize(350, 200);
  chart.update();
};
