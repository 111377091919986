import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { PageWrap } from "../../components/common/Pages";
import BankTransaction from "./BankTransaction";
import { Cryptocurrency } from "./Cryptocurrency";
import { EPayments } from "./EPayments";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import Typography from "@material-ui/core/Typography";
import { CreditCard } from "./CreditCard";
import {t} from "i18next";

export const LoadWrap = styled.div`
  display: flex;
  width: 100%;  
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const gqlGetSupportedCurrenciesWithRates = gql`
  query {
    getSupportedCurrenciesWithRates {
      crypto {
        depositRate
        id
        minAmount
        name
        precision
        validateAs
        withdrawRate
      }
      fiat {
        depositRate
        id
        minAmount
        name
        precision
        validateAs
        withdrawRate
      }
    }
  }
`;

export const Deposit: React.FC<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext);

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (authContext.menuItems.findIndex((el) => el.label === "home") === -1) {
      navigate("/home/main").then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrap>
      <Typography
        variant={"h1"}
        style={{
          padding: "10px 0",
          fontSize: "36px",
        }}
      >
          {t('user.deposit.deposit_title')}
      </Typography>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
          setSelectedTab(newValue)
        }
      >
        <Tab label= {t('user.deposit.nav.bank_transaction')} />
        <Tab label= {t('user.deposit.nav.cryptocurrency')} />
        <Tab label= {t('user.deposit.nav.e_payments')} />
        <Tab label= {t('user.deposit.nav.visa_mastercard')} />
      </Tabs>
      <div style={{ padding: "16px 0 0 0", height: "calc(100% - 48px)" }}>
        {selectedTab === 0 && <BankTransaction />}
        {selectedTab === 1 && <Cryptocurrency />}
        {selectedTab === 2 && <EPayments />}
        {selectedTab === 3 && <CreditCard />}
      </div>
    </PageWrap>
  );
};
