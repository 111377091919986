import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { BANK_OPTIONS } from "../../../../core/constants/options";
//import { Auth } from "bundles/auth/components/Auth";

const Row = styled.div`
  display: flex;
`;

function GridWrap() {
	return (
		<Grid item xs={12} style={{padding: 0, margin: 0}}/>
	);
}

interface ControlProps {
  item: any;
  onClose: () => void;
  onDetails: () => void;
  inProcess?: boolean;
}

export const TransactionCard: React.FC<ControlProps> = ({
  item,
  onClose,
  onDetails,
}) => {
  return (
    <>
      <Row style={{ marginBottom: "24px" }}>
        <Typography variant={"h3"}>Transaction</Typography>
      </Row>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Transaction Type"
            value={item.transactionType}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Created"
            value={item.created}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Processing Status"
            value={item.processingStatus}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Transaction ID"
            value={item.bankTransactionId}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Amount"
            value={item.amount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Destination address"
            value={item._destinationAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Processing Description"
            value={item.processingStatusDescription}
          />
        </Grid>
        {[1, 4, 5].includes(item._transactionTypeId) ? (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Bank for deposit"
              value={
                item._transactionTypeId === 1
                  ? item.bankOptionId
                    ? BANK_OPTIONS[item.bankOptionId]
                    : item.currency === "EUR"
                    ? BANK_OPTIONS[1]
                    : BANK_OPTIONS[5]
                  : item.beneficiaryBankName
              }
            />
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Exchange Description"
            value={item.exchangeDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Comment"
            value={item.comment}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="User Name"
            value={item.user.props.children}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Transaction Code"
            value={item.transactionCode}
          />
        </Grid>
				<Grid item xs={12} md={6}>
					<TextField
						variant="outlined"
						fullWidth
						label="Blockchain"
						value={item.blockchain}
					/>
				</Grid>

				<GridWrap/>

        <Grid item xs={12} md={6}>
          <Typography variant={"h3"}>
            Paid from user: {item.payedFromUser === "Y" ? "Yes" : "No"}
          </Typography>
          <Typography variant={"h3"}>
            Exchanged: {item.exchanged === "Y" ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h3"}>
            Manually handling: {item.manualHandling === "Y" ? "Yes" : "No"}
          </Typography>
          <Typography variant={"h3"}>
            Auto tracked: {item.autoTracking === "Y" ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }} />
        <Grid item xs={12} md={6}>
          <Button
            // style={{minWidth: '240px'}}
            variant="contained"
            color="primary"
            size={"large"}
            onClick={() => onDetails()}
          >
            details
          </Button>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: "end" }}>
          <Button
            style={{ width: "200px" }}
            variant="outlined"
            color="primary"
            size={"large"}
            onClick={() => onClose()}
          >
            back
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
