import React from 'react';
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";

const Component = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;	
`;

const Card = styled.div`
	width: 100%;
	max-width: 1110px;
	display: flex;	
	flex-direction: column;
	margin: 60px 32px;	
`;

const Img = styled.img`
	width: 100%;
	max-width: 680px;
	
	
`;

export interface ComponentProps {
	item: any;
	onClose: () => void;
}

export const BlogCard: React.FC<ComponentProps> = (props) => {

	function createMarkup(html: any) {
		return {__html: html};
	}


	return (
		<Component>
			<Card>
				<Typography variant="h1">
					{props.item.title}
				</Typography>

				<Typography
					variant="body1"
					color="textSecondary"
					component="p"
					style={{marginTop: '32px', marginBottom: '24px'}}
				>
					{props.item.date}
				</Typography>

				{
					props.item.files && props.item.files[0] && props.item.files[0].url
					&& <Img src={props.item.files[0].url} alt=""/>
				}

				<p
					dangerouslySetInnerHTML={createMarkup(props.item.text)}
					style={{marginTop: '32px', marginBottom: '32px'}}
				/>

				<Button
					onClick={() => props.onClose()}
				>
					Back
				</Button>
			</Card>
		</Component>
	);
};
