import React, { useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CurrencyInput } from "../../components/common/CurrencyInput";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  BankTransactionResult,
  EPaymentType,
  MutationConfirmWithdrawEPaymentArgs,
  MutationWithdrawEPaymentArgs,
} from "../../../../store/generated-models";
import { useSnackbar } from "notistack";
import { Dropdown } from "../../components/common/Dropdown";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { LoadWrap, gqlGetSupportedCurrenciesWithRates } from "./Withdraw";
import CircularProgress from "@material-ui/core/CircularProgress";
import { round, OERO_PRECISION } from "../../../../core/helpers/Currencies";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const Row = styled.div`
  display: flex;
`;

const ConfirmWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const Confirm = styled.div`
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const Alert = styled.span`
  color: ${(props) => props.theme.custom.palette.alert};
  font-weight: bold;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.custom.palette.alert};
  &:hover {
    opacity: 0.87;
  }
`;

const gqlwithdrawEPayment = gql`
  mutation withdrawEPayment(
    $comment: String
    $immutableAmount: Int!
    $incomeAmount: Float!
    $incomeCurrency: String!
    $paymentCredentials: String!
    $oeroAmount: Float!
    $paymentType: EPaymentType
  ) {
    withdrawEPayment(
      comment: $comment
      immutableAmount: $immutableAmount
      incomeAmount: $incomeAmount
      incomeCurrency: $incomeCurrency
      oeroAmount: $oeroAmount
      paymentCredentials: $paymentCredentials
      paymentType: $paymentType
    ) {
      id
      transaction {
        firstUserName
        lastUserName
        currency
        amount
        comment
      }
    }
  }
`;

const gqlConfirmWithdrawEPayment = gql`
  mutation confirmWithdrawEPayment($id: String) {
    confirmWithdrawEPayment(id: $id) {
      transaction {
        firstUserName
        lastUserName
        currency
        amount
        comment
        transactionCode
      }
    }
  }
`;

const DEF_CURR_NAME = "EUR";
const PAY_SYS = Object.keys(EPaymentType);

const initFields = {
  transactionType: "",
  paymentCredentials: "",
  fromAmount: "",
  fromCurrency: "OERO",
  toAmount: "",
  toCurrency: DEF_CURR_NAME,
  comment: "",
};

const defaultCurrency = {
  minAmount: 0,
  name: DEF_CURR_NAME,
  withdrawRate: 0,
};

interface ComponentProps extends RouteComponentProps {}

const EPayments: React.FC<ComponentProps> = () => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [withdrawEPayment, { loading: withdrawEPaymentLoading }] = useMutation<
    { withdrawEPayment: BankTransactionResult },
    MutationWithdrawEPaymentArgs
  >(gqlwithdrawEPayment);

  const [confirmWithdrawEPayment, { loading: confirmWithdrawEPaymentLoading }] =
    useMutation<
      { confirmWithdrawEPayment: BankTransactionResult },
      MutationConfirmWithdrawEPaymentArgs
    >(gqlConfirmWithdrawEPayment);

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});

  const [fromAmountValue, setFromAmountValue] = useState(0);
  const [toAmountValue, setToAmountValue] = useState(0);

  const [paySys, setPaySys] = useState<EPaymentType>(EPaymentType.Epay);

  const [currencies, setCurrencies] = useState<any[]>([{ id: DEF_CURR_NAME }]);
  const [immutableAmount, setImmutableAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [txId, setTxId] = useState("");
  const [txInfo, setTxInfo] = useState<any>({});

  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(defaultCurrency);

  const { data, error, loading, refetch } = useQuery(
    gqlGetSupportedCurrenciesWithRates
  );

  useEffect(() => {
    refetch().catch((err) => console.warn(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSelectedCurrency = (cur: string) => {
    let selCur = data.getSupportedCurrenciesWithRates.fiat.find(
      (el: any) => el.name === cur
    );
    if (!selCur) selCur = defaultCurrency;
    return selCur;
  };

  const setInitial = () => {
    setFields({ ...initFields });
    setStatus("");
    setFromAmountValue(0);
    setToAmountValue(0);
    setImmutableAmount(0);
    setTxId("");
    setTxInfo({});
    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  };

  const submitWithdraw = async () => {
    let err: any = {};
    if (!fields.paymentCredentials) err.paymentCredentials = "required";
    if (!fromAmountValue) err.fromAmount = "required";
    if (!toAmountValue) err.toAmount = "required";

    if (
      toAmountValue &&
      fields.toCurrency &&
      authContext.settings &&
      authContext.settings.restrictMinAmounts &&
      toAmountValue < selectedCurrency.minAmount
    ) {
      err.toAmount = `minimum ${selectedCurrency.minAmount} ${selectedCurrency.name}`;
    }

    if (
      !err.fromAmount &&
      authContext.user.state.contractBalance < fromAmountValue
    ) {
      err.fromAmount = "Insufficient funds";
    }

    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar("Correct errors to continue", { variant: "error" });
      return;
    }

    let errMessage = "";

    try {
      const res = await withdrawEPayment({
        variables: {
          immutableAmount: immutableAmount,
          comment: fields.comment,
          incomeAmount: toAmountValue,
          incomeCurrency: fields.toCurrency,
          paymentType: paySys,
          oeroAmount: fromAmountValue,
          paymentCredentials: fields.paymentCredentials,
        },
      });
      if (res.data.withdrawEPayment.id) {
        setTxId(res.data.withdrawEPayment.id);
        setTxInfo(res.data.withdrawEPayment.transaction);
        setStatus("confirm");
        return;
      } else {
        console.warn("submitWithdraw res:", res);
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") {
          errMessage = "Access denied";
        } else if (errorCode === "core.insufficient_funds") {
          errMessage = "Insufficient funds";
        }
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const submitConfirmWithdraw = async () => {
    let errMessage = "";

    try {
      const res = await confirmWithdrawEPayment({
        variables: {
          id: txId,
        },
      });
      console.log(res);
      if (res.data.confirmWithdrawEPayment.transaction.transactionCode) {
        setTxInfo(res.data.confirmWithdrawEPayment.transaction);
        setStatus("done");
        return;
      } else {
        console.warn("submitConfirmWithdraw res:", res);
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") {
          errMessage = "Access denied";
        } else if (errorCode === "core.insufficient_funds") {
          errMessage = "Insufficient funds";
        }
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  useEffect(() => {
    if (!data || !data.getSupportedCurrenciesWithRates) return;

    setCurrencies(
      data.getSupportedCurrenciesWithRates.fiat.map((el: any) => {
        return {
          id: el.id,
          value: el.name,
        };
      })
    );

    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const processValues = (params: any) => {
    if (params.immutable !== undefined) setImmutableAmount(params.immutable);

    let newFields: any = {};
    if (params.fromAmount !== undefined)
      newFields.fromAmount = params.fromAmount;
    if (params.toAmount !== undefined) newFields.toAmount = params.toAmount;
    if (params.toCurrency !== undefined)
      newFields.toCurrency = params.toCurrency;

    let rateVal: number;
    if (params.withdrawRate !== undefined) {
      const selCurr = getSelectedCurrency(params.toCurrency);
      setSelectedCurrency(selCurr);
      rateVal = selCurr.withdrawRate;
    } else {
      rateVal = selectedCurrency.withdrawRate;
    }

    const fromAmountVal =
      params.fromAmount !== undefined ? params.fromAmount : fields.fromAmount;
    const toAmountVal =
      params.toAmount !== undefined ? params.toAmount : fields.toAmount;
    const immutableAmountVal =
      params.immutable !== undefined ? params.immutable : immutableAmount;

    let fromAmt = fromAmountVal === null ? null : parseFloat(fromAmountVal);
    let toAmt = toAmountVal === null ? null : parseFloat(toAmountVal);

    if (fromAmt === null || toAmt === null || !rateVal) {
      setFromAmountValue(0);
      setToAmountValue(0);
      return;
    }

    if (immutableAmountVal === 0) {
      if (isNaN(fromAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        newFields.toAmount = "";
        setFields({ ...fields, ...newFields });
        return;
      }

      fromAmt = round(fromAmt, OERO_PRECISION);
      const calc = round(fromAmt / rateVal, selectedCurrency.precision);
      // const calc = fromAmt / rateVal;
      setFromAmountValue(fromAmt);
      setToAmountValue(calc);
      newFields.toAmount = calc.toString();
      setFields({ ...fields, ...newFields });
    } else {
      if (isNaN(toAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        newFields.fromAmount = "";
        setFields({ ...fields, ...newFields });
        return;
      }

      toAmt = round(toAmt, selectedCurrency.precision);
      const calc = round(toAmt * rateVal, OERO_PRECISION);
      // const calc = toAmt * rateVal;
      setFromAmountValue(calc);
      setToAmountValue(toAmt);
      newFields.fromAmount = calc.toString();
      setFields({ ...fields, ...newFields });
    }
  };

  const handleInputFromAmountChange = (val: string) => {
    processValues({
      immutable: 0,
      fromAmount: val,
    });
  };

  const handleInputToAmountChange = (val: string) => {
    processValues({
      immutable: 1,
      toAmount: val,
    });
  };

  const handleToCurrencyChange = (val: string) => {
    processValues({
      withdrawRate: true,
      toCurrency: val,
    });
  };

  if (error) {
    return <LoadWrap>Internal server error. Try again later</LoadWrap>;
  }

  if (!data || loading) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  // Render CONFIRM step
  if (status === "confirm") {
    return (
      <ConfirmWrap>
        <Confirm>
          <Typography variant={"h2"} style={{ textAlign: "center" }}>
            {t('user.withdraw.please_confirm_transaction')}
          </Typography>

          <div style={{ marginTop: "24px" }}>
            <Typography variant={"h3"}>
              {t('user.withdraw.withdraw_amount')} {txInfo.amount}&nbsp;{txInfo.currency}
            </Typography>
          </div>

          <Row style={{ marginTop: "24px", width: "100%" }}>
            <Button
              variant="contained"
              color="primary"
              size={"large"}
              style={{
                minWidth: "120px",
                maxWidth: "240px",
                width: "100%",
              }}
              onClick={() => submitConfirmWithdraw()}
              disabled={confirmWithdrawEPaymentLoading}
            >
              {t('user.withdraw.confirm')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size={"large"}
              style={{ minWidth: "120px", marginLeft: "20px" }}
              onClick={() => setInitial()}
            >
              {t('user.withdraw.cancel')}
            </Button>
          </Row>
        </Confirm>
      </ConfirmWrap>
    );
  }

  // Render DONE step
  if (status === "done") {
    return (
      <ConfirmWrap>
        <Confirm>
          <Typography variant={"h2"} style={{ textAlign: "center" }}>
            {t('user.withdraw.your_transaction_is')}
          </Typography>
          <Typography variant={"h2"} style={{ textAlign: "center" }}>
            <Alert>{t('user.withdraw.in_processing')}</Alert>
          </Typography>
          <LinkStyled to={"/private/history"} style={{ marginTop: "24px" }}>
            {t('user.withdraw.click_here')}
          </LinkStyled>
        </Confirm>
      </ConfirmWrap>
    );
  }

  // Render INITIAL step
  return (
    <>
      <Grid container spacing={3} style={{ maxWidth: "1000px" }}>
        <Grid item xs={12} md={6}>
          <Dropdown
            style={{ minWidth: "160px", background: "white" }}
            label={t('user.withdraw.payment_system')}
            values={PAY_SYS}
            value={paySys}
            onChange={(val) => setPaySys(val as EPaymentType)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.paymentCredentials}
            helperText={errors.paymentCredentials}
            variant="outlined"
            fullWidth
            label={t('user.withdraw.address')}
            value={fields.paymentCredentials}
            onChange={(e) =>
              setFields({ ...fields, paymentCredentials: e.target.value })
            }
          />
          <Typography variant={"body1"} style={{ marginTop: "8px" }}>
            {t('user.withdraw.enter_here')} {paySys} {t('user.withdraw.enter_here_2')}
          </Typography>
          {/* <Typography variant={"body1"}>
            To identify your payment, we need to know your wallet id, from which
            you will transfer necessary amount.
          </Typography> */}
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }} />
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.fromAmount}
            helperText={errors.fromAmount}
            label={t('user.withdraw.from')}
            amount={fields.fromAmount}
            onAmountChange={(val) => handleInputFromAmountChange(val)}
            currency={fields.fromCurrency}
            onCurrencyChange={(val) =>
              setFields({ ...fields, fromCurrency: val })
            }
            currencies={[{ id: "OERO" }]}
            fullWidth
            dropdownDisabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.toAmount}
            helperText={errors.toAmount}
            label={t('user.withdraw.to')}
            amount={fields.toAmount}
            onAmountChange={(val) => handleInputToAmountChange(val)}
            currency={fields.toCurrency}
            onCurrencyChange={(val) => handleToCurrencyChange(val)}
            currencies={currencies}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.comment}
            helperText={errors.comment}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
          	maxRows={6}
            label={t('user.withdraw.comment')}
            value={fields.comment}
            //onChange={(event) => setComment(event.target.value)}
            onChange={(e) => setFields({ ...fields, comment: e.target.value })}
          />
        </Grid>
      </Grid>

      <Row style={{ marginTop: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "240px", minHeight: "50px" }}
          onClick={() => submitWithdraw()}
          disabled={withdrawEPaymentLoading}
        >
          {t('user.withdraw.next')}
        </Button>
      </Row>
    </>
  );
};

export default withTranslation()(EPayments)
