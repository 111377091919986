// import {Resolvers} from 'apollo-client';
import {AppState, MutationUpdateAppStateArgs, Query} from './generated-models';
import {gql, InMemoryCache} from '@apollo/client';
import {initLocalCache} from './local-cache';

export const localResolvers = {
  Query: {},
  Mutation: {
    updateAppState: (
      _: any,
      params: MutationUpdateAppStateArgs,
      { cache }: { cache: InMemoryCache }
    ): AppState => {
      // Получаем текущее состояние из кэша
      const currentStateQuery = cache.readQuery<Query>({
        query: gql`
          query GetAppState {
            appState @client {
              isFullScreen
              isProMode
              isRealTrading
              isSideBarCollapsed
            }
          }
        `,
      })?.appState;

      const newState: AppState = {
        ...currentStateQuery,
        ...params,
      };

      // Записываем новое состояние в кэш с использованием writeQuery
      cache.writeQuery({
        query: gql`
          query SetAppState {
            appState {
              isFullScreen
              isProMode
              isRealTrading
              isSideBarCollapsed
            }
          }
        `,
        data: {
          appState: newState,
        },
      });

      return newState;
    },
    processInitLocalCache: (
      _: any,
      params: void,
      { cache }: { cache: InMemoryCache }
    ): boolean => {
      initLocalCache(cache);
      return true;
    },
  },
};

