import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { KycProcess } from "./KycProcess";
import { AuthContext } from "../../../../../core/providers/AuthProvider";
import { formatDateTime } from "../../../helpers/Format";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "@apollo/client";
import { USER_RESULT } from "../../../../../core/providers/gql";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const Row = styled.div`
  display: flex;
`;

const StatusText = styled.span`
  // color: ${(props) => props.theme.custom.palette.alert};
  font-weight: bold;
  text-transform: uppercase;

  &.kyc-status-new {
    color: gray;
  }

  &.kyc-status-verification {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &.kyc-status-accepted {
    color: ${(props) => props.theme.custom.palette.success};
  }

  &.kyc-status-declined {
    color: ${(props) => props.theme.custom.palette.alert};
  }
`;

const AlertText = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.alert};
`;

export const KYC_TYPE: any[] = [
  { id: "p", value: "Person" },
  { id: "c", value: "Company" },
];

const Kyc: React.FC<RouteComponentProps> = () => {

  const KYC_STATUS: any = {
    NEW: t('user.settings.not_validated'),
    VERIFICATION: t('user.settings.verification'),
    ACCEPTED: t('user.settings.accepted'),
    DECLINED: t('user.settings.declined'),
  };

  const authContext = useContext(AuthContext);
  // const {enqueueSnackbar} = useSnackbar();

  const [kycStatus, setKycStatus] = useState("NEW");
  const [processStatus, setProcessStatus] = useState(0); // 0 / 1
  // const [item, setItem] = useState<any>({});

  const {
    // data: userData,
    // error: userError,
    refetch: userRefetch,
  } = useQuery(gql`
      query Me {
          me {
              ${USER_RESULT}
          }
      }
	`);

  // useEffect(() => {
  // 	authContext.refetchUser();
  // }, [userData]);

  useEffect(() => {
    authContext.refetchUser();
    userRefetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext.user.valid === null) {
      if (authContext.user.userType === null) {
        setKycStatus("NEW");
      } else {
        setKycStatus("VERIFICATION");
      }
    } else {
      if (authContext.user.valid) {
        setKycStatus("ACCEPTED");
      } else {
        setKycStatus("DECLINED");
      }
    }
  }, [authContext.user]);

  useEffect(() => {
    if (authContext.company === null) {
      authContext.getSettings();
      return;
    }
  }, [authContext.company]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeKycClickHandler = () => {
    if (kycStatus === "DECLINED") {
      setProcessStatus(2);
      return;
    }

    setProcessStatus(1);
  };

  const newKycClickHandler = () => {
    setProcessStatus(1);
  };

  const kycProcessOnCloseHandler = (res: any) => {
    if (res === null) {
      setProcessStatus(0);
      return;
    }

    userRefetch().then();
    // authContext.refetchUser();
    setProcessStatus(0);
  };

  const getUserType = useMemo(() => {
    if (authContext.user.userType) {
      return authContext.user.userType === "p" ? t('user.settings.person') : t('user.settings.company');
    }

    return "";
  }, [authContext.user.userType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (authContext.company === null) {
    return (
      <>
        <Typography variant={"body1"}>Loading settings...</Typography>
      </>
    );
  }

  if (processStatus === 0) {
    return (
      <>
        <Row style={{ marginTop: "4px" }}>
          <Typography variant={"body1"}>
            {t('user.settings.KYC_status')}{" "}
            <StatusText className={"kyc-status-" + kycStatus.toLowerCase()}>
              {KYC_STATUS[kycStatus]}
            </StatusText>
            {getUserType ? t('user.settings.as') + getUserType : null}
            {authContext.user.validationDate
              ? " (" + formatDateTime(authContext.user.validationDate) + ")"
              : null}
          </Typography>
        </Row>

        {authContext.user.kycComment && kycStatus === "DECLINED" && (
          <Row style={{ marginTop: "8px" }}>
            <AlertText variant={"body1"}>
              KYC comment: {authContext.user.kycComment}
            </AlertText>
          </Row>
        )}

        {authContext.user.valid === null && (
          <Row style={{ marginTop: "8px" }}>
            <AlertText variant={"body1"}>
              {t('user.settings.verified')}
            </AlertText>
          </Row>
        )}

        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            color="primary"
            size={"large"}
            style={{ minWidth: "240px", minHeight: "50px" }}
            disabled={kycStatus === "VERIFICATION"}
            onClick={changeKycClickHandler}
          >
            {t('user.settings.Change')} KYC
          </Button>

          {kycStatus === "DECLINED" && (
            <Button
              variant="outlined"
              color="primary"
              size={"large"}
              style={{
                minWidth: "240px",
                marginLeft: "24px",
                minHeight: "50px",
              }}
              onClick={newKycClickHandler}
            >
              {t('user.settings.new')} KYC
            </Button>
          )}
        </Row>
      </>
    );
  }

  // New Card
  if (processStatus === 1) {
    return (
      <KycProcess
        // type={kycType}
        isEdit={false}
        onClose={(res: any) => kycProcessOnCloseHandler(res)}
      />
    );
  }

  // Edit Card
  if (processStatus === 2) {
    // return (
    // 	<KycEdit
    // 		item={item}
    // 		onClose={(res: any) => kycProcessOnCloseHandler(res)}
    // 	/>
    // )
    return (
      <KycProcess
        isEdit={true}
        onClose={(res: any) => kycProcessOnCloseHandler(res)}
      />
    );
  }

  return null;
};

export default withTranslation()(Kyc)
