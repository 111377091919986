import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { SimpleGrid, HeadCell } from "../../../../core/ui/grids/SimpleGrid";
import { gql } from "@apollo/client";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { formatDateTime, formatAmount } from "../../helpers/Format";
import {
  PROCESSING_STATUS,
  TRANSACTION_TYPE,
} from "../../../../core/constants/options";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {QRCodeSVG} from "qrcode.react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { PageWrap } from "bundles/private/components/common/Pages";
import { useSnackbar } from "notistack";
import { MutationCreateUserTransactionStatementArgs } from "store/generated-models";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const ITEM_PER_PAGE: number = 20;

export const TablePageWrap = styled.div`
  padding: 50px 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 13px rgba(158, 158, 158, 0.08);
  @media (max-width: 1280px) {
    padding: 24px 16px;
  }
`;

const Row = styled.div`
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const InvoiceWrap = styled.div`
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }
`;

const WrapText = styled(Typography)`
  overflow-wrap: break-word;
`;

const LoadWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const CopyButton = styled(IconButton)`
  margin: 0 0 0 8px;
  background-color: #0096c3;
  color: white;
  height: 100%;
  width: auto;
  :hover {
    background-color: #087ca7;
  }
`;

const gqlCreateUserTransactionStatement = gql`
  mutation createUserTransactionStatement(
    $dateFrom: DateTime!
    $dateTo: DateTime!
  ) {
    createUserTransactionStatement(dateFrom: $dateFrom, dateTo: $dateTo)
  }
`;

const gqlMyBankTransaction = gql`
  query myBankTransactions($first: Int, $skip: Int) {
    myBankTransactions(
      first: $first
      orderBy: "created"
      desc: true
      skip: $skip
    ) {
      count
      transactions {
        address
        addressService
        amount
        bankTransactionId
        beneficiaryAccountAddress
        beneficiaryAccountCountry
        beneficiaryAccountName
        beneficiaryAccountPostalCity
        beneficiaryAccountPostalCode
        beneficiaryBankAddress
        beneficiaryBankCountry
        beneficiaryBankName
        beneficiaryBankPostalCity
        beneficiaryBankPostalCode
        comment
        companyName
        created
        currency
        description
        fineAmount
        firstUserName
        iban
        ibanAccountNumber
        intermediaryBankRoutingNumberBic
        lastUserName
        oero
        oeroExchangeRate
        ownerName
        paymentDetails
        paymentId
        paymentResult
        processingStatus
        processingStatusDescription
        swiftBic
        transactionCode
        transactionHash
        transactionNumber
        transactionType
        updateStatusUserId
        updated
        wallet
        bankOptionId
      }
    }
  }
`;

const gqlTransactionStatusChanged = gql`
  subscription transactionStatusChanged {
    transactionStatusChanged
  }
`;



const History: React.FC<RouteComponentProps> = () => {

  const headCells: HeadCell[] = [
    { id: "created", label: t('user.history.date') },
    { id: "amount", label: t('user.history.Amount'), align: "right" },
    { id: "oero", label: t('user.history.amount_OERO'), align: "right" },
    { id: "oeroExchangeRate", label: t('user.history.OERO_exchange_rate'), align: "right" },
    { id: "currency", label: t('user.history.currency') },
    { id: "transactionType", label: t('user.history.transaction_type') },
    { id: "processingStatus", label: t('user.history.status') },
    { id: "destinationAddress", label: t('user.history.destination_address') },
    { id: "transactionCode", label: t('user.history.transaction_hash') },
    //{ id: "moreInfo", label: "" },
  ];

  const authContext = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const COMPANY = authContext.company;
  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  const [mode, setMode] = useState<string>("");
  const [item, setItem] = useState<any>();
  const [selectedDateFrom, setSelectedDateFrom] = useState<any>(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(
        0,
        0,
        0,
        0
      )
    )
  );
  const [selectedDateTo, setSelectedDateTo] = useState<any>(
    new Date(new Date().setHours(23, 0, 0, 0))
  );
  const [chosenBank, setChosenBank] = useState<any>(
    COMPANY.banks.find((el) => el.bankOption === 1)
  );

  const [
    createUserTransactionStatement,
    { loading: createUserTransactionStatementLoading },
  ] = useMutation<
    { createUserTransactionStatement: boolean },
    MutationCreateUserTransactionStatementArgs
  >(gqlCreateUserTransactionStatement);


  const { loading, error, data, refetch } = useQuery(gqlMyBankTransaction, {
    variables: {
      first: ITEM_PER_PAGE,
      skip: (page - 1) * ITEM_PER_PAGE,
    },
  });

  const {
    // loading: subscrLoading,
    data: subscrData,
    error: subscrError,
  } = useSubscription(gqlTransactionStatusChanged);

  useEffect(() => {
    if (subscrError) {
      console.warn("subscrError:", subscrError);
      return;
    }

    if (subscrData) {
      refetch().then();
      authContext.refetchUser();
    }
  }, [subscrData, subscrError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext.menuItems.findIndex((el) => el.label === "home") === -1) {
      navigate("/home/main").then();
    } else {
      // getItems();
      refetch().then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) console.warn(">> History useEffect error", error);
  }, [error]);

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.myBankTransactions &&
      data.myBankTransactions.count
    ) {
      const totalCnt = data.myBankTransactions.count;
      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      setItems(
        data.myBankTransactions.transactions.map((el: any) => {
          let transactionType = TRANSACTION_TYPE[el.transactionType]
            ? TRANSACTION_TYPE[el.transactionType]
            : "-";

          const jsonDetails = JSON.parse(el.paymentDetails);
          const paymentType =
            jsonDetails && jsonDetails.type ? jsonDetails.type : "";
          if (paymentType)
            transactionType = `${transactionType} (${paymentType})`;

          let fmtAmount =
            el.currency !== "ETH"
              ? formatAmount(el.amount)
              : formatAmount(el.amount, 5);

          fmtAmount = [4, 5, 6, 10].includes(el.transactionType)
            ? `-${fmtAmount}`
            : fmtAmount;
          const bank = el.bankOptionId
            ? COMPANY.banks.find((bank) => el.bankOptionId === bank.bankOption)
            : COMPANY.banks.find((bank) => 1 === bank.bankOption);

          const destinationAddress =
            el.transactionType === 2
              ? el.addressService
              : el.transactionType === 6 || el.transactionType === 12
              ? el.wallet
              : [4, 5].includes(el.transactionType)
              ? `Bank account/IBAN: ${el.ibanAccountNumber}; SWIFT/BIC: ${el.intermediaryBankRoutingNumberBic}`
              : el.transactionType === 1
              ? `Bank account/IBAN: ${bank.bankAccount}; SWIFT/BIC: ${bank.bankSwiftBic}`
              : "";
          const oero = [4, 5, 6, 10].includes(el.transactionType)
            ? `-${el.oero}`
            : el.oero;

          return {
            ...el,
            oero: oero,
            paymentId: el.paymentId,
            companyName: el.companyName,
            firstName: el.firstName,
            lastName: el.lastName,
            transactionCode: el.transactionCode,
            bankTransactionId: el.bankTransactionId,
            created: formatDateTime(el.created),
            currency: el.currency,
            // amount: formatAmount(el.amount),
            amount: fmtAmount,
            transactionType: transactionType,
            addressService: el.addressService,
            bankOptionId: el.bankOptionId,
            processingStatus: PROCESSING_STATUS[el.processingStatus]
              ? PROCESSING_STATUS[el.processingStatus]
              : "-",
            destinationAddress: destinationAddress,
          };
        })
      );
    }
    // }, [data]);
    //eslint-disable-next-line
  }, [data, loading]);

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
  };

  const onCreateStatement = async () => {
    await createUserTransactionStatement({
      variables: {
        dateFrom: selectedDateFrom,
        dateTo: selectedDateTo,
      },
    })
      .then(() => {
        enqueueSnackbar("Statement successfully created and sent to email", {
          variant: "success",
        });
      })
      .catch((err) => {
        console.warn("Something went wrong", err);
      });
  };

  function copyToClipboard(text: string, message?: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(message ? message : "copied");
      })
      .catch((err) => {
        console.warn("Something went wrong", err);
      });
  }

  const handleItemClick = (e: any) => {
    //console.log(e.currentTarget.id);
    const id = items.findIndex((el) => {
      return el.transactionCode === e.currentTarget.id;
    });
    //console.log(items[id]);
    switch (items[id].transactionType) {
      case "Withdrawal crypto":
      case "Deposit crypto": {
        setMode("crypto");
        break;
      }
      case "Withdrawal bank":
      case "Deposit bank": {
        console.log(COMPANY.banks, items[id].bankOptionId);
        if (!!items[id].bankOptionId) {
          setChosenBank(
            COMPANY.banks.find(
              (bank) => bank.bankOption === items[id].bankOptionId
            )
          );
        } else {
          setChosenBank(COMPANY.banks.find((el) => el.bankOption === 1));
        }
        setMode("bank");
        break;
      }
      case "Deposit E-payment": {
        setMode("epay");
        break;
      }
      default:
    }
    //console.log(authContext.user);
    setItem(items[id]);
  };

  const setInitial = () => {
    setMode("");
  };

  if (!data && loading) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  if (mode === "epay") {
    return (
      <PageWrap>
        <Grid container spacing={2} style={{ marginTop: "24px" }}>
          <Grid item xs={1}>
            <Typography variant={"body1"}>Payment ID</Typography>
          </Grid>
          <Grid item xs={11}>
            <WrapText variant={"body1"} style={{ fontWeight: "bold" }}>
              {item.paymentId}
            </WrapText>
          </Grid>
          <Grid item xs={1}>
            <Typography variant={"body1"}>Amount</Typography>
          </Grid>
          <Grid item xs={11}>
            <WrapText variant={"body1"} style={{ fontWeight: "bold" }}>
              {item.amount} {item.currency}
            </WrapText>
          </Grid>
          {item.comment && (
            <>
              <Grid item xs={7}>
                <Typography variant={"body1"}>Comment</Typography>
              </Grid>
              <Grid item xs={7}>
                <WrapText variant={"body1"}>{item.comment}</WrapText>
              </Grid>
            </>
          )}
        </Grid>
        <Row style={{ marginTop: "20px" }}>
          <Typography variant={"h4"}>
            Transaction code {item.transactionCode}
          </Typography>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Typography variant={"h2"}>
            Status: {item.processingStatus}
          </Typography>
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="outlined"
            color="primary"
            size={"large"}
            style={{ minWidth: "240px", minHeight: "50px" }}
            onClick={() => setInitial()}
          >
            BACK
          </Button>
        </Row>
      </PageWrap>
    );
  }

  if (mode === "crypto") {
    return (
      <PageWrap>
        <Row>
          <Typography variant={"h4"}>
            Amount to transfer:{" "}
            <b>
              {item.amount}&nbsp;{item.currency}
            </b>
          </Typography>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <QRCodeSVG
            value={item.addressService}
            renderAs={"canvas"} // svg, canvas
            size={210}
            level={"M"} // ('L' 'M' 'Q' 'H')	'L'
            includeMargin={true}
            style={{ border: "1px solid #efefef" }}
          />
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <StyledTextField
            style={{
              maxWidth: "420px",
              width: "100%",
            }}
            variant="outlined"
            label="Address"
            disabled={true}
            value={item.addressService}
            InputProps={{
              endAdornment: (
                <CopyButton
                  style={{ borderRadius: "5px", width: "50px" }}
                  onClick={() =>
                    copyToClipboard(item.addressService, "Address copied")
                  }
                >
                  <FileCopyIcon style={{ width: "24px", height: "24px" }} />
                </CopyButton>
              ),
            }}
          />
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Typography variant={"h4"}>
            Transaction code: {item.transactionCode}
          </Typography>
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Typography variant={"h4"}>Comment: {item.comment}</Typography>
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Typography variant={"h2"}>
            Status: {item.processingStatus}
          </Typography>
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="outlined"
            color="primary"
            size={"large"}
            style={{
              minWidth: "120px",
              maxWidth: "240px",
              width: "100%",
              minHeight: "50px",
            }}
            onClick={setInitial}
            //disabled={confirmDepositCryptoLoading}
          >
            BACK
          </Button>
        </Row>
      </PageWrap>
    );
  }

  if (mode === "bank") {
    return (
      <PageWrap>
        <InvoiceWrap>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                {item.transactionType === "Deposit bank"
                  ? "PAYMENT RECEIVER"
                  : "PAYMENT SENDER"}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Company name
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.companyName}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Company address
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.companyAddress}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank name
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>{chosenBank.bankName}</WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank address
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.bankAddress}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank account (IBAN)
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.bankAccount}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Bank SWIFT (BIC)
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.bankSwiftBic}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Routing Number
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.routingNumber}
                  </WrapText>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Reference Number
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>
                    {chosenBank.referenceNumber}
                  </WrapText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                {item.transactionType === "Deposit bank"
                  ? "PAYMENT SENDER"
                  : "PAYMENT RECEIVER"}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    NAME
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={"body1"}>
                    {authContext.user.userType === "c"
                      ? authContext.user.kycName
                      : authContext.user.kycName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    EMAIL
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={"body1"}>
                    {authContext.user.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "24px",
              paddingBottom: "24px",
              borderBottom: "1px solid lightgray",
            }}
          >
            <div>
              <Typography variant={"body1"}>
                Payment description: {item.transactionCode}
              </Typography>
            </div>

            <div style={{ marginTop: "8px" }}>
              <Typography variant={"body1"}>
                <b>IMPORTANT</b>: This code identifies your{" "}
                {item.transactionType === "Deposit bank"
                  ? "deposit"
                  : "withdrawal"}{" "}
                with your account.
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <Typography variant={"h3"}>
              Total amount: {item.amount}&nbsp;{item.currency}
            </Typography>
          </div>
          <div style={{ marginTop: "24px" }}>
            <Typography variant={"h2"}>
              Status: {item.processingStatus}
            </Typography>
          </div>
        </InvoiceWrap>
        <Row style={{ marginTop: "24px" }}>
          <Button
            variant="outlined"
            color="primary"
            size={"large"}
            style={{ minWidth: "120px", minHeight: "50px" }}
            onClick={() => setInitial()}
            //disabled={confirmDepositFiatLoading}
          >
            BACK
          </Button>
        </Row>
      </PageWrap>
    );
  }

  return (
    <TablePageWrap>
      <Row style={{ marginBottom: "12px" }}>
        <Typography variant={"h1"} style={{ fontSize: "36px" }}>
          {t('user.history.title')}
        </Typography>
      </Row>
      <SimpleGrid
        className={"compact-grid"}
        size={"small"}
        headCells={headCells}
        items={items}
        idField="bankTransactionId"
        page={page}
        pageCnt={pageCnt}
        onPageChange={onPageChangeHandler}
        onItemClick={handleItemClick}
      />
      <Row style={{ marginTop: "12px" }}>
        <Typography variant={"h2"} style={{ fontSize: "24px" }}>
          {t('user.history.get_statement')}
        </Typography>
      </Row>
      <Row style={{ marginTop: "12px" }}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={t('user.history.from')}
          autoOk
          value={selectedDateFrom}
          maxDate={selectedDateTo}
          onChange={(newDate) => {
            setSelectedDateFrom(newDate);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={t('user.history.to')}
          autoOk
          minDate={selectedDateFrom}
          maxDate={new Date()}
          value={selectedDateTo}
          onChange={(newDate) => {
            setSelectedDateTo(newDate);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "120px", minHeight: "50px" }}
          onClick={onCreateStatement}
          disabled={createUserTransactionStatementLoading}
        >
          {t('user.history.get_statement_btn')}
        </Button>
      </Row>
    </TablePageWrap>
  );
};

export default withTranslation()(History)
