/* tslint:ignore */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  DateTime: any;
  Upload: any;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  apiKeyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  disabled?: Maybe<Scalars['DateTime']>;
  user?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ApiKeySecret = {
  __typename?: 'ApiKeySecret';
  apiKeyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  disabled?: Maybe<Scalars['DateTime']>;
  secret?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type AppState = {
  __typename?: 'AppState';
  isFullScreen?: Maybe<Scalars['Boolean']>;
  isProMode?: Maybe<Scalars['Boolean']>;
  isRealTrading?: Maybe<Scalars['Boolean']>;
  isSideBarCollapsed?: Maybe<Scalars['Boolean']>;
};

export type BankTransaction = {
  __typename?: 'BankTransaction';
  address?: Maybe<Scalars['String']>;
  addressService?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  autoTracking?: Maybe<Scalars['Boolean']>;
  bankOptionId?: Maybe<Scalars['Int']>;
  bankTransactionId?: Maybe<Scalars['ID']>;
  beneficiaryAccountAddress?: Maybe<Scalars['String']>;
  beneficiaryAccountCountry?: Maybe<Scalars['String']>;
  beneficiaryAccountName?: Maybe<Scalars['String']>;
  beneficiaryAccountPostalCity?: Maybe<Scalars['String']>;
  beneficiaryAccountPostalCode?: Maybe<Scalars['String']>;
  beneficiaryBankAddress?: Maybe<Scalars['String']>;
  beneficiaryBankCountry?: Maybe<Scalars['String']>;
  beneficiaryBankName?: Maybe<Scalars['String']>;
  beneficiaryBankPostalCity?: Maybe<Scalars['String']>;
  beneficiaryBankPostalCode?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Scalars['String']>;
  collected?: Maybe<Scalars['Boolean']>;
  collectingDate?: Maybe<Scalars['DateTime']>;
  collectingDescription?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  derivation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentTemplateName?: Maybe<Scalars['String']>;
  ethTransactionId?: Maybe<Scalars['String']>;
  exchangeDescription?: Maybe<Scalars['String']>;
  exchangeName?: Maybe<Scalars['String']>;
  exchanged?: Maybe<Scalars['Boolean']>;
  exchangedDate?: Maybe<Scalars['DateTime']>;
  fineAmount?: Maybe<Scalars['Float']>;
  firstUserName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ibanAccountNumber?: Maybe<Scalars['String']>;
  intermediaryBankRoutingNumberBic?: Maybe<Scalars['String']>;
  intermediateBalance?: Maybe<Scalars['Float']>;
  lastUserName?: Maybe<Scalars['String']>;
  manualHandling?: Maybe<Scalars['Boolean']>;
  oero?: Maybe<Scalars['Float']>;
  oeroExchangeRate?: Maybe<Scalars['Float']>;
  ownerName?: Maybe<Scalars['String']>;
  payedFromUser?: Maybe<Scalars['Boolean']>;
  payedFromUserDate?: Maybe<Scalars['DateTime']>;
  paymentDetails?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentResult?: Maybe<Scalars['String']>;
  privateKeyService?: Maybe<Scalars['String']>;
  processingStatus?: Maybe<Scalars['Int']>;
  processingStatusDescription?: Maybe<Scalars['String']>;
  realAmount?: Maybe<Scalars['Float']>;
  realAmountDate?: Maybe<Scalars['DateTime']>;
  realAmountUserId?: Maybe<Scalars['String']>;
  realOero?: Maybe<Scalars['Float']>;
  realOeroExchangeRate?: Maybe<Scalars['Float']>;
  swiftBic?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['String']>;
  transactionHash?: Maybe<Scalars['String']>;
  transactionNumber?: Maybe<Scalars['Int']>;
  transactionType?: Maybe<Scalars['Int']>;
  updateStatusUserId?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  user: User;
  wallet?: Maybe<Scalars['String']>;
};

export type BankTransactionListResult = {
  __typename?: 'BankTransactionListResult';
  count?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<BankTransaction>>;
};

export type BankTransactionResult = {
  __typename?: 'BankTransactionResult';
  id?: Maybe<Scalars['String']>;
  transaction: BankTransaction;
};

export type BankTransactionShort = {
  __typename?: 'BankTransactionShort';
  address?: Maybe<Scalars['String']>;
  addressService?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  bankOptionId?: Maybe<Scalars['Int']>;
  bankTransactionId?: Maybe<Scalars['ID']>;
  beneficiaryAccountAddress?: Maybe<Scalars['String']>;
  beneficiaryAccountCountry?: Maybe<Scalars['String']>;
  beneficiaryAccountName?: Maybe<Scalars['String']>;
  beneficiaryAccountPostalCity?: Maybe<Scalars['String']>;
  beneficiaryAccountPostalCode?: Maybe<Scalars['String']>;
  beneficiaryBankAddress?: Maybe<Scalars['String']>;
  beneficiaryBankCountry?: Maybe<Scalars['String']>;
  beneficiaryBankName?: Maybe<Scalars['String']>;
  beneficiaryBankPostalCity?: Maybe<Scalars['String']>;
  beneficiaryBankPostalCode?: Maybe<Scalars['String']>;
  collected?: Maybe<Scalars['Boolean']>;
  collectingDate?: Maybe<Scalars['DateTime']>;
  collectingDescription?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fineAmount?: Maybe<Scalars['Float']>;
  firstUserName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ibanAccountNumber?: Maybe<Scalars['String']>;
  intermediaryBankRoutingNumberBic?: Maybe<Scalars['String']>;
  intermediateBalance?: Maybe<Scalars['Float']>;
  lastUserName?: Maybe<Scalars['String']>;
  oero?: Maybe<Scalars['Float']>;
  oeroExchangeRate?: Maybe<Scalars['Float']>;
  ownerName?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentResult?: Maybe<Scalars['String']>;
  processingStatus?: Maybe<Scalars['Int']>;
  processingStatusDescription?: Maybe<Scalars['String']>;
  swiftBic?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['String']>;
  transactionHash?: Maybe<Scalars['String']>;
  transactionNumber?: Maybe<Scalars['Int']>;
  transactionType?: Maybe<Scalars['Int']>;
  updateStatusUserId?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  user: User;
  wallet?: Maybe<Scalars['String']>;
};

export type BankTransactionShortListResult = {
  __typename?: 'BankTransactionShortListResult';
  count?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<BankTransactionShort>>;
};

export type BankTransactionShortResult = {
  __typename?: 'BankTransactionShortResult';
  id?: Maybe<Scalars['String']>;
  transaction: BankTransactionShort;
};

export type Banks = {
  __typename?: 'Banks';
  bankAcceptedCurrency?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankOption?: Maybe<Scalars['Int']>;
  bankSwiftBic?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  referenceNumber?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
};

export enum Blockchain {
  Eth = 'ETH',
  Trx = 'TRX'
}

export type Blog = {
  __typename?: 'Blog';
  blogId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<BlogPost>>;
  userId?: Maybe<Scalars['String']>;
};

export type BlogPost = {
  __typename?: 'BlogPost';
  blogId?: Maybe<Scalars['String']>;
  commentAllowed?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<PostComment>>;
  created?: Maybe<Scalars['DateTime']>;
  fileUrl?: Maybe<Scalars['String']>;
  files?: Maybe<Array<File>>;
  postId?: Maybe<Scalars['ID']>;
  published?: Maybe<Scalars['DateTime']>;
  shortText?: Maybe<Scalars['String']>;
  status: BlogPostStatus;
  tags?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  user?: Maybe<UserShort>;
};

export type BlogPostListResult = {
  __typename?: 'BlogPostListResult';
  count?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<BlogPost>>;
};

export enum BlogPostStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export type Company = {
  __typename?: 'Company';
  bankAccount?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankSwiftBic?: Maybe<Scalars['String']>;
  banks?: Maybe<Array<Maybe<Banks>>>;
  companyAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  wallets?: Maybe<Wallets>;
};

export type CurrencyWithRate = {
  __typename?: 'CurrencyWithRate';
  depositRate: Scalars['Float'];
  id: Scalars['String'];
  minAmount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  precision?: Maybe<Scalars['Float']>;
  validateAs: Scalars['String'];
  withdrawRate: Scalars['Float'];
};

export type EPayment = {
  __typename?: 'EPayment';
  ePaymentId?: Maybe<Scalars['ID']>;
  ePaymentType: Scalars['Int'];
  merchantId?: Maybe<Scalars['String']>;
  payeeAccount?: Maybe<Scalars['String']>;
  paymentInfo?: Maybe<Scalars['String']>;
};

export enum EPaymentType {
  Advcash = 'Advcash',
  Epay = 'Epay',
  Mercuryo = 'Mercuryo',
  PayPal = 'PayPal',
  Payeer = 'Payeer'
}

export enum ExchangeNames {
  Binance = 'Binance',
  Bitstamp = 'Bitstamp',
  Kraken = 'Kraken'
}

export type ExchangeOrder = {
  __typename?: 'ExchangeOrder';
  created?: Maybe<Scalars['DateTime']>;
  exchange?: Maybe<Scalars['String']>;
  exchangeOrderDescription?: Maybe<Scalars['String']>;
  exchangeOrderDetails?: Maybe<Scalars['String']>;
  exchangeOrderId?: Maybe<Scalars['String']>;
  lastErrMessage?: Maybe<Scalars['String']>;
  market_min_amount?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['ID']>;
  params?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  side: Scalars['String'];
  state?: Maybe<OrderState>;
  status?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  type: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  volume: Scalars['Float'];
};

export type File = {
  __typename?: 'File';
  approved?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  doesFileExist: Scalars['Boolean'];
  encoding?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['ID']>;
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['Float']>;
  linkedId?: Maybe<Scalars['String']>;
  linkedObjectSerialNumber?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  originExtension: Scalars['String'];
  originFileName: Scalars['String'];
  originFileNameWithoutExtension: Scalars['String'];
  params?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
};

export enum FileReason {
  Avatar = 'AVATAR',
  Blog = 'BLOG',
  Deposit = 'DEPOSIT',
  Kyc = 'KYC',
  Statement = 'STATEMENT',
  Support = 'SUPPORT'
}

export type Foo = {
  __typename?: 'Foo';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export type KycFileType = {
  __typename?: 'KycFileType';
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  requiredBeneficiaryCount?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type KycFileValidationList = {
  fileId?: InputMaybe<Scalars['String']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  authToken?: Maybe<Scalars['String']>;
  authTokenAction?: Maybe<Scalars['String']>;
  authTokenActionParam?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  settings?: Maybe<Settings>;
  transactionParams?: Maybe<TransactionParams>;
  user?: Maybe<User>;
};

export type MintingResult = {
  __typename?: 'MintingResult';
  transaction?: Maybe<BankTransactionResult>;
};

export type MintingTransactionShortResult = {
  __typename?: 'MintingTransactionShortResult';
  codeLength?: Maybe<Scalars['Int']>;
  codeLifetime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  transaction: BankTransactionShort;
  trustees?: Maybe<Array<UserShort>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add user bank account */
  addBankAccount: UserBankAccountShort;
  /** Add blog */
  addBlog: Blog;
  /** Add blog post */
  addBlogPost: BlogPost;
  addFoo?: Maybe<Foo>;
  /** Add post comment */
  addPostComment: PostComment;
  /** Add support case */
  addSupportCase: SupportCase;
  advcashFail?: Maybe<Scalars['String']>;
  advcashStatus?: Maybe<Scalars['String']>;
  advcashSuccess?: Maybe<Scalars['String']>;
  /** Create burning OERO token transaction */
  burn?: Maybe<MintingTransactionShortResult>;
  /** Change blog post status */
  changeBlogPostStatus: BlogPost;
  changePassword: Scalars['Boolean'];
  /** Confirmburning OERO token transaction */
  confirmBurn?: Maybe<BankTransactionShortResult>;
  /** Confirm deposit with credit card transaction */
  confirmDepositCreditCard: BankTransactionShortResult;
  /** Confirm deposit in crypto transaction */
  confirmDepositCrypto: BankTransactionShortResult;
  /** Confirm deposit with e-payment transaction */
  confirmDepositEPayment: BankTransactionShortResult;
  /** Confirm deposit in fiat transaction */
  confirmDepositFiat: BankTransactionShortResult;
  confirmDevice: Scalars['Boolean'];
  confirmEmail: Scalars['Boolean'];
  /** Confirm emitting OERO token transaction */
  confirmEmit?: Maybe<BankTransactionShortResult>;
  confirmName: LoginResult;
  /** Confrim withdrawal in crypto transaction */
  confirmWithdrawCrypto: BankTransactionShortResult;
  /** Confirm withdrawal with e-payment transaction */
  confirmWithdrawEPayment: BankTransactionShortResult;
  /** Confirm withdrawal in fiat transaction */
  confirmWithdrawFiat: BankTransactionShortResult;
  /** Create admin transaction statement */
  createAdminTransactionStatement: Scalars['Boolean'];
  /** Create API key for current user */
  createMyApiKey?: Maybe<ApiKeySecret>;
  /** Create API key for defined user */
  createUserApiKey?: Maybe<ApiKeySecret>;
  /** Create user transaction statement */
  createUserTransactionStatement: Scalars['Boolean'];
  /** Delete user bank account */
  deleteBankAccount: UserBankAccountShort;
  /** Delete transaction */
  deleteBankTransaction: BankTransaction;
  deleteBlog: Blog;
  /** Delete blog post */
  deleteBlogPost: BlogPost;
  /** Delete file */
  deleteFile: Scalars['String'];
  /** Delete API key for current user */
  deleteMyApiKey?: Maybe<Scalars['String']>;
  /** Delete post comment */
  deletePostComment: PostComment;
  /** Delete user */
  deleteUser?: Maybe<Scalars['String']>;
  /** Delete API key by ID */
  deleteUserApiKey?: Maybe<Scalars['String']>;
  /** Create deposit with credit card transaction */
  depositCreditCard: BankTransactionShortResult;
  /** Create deposit in crypto transaction */
  depositCrypto: BankTransactionShortResult;
  /** Create deposit with e-payment transaction */
  depositEPayment: BankTransactionShortResult;
  /** Create deposit in fiat transaction */
  depositFiat: BankTransactionShortResult;
  disable2fa: LoginResult;
  /** Create emitting OERO token transaction */
  emit?: Maybe<MintingTransactionShortResult>;
  enable2fa: LoginResult;
  forgotPassword: Scalars['Boolean'];
  generate2faCode: TwoFactorAuthenticationResult;
  get2faQRCode: Scalars['String'];
  initLocalCache: Scalars['Boolean'];
  login: LoginResult;
  logout: Scalars['Boolean'];
  payeerFail?: Maybe<Scalars['String']>;
  payeerStatus?: Maybe<Scalars['String']>;
  payeerSuccess?: Maybe<Scalars['String']>;
  processInitLocalCache: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  sendEmailCodePasswordChange: Scalars['Boolean'];
  sendEmailVerification: Scalars['Boolean'];
  setPassword: Scalars['Boolean'];
  signup: LoginResult;
  /** Create transfer transaction */
  transfer: BankTransactionShort;
  updateAppState: AppState;
  /** Update user bank account */
  updateBankAccount: UserBankAccountShort;
  /** Update transaction handling mode */
  updateBankTransactionHandlingMode: BankTransactionResult;
  /** Update transaction amount */
  updateBankTransactionRealAmount: BankTransactionResult;
  /** Update transaction status */
  updateBankTransactionStatus: BankTransactionResult;
  /** Update blog */
  updateBlog: Blog;
  /** Update blog post */
  updateBlogPost: BlogPost;
  updateFoo?: Maybe<Foo>;
  /** Update current user */
  updateMe?: Maybe<User>;
  /** Update user KYC */
  updateMyKyc?: Maybe<UserKycResult>;
  /** Update post comment */
  updatePostComment: PostComment;
  /** Update user */
  updateUser?: Maybe<User>;
  /** Update user KYC status */
  updateUserKycStatus?: Maybe<UserKycResult>;
  /** Upload file */
  uploadFile: File;
  /** Upload many files */
  uploadFiles: Array<File>;
  /** Update current user KYC */
  uploadMyKyc?: Maybe<UserKycResult>;
  /** Validate user bank account */
  validateBankAccount: UserBankAccount;
  verify2faCode: LoginResult;
  /** Create withdrawal in crypto transaction */
  withdrawCrypto: BankTransactionShortResult;
  /** Create withdrawal with e-payment transaction */
  withdrawEPayment: BankTransactionShortResult;
  /** Create withdrawal in fiat transaction */
  withdrawFiat: BankTransactionShortResult;
};


export type MutationAddBankAccountArgs = {
  accountAlias: Scalars['String'];
  address: Scalars['String'];
  bankName: Scalars['String'];
  bic: Scalars['String'];
  iban: Scalars['String'];
  ownerName: Scalars['String'];
};


export type MutationAddBlogArgs = {
  name: Scalars['String'];
};


export type MutationAddBlogPostArgs = {
  blogId?: InputMaybe<Scalars['String']>;
  commentAllowed: Scalars['Boolean'];
  files?: InputMaybe<Array<UploadParams>>;
  published?: InputMaybe<Scalars['DateTime']>;
  shortText: Scalars['String'];
  status?: InputMaybe<BlogPostStatus>;
  tags?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
};


export type MutationAddFooArgs = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationAddPostCommentArgs = {
  postId: Scalars['String'];
  text: Scalars['String'];
};


export type MutationAddSupportCaseArgs = {
  attachments?: InputMaybe<Array<UploadParams>>;
  text: Scalars['String'];
  type: Scalars['String'];
};


export type MutationBurnArgs = {
  amount?: InputMaybe<Scalars['Float']>;
};


export type MutationChangeBlogPostStatusArgs = {
  blogId?: InputMaybe<Scalars['String']>;
  postId: Scalars['ID'];
  status: BlogPostStatus;
};


export type MutationChangePasswordArgs = {
  code2fa?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationConfirmBurnArgs = {
  code2fa?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  trustees?: InputMaybe<Array<Trustee>>;
};


export type MutationConfirmDepositCreditCardArgs = {
  cardholder?: InputMaybe<Scalars['String']>;
  cvv?: InputMaybe<Scalars['Int']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmDepositCryptoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmDepositEPaymentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmDepositFiatArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmDeviceArgs = {
  recaptcha: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmEmailArgs = {
  recaptcha: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmEmitArgs = {
  code2fa?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  trustees?: InputMaybe<Array<Trustee>>;
};


export type MutationConfirmNameArgs = {
  name: Scalars['String'];
  recaptcha: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmWithdrawCryptoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmWithdrawEPaymentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmWithdrawFiatArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAdminTransactionStatementArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateUserApiKeyArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateUserTransactionStatementArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};


export type MutationDeleteBankAccountArgs = {
  userBankAccountId: Scalars['ID'];
};


export type MutationDeleteBankTransactionArgs = {
  bankTransactionId: Scalars['String'];
};


export type MutationDeleteBlogArgs = {
  blogId: Scalars['String'];
};


export type MutationDeleteBlogPostArgs = {
  blogId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['String'];
};


export type MutationDeleteMyApiKeyArgs = {
  apiKeyId?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePostCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteUserApiKeyArgs = {
  apiKeyId?: InputMaybe<Scalars['String']>;
};


export type MutationDepositCreditCardArgs = {
  comment?: InputMaybe<Scalars['String']>;
  immutableAmount: Scalars['Int'];
  incomeAmount: Scalars['Float'];
  incomeCurrency: Scalars['String'];
  oeroAmount: Scalars['Float'];
  paymentType?: InputMaybe<EPaymentType>;
};


export type MutationDepositCryptoArgs = {
  comment?: InputMaybe<Scalars['String']>;
  cryptoAmount: Scalars['Float'];
  cryptoCurrency: Scalars['String'];
  immutableAmount: Scalars['Int'];
  oeroAmount: Scalars['Float'];
};


export type MutationDepositEPaymentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  immutableAmount: Scalars['Int'];
  incomeAmount: Scalars['Float'];
  incomeCurrency: Scalars['String'];
  oeroAmount: Scalars['Float'];
  paymentType?: InputMaybe<EPaymentType>;
};


export type MutationDepositFiatArgs = {
  bankOptionId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  fiatAmount: Scalars['Float'];
  fiatCurrency: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  immutableAmount: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  oeroAmount: Scalars['Float'];
};


export type MutationDisable2faArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};


export type MutationEmitArgs = {
  amount?: InputMaybe<Scalars['Float']>;
};


export type MutationEnable2faArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  recaptcha: Scalars['String'];
};


export type MutationGet2faQrCodeArgs = {
  data: Scalars['String'];
};


export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']>;
  oAuthProvider?: InputMaybe<OAuthProvider>;
  oAuthToken?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  recaptcha: Scalars['String'];
};


export type MutationSendEmailVerificationArgs = {
  email: Scalars['String'];
};


export type MutationSetPasswordArgs = {
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSignupArgs = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oAuthProvider?: InputMaybe<OAuthProvider>;
  oAuthToken?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  recaptcha: Scalars['String'];
  termsOfUse?: InputMaybe<Scalars['Boolean']>;
};


export type MutationTransferArgs = {
  address: Scalars['String'];
  amount: Scalars['Float'];
  comment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAppStateArgs = {
  currentMarketId?: InputMaybe<Scalars['String']>;
  isFullScreen?: InputMaybe<Scalars['Boolean']>;
  isProMode?: InputMaybe<Scalars['Boolean']>;
  isRealTrading?: InputMaybe<Scalars['Boolean']>;
  isSideBarCollapsed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBankAccountArgs = {
  accountAlias: Scalars['String'];
  address: Scalars['String'];
  bankName: Scalars['String'];
  bic: Scalars['String'];
  iban: Scalars['String'];
  ownerName: Scalars['String'];
  userBankAccountId: Scalars['ID'];
};


export type MutationUpdateBankTransactionHandlingModeArgs = {
  autoTracking?: InputMaybe<Scalars['Boolean']>;
  bankTransactionId: Scalars['String'];
  manualHandling?: InputMaybe<Scalars['Boolean']>;
  payedFromUser?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBankTransactionRealAmountArgs = {
  bankTransactionId: Scalars['String'];
  realAmount?: InputMaybe<Scalars['Float']>;
  realOeroExchangeRate?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateBankTransactionStatusArgs = {
  autoTracking?: InputMaybe<Scalars['Boolean']>;
  bankTransactionId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  feeAmount?: InputMaybe<Scalars['Float']>;
  manualHandling?: InputMaybe<Scalars['Boolean']>;
  payedFromUser?: InputMaybe<Scalars['Boolean']>;
  realAmount?: InputMaybe<Scalars['Float']>;
  realOeroExchangeRate?: InputMaybe<Scalars['Float']>;
  status: Scalars['String'];
};


export type MutationUpdateBlogArgs = {
  blogId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateBlogPostArgs = {
  blogId?: InputMaybe<Scalars['String']>;
  commentAllowed?: InputMaybe<Scalars['Boolean']>;
  files?: InputMaybe<Array<UploadParams>>;
  postId: Scalars['ID'];
  published?: InputMaybe<Scalars['DateTime']>;
  shortText?: InputMaybe<Scalars['String']>;
  status: BlogPostStatus;
  tags?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationUpdateFooArgs = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMeArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  blockchain?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  termsOfUse?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateMyKycArgs = {
  beneficiaryCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<Scalars['String']>>;
  kycId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newFiles?: InputMaybe<Array<UploadParams>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePostCommentArgs = {
  text?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  blockchain?: InputMaybe<Scalars['String']>;
  changePasswordRequired?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  merchantApiKey?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  termsOfUse?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};


export type MutationUpdateUserKycStatusArgs = {
  comment?: InputMaybe<Scalars['String']>;
  fileApprovals?: InputMaybe<Array<KycFileValidationList>>;
  kycId: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUploadFileArgs = {
  uploadParams: UploadParams;
};


export type MutationUploadFilesArgs = {
  uploadParams: Array<UploadParams>;
};


export type MutationUploadMyKycArgs = {
  beneficiaryCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<UploadParams>>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};


export type MutationValidateBankAccountArgs = {
  userBankAccountId: Scalars['ID'];
};


export type MutationVerify2faCodeArgs = {
  code: Scalars['String'];
  token: Scalars['String'];
};


export type MutationWithdrawCryptoArgs = {
  comment?: InputMaybe<Scalars['String']>;
  cryptoAddress: Scalars['String'];
  cryptoAmount: Scalars['Float'];
  cryptoCurrency: Scalars['String'];
  immutableAmount: Scalars['Int'];
  oeroAmount: Scalars['Float'];
};


export type MutationWithdrawEPaymentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  immutableAmount: Scalars['Int'];
  incomeAmount: Scalars['Float'];
  incomeCurrency: Scalars['String'];
  oeroAmount: Scalars['Float'];
  paymentCredentials: Scalars['String'];
  paymentType?: InputMaybe<EPaymentType>;
};


export type MutationWithdrawFiatArgs = {
  bankAccountId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  fiatAmount: Scalars['Float'];
  fiatCurrency: Scalars['String'];
  immutableAmount: Scalars['Int'];
  oeroAmount: Scalars['Float'];
  transactionType: Scalars['String'];
};

export enum OAuthProvider {
  Facebook = 'facebook',
  Google = 'google',
  Microsoft = 'microsoft',
  Twitter = 'twitter'
}

export enum OrderState {
  Created = 'created',
  Error = 'error',
  Executed = 'executed',
  Published = 'published'
}

export type PostComment = {
  __typename?: 'PostComment';
  commentId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  postId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  user: UserShort;
};

export type Query = {
  __typename?: 'Query';
  appState: AppState;
  /** Get core version */
  coreVersion: Scalars['String'];
  /** Get API keys */
  getApiKeys?: Maybe<Array<ApiKey>>;
  /** Get all transactions */
  getBankTransactions: BankTransactionListResult;
  /** Get blog posts */
  getBlogPosts: BlogPostListResult;
  /** Test query */
  getFoos?: Maybe<Array<Foo>>;
  /** Get KYC */
  getKyc: UserKycListResult;
  getSettings: SettingsResult;
  /** Get user statements */
  getStatements: StatementListResult;
  /** Get support cases */
  getSupportCases: SupportCaseListResult;
  /** Get support types */
  getSupportTypes: Array<Scalars['String']>;
  /** Get supported currencies */
  getSupportedCurrencies: SupportedCurrencies;
  /** Get supported currencies with rates */
  getSupportedCurrenciesWithRates: SupportedCurrenciesWithRates;
  /** Get user bank accounts */
  getUserBankAccounts: Array<UserBankAccount>;
  /** Get user transactions */
  getUserBankTransactions: BankTransactionListResult;
  /** Get current user state */
  me: User;
  /** Get API keys of current user */
  myApiKeys?: Maybe<Array<ApiKey>>;
  /** Get bank account of current user */
  myBankAccounts: Array<UserBankAccountShort>;
  /** Get transactions of current user */
  myBankTransactions: BankTransactionShortListResult;
  /** Get KYC of current user */
  myKyc: UserKycResult;
  serverTime: Scalars['String'];
  /** Get user state */
  user?: Maybe<User>;
  /** Get user by email */
  userByEmail: User;
  /** Get user by id */
  userById: User;
  /** Get user by name */
  userByName: User;
  /** Get user by OAuth app id */
  userByOAuthAppId: User;
  /** Get user by referral code */
  userByReferralCode: User;
  /** Get user count */
  userCount?: Maybe<Scalars['Int']>;
  /** Get all users */
  users: UserListResult;
};


export type QueryGetApiKeysArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBankTransactionsArgs = {
  blockchain?: InputMaybe<Scalars['String']>;
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBlogPostsArgs = {
  blogId?: InputMaybe<Scalars['String']>;
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetFoosArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetKycArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  notValidatedKycOnly?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetStatementsArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSupportCasesArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUserBankAccountsArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};


export type QueryGetUserBankTransactionsArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};


export type QueryMyApiKeysArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMyBankAccountsArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMyBankTransactionsArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMyKycArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  filter: Scalars['String'];
};


export type QueryUserByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryUserByIdArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryUserByNameArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryUserByOAuthAppIdArgs = {
  oAuthAppId?: InputMaybe<Scalars['String']>;
  oAuthProvider?: InputMaybe<OAuthProvider>;
};


export type QueryUserByReferralCodeArgs = {
  referralCode?: InputMaybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type RateInput = {
  amount: Scalars['Float'];
  convertedCurrency: Scalars['String'];
  currency: Scalars['String'];
};

export type RateOutput = {
  __typename?: 'RateOutput';
  amount: Scalars['Float'];
  convertedAmount: Scalars['Float'];
  convertedCurrency: Scalars['String'];
  currency: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  companyKycFileDownloadUrl?: Maybe<Scalars['String']>;
  companyKycFileDownloadUrlEn?: Maybe<Scalars['String']>;
  companyKycFileDownloadUrlRu?: Maybe<Scalars['String']>;
  kycFileTypes: Array<KycFileType>;
  maxFileSize?: Maybe<Scalars['Int']>;
  maxFiles?: Maybe<Scalars['Int']>;
  restrictMinAmounts?: Maybe<Scalars['Boolean']>;
};

export type SettingsResult = {
  __typename?: 'SettingsResult';
  company?: Maybe<Company>;
  settings?: Maybe<Settings>;
  transactionParams?: Maybe<TransactionParams>;
};

export type StatementListResult = {
  __typename?: 'StatementListResult';
  count?: Maybe<Scalars['Int']>;
  statements?: Maybe<Array<File>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  transactionStatusChanged: Scalars['ID'];
};

export type SupportCase = {
  __typename?: 'SupportCase';
  attachments?: Maybe<Array<File>>;
  created?: Maybe<Scalars['DateTime']>;
  createds?: Maybe<Scalars['DateTime']>;
  supportCaseId: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['String'];
  user?: Maybe<User>;
};

export type SupportCaseListResult = {
  __typename?: 'SupportCaseListResult';
  cases?: Maybe<Array<SupportCase>>;
  count?: Maybe<Scalars['Int']>;
};

export type SupportedCurrencies = {
  __typename?: 'SupportedCurrencies';
  crypto: Array<Scalars['String']>;
  fiat: Array<Scalars['String']>;
};

export type SupportedCurrenciesWithRates = {
  __typename?: 'SupportedCurrenciesWithRates';
  advcash: Array<CurrencyWithRate>;
  crypto: Array<CurrencyWithRate>;
  epay: Array<CurrencyWithRate>;
  fiat: Array<CurrencyWithRate>;
  payeer: Array<CurrencyWithRate>;
};

export enum TokenAction {
  ApiKey = 'ApiKey',
  ConfirmDevice = 'ConfirmDevice',
  ConfirmEmail = 'ConfirmEmail',
  ConfirmName = 'ConfirmName',
  ConfirmPasswordChange = 'ConfirmPasswordChange',
  Default = 'Default',
  RefreshToken = 'RefreshToken',
  TwoFactorAuth = 'TwoFactorAuth'
}

export type TransactionParams = {
  __typename?: 'TransactionParams';
  averageTransactionFee?: Maybe<Scalars['Float']>;
};

export type Trustee = {
  code?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
};

export type TwoFactorAuthenticationResult = {
  __typename?: 'TwoFactorAuthenticationResult';
  code: Scalars['String'];
  otpauthUrl: Scalars['String'];
  qr: Scalars['String'];
};

export type UploadParams = {
  encoding?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  linkedId?: InputMaybe<Scalars['String']>;
  linkedObjectSerialNumber?: InputMaybe<Scalars['Int']>;
  mimeType?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  params?: InputMaybe<Scalars['String']>;
  reason: FileReason;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  upload: Scalars['Upload'];
};

export type User = {
  __typename?: 'User';
  accessFailedCount?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  beneficiaryCount?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  blockchain?: Maybe<Scalars['String']>;
  changePasswordRequired?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deletedDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  ethAddress?: Maybe<Scalars['String']>;
  ethPrivateKey?: Maybe<Scalars['String']>;
  files?: Maybe<Array<File>>;
  firstName?: Maybe<Scalars['String']>;
  hasEmailAuth?: Maybe<Scalars['Boolean']>;
  is2faEnabled?: Maybe<Scalars['Boolean']>;
  kycComment?: Maybe<Scalars['String']>;
  kycName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  login?: Maybe<Array<UserLogin>>;
  merchantApiKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameConfirmed?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<UserState>;
  termsOfUse?: Maybe<Scalars['Boolean']>;
  trxAddress?: Maybe<Scalars['String']>;
  trxPrivateKey?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  validatedByUser?: Maybe<Scalars['DateTime']>;
  validationDate?: Maybe<Scalars['DateTime']>;
};


export type UserStateArgs = {
  notificationDesc?: InputMaybe<Scalars['Boolean']>;
  notificationFirst?: InputMaybe<Scalars['Int']>;
  notificationOrderBy?: InputMaybe<Scalars['String']>;
  notificationSkip?: InputMaybe<Scalars['Int']>;
  notificationUnreadOnly?: InputMaybe<Scalars['Boolean']>;
};

export type UserBankAccount = {
  __typename?: 'UserBankAccount';
  accountAlias?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  userBankAccountId?: Maybe<Scalars['ID']>;
  valid?: Maybe<Scalars['Boolean']>;
  validatedByUser?: Maybe<Scalars['String']>;
  validationDate?: Maybe<Scalars['DateTime']>;
};

export type UserBankAccountShort = {
  __typename?: 'UserBankAccountShort';
  accountAlias?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  userBankAccountId?: Maybe<Scalars['ID']>;
};

export type UserKyc = {
  __typename?: 'UserKyc';
  beneficiaryCount?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  files?: Maybe<Array<File>>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  userType: Scalars['String'];
};

export type UserKycListResult = {
  __typename?: 'UserKycListResult';
  count?: Maybe<Scalars['Int']>;
  kyc?: Maybe<Array<UserKycResult>>;
};

export type UserKycResult = {
  __typename?: 'UserKycResult';
  beneficiaryCount?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  files?: Maybe<Array<File>>;
  kycCreated?: Maybe<Scalars['DateTime']>;
  kycId?: Maybe<Scalars['ID']>;
  kycName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userType?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  validatedByUser?: Maybe<Scalars['String']>;
  validationDate?: Maybe<Scalars['DateTime']>;
};

export type UserListResult = {
  __typename?: 'UserListResult';
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<User>>;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  area?: Maybe<Scalars['Int']>;
  browser?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  result: Scalars['Int'];
};

export type UserNotification = {
  __typename?: 'UserNotification';
  date?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
};

export type UserShort = {
  __typename?: 'UserShort';
  avatar?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  merchantApiKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  validationDate?: Maybe<Scalars['DateTime']>;
};

export type UserState = {
  __typename?: 'UserState';
  contractBalance?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
  ethBalance?: Maybe<Scalars['Float']>;
  euroBalance?: Maybe<Scalars['Float']>;
  notifications?: Maybe<Array<UserNotification>>;
  trxBalance?: Maybe<Scalars['Float']>;
};


export type UserStateNotificationsArgs = {
  desc?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Wallets = {
  __typename?: 'Wallets';
  BCH?: Maybe<Scalars['String']>;
  BTC?: Maybe<Scalars['String']>;
  ETH?: Maybe<Scalars['String']>;
  LTC?: Maybe<Scalars['String']>;
  OERO?: Maybe<Scalars['String']>;
  TEG?: Maybe<Scalars['String']>;
  USDT?: Maybe<Scalars['String']>;
  XRP?: Maybe<Scalars['String']>;
};
