import React from "react";
import styled from "styled-components";
import { HomeContext } from "../HomeProvider";
import { navigate } from "@reach/router";
import { Button } from "@material-ui/core";
import { MainMenu } from "./MainMenu";
import CookiesPrompt from "../../../core/components/CookiesPrompt";
import { GetCryptoRates } from "services/CryptoRates";
import Marquee from "../../../core/components/Marquee";
import { withTranslation } from 'react-i18next';
import {t} from "i18next";

const DivHeadBG = styled.div`
  background: url(/img/main-background.png) no-repeat center center;
  background-size: cover;
`;

const MovingLine = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  white-space: nowrap;
  color: white;
  position: absolute;
  left: ${window.innerWidth > 1100
    ? `calc(${window.innerWidth / 2} - 550)`
    : "0"};
  top: 80px;
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  margin-left: 3rem;
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
`;

class Main extends React.Component {
  static contextType = HomeContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
      isCookiesPromptOpen: false,
      cryptoRates: [],
    };

    this.handleCloseCookiePrompt = this.handleCloseCookiePrompt.bind(this);
  }

  // handleChangeLanguageRus () {
  //   this.props.i18n.changeLanguage('ru-RUS');
  // }

  handleCloseCookiePrompt() {
    this.setState({
      isCookiesPromptOpen: false,
    });
    localStorage.setItem("cookie-prompt-status", "shown");
  }

  async componentDidMount() {
    const cookiePromptStatus = localStorage.getItem("cookie-prompt-status");
    if (cookiePromptStatus !== "shown") {
      setTimeout(() => {
        this.setState({
          isCookiesPromptOpen: true,
        });
      }, 1000);
    }
    try {
      const cryptoRates = await GetCryptoRates(false);

      //console.log(cryptoRates);
      this.setState({
        cryptoRates: cryptoRates,
      });
    } catch (e) {
      console.warn("Couldn't load rates", e);
    }
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  render() {
    return (
      <>
        <header>
          <MainMenu />
          <CookiesPrompt
            isOpen={this.state.isCookiesPromptOpen}
            handleClose={this.handleCloseCookiePrompt}
          />
          <DivHeadBG className="view full-page-intro">
            <div className="mask d-flex justify-content-center align-items-center">
              <div className="container">
                {this.state.cryptoRates.length > 0 ? (
                  <MovingLine>
                    <Marquee direction="left" delay={0} speed={0.025}>
                      {this.state.cryptoRates.map((val, index) => {
                        return (
                          <span
                            key={`main_crypto_${index}`}
                            style={{ margin: "0 20px" }}
                          >
                            {val.name}/USD: {val.currentPriceUSD}{" "}
                            {
                              <span
                                style={{
                                  color: val.dayChange >= 0 ? "green" : "red",
                                  margin: "0 20px 0 10px",
                                }}
                              >
                                {val.dayChange}%
                              </span>
                            }{" "}
                            {val.name}/OEUR: {val.currentPriceEUR}{" "}
                            <span
                              style={{
                                color: val.dayChangeEUR >= 0 ? "green" : "red",
                                marginLeft: "10px",
                              }}
                            >
                              {val.dayChangeEUR}%
                            </span>
                          </span>
                        );
                      })}
                    </Marquee>
                  </MovingLine>
                ) : (
                  ""
                )}
                <div
                  className="row wow fadeIn mt-5"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  <div className="col-lg-5 mb-4 mt-5 white-text text-center text-lg-left">
                    <h1 className="main-header-f mb-5 mt-5">
                      <span className="mt-5 text-uppercase">{t('main.header.main_content_header_oero')}</span> <span className="text-uppercase">{t('main.header.main_content_header_fiat_euro')}</span> <br />
                      <span className="text-uppercase">{t('main.header.main_content_header_on_the_blockchain')}</span>
                    </h1>
                    <p onClick={this.handleChangeLanguageRus} className="second-header d-md-block main-title-f">
                      {t('main.header.main_content_header_description')}
                    </p>
                    <p className="second-header">{t('main.header.main_content_header_description_for_everyone')}</p>
                    <p></p>
                    <a
                      className="btn_flare btn waves-effect waves-light pr-5 pl-5 main-block-btn d-none d-md-inline-block a-btn"
                      // style={{backgroundColor: '#0a98c2', borderRadius: '.375rem'}}
                      href="/home/transparent"
                    >
                      {t('main.header.main_content_header_btn')}
                    </a>
                  </div>
                  <div className="col-lg-7 mt-md-5 mb-4">
                    <img
                      src="/img/main-img.png"
                      className="img-fluid home-first-img mx-auto"
                      alt=""
                    />
                  </div>

                  <a
                    className="btn waves-effect waves-light pr-5 pl-5 main-block-btn d-md-none mx-auto a-btn"
                    // style={{backgroundColor: '#0a98c2', borderRadius: '.375rem'}}
                    href="/home/transparent"
                  >
                    LEARN MORE
                  </a>
                </div>
              </div>
            </div>
          </DivHeadBG>
        </header>

        {/*Main Layout*/}
        <main className="text-center py-5 mt-3">
          <div className="container">
            <div className="row mt-5 mb-3">
              <div className="col-md-12">
                <h2 className="visElem h2 text-center mb-3 sub-main slideInUp">
                  {t('main.content.title')}
                </h2>
                <p className="visElem text-center mb-5 sub-text slideInUp"></p>
              </div>
            </div>
            <div className="row pb-5">
              <div className="visElem col-lg-4 col-sm-6 mb-4 text-left slideInUp">
                <div className="card-body">
                  <img
                    className="mb-4 home-page-icon"
                    src="/img/icons/LOCK.png"
                    alt=""
                  />
                  <h2 className="card-title mb-4 sub-main">{t('main.content.stable_currency')}</h2>
                  <p className="card-text sub-text">
                    {t('main.content.stable_currency_description')}
                  </p>
                </div>
              </div>
              <div className="visElem col-lg-4 col-sm-6 mb-4 text-left slideInUp">
                <div className="card-body">
                  <img
                    className="mb-4 home-page-icon"
                    src="/img/icons/BANKNOTE.png"
                    alt=""
                  />
                  <h2 className="card-title mb-4 sub-main">{t('main.content.100%_support')}</h2>
                  <p className="card-text sub-text">
                    {t('main.content.100%_support_description')}
                  </p>
                </div>
              </div>
              <div className="visElem col-lg-4 col-sm-6 mb-4 text-left slideInUp">
                <div className="card-body">
                  <img
                    className="mb-4 home-page-icon"
                    src="/img/icons/SEARCH.png"
                    alt=""
                  />
                  <h4 className="card-title mb-4 sub-main">{t('main.content.clarity')}</h4>
                  <p className="card-text sub-text">
                    {t('main.content.clarity_description_before_link')}{" "}
                    <a href="transparent">{t('main.content.clarity_description_link')}</a>
                    {t('main.content.clarity_description_after_link')}
                  </p>
                </div>
              </div>
              <div className="visElem col-lg-4 col-sm-6 mb-4 text-left slideInUp">
                <div className="card-body">
                  <img
                    className="mb-4 home-page-icon"
                    src="/img/icons/BLOCKCHAIN.png"
                    alt=""
                  />
                  <h4 className="card-title mb-4 sub-main">
                    {t('main.content.blockchain_technology')}
                  </h4>
                  <p className="card-text sub-text">
                    {t('main.content.blockchain_technology_description')}
                  </p>
                </div>
              </div>
              <div className="visElem col-lg-4 col-sm-6 mb-4 text-left slideInUp">
                <div className="card-body">
                  <img
                    className="mb-4 home-page-icon"
                    src="/img/icons/PEOPLE.png"
                    alt=""
                  />
                  <h4 className="card-title mb-4 sub-main">
                    {t('main.content.accessibility_for_everyone')}
                  </h4>
                  <p className="card-text sub-text">
                    {t('main.content.accessibility_for_everyone_description')}
                  </p>
                </div>
              </div>
              <div className="visElem col-lg-4 col-sm-6 mb-4 text-left slideInUp">
                <div className="card-body">
                  <img
                    className="mb-4 home-page-icon"
                    src="/img/icons/SECURE.png"
                    alt=""
                  />
                  <h4 className="card-title mb-4 sub-main">{t('main.content.safe')}</h4>
                  <p className="card-text sub-text">
                    {t('main.content.safe_description_before_link')}
                    <a
                      href="https://mtr.mkm.ee/taotluse_tulemus/541690?backurl=%40juriidiline_isik_show%3Fid%3D256426"
                      //eslint-disable-next-line
                      target="_blank"
                    >
                      {t('main.content.safe_description_link')}
                    </a>
                    {t('main.content.safe_description_after_link')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Access-to-reliable-currency" />
          <section
            id="testimonials"
            className="text-center py-5"
            style={{ backgroundColor: "#eef8fb" }}
          >
            <div className="container">
              <div
                className="row wow fadeIn mt-5"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div className="visElem col-md-6 mb-4 text-center text-md-left slideInUp">
                  <h2 className="h2 mb-3 sub-main">{t('main.content.use_cases')}</h2>
                  <br />
                  <br />
                  <p className="mb-4 d-none d-md-block sub-text">
                    {t('main.content.use_cases_top_description')}
                  </p>
                  <p className="mb-5 sub-text">
                    {t('main.content.use_cases_center_description')}
                  </p>
                  <p className="mb-4 d-none d-md-block sub-text">
                    {t('main.content.use_cases_bottom_description_before_link')}
                     <a href="use-cases">{t('main.content.use_cases_bottom_description_use_cases_link')}</a>
                    {t('main.content.use_cases_bottom_description_after_use_cases_link')}
                    <a href="/White_paper_oero.pdf" target="_blank">
                      {t('main.content.use_cases_bottom_description_white_paper_link')}
                    </a>
                    {t('main.content.use_cases_bottom_description_after_link')}
                  </p>

                  {/* <a
                    className="btn_flare btn waves-effect waves-light pr-5 pl-5 d-none d-md-inline-block  a-btn"
                    // style={{marginLeft: 0, backgroundColor: '#0a98c2', borderRadius: '.375rem'}}
                    href="/home/use-cases"
                  >
                    LEARN MORE
                  </a> */}
                </div>
                <div className="col-lg-4 col-md-6 mb-4 use-case-img ml-md-auto">
                  <img
                    src="/img/home-img.png"
                    className="img-fluid"
                    alt="Cryptocurrency exchanges"
                  />
                </div>
              </div>
              <a
                href="/home/use-cases"
                className="btn_flare btn waves-effect waves-light pr-5 pl-5 d-md-none a-btn"
                // style={{backgroundColor: '#0a98c2', borderRadius: '.375rem'}}
              >
                LEARN MORE
              </a>
              {/*<div className="row" style={{paddingTop: '80px', paddingBottom: '50px'}}>*/}
            </div>
          </section>
          <section
            className="text-center"
            style={{
              background: "url(/img/main-join.png) no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div
                className="pt-5 pb-5 row wow fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div className="col-lg-12 col-md-12 px-4 d-lg-flex d-block align-items-center text-center text-white justify-content-center">
                  <h2 className="sub-main visElem slideInUp">
                    {t('main.content.split')}
                  </h2>
                  {/*<a className="btn_flare btn waves-effect waves-light pr-5 pl-5 ml-lg-5"*/}
                  {/*   style={{backgroundColor: '#0a98c2', borderRadius: '.375rem'}}*/}
                  {/*   href="/Account/Login"*/}
                  {/*>*/}
                  {/*  authorization*/}
                  {/*</a>*/}
                  {/*ToDo: add transition to button */}
                  <StyledButton
                    style={{
                      width: "204px",
                      height: "46px",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/login").then()}
                  >
                    {t('main.content.split_btn')}
                  </StyledButton>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withTranslation()(Main)
