import React, {
    createRef,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import styled from "styled-components";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import {Dropdown} from "../../../components/common/Dropdown";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {AuthContext} from "../../../../../core/providers/AuthProvider";
import {
    FileReason,
    UserKycResult,
} from "../../../../../store/generated-models";
import {gql} from "@apollo/client";
import {useLazyQuery} from "@apollo/react-hooks";
import {
    CountrySelector,
    getCountryByCode,
} from "../../../../../core/ui/CountrySelector";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {RestApiContext} from "../../../../../core/providers/RestApiProvider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DocCard from "./DocCard";
import SimpleBar from "simplebar-react";
import OeroLoader from "../../../../../core/ui/OeroLoader";
import Typography from "@material-ui/core/Typography";
import {t} from "i18next";
import {log} from "util";
import {SourcesOfWealthSelector} from "../../../../../core/ui/SourcesOfWealthSelector";
import PEPType from "./PEPType";
import {DocumentNode} from "graphql";

// const Wrap = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	// height: 100%;
// 	height: auto;
//
// 	// overflow-y: auto;
//   /Wrap

const Wrap = styled(SimpleBar)`
  position: relative;
  height: 100%;

  .simplebar-track.simplebar-horizontal {
    display: none;
  }

  .simplebar-track.simplebar-vertical {
    right: -10px;
  }

  .simplebar-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`;

const StyledTextField = styled(TextField)`
  background-color: white;

  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
`;

const HiddenInput = styled.input`
  display: none;
`;

const CompanyDownloadLink = styled.a`
  padding: 12px;
  display: flex;
  height: 100%;
  max-height: 50px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  background: #0295c0;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }
`;

const CancelButton = styled(Button)`
  height: 100%;
  width: 135px;
  margin-right: 24px;
`;

const CommentBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #eeeeee;
  padding: 24px 0;
`;

const AlertText = styled(Typography)`
    // color: ${(props) => props.theme.custom.palette.alert};
  color: #cd1225;
  font-weight: 500;
`;

const CommentHeder = styled(Typography)`
    // color: ${(props) => props.theme.custom.palette.alert};
  color: #cd1225;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 18px;
`;

const gqlKyc = gql`
    query myKyc($id: String){
        myKyc (
            id: $id
        ){
            kycId
            userId
            userType
            name
            countryCode
            phoneNumber
            created
            valid
            validationDate
            validatedByUser
            files {
                approved
                fileId
                doesFileExist
                fileName
                originFileName
                originFileNameWithoutExtension
                originExtension
                type
                mimeType
                encoding
                fileSize
                linkedId
                order
                created
                url
            }
        }
    }
`;


const BENEFICIARY_COUNT: any[] = [
    {id: "1"},
    {id: "2"},
    {id: "3"},
    {id: "4"},
    {id: "5"},
    {id: "6"},
    {id: "7"},
    {id: "8"},
    {id: "9"},
    {id: "10"},
];

const initFields = {
    name: "",
    country: {label: ""},
    phone: "",
    email: "",
    sources: {label: "", checked: false},
    pep: 'Yes',
};

interface ComponentProps {
    isEdit: boolean;
    kycUserId?: string;
    onClose: (res: any) => void;
}

export const KycProcess: React.FC<ComponentProps> = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const authContext = useContext(AuthContext);
    const restContext = useContext(RestApiContext);

    const [fields, setFields] = useState<any>({...initFields});
    const [errors, setErrors] = useState<any>({});

    const [kycType, setKycType] = useState("");
    // const [PEP, setPEP] = useState("");
    const [files, setFiles] = useState<any[]>([]);
    const [fileNo, setFileNo] = useState(0);
    const [fileType, setFileType] = useState("");
    const [fileTypes, setFileTypes] = useState<any[]>([]);
    const [fileInputRefs, setFileInputRefs] = React.useState([]);
    const [beneficiaryCount, setBeneficiaryCount] = useState(1);
    const [beneficiaryCountVal, setBeneficiaryCountVal] = useState("1");
    const [uploadInProcess, setUploadInProcess] = useState(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [getMyKyc, {data, error}] =
        useLazyQuery<{ myKyc: UserKycResult }>(gqlKyc, {
            variables: {
                id: props.kycUserId
            }
        });

    useEffect(() => {
        setFileInputRefs([createRef()]);
        if (props.isEdit) getMyKyc();
        else setKycType("p");
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const SendKycData = async () => {
        const response = await fetch('http://localhost:3001/rest/api/change-kyc-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fields)
        });
        return await response.json();
    }

    const getFilteredFileTypes = () => {
        return authContext.settings.kycFileTypes
            .filter((el: any) => {
                if (props.isEdit) {
                    return (
                        (data.myKyc.userType === "p" && el.index < 10) ||
                        (data.myKyc.userType === "c" && el.index >= 10)
                    );
                } else {
                    return (
                        (kycType === "p" && el.index < 10) ||
                        (kycType === "c" && el.index >= 10)
                    );
                }
            })
            .map((el: any) => {
                let isDecline = false;
                if (props.isEdit) {
                    isDecline =
                        data.myKyc.files.findIndex(
                            (f: any) => f.type === el.id && f.approved === false
                        ) !== -1;
                }

                return {
                    id: el.id,
                    value: `${el.value} ${el.required ? "*" : ""}`,
                    required: el.required,
                    requiredBeneficiaryCount: el.requiredBeneficiaryCount,
                    name: el.value,
                    isDecline: isDecline,
                };
            });
    };

    useEffect(() => {
        if (!data) return;

        const filteredFileTypes = getFilteredFileTypes();
        setFileTypes(filteredFileTypes);

        setKycType(data.myKyc.userType);

        setFields({
            name: data.myKyc.name,
            country: data.myKyc.countryCode
                ? getCountryByCode(data.myKyc.countryCode)
                : {label: ""},
            phone: data.myKyc.phoneNumber,
        });

        if (data.myKyc.files) {
            // setFiles(data.myKyc.files);
            setFiles(
                data.myKyc.files.map((f: any, index: number) => {
                    return {
                        // no: index,
                        typeId: f.type,
                        type: filteredFileTypes.find((el: any) => el.id === f.type).value,
                        // type: f.type,
                        fileName: f.fileName,
                        fileId: f.fileId,
                        url: f.url,
                        status:
                            f.approved === true
                                ? "approved"
                                : f.approved === false
                                ? "declined"
                                : "",
                        name: f.fileName,
                        size: f.fileSize,
                        approved: f.approved,
                    };
                })
            );
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!authContext.settings) return;
        if (!kycType) return;
        setFileTypes(getFilteredFileTypes());
    }, [kycType, authContext.settings]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!error) return;
        console.warn("KycEdit gqlMyKyc error:", error);
    }, [error]);

    const activeFiles = useMemo(() => {
        return files.filter((file: any) => !file.deleted);
    }, [files]);

    const oldFiles = useMemo(() => {
        return files.filter((file: any) => !file.deleted && !!file.fileId);
    }, [files]);

    const newFiles = useMemo(() => {
        return files.filter((file: any) => !file.deleted && !file.fileId);
    }, [files]);

    const kycTypeChangeHandler = (newVal: string) => {
        setKycType(newVal);
        setFields({...initFields});
        setErrors({});
        setFiles([]);
        setFileNo(0);
        setFileType("");
        setFileInputRefs([createRef()]);
        setBeneficiaryCount(1);
        setBeneficiaryCountVal("1");
    };

    const beneficiaryCountChangeHandler = (newVal: string) => {
        setBeneficiaryCountVal(newVal);
        setBeneficiaryCount(parseInt(newVal));
    };

    const addFileHandler = (fType: string) => {
        setFileType(fType);
        fileInputRefs[fileNo].current.click();
    };

    // useEffect(() => {console.log('>> files:', files)}, [files]);

    const deleteItemHandler = async (fileObj: any) => {
        // console.log('>> deleteItemHandler', fileObj);
        setFiles((currFiles) =>
            currFiles.map((file: any) => {
                if (file.fileId && file.fileId === fileObj.fileId)
                    return {...file, deleted: true};
                if (file.no === fileObj.no && !file.fileId)
                    return {...file, deleted: true};
                return file;
            })
        );
    };

    const _handleChange = async (e: any, i: number) => {
        e.persist();

        try {
            const file = e.target.files[0];
            const fileName = file.name;
            const fileSize = file.size;
            console.log(files);
            console.log(file);
            setFileInputRefs((elRefs) =>
                Array(fileNo + 2)
                    .fill(null)
                    .map((_, i) => elRefs[i] || createRef())
            );

            setFiles((currFiles) => [
                ...currFiles,
                {
                    no: fileNo + 1,
                    typeId: fileType,
                    type: fileTypes.find((el: any) => el.id === fileType).value,
                    name: fileName,
                    size: fileSize,
                    file: file,
                },
            ]);
            setFileNo((currVal) => currVal + 1);
            setFileType("");
        } catch (ex) {
        }
    };

    const upload = () => {
        let formData = new FormData();

        if (props.isEdit) {
            formData.append("kycId", data.myKyc.kycId);
        }

        formData.append(
            "countryCode",
            fields.country && fields.country.code ? fields.country.code : ""
        );
        formData.append("name", fields.name);
        formData.append("phoneNumber", fields.phone);
        formData.append("userType", kycType);

        if (kycType === "c") {
            formData.append("beneficiaryCount", beneficiaryCount.toString());
        }

        formData.append("reason", FileReason.Kyc);

        if (props.isEdit) {
            newFiles.forEach((f: any) => {
                const fileObj: any = {type: f.typeId};
                formData.append("filesData", JSON.stringify(fileObj));
                formData.append("newFiles", f.file);
            });

            oldFiles.forEach((f: any) => {
                formData.append("files", f.fileId);
            });

            if (props.kycUserId) {
                formData.append("userId", props.kycUserId)
            }

            return restContext.upload("updateMyKyc", formData);
        } else {
            activeFiles.forEach((f: any) => {
                const fileObj: any = {type: f.typeId};
                formData.append("filesData", JSON.stringify(fileObj));
                formData.append("file", f.file);
            });

            if (props.kycUserId) {
                formData.append("userId", props.kycUserId)
            }

            return restContext.upload("uploadUserKyc", formData);
        }
    };

    const uploadClickHandler = async () => {
        fileTypes.forEach((el) => {
            el.isDecline = false;
        });
        let err: any = {};
        if (!fields.name) err.name = "required";
        if (!fields.phone) err.phone = "required";
        setErrors({...err});

        if (Object.keys(err).length !== 0) {
            enqueueSnackbar("Correct errors to continue", {variant: "error"});
            return;
        }

        if (!fields.country || !fields.country.code) {
            enqueueSnackbar("Please select Country", {variant: "error"});
            return;
        }

        // required files check
        const requiredFiles: any[] = fileTypes.filter((el) => el.required);
        if (requiredFiles.length) {
            for (let i = 0; i < requiredFiles.length; i++) {
                if (
                    files.findIndex(
                        (el) => !el.deleted && el.typeId === requiredFiles[i].id
                    ) === -1
                ) {
                    enqueueSnackbar(`File ${requiredFiles[i].name} is required`, {
                        variant: "error",
                    });
                    fileTypes[i].isDecline = true;
                    return;
                }
            }
        }

        // requiredBeneficiaryCount files check
        const requiredCountFiles: any[] = fileTypes.filter(
            (el) => el.requiredBeneficiaryCount
        );
        if (requiredCountFiles.length) {
            for (let i = 0; i < requiredCountFiles.length; i++) {
                const selFiles = files.filter(
                    (el) => !el.deleted && el.typeId === requiredCountFiles[i].id
                );
                if (selFiles.length < beneficiaryCount) {
                    enqueueSnackbar(
                        `File ${requiredCountFiles[i].name} is required for ${beneficiaryCount} beneficiary`,
                        {variant: "error"}
                    );
                    fileTypes[i].isDecline = true;
                    return;
                }
            }
        }

        let errMessage = "";
        setUploadInProcess(true);

        try {
            const uploadRes = await upload();
            if (uploadRes && uploadRes.data) {
                // !!ToDo
                props.onClose("");
            } else {
                props.onClose("");
            }
            setUploadInProcess(false);
            return;
        } catch (error: any) {
            try {
                const errorCode = error.graphQLErrors[0].extensions.code;
                if (errorCode === "auth.access_denied") {
                    errMessage = "Access denied";
                } else if (errorCode === "user.kyc_data_invalid") {
                    errMessage = "Invalid KYC data";
                }
                if (!errMessage && error.graphQLErrors[0].message)
                    errMessage = error.graphQLErrors[0].message;
            } catch (ignored) {
            }
        }

        setUploadInProcess(false);
        if (!errMessage) errMessage = "Unknown error";

        enqueueSnackbar(errMessage, {variant: "error"});
    };

    return (
        <Wrap>
            {uploadInProcess ? (
                <>
                    <OeroLoader style={{marginLeft: "calc(50% - 50px)"}}/>
                    <h3 style={{textAlign: "center"}}>Loading...</h3>
                </>
            ) : (
                <>
                    <Grid
                        container
                        spacing={2}
                        style={{marginBottom: "24px", maxWidth: "600px"}}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                                display: "flex",
                                justifyContent: isSmallScreen ? "space-between" : "flex-start",
                            }}
                        >
                            <RadioGroup
                                row
                                name="kyc_type"
                                defaultValue="p"
                                value={kycType}
                                onChange={(event) => kycTypeChangeHandler(event.target.value)}
                            >
                                <FormControlLabel
                                    value="p"
                                    label={t('user.settings.person')}
                                    control={<Radio color="primary"/>}
                                    disabled={props.isEdit}
                                />
                                <FormControlLabel
                                    value="c"
                                    label={t('user.settings.company')}
                                    control={<Radio color="primary"/>}
                                    disabled={props.isEdit}
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                                display: "flex",
                                justifyContent: isSmallScreen ? "space-between" : "flex-end",
                            }}
                        ></Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                error={!!errors.name}
                                helperText={errors.name}
                                variant="outlined"
                                fullWidth
                                label={
                                    kycType === "p"
                                        ? t('user.settings.full_name')
                                        : t('user.settings.company_name')
                                }
                                value={fields.name}
                                onChange={(e) => setFields({...fields, name: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                error={!!errors.phone}
                                helperText={errors.phone}
                                variant="outlined"
                                fullWidth
                                label={t('user.settings.phone')}
                                value={fields.phone}
                                onChange={(e) =>
                                    setFields({...fields, phone: e.target.value})
                                }
                            />
                        </Grid>
                        {
                            kycType && kycType === 'p' && !props.isEdit && (
                                <Grid item xs={12}>
                                    <StyledTextField
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        variant="outlined"
                                        fullWidth
                                        label='* Email'
                                        value={fields.email}
                                        onChange={(e) =>
                                            setFields({...fields, email: e.target.value})
                                        }
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <CountrySelector
                                style={{width: "100%", backgroundColor: "white"}}
                                error={!!errors.country}
                                helperText={errors.country}
                                value={fields.country}
                                onChange={(e, v) => {
                                    setFields({...fields, country: v});
                                }}
                            />
                        </Grid>
                        {
                            kycType && kycType === 'p' && (
                                <Grid item xs={12}>
                                    <SourcesOfWealthSelector
                                        style={{width: "100%", backgroundColor: "white"}}
                                        error={!!errors.country}
                                        helperText={errors.country}
                                        value={fields.sources}
                                        onChange={(e, v) => {
                                            setFields({...fields, sources: v});
                                        }}
                                    />
                                </Grid>
                            )
                        }
                        {
                            kycType && kycType === 'p' && (
                                <Grid item xs={12}>
                                    <p>Politically exposed person (PEP) *</p>

                                    <RadioGroup
                                        row
                                        name="pep_type"
                                        defaultValue="Yes"
                                        value={fields.pep}
                                        onChange={(e) =>
                                            setFields({...fields, pep: e.target.value})
                                        }
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            label='Yes'
                                            control={<Radio color="primary"/>}
                                        />
                                        <FormControlLabel
                                            value="No"
                                            label='No'
                                            control={<Radio color="primary"/>}
                                        />
                                    </RadioGroup>
                                    <button onClick={SendKycData}>Send</button>
                                </Grid>
                            )}
                        {
                            // DOWNLOAD PDF
                            kycType && kycType === "c" && (
                                <>
                                    <Grid item xs={12} md={8}>
                                        <CompanyDownloadLink
                                            href={authContext.settings.companyKycFileDownloadUrlEn}
                                            target="_blank"
                                        >
                                            {t('user.settings.download_legal')}
                                        </CompanyDownloadLink>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Dropdown
                                            label={t('user.settings.beneficiary')}
                                            values={BENEFICIARY_COUNT}
                                            value={beneficiaryCountVal}
                                            onChange={(val) => beneficiaryCountChangeHandler(val)}
                                            style={{width: "160px", backgroundColor: "white"}}
                                        />
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>

                    {props.isEdit && (
                        <CommentBlock>
                            <CommentHeder>Comment:</CommentHeder>
                            <AlertText>{authContext.user.kycComment}</AlertText>
                        </CommentBlock>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #EFEFEF",
                            maxWidth: "1000px",
                        }}
                    >
                        {fileTypes &&
                        fileTypes.map((el: any) => (
                            <DocCard
                                key={el.id}
                                docType={el.id}
                                docName={el.name}
                                docRequired={el.required}
                                files={files.filter(
                                    (f: any) => f.typeId === el.id && !f.deleted
                                )}
                                onAddFile={() => addFileHandler(el.id)}
                                onDelFile={(fileObj) => deleteItemHandler(fileObj)}
                                isEdit={props.isEdit}
                                isDecline={el.isDecline}
                            />
                        ))}
                    </div>
                    <Row
                        style={{
                            paddingTop: "30px",
                            borderTop: "1px solid #EEEEEE",
                            marginTop: "10px",
                            fontWeight: 500,
                        }}
                    >
                        (<span style={{color: "red", margin: "0 8px"}}>*</span>) {t('user.settings.please')}
                    </Row>
                </>
            )}

            <Row style={{marginTop: "40px"}}>
                <Button
                    variant="contained"
                    color="primary"
                    size={"large"}
                    style={{maxWidth: "250px", width: "100%", height: "53px"}}
                    disabled={!kycType || uploadInProcess}
                    onClick={uploadClickHandler}
                >
                    {t('user.settings.apply')}
                </Button>

                <CancelButton
                    style={{height: "53px", marginLeft: "20px"}}
                    variant="outlined"
                    size={"large"}
                    onClick={() => props.onClose(null)}
                >
                    {t('user.settings.cancel')}
                </CancelButton>
            </Row>

            {fileInputRefs.map((el, i) => (
                <HiddenInput
                    className={`tmp-input-${i}`}
                    key={i}
                    type="file"
                    ref={fileInputRefs[i]}
                    onChange={(e) => _handleChange(e, i)}
                />
            ))}
        </Wrap>
    );
};
