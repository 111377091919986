import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "@reach/router";
import styled from 'styled-components';

import {Grid as DataGrid, HeadCell} from '../../../../core/ui/grids/Grid';
import Grid from "@material-ui/core/Grid";

import {gql} from "@apollo/client";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
	ApiKeySecret,
	Mutation, MutationDeleteMyApiKeyArgs,
	Query
} from "../../../../store/generated-models";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {formatDateTime} from "../../helpers/Format";

const LoadWrap = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const PageWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const Row = styled.div`
  display: flex;
`;

const gqlMyApiKeys = gql`{
	myApiKeys {
		apiKeyId
		userId
		user
		created
	}
}`;

const gqlCreateMyApiKey = gql`
	mutation createMyApiKey {
		createMyApiKey {
			apiKeyId
			created
			secret
		}
	}
`;

const gqlDeleteMyApiKey = gql`
	mutation deleteMyApiKey (
		$apiKeyId: String!	
	) {
		deleteMyApiKey (
			apiKeyId: $apiKeyId
		)
	}
`;

export const ApiKeys: React.FC<RouteComponentProps> = () => {
	const { t } = useTranslation();

	const headCells: HeadCell[] = [
		{id: 'apiKeyId', label: t('user.settings.api_keys.id')},
		{id: 'created', label: t('user.settings.api_keys.created')}
	];

	const {enqueueSnackbar} = useSnackbar();

	const [mode, setMode] = useState('list');
	const [items, setItems] = useState<any[]>([]);
	const [newApiKey, setNewApiKey] = useState<ApiKeySecret>(null);

	const {data, loading, refetch} = useQuery<Pick<Query, 'myApiKeys'>>(gqlMyApiKeys);

	useEffect(() => {
		if (data && data.myApiKeys && Array.isArray(data.myApiKeys)) {
				setItems(
					data.myApiKeys.map(i => {
					return {
						...i,
						created: formatDateTime(i.created)
					};
				})
			);
		}
	}, [data]);

	const [createMyApiKey] = useMutation<Pick<Mutation, 'createMyApiKey'>>(gqlCreateMyApiKey);

	const [deleteMyApiKey] = useMutation<Pick<Mutation, 'deleteMyApiKey'>, MutationDeleteMyApiKeyArgs>(
		gqlDeleteMyApiKey
	);

	const addItemHandler = () => {
		setMode('new');
		createMyApiKey().then(result => {
			setNewApiKey(result.data.createMyApiKey);
			refetch().then();
		});
	};

	const deleteItemHandler = async (item: any) => {
		let errMessage = '';
		try {
			const res = await deleteMyApiKey({
												 variables: {
													 apiKeyId: item.apiKeyId
												 }
											 });
			if (res.data.deleteMyApiKey) {
				refetch().then(() => {
					enqueueSnackbar(t('user.settings.api_keys.deleted_message'), {variant: 'success'});
				});
				return;
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
				if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {
			}
		}

		if (!errMessage) errMessage = 'Unknown error';
		enqueueSnackbar(errMessage, {variant: 'error'});
	};

	// Render

	if ((!data && loading) || (mode === 'new' && !newApiKey?.secret)) {
		return (
			<LoadWrap>
				<CircularProgress/>
			</LoadWrap>
		);
	}

	if (mode === 'list')
	return (
		<PageWrap>
			<DataGrid
				headCells={headCells}
				items={items}
				onAddItem={addItemHandler}
				addLabel={t('user.settings.api_keys.add_btn')}
				onDeleteItem={deleteItemHandler}
				idField="apiKeyId"
				actions={[
					{id: 'del', onAction: deleteItemHandler},
				]}
			/>
		</PageWrap>
	);

	if (mode === 'new')
		return (
			<>
				<Grid container spacing={3} style={{ marginTop: "4px" }}>
					<Grid item xs={12} md={6}>
						<p>{t('user.settings.api_keys.new_message_1')}</p>
						<p>{t('user.settings.api_keys.new_message_2')}</p>
					</Grid>
					<Grid item xs={12} style={{ padding: 0 }} />
					<Grid item xs={12} md={6}>
						<strong>{newApiKey.secret}</strong>
					</Grid>
				</Grid>

				<Row style={{ marginTop: "40px" }}>
					<Button
						variant="contained"
						color="primary"
						size={"large"}
						style={{ minWidth: "240px", minHeight: "50px" }}
						onClick={() => {
							setMode('list');
							setNewApiKey(null);
						}}
					>
						{t('user.settings.api_keys.back_btn')}
					</Button>
				</Row>
			</>
		)

};
