import React from "react";
import {t} from "i18next";
import {withTranslation} from "react-i18next";
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";

const ExpansionPanel = styled(MuiExpansionPanel)`
  .Mui-expanded,
  .Mui-expanded p {
    color: ${props => props.theme.custom.palette.darkBlue};
    opacity: 0.87;
  }

  &:hover {
    z-index: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14);
  }
`;

const LegalContent = (props) => {

    const data = [
        {
            title: t('legal.terms_of_use_title'),
            content: (
                <React.Fragment>
                    {t('legal.terms_of_use')}
                    <a href="/">{t('legal.terms_of_use_link')}</a>
                    {t('legal.terms_of_use_after_link')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.changes_and_modifications_title'),
            content: (
                <React.Fragment>
                    {t('legal.changes_and_modifications')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.account_eligibility_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.account_eligibility_p_1')}
                    </p>
                    <p>{t('legal.account_eligibility_p_2')}</p>
                    <p>
                        {t('legal.account_eligibility_p_3')}
                    </p>
                    <p>
                        {t('legal.account_eligibility_p_4')}
                    </p>
                    <p>
                        {t('legal.account_eligibility_p_5')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.registration_and_use_account_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.registration_and_use_account_p_1')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_2')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_3')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_4')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_5')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_6')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_7')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_8')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_9')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_10')}
                        <a className="footer-link" href="mailto:support@oero.io">
                            {t('legal.registration_and_use_account_p_10_link')}
                        </a>
                        .
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_11')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_12')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_13')}
                    </p>
                    <p>
                        {t('legal.registration_and_use_account_p_14')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.our_services_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.our_services_p_1')}
                    </p>
                    <p>
                        {t('legal.our_services_p_2')}
                    </p>
                    <p>
                        {t('legal.our_services_p_3')}
                    </p>
                    <p>
                        {t('legal.our_services_p_4')}
                    </p>
                    <p>
                        {t('legal.our_services_p_5')}
                    </p>
                    <p>
                        {t('legal.our_services_p_6')}
                    </p>
                    <p>
                        {t('legal.our_services_p_7')}
                    </p>
                    <p>
                        {t('legal.our_services_p_8')}
                    </p>
                    <p>
                        {t('legal.our_services_p_9')}
                    </p>
                    <p>
                        {t('legal.our_services_p_10')}
                    </p>
                    <p>
                        {t('legal.our_services_p_11')}
                    </p>
                    <p>
                        {t('legal.our_services_p_12')}
                    </p>
                    <p>
                        {t('legal.our_services_p_13')}
                    </p>
                    <p>
                        {t('legal.our_services_p_14')}
                    </p>
                    <p>
                        {t('legal.our_services_p_15')}
                    </p>
                    <p>
                        {t('legal.our_services_p_16')}
                    </p>
                    <p>
                        {t('legal.our_services_p_17')}
                    </p>
                    <p>
                        {t('legal.our_services_p_18')}
                    </p>
                    <p>
                        {t('legal.our_services_p_19')}
                    </p>
                    <p>
                        {t('legal.our_services_p_20')}
                    </p>
                    <p>
                        {t('legal.our_services_p_21')}
                    </p>
                    <p>
                        {t('legal.our_services_p_22')}
                    </p>
                    <p>
                        {t('legal.our_services_p_23')}
                    </p>
                    <p>
                        {t('legal.our_services_p_24')}
                    </p>
                    <p>
                        {t('legal.our_services_p_25')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.copyright_and_limited_license_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.copyright_and_limited_license_p_1')}
                    </p>
                    <p>
                        {t('legal.copyright_and_limited_license_p_2')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.hyperlinks_title'),
            content: (
                <React.Fragment>
                    {t('legal.hyperlinks_p_1')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.third_party_content_title'),
            content: (
                <React.Fragment>
                    {t('legal.third_party_content_p_1')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.user_content_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.user_content_p_1')}
                    </p>
                    <p>
                        {t('legal.user_content_p_2')}
                    </p>
                    <p>
                        {t('legal.user_content_p_3')}
                    </p>
                    <p>
                        {t('legal.user_content_p_4')}
                        <a className="footer-link" href="mailto:oerocoin@gmail.com">
                            {t('legal.user_content_p_4_link')}
                        </a>
                        {t('legal.user_content_p_4_after_link')}
                    </p>
                    <p>
                        {t('legal.user_content_p_5')}
                        <a className="footer-link" href="mailto:oerocoin@gmail.com">
                            {t('legal.user_content_p_5_link')}
                        </a>
                        {t('legal.user_content_p_5_after_link')}
                    </p>
                    <p>
                        {t('legal.user_content_p_6')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.account_suspension_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.account_suspension_p_1')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_2')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_3')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_4')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_5')}
                        <a className="footer-link" href="mailto:accounts@oero.io">
                            {t('legal.account_suspension_p_5_link')}
                        </a>
                        {t('legal.account_suspension_p_5_after_link')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_6')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_7')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_8')}
                    </p>
                    <p>
                        {t('legal.account_suspension_p_9')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.feedback_title'),
            content: (
                <React.Fragment>
                    {t('legal.feedback_p_1')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.aggregate_information_title'),
            content: (
                <React.Fragment>
                    {t('legal.aggregate_information_p_1')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.acceptable_use_policy_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.acceptable_use_policy_p_1')}
                        <br/>
                        {t('legal.acceptable_use_policy_p_1_br')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_2')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_3')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_4')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_5')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_6')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_7')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_8')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_9')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_10')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_11')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_12')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_13')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_14')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_15')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_16')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_17')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_18')}
                    </p>
                    <p>
                        {t('legal.acceptable_use_policy_p_19')}
                    </p>
                </React.Fragment>
            ),
        },
        {
            title: t('legal.indemnification_title'),
            content: (
                <React.Fragment>
                    {t('legal.indemnification_p_1')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.disclaimer_of_warranties_title'),
            content: (
                <React.Fragment>
                    {t('legal.disclaimer_of_warranties_p_1')}
                </React.Fragment>
            ),
        },
        {
            title: t('legal.risks_title'),
            content: (
                <React.Fragment>
                    <p>
                        {t('legal.risks_title_p_1')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_2')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_3')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_4')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_5')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_6')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_7')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_8')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_9')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_10')}
                    </p>
                    <p>
                        {t('legal.risks_title_p_11')}
                    </p>
                </React.Fragment>
            ),
        },
        {
          title:t('legal.discontinuance_and_termination_title'),
          content: (
            <React.Fragment>
                {t('legal.discontinuance_and_termination_p_1')}
            </React.Fragment>
          ),
        },
        {
          title: t('legal.survival_title'),
          content: (
            <React.Fragment>
                {t('legal.survival_p_1')}
            </React.Fragment>
          ),
        },
        {
          title: t('legal.no_waiver_and_severability_title'),
          content: (
            <React.Fragment>
                {t('legal.no_waiver_and_severability_p_1')}
            </React.Fragment>
          ),
        },
        {
          title: t('legal.geographical_restrictions_title'),
          content: (
            <React.Fragment>
                {t('legal.geographical_restrictions_p_1')}
            </React.Fragment>
          ),
        },
        {
          title: t('legal.applicable_law_title'),
          content: (
            <React.Fragment>
                {t('legal.applicable_law_p_1')}
            </React.Fragment>
          ),
        },
        {
          title: t('legal.jurisdiction_title'),
          content: (
            <React.Fragment>
              <p>
                  {t('legal.jurisdiction_p_1')}
              </p>
              <p>
                  {t('legal.jurisdiction_p_2')}
              </p>
            </React.Fragment>
          ),
        },
        {
          title: t('legal.legal_entity_disclosure_title'),
          content: (
            <React.Fragment>
                {t('legal.legal_entity_disclosure_p_1')}
            </React.Fragment>
          ),
        },
        {
          title: t('legal.questions_complaints_and_contact_information_title'),
          content: (
            <React.Fragment>
                {t('legal.questions_complaints_and_contact_information_p_1')}
              <a className="footer-link" href="mailto:oerocoin@gmail.com">
                  {t('legal.questions_complaints_and_contact_information_p_1_link')}
              </a>
            </React.Fragment>
          ),
        },
        {
          title: t('legal.oero_privacy_policy_title'),
          content: (
            <React.Fragment>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_1')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_1')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_2')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_3')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_4')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_5')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_2')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_6')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_7')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_8')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_9')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_1_li_1')}</li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_1_li_2')}
                </li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_1_li_3')}</li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_1_li_4')}</li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_1_li_5')}
                </li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_1_li_6')}</li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_1_li_7')}
                </li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_1_li_8')}</li>
              </ul>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_3')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_10')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_1')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_2')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_3')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_4')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_5')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_6')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_7')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_2_li_8')}
                </li>
              </ul>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_4')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_11')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_12')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_3_li_1')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_3_li_2')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_3_li_3')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_3_li_4')}
                </li>
              </ul>
              <p>
                  {t('legal.oero_privacy_policy_p_13')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_14')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_15')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_5')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_16')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_17')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_18')}
              </p>
              <h3 className="privacy-policy-main">
                  {t('legal.oero_privacy_policy_h_3_6')}
              </h3>
              <p>
                  {t('legal.oero_privacy_policy_p_19')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_20')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_21')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_22')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_23')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_24')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_7')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_25')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_26')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_8')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_27')}
              </p>
              <p>
                  {t('legal.oero_privacy_policy_p_28')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_4_li_1')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_4_li_2')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_4_li_4')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.oero_privacy_policy_ul_4_li_4')}
                </li>
              </ul>
              <p>
                  {t('legal.oero_privacy_policy_p_29')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_9')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_30')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_5_li_1')}</li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_5_li_2')}</li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_5_li_3')}</li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_5_li_4')}</li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_5_li_5')}</li>
                <li className="privacy-policy-list">{t('legal.oero_privacy_policy_ul_5_li_6')}</li>
              </ul>
              <h3 className="privacy-policy-main">
                  {t('legal.oero_privacy_policy_h_3_10')}
              </h3>
              <p>
                  {t('legal.oero_privacy_policy_p_31')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.oero_privacy_policy_h_3_11')}</h3>
              <p>
                  {t('legal.oero_privacy_policy_p_32')}
                <a className="footer-link" href="mailto:oerocoin@gmail.com">
                    {t('legal.oero_privacy_policy_p_32_link')}
                </a>
              </p>
            </React.Fragment>
          ),
        },
        {
          title: t('legal.kyc_aml_policy_title'),
          content: (
            <React.Fragment>
              <h3 className="privacy-policy-main">
                  {t('legal.kyc_aml_policy_h3_1')}
              </h3>
              <p>
                  {t('legal.kyc_aml_policy_h3_1_p')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_1_li_1')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_1_li_2')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_1_li_3')}
                </li>
              </ul>
              <h3 className="privacy-policy-main">{t('legal.kyc_aml_policy_h3_2')}</h3>
              <p>
                  {t('legal.kyc_aml_policy_h3_2_p')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.kyc_aml_policy_h3_3')}</h3>
              <p>
                  {t('legal.kyc_aml_policy_h3_3_p')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_3_li_1')}
                </li>
                <ul className="privacy-policy-lists">
                  <li className="privacy-policy-list">
                      {t('legal.kyc_aml_policy_h3_3_li_2')}
                  </li>
                  <li className="privacy-policy-list">
                      {t('legal.kyc_aml_policy_h3_3_li_3')}
                  </li>
                  <li className="privacy-policy-list">
                      {t('legal.kyc_aml_policy_h3_3_li_4')}
                  </li>
                  <li className="privacy-policy-list">
                      {t('legal.kyc_aml_policy_h3_3_li_5')}
                  </li>
                </ul>
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_3_li_6')}
                </li>
              </ul>
              <h3 className="privacy-policy-main">{t('legal.kyc_aml_policy_h3_4')}</h3>
              <p>
                  {t('legal.kyc_aml_policy_h3_4_p')}
              </p>
              <h3 className="privacy-policy-main">{t('legal.kyc_aml_policy_h3_5')}</h3>
              <p>
                  {t('legal.kyc_aml_policy_h3_5_p')}
              </p>
              <ul className="privacy-policy-lists">
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_li_1')}
                </li>
                <li className="privacy-policy-list">
                    {t('legal.kyc_aml_policy_h3_li_2')}
                </li>
              </ul>
            </React.Fragment>
          ),
        },
        {
          title: t('legal.public_offer_title'),
          content: (
            <React.Fragment>
              <h3>{t('legal.public_offer_h3_1')}</h3>
              <p>
                  {t('legal.public_offer_h3_1_p_1')}
                <a href="/home/legal">{t('legal.public_offer_h3_1_p_1_link')}</a>
              </p>
              <p>
                  {t('legal.public_offer_h3_1_p_2')}
              </p>
              <p>
                  {t('legal.public_offer_h3_1_p_3')}
              </p>
              <p>
                  {t('legal.public_offer_h3_1_p_4')}
              </p>
              <h3>{t('legal.public_offer_h3_2')}</h3>
              <p>
                  {t('legal.public_offer_h3_2_p')}
                <a href="/home/legal">{t('legal.public_offer_h3_2_p_link')}</a>.
              </p>
              <h3>{t('legal.public_offer_h3_3')}</h3>

              <p>
                  {t('legal.public_offer_h3_3_p')}
              </p>

              <h3>{t('legal.public_offer_h3_4')}</h3>
              <p>
                  {t('legal.public_offer_h3_4_p_1')}
              </p>
              <p>
                  {t('legal.public_offer_h3_4_p_2')}
              </p>
              <p>
                  {t('legal.public_offer_h3_4_p_3')}
              </p>
              <p>
                  {t('legal.public_offer_h3_4_p_4')}
              </p>
              <h3>{t('legal.public_offer_h3_5')}</h3>
              <p>
                  {t('legal.public_offer_h3_5_p_1')}
              </p>
              <h3>{t('legal.public_offer_h3_6')}</h3>
              <p>
                  {t('legal.public_offer_h3_6_p_1')}
                <a href="/home/fees">{t('legal.public_offer_h3_6_p_1_link')}</a>{t('legal.public_offer_h3_6_p_1_after_link')}
              </p>
              <p>{t('legal.public_offer_h3_6_p_2')}</p>
              <p>
                  {t('legal.public_offer_h3_6_p_3')}
              </p>
              <p>
                  {t('legal.public_offer_h3_6_p_4')}
              </p>
              <p>
                  {t('legal.public_offer_h3_6_p_5')}
              </p>
              <p>
                  {t('legal.public_offer_h3_6_p_6')}
              </p>
              <p>
                  {t('legal.public_offer_h3_6_p_7')}
              </p>
              <p>
                  {t('legal.public_offer_h3_6_p_8')}
              </p>
              <p>
                  {t('legal.public_offer_h3_6_p_9')}
              </p>
              <h3>{t('legal.public_offer_h3_7')}</h3>
              <p>
                  {t('legal.public_offer_h3_7_p_1')}
              </p>
              <p>
                  {t('legal.public_offer_h3_7_p_2')}
                <a href="/home/legal">{t('legal.public_offer_h3_7_p_2_link')}</a>.
              </p>
              <p>
                  {t('legal.public_offer_h3_7_p_3')}
              </p>
              <h3>{t('legal.public_offer_h3_8')}</h3>
              <p>
                  {t('legal.public_offer_h3_8_p_1')}
              </p>
              <p>
                  {t('legal.public_offer_h3_8_p_2')}
              </p>
              <p>
                  {t('legal.public_offer_h3_8_p_3')}
              </p>
              <p>
                  {t('legal.public_offer_h3_8_p_4')}
              </p>
              <h3>{t('legal.public_offer_h3_9')}</h3>

              <p>
                  {t('legal.public_offer_h3_9_p_1')}
              </p>
              <p>
                  {t('legal.public_offer_h3_9_p_2')}
              </p>
              <p>
                  {t('legal.public_offer_h3_9_p_3')}
              </p>
              <h3>{t('legal.public_offer_h3_10')}</h3>
              <p>
                  {t('legal.public_offer_h3_10_p_1')}
              </p>
              <p>
                  {t('legal.public_offer_h3_10_p_2')}
              </p>
              <p>
                  {t('legal.public_offer_h3_10_p_3')}
              </p>
              <p>
                  {t('legal.public_offer_h3_10_p_4')}
              </p>
              <p>
                  {t('legal.public_offer_h3_10_p_5')}
              </p>
            </React.Fragment>
          ),
        },
    ]

    return (
        <div>
            {data.map((el, i) =>
                <ExpansionPanel
                    key={i}
                    expanded={props.expanded === i}
                    onChange={props.handleChange(i)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        {/*<PanelCardHeaderText>{el.title}</PanelCardHeaderText>*/}
                        <h5 className="mb-0 text-uppercase">{el.title}</h5>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="card-body" style={{textAlign: "justify"}}>
                            {el.content}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )}
        </div>
    )
}

export default withTranslation()(LegalContent)
