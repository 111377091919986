import React from "react";
import { HomeContext } from "../HomeProvider";
import { MainMenu } from "./MainMenu";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

class Transparent extends React.Component {
  static contextType = HomeContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
    };
  }

  componentDidMount() {
    // document.body.classList.add('admin-page');
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  render() {
    return (
      <>
        <header>
          <MainMenu />
          <div
            className="view full-page-intro"
            style={{
              background:
                "url('/img/useCases-background.png') no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            <div className="mask d-flex justify-content-center align-items-center">
              <div className="container">
                <div
                  className="row wow fadeIn"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  <div className="col-lg-5 mt-5 mb-4 white-text text-center text-md-left d-flex align-items-center">
                    <div className="text-left">
                      <h1 className="transparent-main ">
                        {t('transparent.title')}
                      </h1>
                      <br />
                      <p className="mb-4 d-md-block transparent-text">
                        {t('transparent.title_description_top')}
                        <br />
                        <br />
                        {t('transparent.title_description_bottom')}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mb-4 mx-auto justify-content-center d-none d-lg-flex ">
                    <img
                      src="/img/transparency-main.png"
                      className="img-fluid home-first-img align-self-center"
                      alt="Fiat Euro on the Blockchain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="text-center">
          <div id="transparent" />
          <section style={{ backgroundColor: "#eef8fb" }}>
            <div className="container">
              <div
                className="pt-5 pb-5 row wow fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div className="col-md-6 mb-4 order-1 order-md-0">
                  <img
                    src="/img/transparency-transparent.png"
                    className="img-fluid mt-md-5"
                    alt="TRANSPARENT"
                  />
                </div>
                <div className="col-md-6 mb-4 d-flex align-items-center order-0 order-md-1">
                  <div className="visElem text-left">
                    <h2 className="h2 mb-3 mt-5 sub-main text-uppercase">{t('transparent.block_one_title')}</h2>
                    <p className="sub-text">
                      {t('transparent.block_one_description_first')}
                      <br />
                      <br />
                      <span className="transparent-info-number d-none d-md-inline-block">
                        {t('transparent.block_one_description_second')}
                      </span>{" "}
                      <span className="transparent-info-text d-none d-md-inline-block text-uppercase">
                        {t('transparent.block_one_description_third')}
                      </span>
                      <br />
                      <span className="transparent-info-link d-none d-md-inline-block">
                        <a href="https://etherscan.io/token/0xe1fe2a5815d0ff09c317ed04cd5b5a1a5684fb2b">
                          {t('transparent.block_one_description_fourth')}
                        </a>
                      </span>
                      <br />
                      <br />
                      <span className="transparent-info-link d-none d-md-inline-block">
                        {t('transparent.block_one_description_fifth')}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="transparent-mobile-info order-3 col-12 mt-5">
                  <p>
                    <span className="transparent-info-number d-md-none">
                      1 000 000{" "}
                    </span>
                    <span className="transparent-info-text d-md-none">
                      OERO
                    </span>
                    <br />
                    <span className="transparent-info-link d-md-none">
                      <a href="https://etherscan.io/token/0xe1fe2a5815d0ff09c317ed04cd5b5a1a5684fb2b">
                        According to etherscan
                      </a>
                    </span>
                    <br />
                    <br />
                    <span className="transparent-info-link d-md-none">
                      Our statement is updated quarterly.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* <div className="container">
            <section
              className="mt-5 mb-5 wow fadeIn"
              style={{ visibility: "visible", animationName: "fadeIn" }}
            >
              <div className="row">
                <div className="col-md-12 mt-4 mb-4">
                  <h2 className="visElem transpatent-btn-block-text mb-3 sub-text">
                    Our statement is updated quarterly.
                  </h2>
                  <a
                    className="transpatent-btn btn waves-effect waves-light pr-5 pl-5"
                    style={{
                      backgroundColor: "#0a98c2",
                      borderRadius: ".375rem",
                    }}
                    href="/home/daily-statements"
                  >
                    Proof of Funds
                  </a>
                </div>
              </div>
            </section>
          </div> */}
          <section style={{ backgroundColor: "#eef8fb" }}>
            <div className="container">
              <div
                className="pt-5 pb-5 row wow fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div className="col-lg-6 col-md-12 px-4 d-flex align-items-center">
                  <div className="text-left text-left-down">
                    <p className="visElem sub-text">
                      {t('transparent.block_two_description_one')}
                    </p>
                    <p className="visElem transparent-link">
                      <a href="http://coinfabrik.com">Coinfabrik.com</a>
                      <br />
                      <span className="visElem transparent-link-down">
                       {t('transparent.block_two_description_two')}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">
                  <img
                    src="/img/transparency.png"
                    className="img-fluid"
                    alt="OERO smart contracts"
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withTranslation()(Transparent)
