import React, {useState} from "react";
import {MainMenu} from "./MainMenu";
import {withTranslation} from "react-i18next";

const RiskRate = () => {

    const customerData = {
        lowRisk: [
            {id: 1, value: 'Stable, known customer.'},
            {
                id: 2,
                value: 'Financial institutions and DNFBPs – where they are subject to requirements to combat money' +
                    ' laundering and terrorist financing consistent with the FATF Recommendations, have effectively ' +
                    'implemented those requirements, and are effectively supervised or monitored in accordance with ' +
                    'the FATF Recommendations to ensure compliance with those requirements.'
            },
            {id: 3, value: 'The customer is a company listed on a regulated market, which is subject to disclosure ' +
                    'obligations that establish requirements for ensuring sufficient transparency regarding the beneficial owner.'},
            {id: 4, value: 'Public administrations or enterprises.'},
        ],
        mediumRisk: [
            {id: 1, value: 'Explained geographic distance between the financial institution and the customer.'},
            {
                id: 2,
                value: 'A moderate number of funds transfers.'
            },
            {id: 3, value: 'The company with moderate rate of ownership complicity structure.'},
            {id: 4, value: 'Moderate client base stability.'},
            {id: 5, value: 'A new business with sufficient level history.'},
        ],
        highRisk: [
            {id: 1, value: 'The business relationship is conducted in unusual circumstances (e.g. significant ' +
                    'unexplained geographic distance between the financial institution and the customer).'},
            {
                id: 2,
                value: 'Non-resident customers.'
            },
            {id: 3, value: 'A new business with very little history.'},
            {id: 4, value: 'High-net-worth individual.'},
            {id: 6, value: 'Political Exposed Persons ("PEP"), including customers who are relatives of PEPs or ' +
                    'entities whose UBOs is a PEP.'},
            {id: 7, value: 'A customer with a dubious reputation as per public information available.'},
            {id: 8, value: 'Companies that have nominee shareholders or shares in bearer form.'},
            {id: 9, value: 'The ownership structure of the company appears unusual or excessively complex given the ' +
                    'nature of the company’s business.'},
            {id: 10, value: 'Unusually large transactions and unusual transaction patterns that do not have a reasonable,' +
                    ' clear economic or lawful purpose or that are not characteristic of the given business specifics.'},
            {id: 11, value: 'The customer is a legal person or a legal arrangement, which is engaged in holding personal assets.'},
            {id: 12, value: 'The customer is a cash-intensive business.'},
            {id: 13, value: 'Explained geographic distance between the financial institution and the customer.'},
        ],
    }

    const countryData = {
        lowRisk: [
            {id: 1, value: 'Residents of the EU or EEA countries.'},
            {
                id: 2,
                value: 'Residents of the third countries with effective mechanisms to combat money laundering and financing of terrorism.'
            },
            {id: 3, value: 'Residents of the third countries, which credible sources have identified as countries with a limited extent of corruption or other criminal activity.'},
            {id: 4, value: 'Residents of the third countries which, on the basis of credible sources such as mutual' +
                    ' evaluations, reports on detailed assessment or publicly available follow-up reports, have ' +
                    'requirements to combat money laundering and financing of terrorism which are consistent with ' +
                    'the FATF recommendations and which implement these requirements in an efficient manner.'},
            {id: 5, value: 'The customer is a legal person governed by public law established in the country of our company residency.'},
            {id: 6, value: 'The customer is a governmental authority or another authority performing public functions in a contracting state of the EEA.'},
            {id: 7, value: 'The customer is an institution of the EU.'},
            {id: 8, value: 'The customer is a credit institution or financial institution acting on its own behalf or ' +
                    'a credit institution or financial institution located in a contracting state of the EEA or a third' +
                    ' country, which in its country of location is subject to requirements equal to those established in' +
                    ' Directive (EU) 2015/849 of the European Parliament and of the Council and subject to state supervision.'},
        ],
        mediumRisk: [
            {id: 1, value: 'Residents of the moderate risk countries.'},
            {
                id: 2,
                value: 'Residents of the third countries with moderate effective mechanisms to combat money laundering and financing of terrorism.'
            },
            {id: 3, value: 'Residents of the third countries, which credible sources have identified as countries with ' +
                    'a moderate extent of corruption or other criminal activity.'},
        ],
        highRisk: [
            {id: 1, value: 'Residents of the countries identified by credible sources, such as mutual evaluation or ' +
                    'detailed assessment reports or published follow-up reports, as not having adequate AML/CFT systems.'},
            {
                id: 2,
                value: 'Residents of the countries subject to sanctions, embargos or similar measures issued by, for example, the United Nations.'
            },
            {id: 3, value: 'Residents of the countries identified by credible sources as having significant levels of corruption or other criminal activity.'},
            {id: 4, value: 'Residents of the countries or geographic areas identified by credible sources as providing funding or support for terrorist activities, or that have designated terrorist organizations operating within their country.'},
        ],
    }

    const ProductData = {
        lowRisk: [
            {id: 1, value: 'Financial products or services that provide appropriately defined and limited services ' +
                    'to certain types of customers, so as to increase access for financial inclusion purposes.'},
            {
                id: 2,
                value: 'Products where the risks of money laundering and terrorist financing are managed by other ' +
                    'factors such as monetary limits or transparency-enhancing measures.'
            },
            {id: 3, value: 'Basic payment services relating to a liability account.'},
        ],
        mediumRisk: [
            {id: 1, value: 'Alternative Investment.'},
            {
                id: 2,
                value: 'Trade/Export Finance.'
            },
            {id: 3, value: 'Only non-face-to-face via intermediary.'},
            {id: 4, value: 'Brokerage (could be considered as high-risk activity).'},
            {id: 5, value: 'Commercial Banking (could be considered as high-risk activity).'},
            {id: 6, value: 'Credit & Other Card Banking.'},
            {id: 7, value: 'Investment Banking.'},
            {id: 8, value: 'Retail Banking (could be considered as high-risk activity).'},
        ],
        highRisk: [
            {id: 1, value: 'Private banking.'},
            {
                id: 2,
                value: 'Anonymous transactions (which may include cash).'
            },
            {id: 3, value: 'Non-face-to-face business relationships or transactions.'},
            {id: 4, value: 'Payment received from unknown or un-associated third parties.'},
            {id: 5, value: 'Unusually large transactions and unusual transaction patterns that do not have a reasonable, ' +
                    'clear economic or lawful purpose or that are not characteristic of the given business specifics.'},
            {id: 6, value: 'Suddenly Active.'},
            {id: 7, value: 'New products and new business practices, including new delivery mechanism, and the use of ' +
                    'new or developing technologies for both new and pre-existing products.'},
            {id: 8, value: 'A transaction related to oil, arms, precious metals, precious metal products or tobacco products.'},
            {id: 9, value: 'A transaction related to cultural artefacts or other items of archaeological, historical, ' +
                    'cultural and religious importance, or of rare scientific value.'},
            {id: 10, value: 'A transaction related to ivory or protected species.'},
            {id: 11, value: 'Casinos or online gaming.'},
            {id: 12, value: 'Pharmaceuticals and online drug providers.'},
            {id: 13, value: 'Tobacco/E-cigarettes/ cannabis products.'},
            {id: 14, value: 'Telemarketing sales.'},
            {id: 15, value: 'Airlines, accommodations, and ticketing agents.'},
            {id: 16, value: 'Subscription services (magazines, collectibles, etc.).'},
            {id: 17, value: 'Financial counseling/credit repair/debt reduction.'},
            {id: 18, value: 'High-value businesses.'},
        ],
    }

    const [renderingData, SetRenderingData] = useState(customerData);

    const [btns, SetBtns] = useState([
        {name: 'Customer risk factors', status: true},
        {name: 'Country or geographic risk factors', status: false},
        {name: 'Product, service, transaction or delivery channel risk factors', status: false},
    ])

    const changeActiveBtnOnClick = e => {

        const newArr = btns.map((item) =>
            item.name === e.target.textContent
                ? {...item, status: true}
                : {...item, status: false}
        );
        SetBtns(newArr);

        if (e.target.textContent === 'Customer risk factors') {
            SetRenderingData(customerData)
        } else if (e.target.textContent === 'Country or geographic risk factors') {
            SetRenderingData(countryData)
        } else if (e.target.textContent === 'Product, service, transaction or delivery channel risk factors') {
            SetRenderingData(ProductData)
        }
    };


    return (
        <>
            <header>
                <MainMenu/>
                <div
                    className="view full-page-intro"
                    style={{
                        background:
                            "url(/img/useCases-background.png) no-repeat center center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="mask d-flex justify-content-center align-items-center">
                        <div className="container">
                            <div
                                className="row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div
                                    className="col-lg-6 mb-4 white-text text-center d-flex align-items-center justify-content-center justify-content-lg-start">
                                    <h1 className="fees-main text-uppercase">Risk Rate</h1>
                                </div>
                                <div className="col-lg-6 col-xl-5">
                                    <img
                                        src="/img/risk-rate.png"
                                        className="img-fluid home-first-img use-img mx-auto"
                                        alt="Fees"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <main className="text-center py-5 mt-3">
                <div className="container">
                    <section>
                        <div className="d-flex flex-column use-cases-text-providing" style={{textAlign: "justify"}}>
                            <p>Oero International OU prevent money laundering and provide adequate systems and
                                controls to mitigate the risk of financial crimes.</p>
                            <p>For these purposes the company (i) establishes and maintains a Risk Based Approach
                                (RBA) towards assessing and managing the money laundering and terrorist financing
                                risks to the company; (ii) establishes and maintains risk-based customer due
                                diligence, identification, verification and know your customer (KYC) procedures,
                                including enhanced due diligence for those customers presenting higher risk, such as
                                Politically Exposed Persons (PEPs) or residents of countries subject to sanctions,
                                embargoes or similar measures taken by, for example, the EU or UN; (iii) establishes
                                and maintains risk based systems and procedures to monitor on-going customer
                                activity, procedures for reporting suspicious activity; (iv) conducts training for
                                all relevant employees.</p>
                            <p>The company evaluates each client for possible participation in financial crimes and
                                assigns a certain rating to it. Depending upon difficulty, there are 3 standard
                                types of risk ratings:</p>
                        </div>
                    </section>
                    <section className="fees-card-block" style={{marginBottom: "94px"}}>
                        <div className="fees-card">
                            <div className="fees-card-title">
                                Low Risk
                            </div>
                            <div className="fees-card-subtitle">
                                Simplified due Diligence
                            </div>
                            <div className="fees-card-text">
                                Simplified due diligence is the lowest level of verification. We use Simplified due
                                Diligence when there is little chance or risk that customers engage in money
                                laundering or terrorist financing.
                            </div>
                            <div className="fees-line"></div>
                            <div className="d-flex justify-content-between"
                                 style={{width: "100%", marginBottom: "30px"}}>
                                <div>
                                    <div className="fees-iban">IBAN provision fee</div>
                                    <div className="fees-price-iban">€250</div>
                                </div>
                                <div>
                                    <div className="fees-compliance">Compliance fee</div>
                                    <div className="fees-price-compliance">€300</div>
                                </div>
                            </div>
                        </div>
                        <div className="fees-card">
                            <div className="fees-card-title">
                                Medium Risk
                            </div>
                            <div className="fees-card-subtitle">
                                Customer due Diligence
                            </div>
                            <div className="fees-card-text">
                                Customer due diligence is a kind of basic analysis of the client. The company
                                verifies a customer’s identity to a sufficient level of confidence.
                            </div>
                            <div className="fees-line"></div>
                            <div className="d-flex justify-content-between"
                                 style={{width: "100%", marginBottom: "30px"}}>
                                <div>
                                    <div className="fees-iban">IBAN provision fee</div>
                                    <div className="fees-price-iban">€500</div>
                                </div>
                                <div>
                                    <div className="fees-compliance">Compliance fee</div>
                                    <div className="fees-price-compliance">€300</div>
                                </div>
                            </div>
                        </div>
                        <div className="fees-card">
                            <div className="fees-card-title">
                                High Risk
                            </div>
                            <div className="fees-card-subtitle">
                                Enhanced due Diligence
                            </div>
                            <div className="fees-card-text">
                                Enhanced due Diligence means increased control over customers who are more likely to
                                participate in financial crimes due to their nature of business or operations.
                            </div>
                            <div className="fees-line"></div>
                            <div className="d-flex justify-content-between"
                                 style={{width: "100%", marginBottom: "30px"}}>
                                <div>
                                    <div className="fees-iban">IBAN provision fee</div>
                                    <div className="fees-price-iban">€750</div>
                                </div>
                                <div>
                                    <div className="fees-compliance">Compliance fee</div>
                                    <div className="fees-price-compliance">€300</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="risk-table">
                        <div className="risk-table-nav">

                            {btns.map((btn) => <button key={btn.name}
                                                       className={btn.status ? 'active' : 'unactive'}
                                                       onClick={changeActiveBtnOnClick}>{btn.name}</button>)}

                            {/*<button className={btn.status ? 'active' : 'unactive'}*/}
                            {/*     onClick={changeActiveBtnOnClick}>Customer risk factors</button>*/}
                            {/*<button className={'active risk-table-link'}*/}
                            {/*        onClick={changeActiveBtnOnClick}>Country or geographic*/}
                            {/*     risk factors*/}
                            {/*</button>*/}
                            {/*<button className={'active risk-table-link'}*/}
                            {/*        onClick={changeActiveBtnOnClick}>Product, service, transaction or delivery channel risk*/}
                            {/*    factors*/}
                            {/*</button>*/}
                        </div>
                        <div className="risk-table-header">
                            <div className="low-risk">Low Risk</div>
                            <div className="medium-risk">Medium Risk</div>
                            <div className="high-risk">High Risk</div>
                        </div>
                        <div className="risk-table-content">
                            <div className="content-row">
                                <ul className="risk-table-text">
                                    {renderingData.lowRisk.map((el) => {
                                        return (
                                            <li>{el.value}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="content-row">
                                <ul className="risk-table-text">
                                    {renderingData.mediumRisk.map((el) => {
                                        return (
                                            <li>{el.value}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="content-row">
                                <ul className="risk-table-text">
                                    {renderingData.highRisk.map((el) => {
                                        return (
                                            <li>{el.value}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}

export default withTranslation()(RiskRate)
