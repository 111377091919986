import React, { useContext, useEffect, useMemo, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { HeadCell, Grid } from "../../../../core/ui/grids/Grid";
import { PageWrap } from "../../components/common/Pages";
import { gql } from "@apollo/client";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import {
  BankTransactionResult,
  MutationCreateAdminTransactionStatementArgs,
  MutationUpdateBankTransactionHandlingModeArgs,
  MutationUpdateBankTransactionStatusArgs,
} from "../../../../store/generated-models";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { formatValue } from "../../helpers/Format";
import {
	BLOCKCHAIN,
	PROCESSING_STATUS,
	TRANSACTION_TYPE,
} from "../../../../core/constants/options";
import { TransactionCard } from "./TransactionCard";
import { TransactionStatus } from "./TransactionStatus";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { TransactionDetails } from "./TransactionDetails";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import {Dropdown} from "../../components/common/Dropdown";

const ITEM_PER_PAGE: number = 20;

const Row = styled.div`
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const UserLink = styled.span`
  :hover {
    text-decoration: underline;
  }
`;

const InfoIconStyled = styled(InfoIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

const CheckCircleIconStyled = styled(CheckCircleIcon)`
  fill: ${(props) => props.theme.custom.palette.alert};
`;

const LoadWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledGrid = styled(Grid as any)`
  .MuiTableCell-root {
    // max-width: 360px;
    word-break: normal;
  }
`;

const gqlUpdateBankTransactionStatus = gql`
  mutation updateBankTransactionStatus(
    $bankTransactionId: String!
    $comment: String
    $status: String!
    $realAmount: Float
    $realOeroExchangeRate: Float
    $feeAmount: Float
  ) {
    updateBankTransactionStatus(
      bankTransactionId: $bankTransactionId
      comment: $comment
      status: $status
      realAmount: $realAmount
      realOeroExchangeRate: $realOeroExchangeRate
      feeAmount: $feeAmount
    ) {
      id
      transaction {
        address
        addressService
        amount
        autoTracking
        bankTransactionId
        beneficiaryAccountAddress
        beneficiaryAccountCountry
        beneficiaryAccountName
        beneficiaryAccountPostalCity
        beneficiaryAccountPostalCode
        beneficiaryBankAddress
        beneficiaryBankCountry
        beneficiaryBankName
        beneficiaryBankPostalCity
        beneficiaryBankPostalCode
        blockchain
        collected
        collectingDate
        collectingDescription
        comment
        companyName
        created
        currency
        derivation
        description
        documentTemplateName
        ethTransactionId
        exchangeDescription
        exchangeName
        exchanged
        exchangedDate
        fineAmount
        firstUserName
        iban
        ibanAccountNumber
        intermediaryBankRoutingNumberBic
        lastUserName
        manualHandling
        oero
        oeroExchangeRate
        ownerName
        payedFromUser
        payedFromUserDate
        paymentDetails
        paymentId
        paymentResult
        privateKeyService
        processingStatus
        processingStatusDescription
        realAmount
        realAmountDate
        realAmountUserId
        realOero
        realOeroExchangeRate
        swiftBic
        transactionCode
        transactionHash
        transactionNumber
        transactionType
        updateStatusUserId
        updated
        user {
          email
          userId
        }
        wallet
      }
    }
  }
`;

const gqlUpdateBankTransactionHandlingMode = gql`
  mutation updateBankTransactionHandlingMode(
    $bankTransactionId: String!
    $autoTracking: Boolean
    $manualHandling: Boolean
    $payedFromUser: Boolean
  ) {
    updateBankTransactionHandlingMode(
      bankTransactionId: $bankTransactionId
      autoTracking: $autoTracking
      manualHandling: $manualHandling
      payedFromUser: $payedFromUser
    ) {
      id
      transaction {
        address
        addressService
        amount
        autoTracking
        bankTransactionId
        beneficiaryAccountAddress
        beneficiaryAccountCountry
        beneficiaryAccountName
        beneficiaryAccountPostalCity
        beneficiaryAccountPostalCode
        beneficiaryBankAddress
        beneficiaryBankCountry
        beneficiaryBankName
        beneficiaryBankPostalCity
        beneficiaryBankPostalCode
        blockchain	
        collected
        collectingDate
        collectingDescription
        comment
        companyName
        created
        currency
        derivation
        description
        documentTemplateName
        ethTransactionId
        exchangeDescription
        exchangeName
        exchanged
        exchangedDate
        fineAmount
        firstUserName
        iban
        ibanAccountNumber
        intermediaryBankRoutingNumberBic
        lastUserName
        manualHandling
        oero
        oeroExchangeRate
        ownerName
        payedFromUser
        payedFromUserDate
        paymentDetails
        paymentId
        paymentResult
        privateKeyService
        processingStatus
        processingStatusDescription
        realAmount
        realAmountDate
        realAmountUserId
        realOero
        realOeroExchangeRate
        swiftBic
        transactionCode
        transactionHash
        transactionNumber
        transactionType
        updateStatusUserId
        updated
        user {
          email
          userId
        }
        wallet
      }
    }
  }
`;

const getBankTransactions = gql`
  query getBankTransactions($first: Int, $skip: Int, $filter: String, $blockchain: String) {
    getBankTransactions(
      first: $first
      orderBy: "created"
      desc: true
      skip: $skip
      filter: $filter
			blockchain: $blockchain
    ) {
      count
      transactions {
        address
        addressService
        amount
        autoTracking
        bankTransactionId
        beneficiaryAccountAddress
        beneficiaryAccountCountry
        beneficiaryAccountName
        beneficiaryAccountPostalCity
        beneficiaryAccountPostalCode
        beneficiaryBankAddress
        beneficiaryBankCountry
        beneficiaryBankName
        beneficiaryBankPostalCity
        beneficiaryBankPostalCode
        blockchain	
        collected
        collectingDate
        collectingDescription
        comment
        companyName
        created
        currency
        derivation
        description
        documentTemplateName
        ethTransactionId
        exchangeDescription
        exchangeName
        exchanged
        exchangedDate
        fineAmount
        firstUserName
        iban
        ibanAccountNumber
        intermediaryBankRoutingNumberBic
        lastUserName
        manualHandling
        oero
        oeroExchangeRate
        ownerName
        payedFromUser
        payedFromUserDate
        paymentDetails
        paymentId
        paymentResult
        privateKeyService
        processingStatus
        processingStatusDescription
        realAmount
        realAmountDate
        realAmountUserId
        realOero
        realOeroExchangeRate
        swiftBic
        transactionCode
        transactionHash
        transactionNumber
        transactionType
        updateStatusUserId
        updated
        user {
          email
          userId
        }
        wallet
        bankOptionId
        intermediateBalance
      }
    }
  }
`;

const gqlTransactionStatusChanged = gql`
  subscription transactionStatusChanged {
    transactionStatusChanged
  }
`;

const gqlCreateAdminTransactionStatement = gql`
  mutation createAdminTransactionStatement(
    $dateFrom: DateTime!
    $dateTo: DateTime!
  ) {
    createAdminTransactionStatement(dateFrom: $dateFrom, dateTo: $dateTo)
  }
`;

export const Transactions: React.FC<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext);
  const banks = authContext.company.banks;
  const { enqueueSnackbar } = useSnackbar();

  const [mode, setMode] = useState("");
  const [item, setItem] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isFeeActivated, setFeeActivated] = useState(false); //[1, 4].includes(props.item._transactionTypeId)
  const [feeAmount, setFeeAmount] = useState("0.2");
  const [pageCnt, setPageCnt] = useState(0);
  const [filter, setFilter] = useState("");

	const [blockchain, setBlockchain] = useState<any>('');

  const [selectedDateFrom, setSelectedDateFrom] = useState<any>(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(
        0,
        0,
        0,
        0
      )
    )
  );
  const [selectedDateTo, setSelectedDateTo] = useState<any>(
    new Date(new Date().setHours(23, 0, 0, 0))
  );

  const [
    createAdminTransactionStatement,
    { loading: createAdminTransactionStatementLoading },
  ] = useMutation<
    { createAdminTransactionStatement: boolean },
    MutationCreateAdminTransactionStatementArgs
  >(gqlCreateAdminTransactionStatement);

  const { loading, error, data, refetch } = useQuery(getBankTransactions, {
    variables: {
      first: ITEM_PER_PAGE,
      skip: (page - 1) * ITEM_PER_PAGE,
      filter: filter,
			blockchain: blockchain
    },
  });

  const [
    updateBankTransactionStatus,
    { loading: updateBankTransactionStatusLoading },
  ] = useMutation<
    { updateBankTransactionStatus: BankTransactionResult },
    MutationUpdateBankTransactionStatusArgs
  >(gqlUpdateBankTransactionStatus);

  const [updateBankTransactionHandlingMode] = useMutation<
    { updateBankTransactionHandlingMode: BankTransactionResult },
    MutationUpdateBankTransactionHandlingModeArgs
  >(gqlUpdateBankTransactionHandlingMode);

  const { data: subscrData, error: subscrError } = useSubscription(
    gqlTransactionStatusChanged
  );

  useEffect(() => {
    if (subscrError) {
      console.warn("subscrError:", subscrError);
      return;
    }

    if (subscrData) {
      refetch().then();
      authContext.refetchUser();
    }
  }, [subscrData, subscrError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // getItems();
    refetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) console.warn(">> Users useEffect error", error);
  }, [error]);

  const infoClickHandler = (item: any) => {
    setItem(item);
    setMode("card");
  };

  const statusClickHandler = (item: any) => {
    setItem(item);
    setFeeActivated([1, 4, 5].includes(item._transactionTypeId));
    setFeeAmount("0.2");
    setMode("status");
  };

  const onUserClick = (e: any) => {
    e.stopPropagation();
    //console.log("clickedUser", e.target.innerHTML);
    navigate(`users?user=${e.target.innerHTML}`).then();
  };

  const onCreateStatement = async () => {
    await createAdminTransactionStatement({
      variables: {
        dateFrom: selectedDateFrom,
        dateTo: selectedDateTo,
      },
    })
      .then(() => {
        enqueueSnackbar("Statement successfully created and sent to email", {
          variant: "success",
        });
      })
      .catch((err) => {
        console.warn("Something went wrong", err);
      });
  };

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.getBankTransactions &&
      data.getBankTransactions.count !== undefined
    ) {
      const totalCnt = data.getBankTransactions.count;
      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      if (data && data.getBankTransactions) {
        setItems(
          data.getBankTransactions.transactions.map((el: any) => {
            let res: any = {};

            res._transactionTypeId = el.transactionType;
            const transactionType = TRANSACTION_TYPE[el.transactionType]
              ? TRANSACTION_TYPE[el.transactionType]
              : "-";
            res.transactionType = transactionType;
            const bank = el.bankOptionId
              ? banks.find((bank) => el.bankOptionId === bank.bankOption)
              : banks.find((bank) => 1 === bank.bankOption);
            //console.log(bank);
            res._destinationAddress =
              el.transactionType === 2
                ? el.addressService
                : el.transactionType === 6 || el.transactionType === 12
                ? el.wallet
                : [4, 5].includes(el.transactionType)
                ? `Bank account/IBAN: ${el.ibanAccountNumber}; SWIFT/BIC: ${el.intermediaryBankRoutingNumberBic}`
                : el.transactionType === 1
                ? `Bank account/IBAN: ${bank.bankAccount}; SWIFT/BIC: ${bank.bankSwiftBic}`
                : "";

            for (let key in el) {
              if (key === "transactionType") {
                // SKIP
              } else if (key === "processingStatus") {
                res.processingStatus = PROCESSING_STATUS[el.processingStatus]
                  ? PROCESSING_STATUS[el.processingStatus]
                  : "-";
              } else if (key === "processingStatusDescription") {
                res.processingStatusDescription =
                  el.processingStatusDescription &&
                  el.processingStatusDescription !== "null"
                    ? el.processingStatusDescription
                    : "";
              } else if (key === "exchangeDescription") {
                res.exchangeDescription =
                  el.exchangeDescription && el.exchangeDescription !== "null"
                    ? el.exchangeDescription
                    : "";
              } else if (key === "user") {
                res.user = (
                  <UserLink onClick={onUserClick}>{el.user.email}</UserLink>
                );
              } else if (key === "paymentDetails") {
                res.paymentDetails = el.paymentDetails;
                const jsonDetails = JSON.parse(el.paymentDetails);
                const paymentType =
                  jsonDetails && jsonDetails.type ? jsonDetails.type : "";
                if (paymentType)
                  res.transactionType = `${transactionType} (${paymentType})`;
              } else {
                res[key] = formatValue(el[key]);
              }

              if (
                el.processingStatus === 1 ||
                el.processingStatus === 5 ||
                (el.processingStatus === 3 && el.manualHandling)
              )
                res._actions = ["status"];
            }

            return res;
          })
        );
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const statusChangeHandler = async (obj: any) => {
    let errMessage = "";
    try {
      const res = await updateBankTransactionStatus({
        variables: {
          bankTransactionId: obj.bankTransactionId,
          comment: obj.comment,
          status: obj.status,
          realAmount: obj.realAmount,
          realOeroExchangeRate: obj.realOeroExchangeRate,
          feeAmount: obj.feeAmount,
        },
      });
      if (res.data.updateBankTransactionStatus.transaction) {
        refetch().then();
        setMode("");
        return;
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const handleChangeHandler = async (obj: any) => {
    let errMessage = "";
    let variables: any = {
      bankTransactionId: obj.bankTransactionId,
      autoTracking:
        obj.type === "autoTracking" ? !(item.autoTracking === "Y") : undefined,
      manualHandling:
        obj.type === "manualHandling"
          ? !(item.manualHandling === "Y")
          : undefined,
      payedFromUser:
        obj.type === "payedFromUser"
          ? !(item.payedFromUser === "Y")
          : undefined,
    };

    try {
      const res = await updateBankTransactionHandlingMode({
        variables: variables,
      });
      if (res.data.updateBankTransactionHandlingMode.transaction) {
        refetch()
          .then(() => {
            if (obj.type === "autoTracking") {
              setItem({
                ...item,
                autoTracking: item.autoTracking === "Y" ? "N" : "Y",
              });
              setMode("status");
            }

            if (obj.type === "manualHandling") {
              setItem({
                ...item,
                manualHandling: item.manualHandling === "Y" ? "N" : "Y",
              });
              setMode("status");
            }

            if (obj.type === "payedFromUser") {
              setItem({
                ...item,
                payedFromUser: item.payedFromUser === "Y" ? "N" : "Y",
              });
              setMode("status");
            }
          })
          .catch((err) => {
            console.warn("ToDo - err handler:", err);
          });

        if (
          obj.type === "autoTracking" ||
          obj.type === "manualHandling" ||
          obj.type === "payedFromUser"
        ) {
          setMode("reloadCard");
        } else {
          setMode("");
        }

        return;
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
  };

  const searchHandler = (val: string) => {
    if (loading) return;

    setPage(1);
    setFilter(val);
  };

	const onBlockchainFilter = (val: string) => {
		// console.log('!!!', val);
		setBlockchain(val === 'all' ? '' : val);
	};

  const headCells: HeadCell[] = useMemo(
    () => [
      {
        id: "user",
        label: "User",
        //action: onUserClick,
      },
      { id: "transactionType", label: "Type" },
      { id: "amount", label: "Amount", align: "right" },
      { id: "currency", label: "Currency" },
      { id: "oero", label: "Oero", align: "right" },
      { id: "created", label: "Date" },
      { id: "processingStatus", label: "Status" },
      { id: "blockchain", label: "Blockchain" },
      // {id: 'autoTracking', label: 'Auto Tracking'},
      // {id: 'manualHandling', label: 'Manual Handling'},
      // {id: 'exchanged', label: 'Exchanged'},
      // {id: 'payedFromUser', label: 'Payed'},
      { id: "autoTracking", label: "AT" },
      { id: "manualHandling", label: "MH" },
      { id: "exchanged", label: "EX" },
      { id: "payedFromUser", label: "Pay" },
    ],
    []
  );

  if (!data || loading || mode === "reloadCard") {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  if (mode === "card") {
    return (
      <PageWrap>
        <TransactionCard
          item={item}
          onClose={() => setMode("")}
          inProcess={updateBankTransactionStatusLoading}
          onDetails={() => setMode("details")}
        />
      </PageWrap>
    );
  }

  if (mode === "details") {
    return (
      <PageWrap>
        <TransactionDetails item={item} onClose={() => setMode("card")} />
      </PageWrap>
    );
  }

  if (mode === "status") {
    return (
      <PageWrap>
        <TransactionStatus
          item={item}
          onClose={() => setMode("")}
          onChangeStatus={statusChangeHandler}
          onChangeHandle={handleChangeHandler}
          fee={{ isFeeActivated, setFeeActivated, feeAmount, setFeeAmount }}
          inProcess={updateBankTransactionStatusLoading}
        />
      </PageWrap>
    );
  }

  return (
    <PageWrap>
      <Row style={{ marginBottom: "12px" }}>
        <Typography variant={"h3"}>Bank Transactions</Typography>
      </Row>
      <StyledGrid
        className={"compact-grid"}
        size={"small"}
        headCells={headCells}
        items={items}
        idField="bankTransactionId"
        page={page}
        pageCnt={pageCnt}
        onRowAction={infoClickHandler}
        onPageChange={onPageChangeHandler}
        actions={[
          {
            id: "info",
            type: "icon",
            icon: <InfoIconStyled />,
            onAction: infoClickHandler,
          },
        ]}
        availableActions={[
          {
            id: "status",
            type: "icon",
            icon: <CheckCircleIconStyled />,
            onAction: statusClickHandler,
          },
        ]}
        extSearch
        extSearchValue={filter}
        onSearchApply={searchHandler}
				withBlockchainFilter
				onBlockchainFilter={onBlockchainFilter}
      />
      <Row style={{ marginTop: "12px" }}>
        <Typography variant={"h2"} style={{ fontSize: "24px" }}>
          Get statement to your email for period
        </Typography>
      </Row>
      <Row style={{ marginTop: "12px" }}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="From"
          autoOk
          value={selectedDateFrom}
          maxDate={selectedDateTo}
          onChange={(newDate) => {
            setSelectedDateFrom(newDate);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="To"
          autoOk
          minDate={selectedDateFrom}
          maxDate={new Date()}
          value={selectedDateTo}
          onChange={(newDate) => {
            setSelectedDateTo(newDate);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "120px", minHeight: "50px" }}
          onClick={onCreateStatement}
          disabled={createAdminTransactionStatementLoading}
        >
          Get statement
        </Button>
      </Row>
    </PageWrap>
  );
};
