import React from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
// function countryToFlag(isoCode: string) {
// 	return typeof String.fromCodePoint !== 'undefined'
// 		? isoCode
// 			.toUpperCase()
// 			.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
// 		: isoCode;
// }

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const useStyles = makeStyles({
  option: {
    // fontSize: 15,
    "& > span": {
      width: 510,
      marginRight: 10,
      // fontSize: 18,
    },
  },
});

interface ComponentProps {
  id?: string;
  label?: any;
  error?: boolean;
  helperText?: string;
  value?: any;
  onChange?: (e: any, v: any) => void;
  size?: "small" | "medium";
  disabled?: boolean;
  readOnly?: boolean;
  style?: any;
  variant?: any;
}

export const SourcesOfWealthSelector: React.FC<ComponentProps> = (props) => {
  const classes = useStyles();

  return (
    <Autocomplete
      // value={props.value && Object.keys(props.value).length !== 0 ? props.value : null}
      // error={props.error}  !! ToDo
      value={props.value}
      onChange={props.onChange}
      id={props.id}
      style={props.style}
      options={SourcesOfWealthList as SourcesOfWealth[]}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        // <React.Fragment>
        // 	<span>{countryToFlag(option.code)}</span>
        // 	{option.label} ({option.code}) +{option.phone}
        // </React.Fragment>
        <React.Fragment>
          <span>{option.label}</span>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={props.label ? props.label : 'Sources of wealth or income*'}
          variant={props.variant ? props.variant : "outlined"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default withTranslation()(SourcesOfWealthSelector)

export interface SourcesOfWealth {
  label: string;
}

// export const getCountryByCode = (code: string) => {
//   if (!code) return { code: "", label: "", phone: "" };
//   const country = countries.find((el) => el.code === code);
//   if (country) return country;
//   return { code: "OO", label: "Other", phone: "" };
// };

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const SourcesOfWealthList = [
  { label: "Inheritances",},
  { label: "Investments",},
  { label: "Business ownership interests",},
  { label: "Employment income",},
  { label: "Personal savings", },
  { label: "Pension releases",},
  { label: "Share sales and dividends",},
  { label: "Property sales",},
  { label: "Gambling winnings",},
  { label: "Inheritances and gifts",},
  { label: "Compensation from legal rulings",},
];
