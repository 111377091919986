import React, { Component } from "react";
import styled from "styled-components";

const DragSpan = styled.div`
  position: relative;
  user-select: none;
`;

const MarqueeContainer = styled.div`
  cursor: pointer;
  overflow-x: hidden;
  white-space: nowrap;
`;

class Marquee extends Component {
  constructor(props) {
    super(props);
    this.scrollTimer = null;
    this.state = {
      left: 0,
      isDragging: false,
    };
  }

  componentDidMount() {
    this.initMarquee();
  }

  componentWillUnmount() {
    clearInterval(this.scrollTimer);
  }

  initMarquee() {
    const freq = 35;
    this.spanWidth = 6860; //current width
    this.scrollTimer = setInterval(this.move, freq);
  }

  move = () => {
    const offset = 1;
    let left = this.state.left - offset;
    if (left < -this.spanWidth / 2) {
      left = 0;
    }
    this.setState({ left });
  };

  handleMouseEnter = () => {
    //clearInterval(this.scrollTimer);
  };

  handMouseLeave = () => {
    clearInterval(this.scrollTimer);
    const freq = 35;
    this.scrollTimer = setInterval(this.move, freq);
    this.setState({ isDragging: false });
  };

  handleDrag = (e) => {
    this.prePageX = this.currentPageX;
    this.currentPageX = e.pageX;
    const left = this.state.left + (this.currentPageX - this.prePageX);
    this.setState({ left });
  };

  handleMouseDown = (e) => {
    clearInterval(this.scrollTimer);
    this.setState({ isDragging: true });
    this.prePageX = e.pageX;
    this.currentPageX = this.prePageX;
  };

  handleMouseUp = (e) => {
    clearInterval(this.scrollTimer);
    const freq = 35;
    this.scrollTimer = setInterval(this.move, freq);
    this.setState({ isDragging: false });
  };

  render() {
    const { children } = this.props;
    const handleDrag = this.state.isDragging ? this.handleDrag : null;
    return (
      <MarqueeContainer
        //className="marquee-container"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handMouseLeave}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseMove={handleDrag}
      >
        <DragSpan style={{ left: this.state.left }}>
          {children}
          {children}
        </DragSpan>
      </MarqueeContainer>
    );
  }
}

export default Marquee;
