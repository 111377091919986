import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useSnackbar } from "notistack";
import { Tooltip } from "@material-ui/core";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const Row: any = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const RowList = styled(Row)`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 8px;
  }
`;

const ConfirmButton = styled(Button)`
  min-width: 240px;
  margin-right: 20px;
`;

const RejectButton = styled(Button)`
  background-color: ${(props) => props.theme.custom.palette.alert};
  color: #ffffff;
  min-width: 240px;
  margin-right: 20px;

  &:hover {
    opacity: 0.87;
    background-color: ${(props) => props.theme.custom.palette.alert};
  }
`;

// const HandleButton = styled(Button)`
//
// 	&.MuiButton-outlined {
// 		color: ${props => props.theme.custom.palette.success};
// 		&:hover {
//   		color: ${props => props.theme.custom.palette.success};
//   	}
// 	}
//
// 	&.MuiButton-contained {
// 		background-color: ${props => props.theme.custom.palette.success};
// 		color: #FFFFFF;
// 		&:hover {
// 			color: #FFFFFF;
//   		background-color: ${props => props.theme.custom.palette.success};
//   	}
// 	}
//
//   &:hover {
//   	opacity: 0.87;
//   }
// `;

const TooltipIcon = styled(HelpOutlineIcon)`
  color: rgba(0, 0, 0, 0.4) !important;
`;

const CloseButton = styled(Button)`
  min-width: 120px;
  // margin-left: auto;
`;

interface FeeData {
  isFeeActivated: boolean;
  setFeeActivated: (e: any) => void;
  feeAmount: string;
  setFeeAmount: (e: any) => void;
}

interface ControlProps {
  item: any;
  onClose: () => void;
  onChangeStatus: (item: any) => void;
  onChangeHandle: (item: any) => void;
  inProcess?: boolean;
  fee?: FeeData;
}

export const TransactionStatus: React.FC<ControlProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [comment, setComment] = useState(props.item.comment);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmManual, setOpenConfirmManual] = React.useState(false);
  const [openConfirmPFU, setOpenConfirmPFU] = React.useState(false);
  const [amount, setAmount] = useState(props.item.amount);

  const [calculatedFee, setCalculatedFee] = useState(
    (
      (props.item.amount *
        props.item.oeroExchangeRate *
        parseFloat(props.fee.feeAmount)) /
      100
    ).toFixed(2)
  );
  const [amountWithFee, setAmountWithFee] = useState(
    props.item.amount * props.item.oeroExchangeRate - parseFloat(calculatedFee)
  );
  const [oeroExchangeRate, setOeroExchangeRate] = useState(
    props.item.oeroExchangeRate
  );

  const handleChangeFeeAmount = (feeAmount: string) => {
    if (!!feeAmount) {
      const calculatedFee =
        (props.item.amount *
          props.item.oeroExchangeRate *
          parseFloat(feeAmount)) /
        100;
      setCalculatedFee(calculatedFee.toFixed(2));
      setAmountWithFee(props.item.amount - calculatedFee);
    } else {
      setCalculatedFee("0");
      setAmountWithFee(props.item.amount);
    }
    props.fee.setFeeAmount(feeAmount);
  };

  const handleChangeCalculatedFee = (calculatedFee: string) => {
    if (!!calculatedFee) {
      const feeAmount =
        (parseFloat(calculatedFee) /
          props.item.amount /
          props.item.oeroExchangeRate) *
        100;
      props.fee.setFeeAmount(feeAmount.toFixed(2));
      setAmountWithFee(props.item.amount - parseFloat(calculatedFee));
    } else {
      props.fee.setFeeAmount("0");
      setAmountWithFee(props.item.amount);
    }
    setCalculatedFee(calculatedFee);
  };

  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleClosePFU = () => {
    setOpenConfirmPFU(false);
  };

  const handleCloseManual = () => {
    setOpenConfirmManual(false);
  };

  const confirmClickHandler = () => {
    if (
      props.item.payedFromUser !== "Y" &&
      [4, 5, 6, 12].indexOf(props.item._transactionTypeId) === -1
    ) {
      setOpenConfirm(true);
      return;
    }

    if (
      props.item.manualHandling === "Y" &&
      !!props.item.processingStatusDescription
    ) {
      setOpenConfirmManual(true);
      return;
    }

    processChangeStatus("CONFIRMED");
  };

  const processChangeStatus = (status: string) => {
    let valAmount: number;
    let valExchangeRate: number;
    let feePercent: number;
    let fee: number;

    try {
      valAmount = parseFloat(amount);
      valExchangeRate = parseFloat(oeroExchangeRate);
      feePercent = parseFloat(props.fee.feeAmount);
      if (feePercent > 100 || feePercent < 0) throw new Error();
      fee = props.fee.isFeeActivated
        ? (valAmount * oeroExchangeRate * feePercent) / 100
        : 0;
    } catch {
      enqueueSnackbar("Incorrect amount or exchange rate or fee amount", {
        variant: "error",
      });
      return;
    }

    props.onChangeStatus({
      bankTransactionId: props.item.bankTransactionId,
      status: status,
      comment: comment,
      realAmount: valAmount,
      realOeroExchangeRate: valExchangeRate,
      feeAmount: fee,
    });
  };

  const processChangeHandleClick = (type: string) => {
    props.onChangeHandle({
      bankTransactionId: props.item.bankTransactionId,
      type: type,
    });
  };

  const processChangePayedFromUser = () => {
    if (
      props.item.payedFromUser !== "Y" &&
      [4, 5, 6, 12].indexOf(props.item._transactionTypeId) !== -1
    ) {
      setOpenConfirmPFU(true);
      return;
    }

    processChangeHandleClick("payedFromUser");
  };
  return (
    <>
      <Row style={{ marginBottom: "24px" }}>
        <Typography variant="h3">Change transaction status / mode</Typography>
      </Row>

      <RowList style={{ marginBottom: "24px" }}>
        <Typography variant="body1">
          Client:{" "}
          <b>
            {props.item.companyName ??
              `${props.item.firstUserName} ${props.item.lastUserName}`}
          </b>
        </Typography>
        <Typography variant="body1">
          Type: <b>{props.item.transactionType}</b>
        </Typography>
        <Typography variant="body1">
          Amount:{" "}
          <b>
            {props.item.amount} {props.item.currency}
          </b>
        </Typography>
        <Typography variant="body1">
          Date: <b>{props.item.created}</b>
        </Typography>
      </RowList>

      <Row style={{ marginBottom: "24px" }}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          maxRows={6}
          label="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Row>
      <Row style={{ marginBottom: "24px" }}>
        <TextField
          variant="outlined"
          fullWidth
          label="Processing description (Errors displayed here)"
          value={props.item.processingStatusDescription}
        />
      </Row>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Oero Exchange Rate"
            value={oeroExchangeRate}
            onChange={(e) => setOeroExchangeRate(e.target.value)}
          />
        </Grid>
        {[1, 4, 5].includes(props.item._transactionTypeId) ? (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Calculated fee"
                disabled={!props.fee.isFeeActivated}
                value={calculatedFee}
                onChange={(e) => handleChangeCalculatedFee(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={!props.fee.isFeeActivated}
                label={
                  props.item._transactionTypeId === 1
                    ? "Oero amount with fee"
                    : "Fiat amount with fee"
                }
                value={amountWithFee}
                //onChange={(e) => setOeroExchangeRate(e.target.value)}
              />
            </Grid>
          </>
        ) : (
          ""
        )}
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={props.item.manualHandling === "Y"}
                onChange={() => processChangeHandleClick("manualHandling")}
                name="manualHandling"
                color="primary"
              />
            }
            label="Manual Handling"
          />
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={4000}
            title={
              "This parameter signifies need of manual handling of a transaction. Sets automatically in case of error or exceeding the limit, also sets automatically after successful withdraw(in this case 'Paid from user' parameter is also set). In first two cases, disable the switch and confirm transaction to try again. In last case, just succeed transaction"
            }
          >
            <TooltipIcon />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={props.item.autoTracking === "Y"}
                onChange={() => processChangeHandleClick("autoTracking")}
                name="autoTracking"
                color="primary"
              />
            }
            label="Auto Tracking"
          />
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={4000}
            title={
              "Indicator of automatic tracking of the receipt of payment for a transaction from a user. Sets automatically for crypto and e-payment deposits"
            }
          >
            <TooltipIcon />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={props.item.payedFromUser === "Y"}
                // onChange={() => processChangeHandleClick('payedFromUser')}
                onChange={() => processChangePayedFromUser()}
                name="payedFromUser"
                color="primary"
              />
            }
            label="Paid From User"
          />
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={4000}
            title={
              "Indicatior of that the transaction was paid by the user. Sets automatically when user makes deposit with crypto or e-payment. Also sets after successful withdrawing OERO from user (MH is also set). For fiat deposit needs to be set manually before confirming if payment was received."
            }
          >
            <TooltipIcon fill={"rgba(0,0,0,.7)"} />
          </Tooltip>
        </Grid>
        {[1, 4, 5].includes(props.item._transactionTypeId) ? (
          <>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.fee.isFeeActivated}
                    // onChange={() => processChangeHandleClick('payedFromUser')}
                    onChange={() =>
                      props.fee.setFeeActivated(!props.fee.isFeeActivated)
                    }
                    name="bankFee"
                    color="primary"
                  />
                }
                label="Fee"
              />
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={4000}
                title={
                  "Enable or disable fee for bank transaction (deposit or withdraw)"
                }
              >
                <TooltipIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={!props.fee.isFeeActivated}
                label="Fee amount (in %)"
                value={props.fee.feeAmount}
                onChange={(e) => handleChangeFeeAmount(e.target.value)}
              />
            </Grid>
          </>
        ) : (
          ""
        )}
        <Grid item xs={12} style={{ padding: 0 }} />

        <Grid item xs={12} md>
          <ConfirmButton
            variant="contained"
            color="primary"
            size={"large"}
            onClick={confirmClickHandler}
          >
            {props.item.manualHandling !== "Y" ? "Confirm" : "Succeed"}
          </ConfirmButton>
        </Grid>
        <Grid item xs={12} md>
          <RejectButton
            variant="contained"
            size={"large"}
            onClick={() => processChangeStatus("REJECTED")}
          >
            Reject
          </RejectButton>
        </Grid>
        <Grid item xs={12} md style={{ textAlign: "right" }}>
          <CloseButton
            variant="outlined"
            color="primary"
            size={"large"}
            onClick={() => props.onClose()}
          >
            Close
          </CloseButton>
        </Grid>
      </Grid>

      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>{"ATTENTION"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Transaction is not paid from the user. Are you sure to{" "}
            {props.item.manualHandling !== "Y" ? "confirm" : "succeed"} it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (props.item.manualHandling !== "Y") {
                processChangeStatus("SUCCEEDED");
              } else {
                setOpenConfirmManual(true);
                handleClose();
              }
            }}
          >
            {props.item.manualHandling !== "Y" ? "Confirm" : "Succeed"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmManual}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>{"ATTENTION"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Transaction is set in manual handling mode. It may be due to an
            transfer error or exceeding transaction limit. Are you sure to
            succeed it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseManual}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => processChangeStatus("SUCCEEDED")}
          >
            Succeed
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmPFU}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosePFU}
      >
        <DialogTitle>{"ATTENTION"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to mark the transaction as paid from user. After that
            we won't transfer user's OERO automatically. Are you sure the
            transaction has been paid yet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClosePFU}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => processChangeHandleClick("payedFromUser")}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
