import gql from "graphql-tag";

// userId
// name
// email
// nameConfirmed
// termsOfUse
// is2faEnabled
// roles
// hasEmailAuth
// state {
// 	contractBalance
// 	ethBalance
// 	date
// }

export const USER_RESULT = `
	accessFailedCount
	avatar
	birthday
	blockchain
	created
	deleted
	deletedDate
	email
	emailConfirmed
	ethAddress
	trxAddress
	avaxAddress
	bnbAddress
	firstName
	hasEmailAuth
	is2faEnabled
	kycComment
    kycName
	lastName
	name
	nameConfirmed
	roles
	state {
		contractBalance
		ethBalance
		trxBalance
		euroBalance
		date
	}
	termsOfUse
	updated
	userId
	userType
	valid
	validationDate
`;

export const COMPANY_RESULT = `
	bankAccount
	bankAddress
	bankName
	bankSwiftBic
	companyAddress
	companyName
	registrationNumber
	swift
    banks {
        bankName
        bankAddress
        bankAccount
        bankSwiftBic
        bankAcceptedCurrency
        bankOption
        companyAddress
	    companyName
        routingNumber
        referenceNumber
        isActive
    }
	wallets {
		BCH
		BTC
		ETH
		LTC
		OERO
		TEG
		USDT
		XRP
	}
`;

export const SETTINGS_RESULT = `
	companyKycFileDownloadUrlEn
	companyKycFileDownloadUrlRu
	kycFileTypes {
		id
		index
		value
		required
		requiredBeneficiaryCount
	}
	maxFileSize
	maxFiles
	restrictMinAmounts
`;

export const LOGIN_RESULT = `
	authToken
	authTokenAction
	authTokenActionParam
	user {				
		${USER_RESULT}
	}
	company {
		${COMPANY_RESULT}
	}
	settings {
		${SETTINGS_RESULT}
	}
	transactionParams {
    averageTransactionFee
	}
`;

export const gqlLogin = gql`
    mutation Login(
        $email: String,
        $password: String,
        $oAuthProvider: OAuthProvider,
        $oAuthToken: String,
        $recaptcha: String!
    ) {
        login (
            email:$email,
            password:$password,
            oAuthProvider: $oAuthProvider,
            oAuthToken: $oAuthToken,
            recaptcha: $recaptcha
        ) {
            ${LOGIN_RESULT}
        }
    }
`;

export const gqlVerify2faCode = gql`
    mutation Verify2faCode(
        $code: String!,
        $token: String!
        #$recaptcha: String!,
    ) {
        verify2faCode (
            code: $code,
            token: $token
            #recaptcha: $recaptcha,
        ) {
            ${LOGIN_RESULT}
        }
    }
`;

export const gqlConfirmName = gql`
    mutation ConfirmName(
        $name: String!,
        $recaptcha: String!,
        $token: String!
    ) {
        confirmName (
            name: $name,
            recaptcha: $recaptcha,
            token: $token
        ) {
            ${LOGIN_RESULT}
        }
    }
`;

export const gqlSignup = gql`
    mutation Signup(
        $email: String,
        $password: String,
        $oAuthProvider: OAuthProvider,
        $oAuthToken: String,
        $termsOfUse: Boolean,
        $name: String,
        # $givenReferralCode: String,
        $recaptcha: String!
    ) {
        signup (
            email:$email,
            password:$password,
            oAuthProvider: $oAuthProvider,
            oAuthToken: $oAuthToken,
            termsOfUse: $termsOfUse,
            name : $name,
            # givenReferralCode: $givenReferralCode,
            recaptcha: $recaptcha
        ) {
            ${LOGIN_RESULT}
        }
    }
`;

export const gqlSettings = gql`
    query {
        getSettings{
            company {
                ${COMPANY_RESULT}
            }
            settings {
                ${SETTINGS_RESULT}
            }
            transactionParams {
                averageTransactionFee
            }
        }
    }
`;

export const gqlEmailVerification = gql`
	mutation SendEmailVerification($email: String!){
		sendEmailVerification (email: $email)
	}
`;
