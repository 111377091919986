import React from "react";
import { HomeContext } from "../HomeProvider";
import { MainMenu } from "./MainMenu";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

class Faq extends React.Component {
  static contextType = HomeContext;
  context;

  constructor(props) {
    super(props);

    this.state = {
      // page: 'Users'
    };
  }

  componentDidMount() {
    // document.body.classList.add('admin-page');
  }

  componentWillUnmount() {
    // document.body.classList.remove('admin-page');
  }

  render() {
    return (
      <>
        <header>
          <MainMenu />

          <div
            className="view full-page-intro"
            style={{
              background:
                "url(/img/useCases-background.png) no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            <div className="mask d-flex justify-content-center align-items-center">
              <div className="faq-main-content d-flex flex-column flex-lg-row justify-content-between container">
                <div className="faq-main-content-left col-lg-6 align-self-center">
                  <h1 className="faq-main">{t('FAQs.faq_title')}</h1>
                  <p className="mt-lg-5 faq-main-text">
                    {t('FAQs.faq_subtitle')}
                  </p>
                </div>
                <div className="faq-main-content-right col-lg-6">
                  <img
                    src="/img/faq-bg-img.png"
                    alt=""
                    className="home-first-img mt-lg-5 mx-auto faq-main-img w-lg-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="container mt-5 py-5">
          <h2 className="visElem faq-h2 mt-lg-5 slideInUp">{t('FAQs.about_oero_title')}</h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.about_oero_description')}
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">{t('FAQs.what_is_oero_title')}</h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.what_is_oero_description')}
             <a href="/home/white-paper">{t('FAQs.what_is_oero_link')}</a>.
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">{t('FAQs.how_does_Oero_work_tile')}</h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.how_does_Oero_work_description')}
            <a href="/home/transparent">{t('FAQs.how_does_Oero_work_link')}</a>.
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">
            {t('FAQs.where_can_i_get_oero')}
          </h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.where_can_i_get_oero_description')}
            <a href="/home/buying-guide">{t('FAQs.where_can_i_get_oero_link')}</a>.
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">{t('FAQs.who_can_use_oero')}</h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.who_can_use_oero_description')}
            <a href="/home/buying-guide">{t('FAQs.who_can_use_oero_link')}</a>.
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">
            {t('FAQs.where_can_i_use_oero')}
          </h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.where_can_i_use_oero_description')}
            <a href="/home/transparent">{t('FAQs.where_can_i_use_oero_link')}</a>{t('FAQs.where_can_i_use_oero_after_link')}
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">
            {t('FAQs.can_i_exchange_OEURs_into_euros')}
          </h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.can_i_exchange_OEURs_into_euros_description')}
            <a href="/home/buying-guide">{t('FAQs.can_i_exchange_OEURs_into_euros_link')}</a>.
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">
            {t('FAQs.do_i_have_to_go_through_the_KYC_process')}
          </h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.do_i_have_to_go_through_the_KYC_process_description')}
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">{t('FAQs.is_legal')}</h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.is_legal_description')}
          </p>

          <h2 className="visElem faq-h2 mt-5 slideInUp">
            {t('FAQs.store_oeros')}
          </h2>
          <p className="visElem faq-text slideInUp">
            {t('FAQs.store_oeros_description')}
          </p>
        </main>
        <section className="start-using py-5">
          <div className="start-using-contant py-5 text-center">
            <h2 className="start-using-text">
              {t('FAQs.start_using_euros')}
            </h2>
            <a href="/login">
              <button className="btn_flare btn start-using-btn mt-5 waves-effect waves-light">
                {t('FAQs.Authorithation')}
              </button>
            </a>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(Faq)

