import React, {useState} from 'react';
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {gql} from "@apollo/client";
import {useMutation} from "@apollo/react-hooks";
import {BankTransactionShortResult, MintingTransactionShortResult, MutationConfirmBurnArgs, MutationBurnArgs} from "../../../store/generated-models";
import Typography from "@material-ui/core/Typography";

const Row = styled.div`
  display: flex;
`;

const TextRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const gqlBurn = gql`
    mutation burn (
        $amount: Float!
    ) {
        burn (
            amount: $amount
        ) {
            id
            transaction {
                bankTransactionId
                oero
                wallet
            },
            trustees {
                email
                name
            }
            codeLength
            codeLifetime
        }
    }
`;

const gqlConfirmBurn = gql`
    mutation confirmBurn (
        $id: String
        $code2fa: String
        $trustees: [Trustee!]
    ) {
        confirmBurn (
            id: $id
            code2fa: $code2fa
            trustees: $trustees
        ) {
            transaction {
                bankTransactionId
                created
                oero
                wallet
            }
        }
    }
`;

export const Burn: React.FC = () => {

	const {enqueueSnackbar} = useSnackbar();

	const [mode, setMode] = useState<string>('');
	const [errors, setErrors] = useState<any>({});
	const [amountVal, setAmountVal] = useState<string>('');
	const [burnRes, setBurnRes] = useState<any>({});
	const [codes, setCodes] = useState<string[]>([]);
	const [code2fa, setCode2fa] = useState<string>('');
	const [code2faError, setCode2faError] = useState<string>('');
	const [transaction, setTransaction] = useState<any>({});

	const [burn,
		{loading: burnLoading}
	] = useMutation<{ burn: MintingTransactionShortResult }, MutationBurnArgs>(gqlBurn);

	const [confirmBurn,
		{loading: confirmBurnLoading}
	] = useMutation<{ confirmBurn: BankTransactionShortResult }, MutationConfirmBurnArgs>(gqlConfirmBurn);

	const burnHandler = async () => {

		let err: any = {};
		let errMessage = '';
		let amountNum = Number(amountVal);

		if (isNaN(amountNum) || !amountNum) {
			err.amount = 'incorrect amount';
		}

		setErrors({...err});

		if (Object.keys(err).length !== 0) {
			enqueueSnackbar('Correct errors to continue', {variant: 'error'});
			return;
		}

		try {
			let variables: any = {
				amount: amountNum
			};

			const res = await burn({variables: variables});
			if (res.data.burn.id) {
				setBurnRes(res.data.burn);
				setMode('confirm');
				return;
			} else {
				errMessage = 'Process error. Please try again later';
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === 'auth.access_denied') {
					errMessage = 'Access denied';
				} else if (errorCode === 'core.burning_error') {
					errMessage = 'Burning error';
				}
				if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {
			}
		}

		if (!errMessage) errMessage = 'Unknown error';
		enqueueSnackbar(errMessage, {variant: 'error'});
	};

	const confirmHandler = async () => {
		let err: string[] = [];
		let errFlag = false;
		let errMessage = '';

		if (!code2fa) {
			errFlag = true;
			setCode2faError('required');
		} else {
			setCode2faError('');
		}

		const trustees: any[] = burnRes.trustees.map((el: any, i: number) => {
			const code = Number(codes[i]);
			if (code && !isNaN(code)) {
				err.push('');
			} else {
				err.push('invalid');
				errFlag = true;
			}

			return {id: el.email, code: code};
		});

		setErrors({codes: [...err]});

		if (errFlag) {
			enqueueSnackbar('Correct errors to continue', {variant: 'error'});
			return;
		}

		try {
			let variables: any = {
				id: burnRes.id,
				code2fa: code2fa,
				trustees: trustees
			};

			const res = await confirmBurn({variables: variables});

			if (res.data.confirmBurn.transaction.bankTransactionId) {
				setTransaction(res.data.confirmBurn.transaction);
				setMode('finish');
				return;
			} else {
				errMessage = 'Process error. Please try again later';
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === 'auth.access_denied') {
					errMessage = 'Access denied';
				} else if (errorCode === 'core.burning_error') {
					errMessage = 'Burning error';
				}
				if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {
			}
		}

		if (!errMessage) errMessage = 'Unknown error';
		enqueueSnackbar(errMessage, {variant: 'error'});
	};

	const finishHandler = () => {
		setMode('');
		setErrors({});
		setAmountVal('');
		setBurnRes({});
		setCodes([]);
		setCode2fa('');
		setCode2faError('');
		setTransaction({});
	};

	if (mode === 'confirm')
		return (
			<React.Fragment>
				<Typography variant="h4" component="h4">
					Please enter codes:
				</Typography>

				<TextRow>
					<TextField
						error={!!code2faError}
						helperText={code2faError}
						style={{width: '240px'}}
						variant='outlined'
						label={'2fa code'}
						value={code2fa}
						onChange={e => setCode2fa(e.target.value)}
					/>
				</TextRow>

				{burnRes && burnRes.trustees && burnRes.trustees.length > 0 &&
				burnRes.trustees.map((trustee: any, i: number) =>
					<TextRow key={i}>
						<Typography variant={"body1"}>{trustee.name} ({trustee.email})</Typography>
						<TextField
							error={errors.codes && !!errors.codes[i]}
							helperText={errors.codes && errors.codes[i]}
							style={{width: '240px', marginTop: '8px'}}
							variant='outlined'
							value={codes[i] ? codes[i] : ''}
							onChange={e => {
								let newCodes = [...codes];
								newCodes[i] = e.target.value;
								setCodes(newCodes);
							}}
						/>
					</TextRow>
				)}
				<Row style={{marginTop: '24px'}}>
					<Button
						variant="contained"
						color="primary"
						size={"large"}
						style={{minWidth: '240px'}}
						onClick={confirmHandler}
						disabled={confirmBurnLoading}
					>
						Confirm
					</Button>
				</Row>

			</React.Fragment>
		);

	if (mode === 'finish')
		return (
			<React.Fragment>
				<TextRow>
					<Typography variant="h4" component="h4">Transaction ID: {transaction.bankTransactionId}</Typography>
				</TextRow>
				<TextRow>
					<Typography variant="h4" component="h4">Action: Burning</Typography>
				</TextRow>
				<TextRow>
					<Typography variant="h4" component="h4">Amount: {transaction.oero}</Typography>
				</TextRow>
				<Row style={{marginTop: '24px'}}>
					<Button
						variant="contained"
						color="primary"
						size={"large"}
						style={{minWidth: '240px'}}
						onClick={finishHandler}
					>
						OK
					</Button>
				</Row>
			</React.Fragment>
		);

	return (
		<React.Fragment>
			<Row style={{marginTop: '12px'}}>
				<TextField
					error={!!errors.amount}
					helperText={errors.amount}
					variant='outlined'
					label='Amount'
					style={{minWidth: '240px'}}
					value={amountVal}
					onChange={e => setAmountVal(e.target.value)}
				/>
			</Row>
			<Row style={{marginTop: '24px'}}>
				<Button
					variant="contained"
					color="primary"
					size={"large"}
					style={{minWidth: '240px'}}
					onClick={burnHandler}
					disabled={!amountVal || burnLoading}
				>
					Next
				</Button>
			</Row>
		</React.Fragment>
	)
};
