import React from "react";
import {HomeContext} from "../HomeProvider";
import {MainMenu} from "./MainMenu";
import styled from "styled-components";
import OeroIcon from "../../../assets/oero-icon.svg";
import {GetCryptoRates} from "services/CryptoRates";
import OeroLoader from "../../../core/ui/OeroLoader";
import {withTranslation} from "react-i18next";
import {t} from "i18next";
import {navigate} from "@reach/router";

const CryptoShortName = styled.span`
  color: rgba(0, 0, 0, 0.3);
`;

const StyledPercent = styled.span`
  color: ${(props) => (props.isPositive ? "#1B9650" : "#DE0000")};
`;

const CryptoIcon = styled.img`
  width: 35px;
  height: 35px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const NameContainer = styled.div`
  & > * {
    margin-left: 10px;
  }

  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
  }
`;

const DataTable = styled.table`
  & > thead th {
    vertical-align: middle !important;
  }

  & > tbody th {
    text-align: start;
  }

  th,
  td {
    border-color: #4d4d4d;
  }

  tbody {
    tr td:nth-child(3),
    tr th:nth-child(3),
    tr td:nth-child(7),
    tr th:nth-child(7) {
      text-align: start;
    }

    tr th {
      height: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    tr td:nth-child(3),
    tr th:nth-child(3) {
      display: table-cell;
    }
  }
`;

class Fees extends React.Component {
    static contextType = HomeContext;
    context;

    constructor(props) {
        super(props);
        this.state = {
            cryptoRates: new Array(7).fill({}),
        };
    }

    async componentDidMount() {
        try {
            const cryptoRates = await GetCryptoRates(true);
            //console.log(cryptoRates);
            this.setState({
                cryptoRates: cryptoRates,
            });
        } catch (e) {
            console.warn("Couldn't load rates", e);
        }
    }

    componentWillUnmount() {
        // document.body.classList.remove('admin-page');
    }

    render() {
        return (
            <>
                <header>
                    <MainMenu/>
                    <div
                        className="view full-page-intro"
                        style={{
                            background:
                                "url(/img/useCases-background.png) no-repeat center center",
                            backgroundSize: "cover",
                        }}
                    >
                        <div className="mask d-flex justify-content-center align-items-center">
                            <div className="container">
                                <div
                                    className="row wow fadeIn"
                                    style={{visibility: "visible", animationName: "fadeIn"}}
                                >
                                    <div
                                        className="col-lg-6 mb-4 white-text text-center d-flex align-items-center justify-content-center justify-content-lg-start">
                                        <h1 className="fees-main text-uppercase">{t('fees.fees_title')}</h1>
                                    </div>
                                    <div className="col-lg-6 col-xl-5">
                                        <img
                                            src="/img/main-fees.png"
                                            className="img-fluid home-first-img use-img mx-auto"
                                            alt="Fees"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <main className="text-center py-5 mt-3">
                    <div className="container">
                        <section>
                            <div className='fees_title'>
                                Dedicated vIBANs providing fees
                            </div>
                        </section>
                        <section className="fees-card-block">
                            <div className="fees-card">
                                <div className="fees-card-title">
                                    Low Risk
                                </div>
                                <div className="fees-card-subtitle">
                                    Simplified due Diligence
                                </div>
                                <div className="fees-card-text">
                                    Simplified due diligence is the lowest level of verification. We use Simplified due
                                    Diligence when there is little chance or risk that customers engage in money
                                    laundering or terrorist financing.
                                </div>
                                <div className="fees-line"></div>
                                <div className="d-flex justify-content-between"
                                     style={{width: "100%", marginBottom: "30px"}}>
                                    <div>
                                        <div className="fees-iban">IBAN provision fee</div>
                                        <div className="fees-price-iban">€250</div>
                                    </div>
                                    <div>
                                        <div className="fees-compliance">Compliance fee</div>
                                        <div className="fees-price-compliance">€300</div>
                                    </div>
                                </div>
                                <div>
                                    <button className="my-btn" onClick={() => navigate("/home/risk-rate")}>learn more</button>
                                </div>
                            </div>
                            <div className="fees-card">
                                <div className="fees-card-title">
                                    Medium Risk
                                </div>
                                <div className="fees-card-subtitle">
                                    Customer due Diligence
                                </div>
                                <div className="fees-card-text">
                                    Customer due diligence is a kind of basic analysis of the client. The company
                                    verifies a customer’s identity to a sufficient level of confidence.
                                </div>
                                <div className="fees-line"></div>
                                <div className="d-flex justify-content-between"
                                     style={{width: "100%", marginBottom: "30px"}}>
                                    <div>
                                        <div className="fees-iban">IBAN provision fee</div>
                                        <div className="fees-price-iban">€500</div>
                                    </div>
                                    <div>
                                        <div className="fees-compliance">Compliance fee</div>
                                        <div className="fees-price-compliance">€300</div>
                                    </div>
                                </div>
                                <div>
                                    <button className="my-btn" onClick={() => navigate("/home/risk-rate")}>learn more</button>
                                </div>
                            </div>
                            <div className="fees-card">
                                <div className="fees-card-title">
                                    High Risk
                                </div>
                                <div className="fees-card-subtitle">
                                    Enhanced due Diligence
                                </div>
                                <div className="fees-card-text">
                                    Enhanced due Diligence means increased control over customers who are more likely to
                                    participate in financial crimes due to their nature of business or operations.
                                </div>
                                <div className="fees-line"></div>
                                <div className="d-flex justify-content-between"
                                     style={{width: "100%", marginBottom: "30px"}}>
                                    <div>
                                        <div className="fees-iban">IBAN provision fee</div>
                                        <div className="fees-price-iban">€750</div>
                                    </div>
                                    <div>
                                        <div className="fees-compliance">Compliance fee</div>
                                        <div className="fees-price-compliance">€300</div>
                                    </div>
                                </div>
                                <div>
                                    <button className="my-btn" onClick={() => navigate("/home/risk-rate")}>learn more</button>
                                </div>
                            </div>
                        </section>
                        <section
                            className="mt-5 mb-5 wow fadeIn"
                            style={{visibility: "visible", animationName: "fadeIn"}}
                        >
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <table className="table-fees">
                                        <thead>
                                        <tr>
                                            <th>{t('fees.fees_table_1_row_1')}</th>
                                            <th>
                                                {t('fees.fees_table_1_row_2')}<br/>
                                                {t('fees.fees_table_1_row_2_br')}
                                            </th>
                                            <th>
                                                {t('fees.fees_table_1_row_3')} <br/>
                                                {t('fees.fees_table_1_row_3_br')}
                                            </th>
                                            <th>
                                                {t('fees.fees_table_1_row_4')} <br/>
                                                {t('fees.fees_table_1_row_4_br')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>10 000 - 100 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>3%</td>
                                        </tr>
                                        <tr>
                                            <th>100 000 - 500 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>2.75%</td>
                                        </tr>
                                        <tr>
                                            <th>500 000 - 1 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>2.5%</td>
                                        </tr>
                                        <tr>
                                            <th>1 000 000 - 2 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>2.25%</td>
                                        </tr>
                                        <tr>
                                            <th>2 000 000 - 5 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>2%</td>
                                        </tr>
                                        <tr>
                                            <th>5 000 000 - 10 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>1.75%</td>
                                        </tr>
                                        <tr>
                                            <th>10 000 000 - 20 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>1.5%</td>
                                        </tr>
                                        <tr>
                                            <th>20 000 000 - 50 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>1.25%</td>
                                        </tr>
                                        <tr>
                                            <th>50 000 000 - 100 000 000 EUR</th>
                                            <td>0.2%</td>
                                            <td>FREE</td>
                                            <td>1%</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p className="mt-2 d-sm-none">
                                        Fee per Crypto Deposit or Withdrawal (OERO only):{" "}
                                        <span className="font-weight-bold">free</span>
                                    </p>
                                </div>
                            </div>
                            {this.state.cryptoRates[0].eurExcange ? "" : <OeroLoader/>}
                            <div
                                className="row"
                                style={{
                                    marginTop: "50px",
                                    display: this.state.cryptoRates[0].eurExcange
                                        ? "block"
                                        : "none",
                                }}
                            >
                                <div className="col-md-12 mb-4 scrollable">
                                    <table className="table-fees table-crypto">
                                        <thead>
                                        <tr>
                                            <th>{t('fees.fees_table_2_row_1')}</th>
                                            <th colSpan={2}>{t('fees.fees_table_2_row_2')}</th>
                                            <th>24h %</th>
                                            <th>7d %</th>
                                            <th>{t('fees.fees_table_2_row_5')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <NameContainer>
                                                    <CryptoIcon src={OeroIcon}/>
                                                    <span>Oero</span>{" "}
                                                    <CryptoShortName>OEUR</CryptoShortName>
                                                </NameContainer>
                                            </th>
                                            <td>1 OEUR</td>
                                            <td>
                                                {parseFloat(
                                                    this.state.cryptoRates[0].eurExcange
                                                ).toFixed(2)}{" "}
                                                USD
                                            </td>

                                            <td style={{textAlign: "center"}}>-</td>
                                            <td style={{textAlign: "center"}}>-</td>
                                            <td>
                                                <canvas
                                                    id={`canvas_oero`}
                                                    width={300}
                                                    height={75}
                                                    style={{margin: "0 auto"}}
                                                />
                                            </td>
                                        </tr>
                                        {this.state.cryptoRates.map((val, index) => {
                                            return (
                                                <tr key={`fees_${index}`}>
                                                    <th>
                                                        <NameContainer>
                                                            <CryptoIcon
                                                                src={
                                                                    val.name
                                                                        ? require(`../../../assets/${val.name}-icon.svg`)
                                                                        : ""
                                                                }
                                                                alt=""
                                                            />
                                                            <span>{val.fullName}</span>
                                                            <CryptoShortName>{val.name}</CryptoShortName>
                                                        </NameContainer>
                                                    </th>
                                                    <td>{val.currentPriceEUR} OEUR</td>
                                                    <td>{val.currentPriceUSD} USD</td>
                                                    <td>
                                                        <StyledPercent isPositive={val.dayChange > 0}>
                                                            {val.dayChange}%
                                                        </StyledPercent>
                                                    </td>
                                                    <td>
                                                        <StyledPercent isPositive={val.weekChange > 0}>
                                                            {val.weekChange}%
                                                        </StyledPercent>
                                                    </td>
                                                    <td>
                                                        <canvas
                                                            id={`canvas_${index}`}
                                                            width={300}
                                                            height={75}
                                                            style={{margin: "0 auto"}}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section style={{backgroundColor: "#eef8fb"}}>
                        <div className="container">
                            <div
                                className="pt-5 pb-5 row wow fadeIn"
                                style={{visibility: "visible", animationName: "fadeIn"}}
                            >
                                <div className="col-md-12 px-4 d-flex align-items-center">
                                    <p className="text-left d-md-block font-weight-bold">
                                        {t('fees.fees_notes_1')}
                                        <br/>
                                        <br/>
                                        {t('fees.fees_notes_2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <section
                            className="mt-5 mb-5 wow fadeIn"
                            style={{visibility: "visible", animationName: "fadeIn"}}
                        >
                            <div className="row">
                                <div className="col-md-12 mb-4 scrollable">
                                    <DataTable className="table-fees">
                                        <thead>
                                        <tr>
                                            <th rowSpan={2} style={{width: "260px"}}>
                                                {t('fees.bank')}
                                            </th>
                                            <th colSpan={4}>{t('fees.deposit')}</th>
                                            <th colSpan={4}>{t('fees.withdrawal')}</th>
                                        </tr>
                                        <tr>
                                            <th>{t('fees.Fiat')}</th>
                                            <th>{t('fees.Method')}</th>
                                            <th>{t('fees.MIN_per_transaction')}</th>
                                            <th>{t('fees.MAX_per_transaction')}</th>
                                            <th>{t('fees.Fiat')}</th>
                                            <th>{t('fees.Method')}</th>
                                            <th>{t('fees.MIN_per_transaction')}</th>
                                            <th>{t('fees.MAX_per_transaction')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>Clear Junction</th>
                                            <td>EUR</td>
                                            <td>SEPA</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>EUR</td>
                                            <td>SEPA</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        <tr>
                                            <th>Signature Bank (Clear Junction)</th>
                                            <td>USD</td>
                                            <td>Wire/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>USD</td>
                                            <td>Wire/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        <tr>
                                            <th>GlobalNetint</th>
                                            <td>EUR</td>
                                            <td>SEPA/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>EUR</td>
                                            <td>SEPA/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        <tr>
                                            <th>Safe Payments Solutions</th>
                                            <td>EUR</td>
                                            <td>SEPA</td>
                                            <td>500</td>
                                            <td>300 000</td>
                                            <td>EUR</td>
                                            <td>SEPA</td>
                                            <td>500</td>
                                            <td>250 000</td>
                                        </tr>
                                        <tr>
                                            <th>FV Bank</th>
                                            <td>USD</td>
                                            <td>Wire/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>USD</td>
                                            <td>Wire/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        <tr>
                                            <th>Prime Trust</th>
                                            <td>USD</td>
                                            <td>Wire/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>USD</td>
                                            <td>Wire/SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        <tr>
                                            <th>Prime Trust Network</th>
                                            <td>USD</td>
                                            <td>Prime-X</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>USD</td>
                                            <td>Prime-X</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        <tr>
                                            <th>GlobalNetint</th>
                                            <td>USD</td>
                                            <td>SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                            <td>USD</td>
                                            <td>SWIFT</td>
                                            <td>500</td>
                                            <td>10 000 000</td>
                                        </tr>
                                        </tbody>
                                    </DataTable>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        );
    }
}

export default withTranslation()(Fees)
