import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { HeadCell, Grid } from "../../../../core/ui/grids/Grid";
import { PageWrap } from "../../components/common/Pages";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { BlogPostCard } from "./BlogPostCard";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import {
  BlogPost,
  BlogPostStatus,
  FileReason,
  MutationChangeBlogPostStatusArgs,
} from "../../../../store/generated-models";
import { formatDate } from "../../helpers/Format";
import { useSnackbar } from "notistack";
import { RestApiContext } from "../../../../core/providers/RestApiProvider";

const ITEM_PER_PAGE: number = 20;

const IconActionDisable = styled(PauseCircleFilledIcon)`
  fill: ${(props) => props.theme.custom.palette.alert};
`;

const IconActionEnable = styled(PlayCircleFilledIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

const Row = styled.div`
  display: flex;
`;

const LoadWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const gqlGetBlogPosts = gql`
  query getBlogPosts($first: Int, $skip: Int, $filter: String) {
    getBlogPosts(
      first: $first
      orderBy: "created"
      desc: true
      skip: $skip
      filter: $filter
    ) {
      count
      posts {
        postId
        status
        created
        published
        title
        shortText
        text
        fileUrl
        files {
          fileId
          fileName
          url
        }
      }
    }
  }
`;

// const gqlAddBlogPost = gql`
//     mutation addBlogPost (
//         $commentAllowed: Boolean!,
//         $status: BlogPostStatus,
//         $published: DateTime,
//         $tags: String,
//         $text: String!,
//         $title: String!,
//         $shortText: String!,
//         $files: [UploadParams!]
//     ) {
//         addBlogPost (
//             commentAllowed: $commentAllowed,
//             status: $status,
//             published: $published,
//             tags: $tags,
//             text: $text,
//             title: $title,
//             shortText: $shortText,
//             files: $files,
//         ) {
//             postId
//             status
//             created
//             published
//             title
//             shortText
//             text
//             fileUrl
//             files {
//                 fileId
//                 fileName
//                 url
//             }
//         }
//     }
// `;

// const gqlUpdateBlogPost = gql`
//     mutation updateBlogPost (
//         $postId: ID!
//         $status: BlogPostStatus!,
//         $tags: String,
//         $text: String!,
//         $title: String!
//         $published: DateTime,
//         $shortText: String!,
//         $files: [UploadParams!]
//     ) {
//         updateBlogPost (
//             postId: $postId,
//             status: $status,
//             tags: $tags,
//             text: $text,
//             title: $title,
//             published: $published,
//             shortText: $shortText,
//             files: $files,
//         ) {
//             postId
//             status
//             created
//             published
//             title
//             shortText
//             text
//             fileUrl
//             files {
//                 fileId
//                 fileName
//                 url
//             }
//         }
//     }
// `;

const gqlChangeBlogPostStatus = gql`
  mutation changeBlogPostStatus($postId: ID!, $status: BlogPostStatus!) {
    changeBlogPostStatus(postId: $postId, status: $status) {
      postId
      status
      created
      published
      title
      shortText
      text
      fileUrl
      files {
        fileId
        fileName
        url
      }
    }
  }
`;

const headCells: HeadCell[] = [
  { id: "title", label: "Title" },
  { id: "date", label: "Date" },
  { id: "status", label: "Status" },
];

export const BlogPosts: React.FC<RouteComponentProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const restContext = useContext(RestApiContext);

  const [mode, setMode] = useState("");
  const [item, setItem] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [filter, setFilter] = useState("");

  const { loading, error, data, refetch } = useQuery(gqlGetBlogPosts, {
    variables: {
      first: ITEM_PER_PAGE,
      skip: (page - 1) * ITEM_PER_PAGE,
      filter: filter,
    },
  });

  // const [addBlogPost, {
  // 	data: addBlogPostData,
  // 	loading: addBlogPostLoading
  // }] = useMutation<{ addBlogPost: BlogPost }, MutationAddBlogPostArgs>(
  // 	gqlAddBlogPost
  // );

  // const [updateBlogPost, {
  // 	data: updateBlogPostData,
  // 	loading: updateBlogPostLoading
  // }] = useMutation<{ updateBlogPost: BlogPost }, MutationUpdateBlogPostArgs>(
  // 	gqlUpdateBlogPost
  // );

  const [changeBlogPostStatus] = useMutation<
    { changeBlogPostStatus: BlogPost },
    MutationChangeBlogPostStatusArgs
  >(gqlChangeBlogPostStatus);

  useEffect(() => {
    refetch().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) console.warn(">> Users useEffect error", error);
  }, [error]);

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.getBlogPosts &&
      data.getBlogPosts.count !== undefined
    ) {
      const totalCnt = data.getBlogPosts.count;
      setPageCnt(
        Math.floor(totalCnt / ITEM_PER_PAGE) +
          (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0)
      );

      if (data.getBlogPosts.posts) {
        setItems(
          data.getBlogPosts.posts.map((el: any) => {
            const active = el.status === BlogPostStatus.Enabled;
            return {
              ...el,
              ...{ date: formatDate(el.published) },
              ...{ active: active },
              ...{ _actions: active ? ["disable"] : ["enable"] },
            };
          })
        );
      } else {
        setItems([]);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refetch().then();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChangeHandler = (event: any, value: number) => {
    setPage(value);
  };

  const addItemHandler = () => {
    setItem(null);
    setMode("card");
  };

  const editItemHandler = (item: any) => {
    setItem(item);
    setMode("card");
  };

  // const itemSaveHandler = async (item: any) => {
  //
  // 	if (item.postId) {
  // 		// edit item
  // 		let errMessage = '';
  // 		try {
  // 			let variables: any = {
  // 				postId: item.postId,
  // 				text: item.text,
  // 				title: item.title,
  // 				shortText: item.shortText,
  // 				published: item.published,
  // 				status: item.active ? BlogPostStatus.Enabled : BlogPostStatus.Disabled  // 'Disabled' // Enabled,
  // 			};
  //
  // 			if (item._file && item._file.info) {
  // 				variables.files = [
  // 					{
  // 						reason: FileReason.Blog,
  // 						upload: item._file.info
  // 					}
  // 				];
  // 			}
  //
  // 			const res = await updateBlogPost({variables: variables});
  // 			if (res.data.updateBlogPost.postId) {
  // 				refetch().then();
  // 				setMode('');
  // 				return;
  // 			}
  // 		} catch (error: any) {
  // 			try {
  // 				const errorCode = error.graphQLErrors[0].extensions.code;
  // 				if (errorCode === 'auth.access_denied') {
  // 					errMessage = 'Invalid credentials';
  // 				}
  // 			} catch (ignored) {
  // 			}
  // 		}
  //
  // 		if (!errMessage) errMessage = 'Unknown error';
  // 	} else {
  // 		// add item
  // 		let errMessage = '';
  // 		try {
  // 			let variables: any = {
  // 				commentAllowed: true,
  // 				text: item.text,
  // 				title: item.title,
  // 				shortText: item.shortText,
  // 				published: item.published,
  // 				status: item.active ? BlogPostStatus.Enabled : BlogPostStatus.Disabled,  // 'Disabled' // Enabled,
  // 				// tags: $tags,
  // 			};
  //
  // 			if (item._file && item._file.info) {
  // 				variables.files = [
  // 					{
  // 						reason: FileReason.Blog,
  // 						upload: item._file.info
  // 					}
  // 				];
  // 			}
  //
  // 			const res = await addBlogPost({
  // 				variables: variables
  // 			});
  // 			if (res.data.addBlogPost.postId) {
  // 				refetch().then();
  // 				setMode('');
  // 				return;
  // 			}
  // 		} catch (error: any) {
  // 			try {
  // 				const errorCode = error.graphQLErrors[0].extensions.code;
  // 				if (errorCode === 'auth.access_denied') {
  // 					errMessage = 'Invalid credentials';
  // 				}
  // 			} catch (ignored) {
  // 			}
  // 		}
  //
  // 		if (!errMessage) errMessage = 'Unknown error';
  // 		console.log('itemSaveHandler errMessage:', errMessage);
  // 	}
  // };

  const uploadAdd = (item: any) => {
    let formData = new FormData();
    formData.append("commentAllowed", "true");
    formData.append("text", item.text);
    formData.append("title", item.title);
    formData.append("shortText", item.shortText);
    formData.append("published", item.published);
    formData.append(
      "status",
      item.active ? BlogPostStatus.Enabled : BlogPostStatus.Disabled
    );
    formData.append("reason", FileReason.Blog);
    formData.append("file", item._file.info);
    formData.append("fileData", JSON.stringify({ type: FileReason.Blog }));
    return restContext.upload("addBlogPost", formData);
  };

  const uploadEdit = (item: any) => {
    let formData = new FormData();
    formData.append("postId", item.postId);
    formData.append("text", item.text);
    formData.append("title", item.title);
    formData.append("shortText", item.shortText);
    formData.append("published", item.published);
    formData.append(
      "status",
      item.active ? BlogPostStatus.Enabled : BlogPostStatus.Disabled
    );
    formData.append("reason", FileReason.Blog);
    formData.append("file", item._file.info);
    formData.append("fileData", JSON.stringify({ type: FileReason.Blog }));
    return restContext.upload("updateBlogPost", formData);
  };

  const itemSaveHandler = async (item: any) => {
    if (item.postId) {
      // edit item
      let errMessage = "";
      try {
        const uploadRes = await uploadEdit(item);
        if (uploadRes && uploadRes.status && uploadRes.status === 200) {
          refetch().then();
          setMode("");
          enqueueSnackbar("Post is updated", { variant: "success" });
          return;
        } else {
          errMessage = "Process error. Please try again later";
        }
      } catch (error: any) {
        try {
          const errorCode = error.graphQLErrors[0].extensions.code;
          if (errorCode === "auth.access_denied") errMessage = "Access denied";
          if (!errMessage && error.graphQLErrors[0].message)
            errMessage = error.graphQLErrors[0].message;
        } catch (ignored) {}
      }

      if (!errMessage) errMessage = "Unknown error";
      enqueueSnackbar(errMessage, { variant: "error" });
      return;
    } else {
      // add item
      let errMessage = "";
      try {
        const uploadRes = await uploadAdd(item);
        if (uploadRes && uploadRes.status && uploadRes.status === 200) {
          refetch().then();
          setMode("");
          enqueueSnackbar("Post is added", { variant: "success" });
          return;
        } else {
          errMessage = "Process error. Please try again later";
        }
      } catch (error: any) {
        try {
          const errorCode = error.graphQLErrors[0].extensions.code;
          if (errorCode === "auth.access_denied") errMessage = "Access denied";
          if (!errMessage && error.graphQLErrors[0].message)
            errMessage = error.graphQLErrors[0].message;
        } catch (ignored) {}
      }

      if (!errMessage) errMessage = "Unknown error";
      enqueueSnackbar(errMessage, { variant: "error" });
      return;
    }
  };

  const processChangeBlogPostStatus = async (item: any, status: string) => {
    let errMessage = "";
    try {
      let variables: any = {
        postId: item.postId,
        status:
          status === "enable"
            ? BlogPostStatus.Enabled
            : BlogPostStatus.Disabled,
      };

      const res = await changeBlogPostStatus({ variables: variables });
      if (res.data.changeBlogPostStatus.postId) {
        refetch().then();
        setMode("");
        return;
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const statusDisableHandler = (item: any) => {
    processChangeBlogPostStatus(item, "disable").then();
  };

  const statusEnableHandler = (item: any) => {
    processChangeBlogPostStatus(item, "enable").then();
  };

  const searchHandler = (val: string) => {
    if (loading) return;

    setPage(1);
    setFilter(val);
  };

  if (!data && loading) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  if (mode === "card")
    return (
      <PageWrap>
        <BlogPostCard
          item={item}
          onClose={() => setMode("")}
          onSave={itemSaveHandler}
          // inProcess={addBlogPostLoading}
        />
      </PageWrap>
    );

  return (
    <PageWrap>
      <Row style={{ marginBottom: "12px" }}>
        <Typography variant={"h3"}>Blog Posts</Typography>
      </Row>
      <Grid
        headCells={headCells}
        items={items}
        idField="postId"
        onAddItem={addItemHandler}
        addLabel="ADD POST"
        page={page}
        pageCnt={pageCnt}
        onPageChange={onPageChangeHandler}
        actions={[{ id: "edit", onAction: editItemHandler }]}
        availableActions={[
          {
            id: "disable",
            type: "icon",
            icon: <IconActionDisable />,
            onAction: statusDisableHandler,
          },
          {
            id: "enable",
            type: "icon",
            icon: <IconActionEnable />,
            onAction: statusEnableHandler,
          },
        ]}
        extSearch
        extSearchValue={filter}
        onSearchApply={searchHandler}
      />
    </PageWrap>
  );
};
