import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CurrencyInput } from "../../components/common/CurrencyInput";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  BankTransactionShortResult,
  EPaymentType,
  MutationConfirmDepositEPaymentArgs,
  MutationDepositEPaymentArgs,
} from "../../../../store/generated-models";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import { Dropdown } from "../../components/common/Dropdown";
import { Advcash } from "./payments/Advcash";
import { Payeer } from "./payments/Payeer";
import { Epay } from "./payments/Epay";
import { gqlGetSupportedCurrenciesWithRates, LoadWrap } from "./Deposit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { round, OERO_PRECISION } from "../../../../core/helpers/Currencies";
import { PayPal } from "./payments/PayPal";
import {t} from "i18next";

const WrapText = styled(Typography)`
  overflow-wrap: break-word;
`;

const Row = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const gqlDepositEPayment = gql`
  mutation depositEPayment(
    $comment: String
    $immutableAmount: Int!
    $incomeAmount: Float!
    $incomeCurrency: String!
    $oeroAmount: Float!
    $paymentType: EPaymentType
  ) {
    depositEPayment(
      comment: $comment
      immutableAmount: $immutableAmount
      incomeAmount: $incomeAmount
      incomeCurrency: $incomeCurrency
      oeroAmount: $oeroAmount
      paymentType: $paymentType
    ) {
      id
      transaction {
        address
        addressService
        amount
        bankTransactionId
        beneficiaryAccountAddress
        beneficiaryAccountCountry
        beneficiaryAccountName
        beneficiaryAccountPostalCity
        beneficiaryAccountPostalCode
        beneficiaryBankAddress
        beneficiaryBankCountry
        beneficiaryBankName
        beneficiaryBankPostalCity
        beneficiaryBankPostalCode
        comment
        companyName
        created
        currency
        description
        fineAmount
        firstUserName
        iban
        ibanAccountNumber
        intermediaryBankRoutingNumberBic
        lastUserName
        oero
        oeroExchangeRate
        ownerName
        paymentDetails
        paymentId
        paymentResult
        processingStatus
        processingStatusDescription
        swiftBic
        transactionCode
        transactionHash
        transactionNumber
        transactionType
        updateStatusUserId
        updated
        # user
        wallet
      }
    }
  }
`;

const gqlConfirmDepositEPayment = gql`
  mutation confirmDepositEPayment($id: String) {
    confirmDepositEPayment(id: $id) {
      id
      transaction {
        address
        addressService
        amount
        bankTransactionId
        beneficiaryAccountAddress
        beneficiaryAccountCountry
        beneficiaryAccountName
        beneficiaryAccountPostalCity
        beneficiaryAccountPostalCode
        beneficiaryBankAddress
        beneficiaryBankCountry
        beneficiaryBankName
        beneficiaryBankPostalCity
        beneficiaryBankPostalCode
        comment
        companyName
        created
        currency
        description
        fineAmount
        firstUserName
        iban
        ibanAccountNumber
        intermediaryBankRoutingNumberBic
        lastUserName
        oero
        oeroExchangeRate
        ownerName
        paymentDetails
        paymentId
        paymentResult
        processingStatus
        processingStatusDescription
        swiftBic
        transactionCode
        transactionHash
        transactionNumber
        transactionType
        updateStatusUserId
        updated
        # user
        wallet
      }
    }
  }
`;

const PAY_SYS = Object.keys(EPaymentType);

const DEF_CURR_NAME = "EUR";

const initFields = {
  fromAmount: "",
  toAmount: "",
  fromCurrency: DEF_CURR_NAME,
  toCurrency: "OERO",
  comment: "",
  payerAccount: "",
};

const defaultCurrency = {
  minAmount: 0,
  name: DEF_CURR_NAME,
  depositRate: 0,
};

interface ComponentProps extends RouteComponentProps {
  // curData: any;
}

export const EPayments: React.FC<ComponentProps> = () => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [paySys, setPaySys] = useState<EPaymentType>(EPaymentType.Epay);
  const [status, setStatus] = useState(""); // confirm

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});

  const [fromAmountValue, setFromAmountValue] = useState(0);
  const [toAmountValue, setToAmountValue] = useState(0);
  const [currencies, setCurrencies] = useState<any[]>([{ id: DEF_CURR_NAME }]);
  const [immutableAmount, setImmutableAmount] = useState(0);

  const [txId, setTxId] = useState("");
  const [txInfo, setTxInfo] = useState<any>({});
  const [payDetails, setPayDetails] = useState<any>({});
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(defaultCurrency);

  const submitRef = React.useRef();

  const { data, error, loading } = useQuery(gqlGetSupportedCurrenciesWithRates);

  const getSelectedCurrency = (cur: string) => {
    // ! DON'T REMOVE: get currencies from selected payment system setting
    // let selCur = data.getSupportedCurrenciesWithRates[paySys.toLowerCase()].find((el: any) => el.id === cur);
    let selCur = data.getSupportedCurrenciesWithRates.fiat.find(
      (el: any) => el.id === cur
    );
    if (!selCur) selCur = defaultCurrency;
    return selCur;
  };

  const [depositEPayment, { loading: depositEPaymentLoading }] = useMutation<
    {
      depositEPayment: BankTransactionShortResult;
    },
    MutationDepositEPaymentArgs
  >(gqlDepositEPayment);

  const [confirmDepositEPayment, { loading: confirmDepositEPaymentLoading }] =
    useMutation<
      {
        confirmDepositEPayment: BankTransactionShortResult;
      },
      MutationConfirmDepositEPaymentArgs
    >(gqlConfirmDepositEPayment);

  const setInitial = () => {
    setFields({ ...initFields });
    setStatus("");
    setFromAmountValue(0);
    setToAmountValue(0);
    setImmutableAmount(0);
    setTxId("");
    setTxInfo({});
    // setRate(getRate(DEF_CURR_NAME));
    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  };

  const submitDepositEPayment = async () => {
    let err: any = {};
    if (!fromAmountValue) err.fromAmount = "required";
    if (!toAmountValue) err.toAmount = "required";
    // if (paySys === EPaymentType.Epay && !fields.payerAccount) err.payerAccount = 'required';

    if (
      fromAmountValue &&
      fields.fromCurrency &&
      authContext.settings &&
      authContext.settings.restrictMinAmounts &&
      fromAmountValue < selectedCurrency.minAmount
    ) {
      err.fromAmount = `minimum ${selectedCurrency.minAmount} ${selectedCurrency.name}`;
    }

    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar("Correct errors to continue", { variant: "error" });
      return;
    }

    const coef =
      fields.fromCurrency === "USD" || fields.fromCurrency === "EUR"
        ? 100
        : 100000;
    const roundedAmount = Math.round(fromAmountValue * coef) / coef;

    let errMessage = "";
    try {
      const res = await depositEPayment({
        variables: {
          comment: fields.comment,
          immutableAmount: immutableAmount,
          incomeAmount: roundedAmount,
          incomeCurrency: fields.fromCurrency,
          oeroAmount: toAmountValue,
          paymentType: paySys,
        },
      });
      if (
        res.data.depositEPayment.id &&
        res.data.depositEPayment.transaction.paymentDetails
      ) {
        if (res.data.depositEPayment.transaction.paymentDetails) {
          setPayDetails(
            JSON.parse(res.data.depositEPayment.transaction.paymentDetails)
          );
        }

        setTxId(res.data.depositEPayment.id);
        setTxInfo(res.data.depositEPayment.transaction);
        setStatus("confirm");
        return;
      } else {
        console.warn(">> submitDepositEPayment not OK, res:", res);
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "core.over_transaction_limit_per_day")
          errMessage = error.graphQLErrors[0].message;
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  const clickSubmit = () => {
    setTimeout(() => {
      if (paySys !== EPaymentType.PayPal) {
        let element: HTMLElement = submitRef.current as HTMLElement;
        element.click();
      }

      setStatus("done");
    }, 0);
  };

  const submitConfirmDepositEPayment = async () => {
    let errMessage = "";
    try {
      const res = await confirmDepositEPayment({
        variables: {
          id: txId,
        },
      });
      if (res.data.confirmDepositEPayment.transaction.transactionCode) {
        setTxInfo(res.data.confirmDepositEPayment.transaction);

        // if (res.data.confirmDepositEPayment.transaction.paymentDetails) {
        // 	setPayDetails(JSON.parse(res.data.confirmDepositEPayment.transaction.paymentDetails));
        // }

        clickSubmit();

        return;
      } else {
        console.warn(">> submitConfirmDepositEPayment not OK, res:", res);
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  useEffect(() => {
    if (!data || !data.getSupportedCurrenciesWithRates) return;

    // ! DON'T REMOVE: get currencies from selected payment system setting
    // setCurrencies(data.getSupportedCurrenciesWithRates[paySys.toLowerCase()].map((el: any) => {
    // 	return {
    // 				id: el.id,
    // 				value: el.name
    // 			}
    // }));
    setCurrencies(
      data.getSupportedCurrenciesWithRates.fiat.map((el: any) => {
        return {
          id: el.id,
          value: el.name,
        };
      })
    );

    setSelectedCurrency(getSelectedCurrency(DEF_CURR_NAME));
  }, [data, paySys]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // let isValid = true;

    // console.log('>>>', fields.fromAmount, '>', fields.toAmount, '>', fields.fromCurrency, '>', fields.toCurrency);

    // parse what we have in the form
    let fromAmt =
      fields.fromAmount === null ? null : parseFloat(fields.fromAmount);
    let toAmt = fields.toAmount === null ? null : parseFloat(fields.toAmount);

    if (fromAmt === null || toAmt === null || !selectedCurrency.depositRate) {
      setFromAmountValue(0);
      setToAmountValue(0);
      return;
    }

    if (!selectedCurrency.depositRate) {
      console.error("Not find rate");
      return;
    }

    // console.log('>>', immutableAmount);

    if (immutableAmount === 0) {
      if (isNaN(fromAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        setFields({ ...fields, toAmount: "" });
        return;
      }

      fromAmt = round(fromAmt, selectedCurrency.precision);
      const calc = round(
        fromAmt * selectedCurrency.depositRate,
        OERO_PRECISION
      );
      setFromAmountValue(fromAmt);
      setToAmountValue(calc);
      setFields({ ...fields, toAmount: calc.toString() });
    } else {
      if (isNaN(toAmt)) {
        setFromAmountValue(0);
        setToAmountValue(0);
        setFields({ ...fields, fromAmount: "" });
        return;
      }

      toAmt = round(toAmt, OERO_PRECISION);
      const calc = round(
        toAmt / selectedCurrency.depositRate,
        selectedCurrency.precision
      );
      setFromAmountValue(calc);
      setToAmountValue(toAmt);
      setFields({ ...fields, fromAmount: calc.toString() });
    }
  }, [fields.fromAmount, fields.toAmount, fields.fromCurrency]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputFromAmountChange = (val: string) => {
    setFields({ ...fields, fromAmount: val });
    setImmutableAmount(0);
  };

  const handleInputToAmountChange = (val: string) => {
    setFields({ ...fields, toAmount: val });
    setImmutableAmount(1);
  };

  const handleFromCurrencyChange = (val: string) => {
    setSelectedCurrency(getSelectedCurrency(val));
    setFields({ ...fields, fromCurrency: val });
    setImmutableAmount(0);
  };

  if (
    !data ||
    loading ||
    depositEPaymentLoading ||
    confirmDepositEPaymentLoading
  ) {
    return (
      <LoadWrap>
        <CircularProgress />
      </LoadWrap>
    );
  }

  if (error) {
    return <LoadWrap>Internal server error. Try again later</LoadWrap>;
  }
  // RENDER CONFIRM STEP
  if (status === "confirm" || status === "done") {
    console.log(txInfo, paySys);
    return (
      <>
        {status === "confirm" && (
          <Grid container spacing={2} style={{ marginTop: "24px" }}>
            <Grid item xs={1}>
              <Typography variant={"body1"}>{t('user.deposit.payment_id')}</Typography>
            </Grid>
            <Grid item xs={11}>
              <WrapText variant={"body1"} style={{ fontWeight: "bold" }}>
                {`${txInfo.paymentId}`.toUpperCase()}
              </WrapText>
            </Grid>
            <Grid item xs={1}>
              <Typography variant={"body1"}>{t('user.deposit.amount')}</Typography>
            </Grid>
            <Grid item xs={11}>
              <WrapText variant={"body1"} style={{ fontWeight: "bold" }}>
                {`${txInfo.amount} ${txInfo.currency}`.toUpperCase()}
              </WrapText>
            </Grid>
            {txInfo.comment && (
              <>
                <Grid item xs={7}>
                  <Typography variant={"body1"}>{t('user.deposit.comment_2')}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <WrapText variant={"body1"}>{txInfo.comment}</WrapText>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {paySys === EPaymentType.Advcash && status === "confirm" && (
          <Advcash
            submitRef={submitRef}
            // action="https://wallet.advcash.com/sci/"
            action={payDetails.url}
            fields={{
              ac_account_email: payDetails.payeeAccount,
              ac_sci_name: payDetails.sciName,
              ac_amount: txInfo.amount,
              ac_currency: txInfo.currency,
              ac_order_id: txInfo.paymentId,
              ac_sign: payDetails.sign,
              ac_success_url: payDetails.successCallback,
              ac_success_url_method: "GET",
              ac_fail_url: payDetails.failCallback,
              ac_fail_url_method: "GET",
              ac_status_url: payDetails.statusCallback,
              ac_status_url_method: "GET",
              ac_comments: txInfo.comment,
            }}
          />
        )}
        {paySys === EPaymentType.Payeer && status === "confirm" && (
          <Payeer
            submitRef={submitRef}
            // action="https://payeer.com/merchant/"
            action={payDetails.url}
            fields={{
              m_shop: payDetails.payeeAccount,
              m_orderid: txInfo.paymentId,
              m_amount: txInfo.amount,
              m_curr: txInfo.currency,
              m_desc: txInfo.comment,
              m_sign: payDetails.sign,
            }}
          />
        )}
        {paySys === EPaymentType.Epay && status === "confirm" && (
          <Epay
            submitRef={submitRef}
            // action="https://api.epay.com/paymentApi/merReceive"
            action={payDetails.url}
            fields={{
              PAYMENT_AMOUNT: txInfo.amount,
              PAYMENT_UNITS: txInfo.currency,
              PAYMENT_ID: txInfo.paymentId,
              STATUS_URL: payDetails.statusCallback,
              PAYMENT_URL: payDetails.successCallback,
              NOPAYMENT_URL: payDetails.failCallback,
              V2_HASH: payDetails.sign,
              PAYEE_ACCOUNT: payDetails.payeeAccount,
              PAYEE_NAME: "OERO",
              SUGGESTED_MEMO: txInfo.comment,
              PAYMENT_URL_METHOD: "get",
              NOPAYMENT_URL_METHOD: "get",
              // PAYER_ACCOUNT: payDetails.payerAccount,
              // CHARACTER_ENCODING: 'UTF-8',
              // SUGGESTED_MEMO: txInfo.comment,
              // FORCED_PAYEE_ACCOUNT: payDetails.payeeAccount,
              // --
              // BATCH_NUM: '698558',
              // INTERFACE_LANGUAGE: 'ZH_CN',
              // BAGGAGE_FIELDS: 'KEY_CODE BATCH_NUM',
              // KEY_CODE: '145698',
            }}
          />
        )}
        {status === "done" && (
          <Row style={{ marginTop: "20px" }}>
            <Typography variant={"h4"}>
              {t('user.deposit.transaction_confirmed')} {txInfo.transactionCode}
            </Typography>
          </Row>
        )}
        <Row style={{ marginTop: "24px" }}>
          {paySys === EPaymentType.PayPal && (
            <PayPal
              onClick={() => submitConfirmDepositEPayment()}
              onCancel={() => setInitial()}
              submitRef={submitRef}
              // action="https://api.epay.com/paymentApi/merReceive"
              action={payDetails.url}
              fields={{
                amount: txInfo.amount.toFixed(2),
                currency: txInfo.currency,
                paymentId: txInfo.paymentId,
                description: txInfo.paymentId,
                clientId: payDetails.clientId,
                // PAYER_ACCOUNT: payDetails.payerAccount,
                // CHARACTER_ENCODING: 'UTF-8',
                // SUGGESTED_MEMO: txInfo.comment,
                // FORCED_PAYEE_ACCOUNT: payDetails.payeeAccount,
                // --
                // BATCH_NUM: '698558',
                // INTERFACE_LANGUAGE: 'ZH_CN',
                // BAGGAGE_FIELDS: 'KEY_CODE BATCH_NUM',
                // KEY_CODE: '145698',
              }}
            />
          )}
          {(paySys !== EPaymentType.PayPal || status === "done") && (
            <Button
              variant="contained"
              color="primary"
              size={"large"}
              style={{
                minWidth: "120px",
                maxWidth: "240px",
                width: "100%",
                minHeight: "50px",
              }}
              onClick={
                status === "confirm"
                  ? () => submitConfirmDepositEPayment()
                  : () => setInitial()
              }
              disabled={confirmDepositEPaymentLoading}
            >
              {status === "confirm" ? t('user.deposit.confirm') : "OK"}
            </Button>
          )}
          {status === "confirm" ? (
            <Button
              variant="outlined"
              color="primary"
              size={"large"}
              style={{
                minWidth: "120px",
                marginLeft: "20px",
                minHeight: "50px",
              }}
              onClick={() => setInitial()}
            >
              {t('user.deposit.cancel')}
            </Button>
          ) : null}
        </Row>
      </>
    );
  }

  // RENDER DONE STEP
  // if (status === "done") {
  //   return (
  //     <>
  //       <Row style={{ marginTop: "20px" }}>
  //         <Typography variant={"h4"}>
  //           Transaction confirmed, code {txInfo.transactionCode}
  //         </Typography>
  //       </Row>
  //       <Row style={{ marginTop: "24px" }}>
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           size={"large"}
  //           style={{ minWidth: "240px", minHeight: "50px" }}
  //           onClick={() => setInitial()}
  //         >
  //           OK
  //         </Button>
  //       </Row>
  //     </>
  //   );
  // }

  // RENDER INITIAL STEP
  return (
    <>
      <Row style={{ marginBottom: "24px" }}>
        <Dropdown
          style={{ minWidth: "160px", background: "white" }}
          label={t('user.deposit.payment_system')}
          values={PAY_SYS}
          value={paySys}
          onChange={(val) => setPaySys(val as EPaymentType)}
        />
      </Row>

      <Grid container spacing={3} style={{ maxWidth: "1000px" }}>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.fromAmount}
            helperText={errors.fromAmount}
            label={t('user.deposit.from')}
            amount={fields.fromAmount}
            onAmountChange={(val) => handleInputFromAmountChange(val)}
            currency={fields.fromCurrency}
            onCurrencyChange={(val) => handleFromCurrencyChange(val)}
            currencies={currencies}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyInput
            error={!!errors.toAmount}
            helperText={errors.toAmount}
            label={t('user.deposit.to')}
            amount={fields.toAmount}
            onAmountChange={(val) => handleInputToAmountChange(val)}
            currency={fields.toCurrency}
            onCurrencyChange={(val) =>
              setFields({ ...fields, toCurrency: val })
            }
            currencies={[{ id: "OERO" }]}
            fullWidth
            dropdownDisabled
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.comment}
            helperText={errors.comment}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
          	maxRows={6}
            label={t('user.deposit.comment')}
            value={fields.comment}
            //onChange={(event) => setComment(event.target.value)}
            onChange={(e) => setFields({ ...fields, comment: e.target.value })}
          />
        </Grid>
      </Grid>

      <Row style={{ marginTop: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "240px", minHeight: "50px" }}
          onClick={() => submitDepositEPayment()}
        >
          {t('user.deposit.next')}
        </Button>
      </Row>
    </>
  );
};
